import React from 'react'
import HappyClients from '../../images/industry/200-plus-happy-clients.png'
import cloudBased from '../../images/industry/cloud-based-platform.png'

export default function VibtreeBenefits() {
  return (
    <>

    {/* vibtree benifits start  */}

    <h2 className='v-b-s-h2 vib-res-margin-top-100-50 vib-margin-bottom-50-0 vibtree-benefits-padding-bottom vib-blue'>
          Why choose Vibtree for your cloud <br />
          Telephony Solutions?
    </h2>

    <div className="v-b-s-flex-container v-b-s-flex-container-indus-res">
    
      <div className="">
         <div className="">
         <img 
         src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/vibtree-benefits/99.9-percent-uptime.png" 
         alt="99.9-percent-uptime" />
         </div>
         <h6>99.9% Uptime</h6>
      </div>
    
      <div>
         <div className="">
         <img 
         src={HappyClients} 
         alt="remote-operation"  
         />
         </div>
         <h6 style={{marginBottom:'0px', marginTop:'10px'}}>2000+ Happy<br /> Clients</h6>
      </div>
    
      <div>
         <div className="">
         <img 
         src={cloudBased} 
         alt="crm-integration"  
         />
         </div>
         <h6 style={{marginBottom:'0px', marginTop:'10px'}}>Cloud Based<br /> Platform</h6>
      </div>
    
      <div>
         <div className="">
         <img 
         src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/vibtree-benefits/24-7-support.png" 
         alt="crm-integration"  
         />
         </div>
         <h6>24x7 Support</h6>
      </div>
    
      <div>
         <div className="">
         <img 
         src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/vibtree-benefits/flexible-pricing.png" 
         alt="flexible-pricing"  
         />
         </div>
         <h6>Flexible Pricing</h6>
      </div>
    
      <div>
         <div className="">
         <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/vibtree-benefits/cancel-anytime.png" alt="crm-integration" />
         </div>
         <h6>Cancel Anytime</h6>
      </div>
    </div>

    {/* vibtree benifits end  */}
      
    </>
  )
}
