import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Card } from 'react-bootstrap';
import './ivrStudio.css'
import SignUpModel from '../navbar/components/SignUpModel';
import { Helmet } from 'react-helmet';
import { vibContext } from 'src/App';
import LoaderComponent from 'src/LoaderComponent';
import GetStarted from 'src/GetStarted';


export default function IvrStudio() { 

  const [accFirst, setaccFirst] = useState('yes');
  const [accSecond, setaccSecond] = useState('no');
  const [accThird, setaccThird] = useState('no');
  const [accFourth, setaccFourth] = useState('no');
  const [accFive, setaccFive] = useState('no');

  const [modalShow, setModalShow] = useState(false);

  const [ImagesLoadCount, setImagesLoadCount] = useState(0);
  const [loader, setloader] = useState(true);

  const ivrContext= useContext(vibContext);
  const contextId=ivrContext.vibState;
  useEffect(() => {
    const element = document.getElementById(contextId);
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0
      });
    }, 100);
  },[contextId]);

  var handleClick1=()=>{
    setaccSecond('no')
    setaccThird('no')
    setaccFourth('no')
    setaccFive('no')
    return(
      accFirst==='yes' ? setaccFirst('no') : accFirst==='no' ? setaccFirst('yes') : null
    )
  }
  var handleClick2=()=>{
    setaccFirst('no');
    setaccThird('no');
    setaccFourth('no');
    setaccFive('no');
    return(
      accSecond==='yes' ? setaccSecond('no') : accSecond==='no' ? setaccSecond('yes') : null
    )
  }
  var handleClick3=()=>{
    setaccFirst('no');
    setaccSecond('no');
    setaccFourth('no');
    setaccFive('no');
    return(
      accThird==='yes' ? setaccThird('no') : accThird==='no' ? setaccThird('yes') : null
    )
  }
  var handleClick4=()=>{
    setaccFirst('no');
    setaccSecond('no');
    setaccThird('no');
    setaccFive('no');
    return(
      accFourth==='yes' ? setaccFourth('no') : accFourth==='no' ? setaccFourth('yes') : null
    )
  }
  var handleClick5=()=>{
    setaccFirst('no');
    setaccSecond('no');
    setaccThird('no');
    setaccFourth('no');
    return(
      accFive==='yes' ? setaccFive('no') : accFive==='no' ? setaccFive('yes') : null
    )
  }

    // image loading spinner start 
    const imagesLoadIndicator=()=>{
      const images = document.getElementsByClassName('classForLoaded');
  
      setImagesLoadCount(ImagesLoadCount+1);
  
      if (ImagesLoadCount===images.length-1) {
        setloader(false)
      }
  
    }
    // image loading spinner end

  return (
    <div>
    <Helmet>
        <title>Get access to call analytics with Incoming Call tracking software</title>
        {/* <link rel="icon" href={favicon} /> */}
        <meta name="description" content="Design call flows & handle large call volumes easily with this interactive voice response (IVR) builder & route customer calls to the right agents. "/>
        <link rel="canonical" href="https://www.vibtree.com/products/ivr-studio/" />
        {/* og tags start  */}
        {/* <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Get access to call analytics with Incoming Call tracking software" />
        <meta property="og:description" content="Design call flows & handle large call volumes easily with this interactive voice response (IVR) builder & route customer calls to the right agents. " />
        <meta property="og:url" content="https://www.vibtree.com/products/ivr-studio/" />
        <meta property="og:site_name" content="Vibtree Technologies - Award winning cloud telephony service" />
        <meta property="og:image" content='https://vibtreedan.s3.eu-central-1.amazonaws.com/ivr-studio/pick-drop-done.svg'/>
        <meta property="og:image:secure_url" content='https://vibtreedan.s3.eu-central-1.amazonaws.com/ivr-studio/pick-drop-done.svg' />
        <meta property="og:image:width" content="700" />
        <meta property="og:image:height" content="700" />
        <meta property="og:image:alt" content="Award winning cloud telephony service"/>
        <meta property="og:image:type" content="image/svg" /> */}
        {/* og tags end  */}
        {/* twitter tag start  */}
        {/* <meta name= "twitter:site" content="@vibtree"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Get access to call analytics with Incoming Call tracking software" />
        <meta name="twitter:description" content="Design call flows & handle large call volumes easily with this interactive voice response (IVR) builder & route customer calls to the right agents. " />
        <meta name="twitter:image" content='https://vibtreedan.s3.eu-central-1.amazonaws.com/ivr-studio/pick-drop-done.svg' /> */}
        {/* twitter tag end  */}
        <script  type="application/ld+json">
        {`{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "Vibtree Technologies",
            "legalName": "Vibtree Technologies",
            "alternateName": "www.vibtree.com",
            "description": "Design call flows & handle large call volumes easily with this interactive voice response (IVR) builder & route customer calls to the right agents. ",
            "url": "https://www.vibtree.com/products/ivr-studio/",
            "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
            "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "18005724590",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/vibtree",
              "https://twitter.com/vibtree",
              "https://www.instagram.com/vibtree",
              "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
              "https://www.linkedin.com/company/vibtree",
              "https://www.vibtree.com"
            ]}`}
        </script>

        <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "products"
                    }
                },{
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@id": "https://www.vibtree.com/products/ivr-studio/",
                        "name": "ivr-studio"
                    }
                }]
            }`}
        </script>

        <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "What is a Drag-and-Drop IVR?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Drag–and–Drop IVR, or IVR builder, is exactly like a 
              regular IVR system except that it has an easy-to-use interface for configuring new 
              IVRs and making changes to existing IVR."
                }
              }, {
                "@type": "Question",
                "name": "What are the technical skills needed 
                         to use IVR Studio?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Generally for building a regular IVR, 
              one may need specialized coding skills in VXML, so businesses often require 
              special IT team of system integrator for making minor changes to IVR. 
              However, for using IVR Studio, one does not need any coding skills for 
              building new IVR or even making changes. Anyone in the organization with 
              basic computer skills and IVR knowledge can use IVR studio to build new 
              IVR with simple drag and drop function."
                }
              }, {
                "@type": "Question",
                "name": "What are the benefits of using IVR Studio?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Using drag and drop IVR can help your business 
              in multiple ways like- improving customer service, delivering outstanding 
              self-service for users, handling large call volume, building feedback surveys, 
              focusing on outbound marketing campaigns and changing menus and messages based 
              on seasons, offers and festivals."
                }
              }, {
                "@type": "Question",
                "name": "How much does IVR Studio service cost?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "At Vibtree, all our product prices & packages 
              are fully customizable. We only charge for the services you need based on your 
              business requirements. Our service packs are highly flexible and can be adjusted 
              according to the cloud services you choose.  You can book a demo session for a 
              customized pricing plan or start your free trial to explore pricing."
                }
              }, {
                "@type": "Question",
                "name": "Can I use IVR Studio with other services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "IVR Studio can be easily used with our 
              business cloud telephony suite to enhance your business operations. Users 
              can avail bundled cloud telephony services to customize the services to 
              match their business requirements. Our cloud telephony business 
              suite offers full features like cloud phone, Bulk-SMS, 
              call monitoring, call flows, call tracking, contact center, 
              cloud number, smart caller, voice messenger and much more. 
              To get more information on cloud SMS, email us at info@vibtree.com."
                }
              }]
            }`}
        </script>

    </Helmet>
        {/* body schema tag start  */}
        <body itemScope itemType="https://schema.org/WebPage"></body>
        {/* body schema tag start  */}

        {loader===true ? <LoaderComponent/> : null}
        
    {/* <Suspense fallback={<>Loading...</>}> */}
    {/* banner start  */}
    <div className="banner sms-banner vib-margin-bottom-100-0" 
        style={{
          position:"relative",
          top:"0px", 
          minHeight:"600px",
          paddingTop:"50px",
          overflow:'hidden',
          paddingRight:'0px',
          paddingLeft:'0px'}}>
          <div className="container-fluid">
            <div className="row align-items-center 
                            vh-100-middle  
                            voice-allow-overflow 
                            vib-flex-wrap-reverse 
                            pb-5
                            justify-content-center-1000 
                            banner-breaking-padding-1000">
              <div className="col-lg-6 
                              for-padding-remove 
                              voice-allow-overflow"  
                   style={{marginBottom:'-20px'}}>
              <h1 className='vib-container-heading vib-res-left voice-allow-overflow banner-heading-width' 
              style={{marginTop:'60px',fontSize:'50px',lineHeight:'75px',fontWeight:'700'}}>
              <span className="vib-blue">Pick. </span> Drop. <span className="vib-blue">Done.</span>
              </h1>
                <h5 className='vib-bold-para 
                               vib-res-left  
                               voice-allow-overflow 
                               banner-heading-width' 
                    style={{marginBottom:'30px',
                            fontSize:'28px',
                            marginTop:'20px',
                            lineHeight:'36px',
                            fontWeight:'400'}}>  
                IVR Studio is an intuitive visual workflow <br />
                builder that cuts down the response<br /> 
                time and the need for training to <br />
                minimal.
                </h5>
                
                <GetStarted/>

                {/* <div className="row mt-3 bannerresfor" style={{Height:"300px"}}>
                  <div className="col-12 p-0 banner-logo-slider" style={{position:"relative",left:"-13px",top:"20px",maxHeight:"170px"}}>
                    <div className="responsive-banner-logos">

                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-6 ivr-top-banner-image  
                              banner-break-point-breaker-1000
                              banner-breaking-margin-1000" 
                   style={{display:'flex',justifyContent:'center',marginTop:'50px'}}>
             
                <img 
                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/ivr-studio/modernistic-way-to-upgrade-customer-experience-with-ivr-builder.svg' 
                className="img-fluid
                bannerdivimg  
                vib-sms-left-margin-auto
                classForLoaded" 
                onLoad={imagesLoadIndicator}
                alt='get-features-of-phone-without-phone'
                // style={{maxWidth:'633px',height:'auto'}}
                />
              {/* </Suspense> */}
              </div>
            </div>
          </div>
    </div>
    {/* banner end  */}

    {/* plan-build-collaborate start  */}
    <h1 className='vib-container-heading vib-res-center v-mt-150-80 px-3' style={{fontWeight:'700',fontSize:'52px'}}>
        Plan - <span className="vib-blue">Build </span>- Collaborate
    </h1>
    {/* one-zig-zag-start  */}
    <div className="vib-inner-section vib-res-margin-top-100-50 vib-margin-bottom-50-0  px-3">
            <div className="bottom-margin-50" style={{maxWidth:'500px'}}>
              <h1 className='vib-container-sub-heading vib-res-left' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Modernistic Way To Upgrade Customer Experience With <br/>
              <span className='blue-under-line' style={{paddingBottom:' 15px'}}> IVR Build</span>er
              </h1>
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'36px',
                      color:'#7C8087',
                      fontWeight:'normal'}}>
              IVRs are an important part of the customer experience. 
              IVR Studio offers you a comprehensive platform to help 
              you build dynamic IVRs that respond intelligently to 
              customer inputs.  
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image">
                <img 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/ivr-studio/modernistic-way-to-upgrade-customer-experience-with-ivr-builder.svg'
                     alt="manage-calls-while-on-move" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>

        <div className="vib-inner-section-reverse vib-margin-top-200-50 vib-margin-bottom-50-0  px-3">

            <div className="vib-img-section ivr-zig-zag-image">
                    <img 
                         src='https://vibtreedan.s3.eu-central-1.amazonaws.com/ivr-studio/single-space-multiple-channels.svg'
                         alt="choose-calling-number" 
                         className='vib-img classForLoaded'
                         onLoad={imagesLoadIndicator}
                         />

            </div>
            
            <div id='virtual-receptionist' className="bottom-margin-50" style={{maxWidth:'500px'}}>
              <h1 className='vib-container-sub-heading vib-res-right'
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Single Space<br />
                  Multiple Chan<span className='blue-under-line' style={{paddingBottom:' 15px'}}>nels</span>
              </h1>

              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                 IVR Studio allows you to build highly meaningful customer 
                 interactions on the highly used platforms. You can 
                 connect with your customers the way they like.  
              </h5>
            </div>
        </div>
        
        {/* one-zig-zag-end  */}
        <div className="vib-inner-section vib-margin-top-200-50 vib-margin-bottom-50-0 vib-padding-bottom-100-50 px-3">
            <div id='ivr-hosting' className="bottom-margin-50" style={{maxWidth:'500px'}}>
              <h1 className='vib-container-sub-heading vib-res-left' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  User Friendly Interface <br />
              <span className='blue-under-line' style={{paddingBottom:' 15px'}}>Easy T</span>o Build
              </h1>
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
              Upload IVR audio, use text-to-speech or record 
              your own audio for building your conversation 
              sequence with IVR Studio.    
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image">
                <img 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/ivr-studio/user-friendly-interface-easy-to-build.svg'
                     alt="manage-calls-while-on-move" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>

    {/* plan-build-collaborate end  */}


    {/* build your ivr start  */}
        <h1 className='vib-container-heading vib-res-center  px-3' style={{fontWeight:'700',fontSize:'52px'}}>
        Build Your IVR <span className="vib-blue">On The Fly</span><br />
        </h1>

        <div className="sms-cloud-flex  px-3" style={{margin:'auto'}}>

          <div className="sms-cloud-flex-item-voice voice-sms-card" style={{boxShadow:'none'}}>
            <img 
                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/scalable-flexible-and-reliable.svg'
                alt="scalable-flexible-and-reliable"  
                style={{height:'154px',width:'154px'}} 
                className='classForLoaded'
                onLoad={imagesLoadIndicator}
                />
            <h6 style={{fontSize:'20px'}}>SMS Response</h6>
            <p style={{fontSize:'15px'}}>
            Send messages to customers to get their feedback or response. 
            </p>
          </div>

          <div className="sms-cloud-flex-item-voice  voice-sms-card  px-3" style={{boxShadow:'none'}}>
            <img 
                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/scalable-flexible-and-reliable.svg' 
                alt="scalable-flexible-and-reliable" 
                style={{height:'154px',width:'154px'}} 
                className='classForLoaded'
                onLoad={imagesLoadIndicator}
            />
            <h6  style={{fontSize:'20px'}}>Multi-Level Cloud IVR</h6>
            <p  style={{fontSize:'15px'}}>
            Route calls intelligently with conveniently built multi-level IVR.   
            </p>
          </div>

          <div className="sms-cloud-flex-item-voice  voice-sms-card  px-3"  style={{boxShadow:'none'}}>
            <img 
                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/scalable-flexible-and-reliable.svg' 
                alt="scalable-flexible-and-reliable" 
                style={{height:'154px',width:'154px'}}
                className='classForLoaded'
                onLoad={imagesLoadIndicator}
                />
            <h6  style={{fontSize:'20px'}}>Call Forwarding</h6>
            <p   style={{fontSize:'15px'}}>
            Forward calls to other phone lines like your office or home number.   
            </p>
          </div>

        </div>
    {/* build your ivr end  */}
    {/* top reasons for choosing start  */}
    <div className="vib-container px-3">

    <h1 className='vib-container-heading vib-res-center' style={{fontWeight:'600',fontSize:'40px'}}>
    Top Reasons For Choosing <span className="vib-blue">Vibtree</span><br />
    For Your Business 
    </h1>

    </div>

     <div className="part-why-left-top  px-3"  style={{marginTop:'0px',padding:'0px 0px'}}>

      <div className="sms-part-why-com">
        <img 
             src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/scalable-flexible-and-reliable.svg' 
             alt="scalable-flexible-and-reliable" 
             className='partner-why-part classForLoaded'
             onLoad={imagesLoadIndicator}
             />
        <h3 className='vib-sub-sub-heading' style={{fontSize:'24px',marginTop:'0px',marginBottom:'5px'}}>Scalable & Reliable Solutions</h3>
        <p style={{fontSize:'16px'}}>You can always rely on Vibree to offer you highly reliable 
        solutions that are made to scale and customized for your needs. 
        </p>
      </div>  
   
      <div className="part-why-cus">
        <img 
            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/easy-api-integration.svg' 
            alt="easy-api-integration" 
            className='partner-why-part classForLoaded' 
            style={{padding:'26px'}}
            onLoad={imagesLoadIndicator}
            />
        <h3 className='vib-sub-sub-heading' style={{fontSize:'24px',marginTop:'0px',marginBottom:'5px'}}>Enterprise Grade Services</h3>
        <p style={{fontSize:'16px'}}>
        Our products and services are engineered for round the 
        clock availability with 99.99% uptime, so you can 
        rely on Vibtree all the time.  
        </p>
      </div>

     </div>


    <div className="part-why-inner vib-ivr-con  px-3" style={{marginTop:'10px !important'}}>

      <div className="part-why-left"   style={{marginTop:'0px',padding:'0px 0px',display:'flex',alignItems:'center'}}>
          
             <div className="part-why-cus">
               <img 
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/affordable-pricing-models.svg' 
                    alt="affordable-pricing-models" 
                    className='partner-why-part classForLoaded'
                    onLoad={imagesLoadIndicator}
                    />
               <h3 className='vib-sub-sub-heading' 
                   style={{fontSize:'24px',marginTop:'0px',marginBottom:'5px'}}>
                   Flexible Pricing Models
               </h3>
               <p style={{fontSize:'16px'}}>
               Here at Vibtree, you never pay for what you don't use. 
               Build your own pack and use the services you need, conveniently.  
               </p>
             </div>
          
      </div>
      
      <div className="sms-part-why-middle">
      
         <div className="sms-part-why-middle-img ivr-center-img-zig-zag" style={{maxHeight:'200px',overflow:'visible'}}>
           <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/ivr-studio/top-reasons-for-choosing-vibtree-for-your-business.svg'
                alt="top-reasons-for-choosing-vibtree-for-your-business" 
                onLoad={imagesLoadIndicator}
                className='sms-partner-why-part-middle classForLoaded' 
                style={{marginLeft:'10px',height:'350px',position:'relative',top:'-60px'}} />
         </div>
      
      </div>
      
      <div className="part-why-right" style={{display:'flex',alignItems:'center'}}>
      
         <div className="part-why-right">
         <img 
               src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/custom-made-templates.svg'
               alt="custom-made-templates" 
               className='partner-why-part classForLoaded'
               onLoad={imagesLoadIndicator}
               />
           <h3 className='vib-sub-sub-heading' 
           style={{fontSize:'24px',marginTop:'0px',marginBottom:'5px'}}>Complete Cloud Telephony Suite</h3>
           <p style={{fontSize:'16px'}}>
           Vibtree brings together all clout telephony services 
           under one roof. Club different cloud telephony services 
           to make the best set up for your business. 
           </p>
         </div>
      
      </div>
    </div>  

      <div className="part-why-left-top px-3"  style={{marginTop:'0px',padding:'0px 0px',marginBottom:'50px'}}>

        <div className="sms-part-why-com">

          <img 
              src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/available-internationally.svg' 
              alt="available-internationally" 
              className='partner-why-part classForLoaded' 
              style={{padding:'26px'}}
              onLoad={imagesLoadIndicator}
              />
          <h3 className='vib-sub-sub-heading' style={{fontSize:'24px',marginTop:'0px',marginBottom:'5px'}}>Responsive Support Team </h3>
          <p>Our team cares about your success and that is why we they 
          are available to offer you premium support services and 
          consultation 24x7.
          </p>
        </div>  
        
        <div className="part-why-cus">
          <img 
              src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/real-time-sms-tracking.svg'
              alt="real-time-sms-tracking" 
              className='partner-why-part classForLoaded'
              onLoad={imagesLoadIndicator} 
              style={{padding:'22px'}}/>
          <h3 className='vib-sub-sub-heading' style={{fontSize:'24px',marginTop:'0px',marginBottom:'5px'}}>Scalable, Flexible & Reliable</h3>
          <p>
          Our services are built for scale to offer a high level of 
          flexibility as our solutions grow along with your business.   
          </p>
        </div>
        
        </div>
      
    {/* top reasons for choosing end */}

    {/* looseing leads and loseing money start  */}
    <section className="book-a-call vib-res-margin-top  px-3">
        <div className="container-fluid">
          <div className="row book-for-display-center d-flex vib-justify-content-evenly">
            <div className="col-lg-6 text-start book-for-half">
              <div className="wrap">
                <h2 
                className="text-start heading-extra-length" 
                style={{fontSize:'33px',fontWeight:'700'}}>
                Get started instantly. Sign up now!
                </h2>
                <p 
                className='vib-bold-para heading-extra-length' 
                style={{fontSize:'33px',
                        lineHeight:'45px',
                        marginTop:'10px',
                        fontWeight:'400'}}>
                   Create your account to get a <br />
                   free consultation call.
                </p>
                <div className="about-button-center" 
                     style={{display:'flex',flexWrap:'wrap',maxWidth:'500px'}}>

                  <a  href
                      className="hvr-bounce-to-bottom1 design1 ivr-get-started-now" 
                      onClick={() => setModalShow(true)}
                      style={{
                        backgroundColor:'#0046BB',
                        color:'#fafafa',
                        fontWeight:'normal',
                        fontSize:'26px',
                        padding:'3px',
                        width:'250px',
                        marginBottom:'50px',
                        border: '2px solid #74A8FF',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        marginLeft:'0px'}}>
                      Get Started Now!
                  </a>
                  <SignUpModel
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                  <div className="call-now-wrapper">
                  <a  href='tel:18005724590' className="hvr-bounce-to-bottom1 design1" 
                      style={{
                        backgroundColor:'#FFCE42',
                        color:'black',
                        fontWeight:'300',
                        fontSize:'26px',
                        padding:'5px',
                        width:'200px',
                        marginBottom:'50px'}}>
                      Call Us Now
                  </a>
                  </div>

                  </div>
              </div>
            </div>
            <div className="col-lg-6 
                            col-md-6 
                            book-col-cover-auto 
                            vib-sms-left-margin-auto"
                 style={{display:'flex',justifyContent:'center'}}>
              <img 
                  src='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/losing-leads-and-losing-money.svg' 
                  className="img-fluid 
                             book-image-now 
                             book-img-disappear
                             vib-sms-left-margin-auto
                             classForLoaded" 
                  alt="losing-leads-and-losing-money"
                  onLoad={imagesLoadIndicator}
                  style={{marginTop:'80px'}}
                  />
              <center style={{display:'contents'}} >
              <img 
              src='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/losing-leads-and-losing-money.svg' 
              alt="losing-leads-and-losing-money" 
              onLoad={imagesLoadIndicator}
              className='book-image-now book-img-appear classForLoaded'/>
              </center>
            </div>
          </div>
        </div>
      </section>
     {/* looseing leads and loseing money end  */}

     {/* accordin start  */}
    <div className="vib-container  px-3"> 
         <h1 className='ct-acc-h1'>Frequently Asked Questions:<span className='vib-blue'> IVR Studio</span></h1>
    </div>
    <div className="vib-container-narrow sms-accordin-margin-top" style={{marginTop:'50px',marginBottom:'130px'}}>
    <Accordion defaultActiveKey="0" style={{width:'100%', padding:'20px'}}>

        <Card id='drag-and-drop-ivr' className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="0"
          onClick={handleClick1} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white',
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> What is a Drag-and-Drop IVR?</span>
             <div className="accordin-right-icon" 
             style={{transform: accFirst==='yes' ? 'rotate(90deg)':accFirst==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span> Drag–and–Drop IVR, or IVR builder, is exactly like a 
              regular IVR system except that it has an easy-to-use interface for configuring new 
              IVRs and making changes to existing IVR. 
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="1" 
          onClick={handleClick2} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> What are the technical skills needed 
            to use IVR Studio?</span>
             <div className="accordin-right-icon" 
             style={{transform: accSecond==='yes' ? 'rotate(90deg)':accSecond==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span> Generally for building a regular IVR, 
              one may need specialized coding skills in VXML, so businesses often require 
              special IT team of system integrator for making minor changes to IVR. 
              However, for using IVR Studio, one does not need any coding skills for 
              building new IVR or even making changes. Anyone in the organization with 
              basic computer skills and IVR knowledge can use IVR studio to build new 
              IVR with simple drag and drop function.   
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEvent:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="2" 
          onClick={handleClick3} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> What are the benefits of using IVR Studio?</span>
             <div className="accordin-right-icon" 
             style={{transform: accThird==='yes' ? 'rotate(90deg)':accThird==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span> Using drag and drop IVR can help your business 
              in multiple ways like- improving customer service, delivering outstanding 
              self-service for users, handling large call volume, building feedback surveys, 
              focusing on outbound marketing campaigns and changing menus and messages based 
              on seasons, offers and festivals. 
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="3" 
          onClick={handleClick4} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> How much does IVR Studio service cost?</span>
             <div className="accordin-right-icon" 
             style={{transform: accFourth==='yes' ? 'rotate(90deg)':accFourth==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span> At Vibtree, all our product prices & packages 
              are fully customizable. We only charge for the services you need based on your 
              business requirements. Our service packs are highly flexible and can be adjusted 
              according to the cloud services you choose.  You can book a demo session for a 
              customized pricing plan or start your free trial to explore pricing.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="4" 
          onClick={handleClick5} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span>  Can I use IVR Studio with other services?</span>
             <div className="accordin-right-icon" 
             style={{transform: accFive==='yes' ? 'rotate(90deg)':accFive==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span> IVR Studio can be easily used with our 
              business cloud telephony suite to enhance your business operations. Users 
              can avail bundled cloud telephony services to customize the services to 
              match their business requirements. Our cloud telephony business 
              suite offers full features like cloud phone, Bulk-SMS, 
              call monitoring, call flows, call tracking, contact center, 
              cloud number, smart caller, voice messenger and much more. <br />
              To get more information on cloud SMS, email us at 
              <a style={{color:'inherit'}} className='vib-h-b-u' href="mailto:info@vibtree.com"> info@vibtree.com.</a> 
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
    </Accordion>
    </div>
    {/* accordin start  */}

    {/* </Suspense> */}
    </div>
  )
}
