import React, { useEffect, useState } from "react";
import './commoncss.css'
import SchedulePopUp from "./SchedulePopUp";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from 'axios';
import validator from "validator";
import { crmBaseUrl } from '../../utils/config'
import dashboard from './../../images/styles/dashboard.jfif'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
export default function BookACall() {
  const [isEmailValid, setEmailValidity] = useState(null);
  const [emailExists, setEmailExists] = useState(true);

  const [modalShow, setModalShow] = useState(false);
  const [inputEmail, setinputEmail] = useState('');

  const [ref_page_url, setref_page_url] = useState('');
  const [utm_source_s, setutm_source] = useState('');
  const [utm_medium_s, setutm_medium] = useState('');
  const [utm_campaign_s, setutm_campaign] = useState('');
  const [utm_term_s, setutm_term] = useState('');
  const [utm_content_s, setutm_content] = useState('');
  const [publisherid_s, setpublisherid] = useState('');

  let refferrerUrl = document.referrer
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    setref_page_url(refferrerUrl)
    setutm_source(params.utm_source)
    setutm_medium(params.utm_medium)
    setutm_campaign(params.utm_campaign)
    setutm_term(params.utm_term)
    setutm_content(params.utm_content)
    setpublisherid(params.publisherid)
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (isEmailValid) {
      emailCheck()
    }
  }, [inputEmail]);

  async function emailCheck() {
    try {
      // fetch data from a url endpoint
      const response = await axios.get(`${crmBaseUrl}/email_exist?email_address=${inputEmail}`);
      console.log('email check', response)
      if (response.data.data === true) {
        setEmailExists(false)
      } else if (response.data.data === false) {
        setEmailExists(true)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <div>
      <section className="book-a-call vib-res-margin-top-100-50">
        <h1
          className='hyvibtreee'
        // style={{ color: "white", fontSize: "6rem", marginLeft: "50px" }}
        >Try Vibtree 🚀</h1>
        <div className="container-fluid"
          style={{
            // marginTop: "30px" 
            // height: "90vh"
          }}
        >
          <div className="row book-for-display-center d-flex justify-content-between book-my-call-center-image-home" style={{ marginLeft: "26px" }}>
            <div className="col-lg-6 text-start book-for-half">
              <div className="wrap">
                {/* <h2 className="text-start">
                  Discover How Vibtree Can Work For You. Book a Call
                </h2> */}
                <h2 className="text-start" style={{ marginTop: "40px", marginBottom: "70px" }}>
                  Discover How Vibtree
                  <br />
                  Can Work For You.

                </h2>

                {/* <p>
                  We promise to resolve some of your major concerns in your
                  existing systems.
                </p> */}
                <p>
                  {/* Call */}
                  {/* <a href='#null' className="btn btn-outline-primary btn-lg ml-2 mr-2" style={{ color: 'white' }}>
                    <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/book-a-call/india.svg' style={{ width: "30px" }} alt="india" />
                    &nbsp;&nbsp;18005724590
                  </a>
                  or */}
                  {/* <a href="https://calendly.com/vibtree-abhishek/30min?month=2022-12" target='_blank'>


                    <p className="btn btn-warning btn-lg ml-2 homefea-res"
                      style={{ margin: 'auto', color: 'black' }}
                    // onClick={() => setModalShow(true)}

                    >
                      Schedule a Meeting
                    </p>
                  </a> */}

                  <SchedulePopUp
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />

                </p>


                {/* eslint-disable-next-line  */}


                {/* eslint-disable-next-line  */}
                <form
                  className="d-flex 
                                  flex-column 
                                  align-items-center 
                                  justify-content-lg-center
                                  mt-5
                                 
                                  mb-5"
                  autoComplete="false"
                  role="form"
                  method="post"
                  action="https://launcher.vibtree.in/form/submit?formId=15"
                  id="mauticform_vibtreesignup"
                  data-mautic-form="vibtreesignup"
                  encType="multipart/form-data"
                >

                  <div className="input-group textfullwidth"
                    style={{ maxWidth: '515px', marginRight: 'auto' }}>

                    <div className="input-group-prepend left" style={{ zIndex: '5' }}>
                      <span className="input-group-text1" id="basic-addon1" style={{ borderRight: 'none', backgroundColor: '#FFFFFF' }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          fill="currentColor"
                          className="bi bi-envelope"
                          viewBox="0 0 16 16"
                          style={{ marginTop: "14px", marginLeft: "6px" }}
                        >
                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                        </svg>
                      </span>
                    </div>

                    <input
                      placeholder='Enter Your Email Id'
                      className='form-control form-control-lg scheduledmeetinginput'
                      name="mauticform[email]"
                      type="email"
                      id="mauticform_input_vibtreesignup_email"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ borderRight: 'none', height: "50px" }}
                      onChange={(e) => {
                        setinputEmail(e.target.value);
                        setEmailValidity(validator.isEmail(e.target.value));
                      }}
                      required
                    />


                    <div className="input-group-append middle book-my-call-res tickmarksbutton" style={{ border: 'none' }}>
                      <span className="input-group-text">
                        {(() => {
                          if (inputEmail == '') {
                            return
                          }
                          if (isEmailValid === true && emailExists === true) {
                            return <div>
                              <i className="bi bi-check2-circle" style={{ color: "green" }}></i>
                            </div>;
                          }
                          return <div>
                            <i className="bi bi-x-circle" style={{ color: "red" }}></i>
                          </div>;
                        })()}
                      </span>
                    </div>

                    <div className="input-group-append getstartedinput">
                      {/* eslint-disable-next-line  */}
                      <a
                        // name="mauticform[submit]"
                        id="mauticform_input_vibtreesignup_submit"
                        className="hvr-bounce-to-bottom1 design1 foo-b-s getstartedbuttons"
                        type="submit"
                        style={{
                          maxHeight: '50px',
                          // maxWidth: '150px',
                          borderRadius: '0px 6px 6px 0px',
                          fontWeight: '400',
                          border: 'none',
                          padding: '2px auto 4px auto !important',
                          fontSize: '19px',
                          width: '15vw',
                          backgroundColor: '#ffc107',
                          color: 'black'
                        }}
                        href={`https://app.vibtree.com/auth/register?email=${inputEmail}`}
                        target='_blank'>
                        Get Started
                      </a>
                    </div>
                  </div>



                  <input id="mauticform_input_vibtreesignup_ref_page_url" name="mauticform[ref_page_url]" value={ref_page_url} className="mauticform-hidden" type="hidden" />
                  <input id="mauticform_input_vibtreesignup_service" name="mauticform[service]" value='home page' className="mauticform-hidden" type="hidden" />
                  <input id="mauticform_input_vibtreesignup_utm_source" name="mauticform[utm_source]" value={utm_source_s} className="mauticform-hidden" type="hidden" />
                  <input id="mauticform_input_vibtreesignup_utm_medium" name="mauticform[utm_medium]" value={utm_medium_s} className="mauticform-hidden" type="hidden" />
                  <input id="mauticform_input_vibtreesignup_utm_campaign" name="mauticform[utm_campaign]" value={utm_campaign_s} className="mauticform-hidden" type="hidden" />
                  <input id="mauticform_input_vibtreesignup_utm_term" name="mauticform[utm_term]" value={utm_term_s} className="mauticform-hidden" type="hidden" />
                  <input id="mauticform_input_vibtreesignup_utm_content" name="mauticform[utm_content]" value={utm_content_s} className="mauticform-hidden" type="hidden" />
                  <input id="mauticform_input_vibtreesignup_publisherid" name="mauticform[publisherid]" value={publisherid_s} className="mauticform-hidden" type="hidden"></input>

                  <input type="hidden" name="mauticform[formId]" id="mauticform_vibtreesignup_id" value="15" />
                  <input type="hidden" name="mauticform[return]" id="mauticform_vibtreesignup_return" value="" />
                  <input type="hidden" name="mauticform[formName]" id="mauticform_vibtreesignup_name" value="vibtreesignup"></input>
                  <span className="mauticform-errormsg" style={{ display: 'none' }}>This is required.</span>

                </form>

                {emailExists === false ? (
                  <p
                    style={{
                      fontWeight: "300",
                      marginTop: "-30px",
                      // margin: "0px",
                      padding: "0px",
                      fontSize: ".8rem",
                      color: "white",
                    }}
                  >
                    Email ID already Registered
                  </p>
                ) : ''}



              </div>

              <div style={{ marginTop: "70px" }}>


                <h2 style={{ fontSize: "25px", lineHeight: "40px" }}>Want a tour of Vibtree?
                  <br />
                  {/* <br /> */}
                  <span
                    // style={{ color: "#f7d439", transition: "all 0.5s ease-in-out" }}
                    className="getademo">
                    Get a Demo <ArrowForwardIcon />
                  </span>

                </h2>
                {/* <h2>Get a Demo ></h2> */}
              </div>
            </div>
            {/* <div >
              <img src={dashboard} alt="" />
            </div> */}

            {/* <div className="col-lg-6 col-md-6 book-col-cover-auto">
              <LazyLoadImage src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/book-a-call/call-now.svg' className="img-fluid book-image-now book-img-disappear" alt="callnow" />
              <center style={{ display: 'contents' }}>
                <LazyLoadImage src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/book-a-call/call-now.svg' alt="callnow" className='book-image-now book-img-appear' /></center>
            </div> */}
            <div className="col-lg-6 col-md-6 book-col-cover-auto" style={{ paddingRight: "0px" }}>
              <LazyLoadImage src={dashboard} className="img-fluid book-image-now book-img-disappear bookcallimage" alt="callnow"
              //  style={{
              //   maxWidth: "85%", marginLeft: "15%",
              //   boxShadow: "0px 0px 6px 1px #222223"
              //   // maxWidth: "80%", marginLeft: "21%", height: "94%"
              //   //  marginTop: "10%", height: "70%"
              // }}
              />
              <center style={{ display: 'contents' }}>
                <LazyLoadImage src={dashboard} alt="callnow" className='book-image-now book-img-appear' /></center>
            </div>
          </div>
        </div>
      </section >
    </div >
  );
}
