import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import './commoncss.css'


export default function SchedulePopUp(props) {

  useEffect(() => {
    const body = document.querySelector('body');
    const script = document.createElement('script');
    script.setAttribute(
      'src','https://assets.calendly.com/assets/external/widget.js'
    );
    script.setAttribute(
      'async',''
    );
    body.appendChild(script);
  });

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <!-- Calendly inline widget begin --> */}
        <div className="calendly-inline-widget" 
             data-url="https://calendly.com/vibtree/appointment-schedule" 
             style={{minWidth:"320px", height:"630px"}}></div>
        {/* <!-- Calendly inline widget end --> */}
      </Modal>
    );
  }