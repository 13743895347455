import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import styles from "../Styling/RecommendedMovies.module.css";
import styles from "styled-components";
import Marquee from "react-fast-marquee";
import Tanmoyroy from './../../images/Tanmoyroy.jpg'
import Dona from './../../images/dona.png'
import Ajay from './../../images/ajay.jpg'
import Rohit from './../../images/rohit.jpg'
import Santi from './../../images/santi.png'
import Shivam from './../../images/shivam.png'
import Siddhant from './../../images/siddhant.png'
import sourav from './../../images/sourav.jpg'
import supreet from './../../images/supreet.jpg'
import './commoncss.css'
const Div = styles.div`
    margin: 50px 0px;
    .contentText {
        width: 78%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .contentText h2 {
        font-weight: 700;
        margin-bottom: 0px;
    }
    .contentText .link {
        font-size: 18px;
        color: crimson;
    }
    .entertainment_container {
        width: 80%;
        // margin: auto;
        margin-left: 11%;
        margin-top: 1%
    }
    .entertainment_container img {
        width: 90%;
        border-radius: 10px;
        cursor: pointer;
        margin: 20px;
    }
`

const data = [
    "https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MTM1KyBFdmVudHM%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/workshops-collection-202007231330.png",
    "https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-OSBFdmVudHM%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/fitness-collection-2020081150.png",
    "https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-NTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/kids-collection-202007220710.png",
    "https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-OTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/comedy-shows-collection-202007220710.png",
    "https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/music-shows-collection-202007220710.png",
    "https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-NCBFdmVudHM%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/esports-collection-202011150107.png",
    "https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MjUrIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/self-improvement-collection-202007220710.png",
    "https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/food-n-drinks-collection-202010061250.png",
    "https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MjArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/interactive-games-collection-202012041129.png",
    "https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MjUrIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/art-and-crafts-collection-202007220710.png",
    "https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/theatre-shows-collection-202012041128.png",
    "https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/adventure-collection-202010140844.png"
]

export const Coursel = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 6,
            slidesToSlide: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };
    return (
        <Div
        // style={{ margin: 10 }}
        >
            {/* <div className="contentText">
                <h2>The Best of Entertainment</h2>
            </div> */}
            {/* <div className="entertainment_container">
                <Carousel autoPlaySpeed={1000} transitionDuration={0} responsive={responsive} autoPlay infinite removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]} >
                    {
                        data?.map((image, index) => (
                            <div key={index + 1}>
                                <img src={image} alt="Entertainment" />
                            </div>
                        ))
                    }
                </Carousel>
            </div> */}


            <div className="skillsContainer" style={{ marginBottom: "150px" }}>
                <div className="skill--scroll">
                    <Marquee
                        gradient={false}
                        speed={50}
                        pauseOnHover={true}
                        pauseOnClick={true}
                        delay={0}
                        play={true}
                        direction="left"
                    >

                        <div style={{
                            //  width: "500px", height: "350px",
                            backgroundColor: "#f9f9ff", borderRadius: "20px", margin: "20px"
                        }} className='autoslidehover'>
                            <div
                                // style={{ width: "100%", fontWeight: "500", textAlign: "center", padding: "60px", fontSize: "20px" }}
                                className='headingtestmonial'
                            >
                                <span className="dotcomma">"</span>Great product!! A fully customizable, end to end functional Business phone system personalized to your business needs. Keep updating and keep surprising us. Cheers!<span className="dotcomma">"</span>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        {/* <img src="https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/adventure-collection-202010140844.png" alt="" style={{ width: "25px", height: "25px", borderRadius: "50%" }} /> */}
                                        <img src={Tanmoyroy} alt="" style={{ width: "55px", height: "55px", borderRadius: "5px" }} />
                                    </div>
                                    <div
                                        style={{ marginLeft: "10px" }}
                                    >
                                        <div style={{ fontWeight: "600" }}>
                                            Tanmoy Roy</div>
                                        <div className="testmonialname">
                                            General Manager</div>
                                    </div>

                                </div>
                                <div style={{ fontWeight: "500" }}>
                                    {/* OnePageCRM */}
                                    {/* <div>
                            Carmel Granahan</div>
                        <div>
                            Head of Customer Success</div> */}
                                </div>
                            </div>
                        </div>
                        <div style={{
                            //  width: "500px", height: "350px",
                            backgroundColor: "#f9f9ff", borderRadius: "20px", margin: "20px"
                        }} className='autoslidehover'>
                            <div
                                // style={{ width: "100%", fontWeight: "500", textAlign: "center", padding: "60px", fontSize: "20px" }}
                                className='headingtestmonial'
                            >
                                <span className="dotcomma">"</span>We have been using the service for about 3 to 4 months now and the team is doing a amazing job. Very strong on support and issue resolution. Our POC is Ishani and she has been zealous in supporting and helping us use the product better. All in all great product with a great team.<span className="dotcomma">"</span>                           </div>

                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        {/* <img src="https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/adventure-collection-202010140844.png" alt="" style={{ width: "25px", height: "25px", borderRadius: "50%" }} /> */}
                                        <img src={Rohit} alt="" style={{ width: "55px", height: "55px", borderRadius: "5px" }} />
                                    </div>
                                    <div
                                        style={{ marginLeft: "10px" }}
                                    >
                                        <div style={{ fontWeight: "600" }}>
                                            Rohit</div>
                                        <div className="testmonialname">
                                            General Manager</div>
                                    </div>

                                </div>
                                <div style={{ fontWeight: "500" }}>
                                    {/* OnePageCRM */}
                                    {/* <div>
                            Carmel Granahan</div>
                        <div>
                            Head of Customer Success</div> */}
                                </div>
                            </div>
                        </div>
                        <div style={{
                            //  width: "500px", height: "350px",
                            backgroundColor: "#f9f9ff", borderRadius: "20px", margin: "20px"
                        }} className='autoslidehover'>
                            <div
                                // style={{ width: "100%", fontWeight: "500", textAlign: "center", padding: "60px", fontSize: "20px" }}
                                className='headingtestmonial'
                            >
                                <span className="dotcomma">"</span>Our experience with Vibtree has been satisfying so far. Their solution has streamlined our entire call handling process and helped us improve our customer experience. Recommended!<span className="dotcomma">"</span>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        {/* <img src="https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/adventure-collection-202010140844.png" alt="" style={{ width: "25px", height: "25px", borderRadius: "50%" }} /> */}
                                        <img src={Santi} alt="" style={{ width: "55px", height: "55px", borderRadius: "5px" }} />
                                    </div>
                                    <div
                                        style={{ marginLeft: "10px" }}
                                    >
                                        <div style={{ fontWeight: "600" }}>
                                            Santi Ranjan Debnath</div>
                                        <div className="testmonialname">
                                            Manager</div>
                                    </div>

                                </div>
                                <div style={{ fontWeight: "500" }}>
                                    {/* OnePageCRM */}
                                    {/* <div>
                            Carmel Granahan</div>
                        <div>
                            Head of Customer Success</div> */}
                                </div>
                            </div>
                        </div>
                        <div style={{
                            //  width: "500px", height: "350px",
                            backgroundColor: "#f9f9ff", borderRadius: "20px", margin: "20px"
                        }} className='autoslidehover'>
                            <div
                                // style={{ width: "100%", fontWeight: "500", textAlign: "center", padding: "60px", fontSize: "20px" }}
                                className='headingtestmonial'
                            >
                                <span className="dotcomma">"</span>We are impressed with the services provided by Vibtree. Our experience with the services has been satisfying and their customers support is great and so far, we have received a clarification whenever required. Highly Recommend!<span className="dotcomma">"</span>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        {/* <img src="https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/adventure-collection-202010140844.png" alt="" style={{ width: "25px", height: "25px", borderRadius: "50%" }} /> */}
                                        <img src={Dona} alt="" style={{ width: "55px", height: "55px", borderRadius: "5px" }} />
                                    </div>
                                    <div
                                        style={{ marginLeft: "10px" }}
                                    >
                                        <div style={{ fontWeight: "600" }}>
                                            Dona Burman</div>
                                        <div className="testmonialname">
                                            Brand Manager</div>
                                    </div>

                                </div>
                                <div style={{ fontWeight: "500" }}>
                                    {/* OnePageCRM */}
                                    {/* <div>
                            Carmel Granahan</div>
                        <div>
                            Head of Customer Success</div> */}
                                </div>
                            </div>
                        </div>
                        <div style={{
                            //  width: "500px", height: "350px",
                            backgroundColor: "#f9f9ff", borderRadius: "20px", margin: "20px"
                        }} className='autoslidehover'>
                            <div
                                // style={{ width: "100%", fontWeight: "500", textAlign: "center", padding: "60px", fontSize: "20px" }}
                                className='headingtestmonial'
                            >
                                <span className="dotcomma">"</span>We have been with the technology since the beta version, The development over the phase of time is remarkable, We have got continuous support from the complete vibtree team.<span className="dotcomma">"</span>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        {/* <img src="https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/adventure-collection-202010140844.png" alt="" style={{ width: "25px", height: "25px", borderRadius: "50%" }} /> */}
                                        <img src={Shivam} alt="" style={{ width: "55px", height: "55px", borderRadius: "5px" }} />
                                    </div>
                                    <div
                                        style={{ marginLeft: "10px" }}
                                    >
                                        <div style={{ fontWeight: "600" }}>
                                            Shivam Shah</div>
                                        <div className="testmonialname">
                                            Marketing Manager</div>
                                    </div>

                                </div>
                                <div style={{ fontWeight: "500" }}>
                                    {/* OnePageCRM */}
                                    {/* <div>
                            Carmel Granahan</div>
                        <div>
                            Head of Customer Success</div> */}
                                </div>
                            </div>
                        </div>
                        <div style={{
                            //  width: "500px", height: "350px",
                            backgroundColor: "#f9f9ff", borderRadius: "20px", margin: "20px"
                        }} className='autoslidehover'>
                            <div
                                // style={{ width: "100%", fontWeight: "500", textAlign: "center", padding: "60px", fontSize: "20px" }}
                                className='headingtestmonial'
                            >
                                <span className="dotcomma">"</span>Been using Vibtree's IVR solution for little over 6 months now and I must say it's a great tool for multi property businesses. The dash is clean and simple to use, support services are prompt and efficient. Recommended!<span className="dotcomma">"</span>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        {/* <img src="https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/adventure-collection-202010140844.png" alt="" style={{ width: "25px", height: "25px", borderRadius: "50%" }} /> */}
                                        <img src={Ajay} alt="" style={{ width: "55px", height: "55px", borderRadius: "5px" }} />
                                    </div>
                                    <div
                                        style={{ marginLeft: "10px" }}
                                    >
                                        <div style={{ fontWeight: "600" }}>
                                            Ajay Antony</div>
                                        <div className="testmonialname">
                                            Owner</div>
                                    </div>

                                </div>
                                <div style={{ fontWeight: "500" }}>
                                    {/* OnePageCRM */}
                                    {/* <div>
                            Carmel Granahan</div>
                        <div>
                            Head of Customer Success</div> */}
                                </div>
                            </div>
                        </div>
                        <div style={{
                            //  width: "500px", height: "350px",
                            backgroundColor: "#f9f9ff", borderRadius: "20px", margin: "20px"
                        }} className='autoslidehover'>
                            <div
                                // style={{ width: "100%", fontWeight: "500", textAlign: "center", padding: "60px", fontSize: "20px" }}
                                className='headingtestmonial'
                            >
                                <span className="dotcomma">"</span>Vibtree provides a simple solution to calls handling and feedback, the cost is also reasonable.<span className="dotcomma">"</span>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        {/* <img src="https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/adventure-collection-202010140844.png" alt="" style={{ width: "25px", height: "25px", borderRadius: "50%" }} /> */}
                                        <img src={Siddhant} alt="" style={{ width: "55px", height: "55px", borderRadius: "5px" }} />
                                    </div>
                                    <div
                                        style={{ marginLeft: "10px" }}
                                    >
                                        <div style={{ fontWeight: "600" }}>
                                            Siddhant Agarwal</div>
                                        <div className="testmonialname">
                                            Owner</div>
                                    </div>

                                </div>
                                <div style={{ fontWeight: "500" }}>
                                    {/* OnePageCRM */}
                                    {/* <div>
                            Carmel Granahan</div>
                        <div>
                            Head of Customer Success</div> */}
                                </div>
                            </div>
                        </div>
                        <div style={{
                            //  width: "500px", height: "350px",
                            backgroundColor: "#f9f9ff", borderRadius: "20px", margin: "20px"
                        }} className='autoslidehover'>
                            <div
                                // style={{ width: "100%", fontWeight: "500", textAlign: "center", padding: "60px", fontSize: "20px" }}
                                className='headingtestmonial'
                            >
                                <span className="dotcomma">"</span>Stable system, good support team, the product has helped us in our business. I would recommend it.<span className="dotcomma">"</span>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        {/* <img src="https://in.bmscdn.com/discovery-catalog/collections/tr:w-800,h-800:ote-MTArIEV2ZW50cw%3D%3D,otc-FFFFFF,otf-Roboto,ots-64,ox-48,oy-320,ott-b:w-300/adventure-collection-202010140844.png" alt="" style={{ width: "25px", height: "25px", borderRadius: "50%" }} /> */}
                                        <img src={sourav} alt="" style={{ width: "55px", height: "55px", borderRadius: "5px" }} />
                                    </div>
                                    <div
                                        style={{ marginLeft: "10px" }}
                                    >
                                        <div style={{ fontWeight: "600" }}>
                                            Sourav</div>
                                        <div className="testmonialname">
                                            Owner</div>
                                    </div>

                                </div>
                                <div style={{ fontWeight: "500" }}>
                                    {/* OnePageCRM */}
                                    {/* <div>
                            Carmel Granahan</div>
                        <div>
                            Head of Customer Success</div> */}
                                </div>
                            </div>
                        </div>






                        {/* {
                            data?.map((image, index) => (
                                <div key={index + 1}>
                                    <img src={image} alt="Entertainment" />
                                </div>
                            ))
                        } */}
                    </Marquee>
                </div>
            </div>
        </Div>
    )
}