import React, { useContext, useEffect, useState } from 'react'
import '../navbar.css'
import ContactUsPopUp from './ContactUsPopUp';
import { vibContext } from 'src/App';

export default function Partners() { 
  const [modalShow, setModalShow] = useState(false);

  const partnerContext= useContext(vibContext);
  const contextId=partnerContext.vibState;
  useEffect(() => {
    const element = document.getElementById(contextId);
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0
      });
    }, 100);
  },[contextId]);

  return (
    <div className="nav-item dropdown position-static" style={{maxHeight:'80vh',overflowY:'scroll'}}>
      <div className="partners_position 
                      navbar 
                      navbar-expand-lg 
                      navbar-light 
                      dropdown-menu 
                      support-drop" 
           aria-labelledby="navbarDropdown"
           style={{backgroundColor:'#042E76',borderRadius:'10px',maxHeight:'80vh',overflowY:'scroll'}}>
                <div className=" for_partners_display" 
                     style={{marginTop:"30px", 
                             marginBottom:"30px",
                             paddingTop:'25px',
                             width:'100%'}}>
                  <div className="row fornewmobnew partner-res-column" style={{width:'100%'}}>

                    <div className="col-auto d-none d-lg-block" style={{flexDirection: 'column', justifyContent: 'flex-end', display: 'flex'}}>
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/we-are-here-for-you-24by7.svg' className="drop-item-img" alt='we-are-here-for-you-24by7'/>
                    </div>

                    <div className="col-12 col-md partnerformob" style={{flexDirection: 'column', justifyContent: 'flex-end', display: 'flex'}}>
                      <div className="forpartmob" style={{marginTop:'50px',textAlign:'center'}}>
                     <h2 style={{color:'#FFCE42'}}>
                       We are here for you 24x7
                      </h2>
                       <h3 style={{color:'#FAFAFA',
                                   fontSize:'15px',
                                   lineHeight:'22px',
                                   fontWeight:'400 !important',
                                   wordSpacing:'2px'}}>
                       Hassle free support for any time by quality support team</h3>
                         </div>
                         <p className="hvr-bounce-to-bottom1 design1"
                            onClick={() => setModalShow(true)}
                            style={{fontWeight:'500',
                            marginBottom:'auto',
                            marginTop:'68px',
                            maxWidth:'400px'}}>
                          Contact Us Now
                         </p>
                         <ContactUsPopUp
                                         show={modalShow}
                                         onHide={() => setModalShow(false)}
                         />
                         </div>
                    <div className="col-12 col-md formartopee" 
                         style={{marginTop:"40px",maxWidth:'350px'}}>
 
                     <div className="card card-body mt-3 marginleftmobnow" 
                          style={{padding:"5px", 
                                  marginTop:"100px !important",
                                  minWidth:'250px'}}>
                       <div className="row no-margin align-items-center"
                            style={{width:'280px'}}>
                           <div className="col-auto">
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-headphones1" viewBox="0 0 16 16">
                             <path d="M8 3a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a6 6 0 1 1 12 0v5a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1V8a5 5 0 0 0-5-5z"/>
                           </svg>
                           </div>
                           <div className="col removeforpadding" style={{padding:'18px 0 18px 0'}}>
                               <a href='tel:18005724590' className='part-number' style={{paddingLeft:'8px'}}>
                               &nbsp;  <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/book-a-call/india.svg' alt="indian-flag" className="indian-flag-front" style={{width:'20px'}}/>&nbsp;1800 572 4590</a>
                               {/* <h3>+91 75960 35301</h3> */}
                           </div>
                           

                       </div>
                     </div>
 
                       <div className="card card-body mt-3 marginleftmobnow struggling-margin-top" 
                       style={{padding:"5px",
                               minWidth:'250px', 
                               marginTop:'35px !important'}}>
                         <div className="row no-margin align-items-center"
                            style={{}}>
                          <div className="col-auto">
                            <svg className="bi bi-envelope1" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" d="M14 3H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                              <path d="M.05 3.555C.017 3.698 0 3.847 0 4v.697l5.803 3.546L0 11.801V12c0 .306.069.596.192.856l6.57-4.027L8 9.586l1.239-.757 6.57 4.027c.122-.26.191-.55.191-.856v-.2l-5.803-3.557L16 4.697V4c0-.153-.017-.302-.05-.445L8 8.414.05 3.555z"/>
                            </svg>
                          </div>
                             <div className="col removeforpadding" style={{padding:'18px 0 18px 0'}}>
                                 {/* <h3 className="forlinea">support@vibtree.com</h3> */}
                                 <a 
                                   href='mailto:info@vibtree.com' 
                                   className="forlinea part-number" 
                                   style={{paddingLeft:'10px'}}>
                                   info@vibtree.com
                                 </a>
                             </div>
                            
                         </div>
                     </div>
                     
 
                   </div>
                  
                  </div>
                </div>
              </div>
    </div>
  )
}
