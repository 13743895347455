import React, { useContext, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { useState } from 'react'
import { Helmet } from "react-helmet";
import './sms.css'

import favicon from '../../images/vibtree.ico'
import ScrollHandler from '../../ScrollHandler';
import { vibContext } from 'src/App';
import GetStarted from 'src/GetStarted';
import Group from './../../images/Group.png'
import BookACall from '../homePage/BookACall';


export default function Smslaptop() {
    const [inputEmail, setinputEmail] = useState('');

    const [accFirst, setaccFirst] = useState('yes');
    const [accSecond, setaccSecond] = useState('no');
    const [accThird, setaccThird] = useState('no');
    const [accFourth, setaccFourth] = useState('no');
    const [accFive, setaccFive] = useState('no');
    const [accSix, setaccSix] = useState('no');
    const [accSeven, setaccSeven] = useState('no');
    const [accEight, setaccEight] = useState('no');

    const [ImagesLoadCount, setImagesLoadCount] = useState(0);
    const [loader, setloader] = useState(true);

    const smsContext = useContext(vibContext);
    const contextId = smsContext.vibState;
    useEffect(() => {
        const element = document.getElementById(contextId);
        setTimeout(() => {
            window.scrollTo({
                behavior: element ? "smooth" : "auto",
                top: element ? element.offsetTop : 100
            });
        }, 100);
    }, [contextId]);

    // let email = (new URLSearchParams(window.location.search)).get("emailid");

    var handleClick1 = () => {
        setaccSecond('no')
        setaccThird('no')
        setaccFourth('no')
        setaccFive('no')
        setaccSix('no')
        setaccSeven('no')
        setaccEight('no')
        return (
            accFirst === 'yes' ? setaccFirst('no') : accFirst === 'no' ? setaccFirst('yes') : null
        )
    }
    var handleClick2 = () => {
        setaccFirst('no');
        setaccThird('no');
        setaccFourth('no');
        setaccFive('no');
        setaccSix('no');
        setaccSeven('no');
        setaccEight('no');
        return (
            accSecond === 'yes' ? setaccSecond('no') : accSecond === 'no' ? setaccSecond('yes') : null
        )
    }
    var handleClick3 = () => {
        setaccFirst('no');
        setaccSecond('no');
        setaccFourth('no');
        setaccFive('no');
        setaccSix('no');
        setaccSeven('no');
        setaccEight('no');
        return (
            accThird === 'yes' ? setaccThird('no') : accThird === 'no' ? setaccThird('yes') : null
        )
    }
    var handleClick4 = () => {
        setaccFirst('no');
        setaccSecond('no');
        setaccThird('no');
        setaccFive('no');
        setaccSix('no');
        setaccSeven('no');
        setaccEight('no');
        return (
            accFourth === 'yes' ? setaccFourth('no') : accFourth === 'no' ? setaccFourth('yes') : null
        )
    }

    var handleClick5 = () => {
        setaccFirst('no');
        setaccSecond('no');
        setaccThird('no');
        setaccFourth('no');
        setaccSix('no');
        setaccSeven('no');
        setaccEight('no');
        return (
            accFive === 'yes' ? setaccFive('no') : accFive === 'no' ? setaccFive('yes') : null
        )
    }
    var handleClick6 = () => {
        setaccFirst('no')
        setaccSecond('no')
        setaccThird('no')
        setaccFourth('no')
        setaccFive('no')
        setaccSeven('no')
        setaccEight('no')
        return (
            accSix === 'yes' ? setaccSix('no') : accSix === 'no' ? setaccSix('yes') : null
        )
    }
    var handleClick7 = () => {
        setaccFirst('no');
        setaccSecond('no');
        setaccThird('no');
        setaccFourth('no');
        setaccFive('no');
        setaccSix('no');
        setaccEight('no');
        return (
            accSeven === 'yes' ? setaccSeven('no') : accSeven === 'no' ? setaccSeven('yes') : null
        )
    }
    var handleClick8 = () => {
        setaccFirst('no');
        setaccSecond('no');
        setaccThird('no');
        setaccFourth('no');
        setaccFive('no');
        setaccSix('no');
        setaccSeven('no');
        return (
            accEight === 'yes' ? setaccEight('no') : accEight === 'no' ? setaccEight('yes') : null
        )
    }

    // image loading spinner start 
    const imagesLoadIndicator = () => {
        const images = document.getElementsByClassName('classForLoaded');

        setImagesLoadCount(ImagesLoadCount + 1);

        if (ImagesLoadCount === images.length - 1) {
            setloader(false)
        }

    }
    // image loading spinner end

    return (
        <div
            style={{
                backgroundImage: `url(${Group})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionY: "450px",
                backgroundPositionX: "right",
                backgroundAttachment: "fixed",
                backgroundSize: "300px",
            }}
        >
            <Helmet>
                <title>Get Bulk SMS Service for Promotional & Transactional SMS- Cloud-SMS</title>
                <meta name="description" content="Are you looking for Best Bulk SMS services provider, Transactional SMS, Promotional SMS, API Integration for cloud-based SMS services & communications?" />
                <link rel="canonical" href="https://www.vibtree.com/products/bulk-sms/" />
                <script type="application/ld+json">
                    {`{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "Vibtree Technologies",
            "legalName": "Vibtree Technologies",
            "alternateName": "www.vibtree.com",
            "description": "Are you looking for Best Bulk SMS services provider, Transactional SMS, Promotional SMS, API Integration for cloud-based SMS services & communications?",
            "url": "https://www.vibtree.com/products/bulk-sms/",
            "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
            "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "18005724590",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/vibtree",
              "https://twitter.com/vibtree",
              "https://www.instagram.com/vibtree",
              "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
              "https://www.linkedin.com/company/vibtree",
              "https://www.vibtree.com"
            ]}`}
                </script>

                <script type="application/ld+json">
                    {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "products"
                    }
                },{
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@id": "https://www.vibtree.com/products/bulk-sms/",
                        "name": "sms"
                    }
                }]
            }`}
                </script>

                <script type="application/ld+json">
                    {`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What do you mean by Bulk SMS?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Bulk SMS allows businesses to broadcast transactional 
            or promotional information to a large volume of targeted 
            customers through short messaging service."
              }
            }, {
              "@type": "Question",
              "name": "What is transactional SMS?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "A transactional message is usually sent as a response when a user 
            interacts with your website or service or business. A few basic examples 
            include account alerts, shipping notifications, billing alerts, 
            and identity validation. These messages may be a little critical by 
            nature; but it can also be used additionally for brand promotion 
            as well. Bulk SMS services offer both transactional and promotional 
            messages."
              }
            }, {
              "@type": "Question",
              "name": "What is promotional SMS?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Promotional SMS, bulk SMS is referred to a message sent by a brand to 
            customers and prospects; informing them about discounts, promotions, product 
            offers etc, also called as SMS bulk marketing. Generally promotional campaigns 
            are run by marketers to enhance customer engagement and ultimately increase 
            sales. Bulk SMS services offer both transactional and promotional messages."
              }
            }, {
              "@type": "Question",
              "name": "What is difference between promotional SMS and transactional SMS?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Transactional SMS are used to forward informational messages, 
            OTPs, booking and order alerts to registered customers. 
            These messages are not directly intended for marketing of 
            products or business; whereas promotional SMS are used to 
            inform new and existing customers about ongoing offers, 
            promotions, discounts and upcoming products. Promotional 
            SMS may be or may not be solicited by the customers."
              }
            }, {
              "@type": "Question",
              "name": "How much does cloud-SMS service cost?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At Vibtree, all our bulk SMS prices & packages are fully customizable. 
            We only charge for the services you need based on your business requirements. 
            Our bulk SMS packs are highly flexible and can be adjusted according to 
            the SMS services you choose.  You can book a demo session for a customized 
            pricing plan or start your free trial to explore pricing."
              }
            }, {
              "@type": "Question",
              "name": "What are the most common uses of cloud-based Bulk SMS Services?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Bulk messaging services can be used for several marketing, sales, customer support 
            and brand awareness projects. Organizations often use bulk SMS services to send greetings, 
            wishes, reminders, acknowledgements, announcements, offers and updates etc. to stay in touch 
            with leads and existing customers. Businesses use Bulk SMS for transactional and promotional 
            activities."
              }
            }, {
              "@type": "Question",
              "name": "Why should I use Bulk SMS?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "With Bulk Messaging or cloud SMS, you can reach a huge customer base with less 
            effort, intervention, time engagement and expenses. Our services are completely 
            customizable, scalable, flexible and highly reliable based on the communication 
            needs of your business."
              }
            }, {
              "@type": "Question",
              "name": "Can I use Cloud SMS service with Other cloud telephony services?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Cloud-SMS or Bulk SMS can be easily used with our business cloud telephony 
            suite to enhance your business operations. Users can use bundled cloud 
            telephony services to customize the services to match their business 
            requirements. Our cloud telephony business suite offers full cloud 
            phone features like IVR or voice menu, call recording, call monitoring, 
            call flows, call tracking, contact center, lead center, cloud number, 
            smart caller, voice messenger and much more. 
            To get more information on cloud SMS, email us at info@vibtree.com."
              }
            }]
          }`}
                </script>

            </Helmet>
            {/* body schema tag start  */}
            <body itemScope itemType="https://schema.org/WebPage"></body>
            {/* body schema tag start  */}
            {/* banner start  */}
            <ScrollHandler />
            <div className="headingchange banner sms-banner" style={{ position: "relative", top: "0px", minHeight: "600px", paddingTop: "50px", overflow: 'hidden' }}>
                <div className="container-fluid margin-top-res-sms">
                    <div className="row vh-100-middle justify-content-center-1000 banner-breaking-padding-1000" style={{ flexWrap: 'wrap-reverse' }}>
                        <div className="col-lg-6 for-padding-remove textcontainer" >
                            <h1 className='vib-container-heading vib-res-left smsheading' style={{ marginTop: '60px', fontSize: '50px', lineHeight: '70px' }}>
                                {/* Tailored & Automated <br /><span className="vib-blue">Bulk-SMS Services </span> */}
                                Send SMS Anywhere<br /><span className="vib-blue">Two-Way or One-way</span>
                            </h1>
                            <h5
                                className='vib-bold-para vib-res-left'
                                style={{
                                    marginBottom: '40px',
                                    fontSize: '24px',
                                    fontWeight: '400',
                                    lineHeight: '40px',
                                    color: '#7C8087'
                                }}>
                                {/* Cost effective personalized messaging solutions to cater to your business
                needs like sending promotional or reminder SMS, OTP or other transactional
                messages in bulk. */}

                            </h5>
                            <div className='smsgetstarted'>
                                <GetStarted />
                            </div>



                            {/* <div className="row mt-3 bannerresfor" style={{Height:"300px"}}>
                  <div className="col-12 p-0 banner-logo-slider" style={{position:"relative",left:"-13px",top:"20px",maxHeight:"170px"}}>
                    <div className="responsive-banner-logos">

                    </div>
                  </div>
                </div> */}
                        </div>
                        <div className="col-lg-6 banner-break-point-breaker-1000" style={{ display: 'flex', justifyContent: 'center' }}>
                            {/* {onScreen ? ( */}
                            {/* <img src={home} className="img-fluid bannerdivimg vib-sms-left-margin-auto" alt='home'/> */}

                            <img onLoad={imagesLoadIndicator}
                                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/tailored-and-automated-bulk-sms-services.svg' // use normal <img> attributes as props

                                className="img-fluid 
                                 bannerdivimg 
                                 vib-sms-left-margin-auto
                                 mb-5
                                 phoneimage
                                 classForLoaded"
                                alt='tailored-and-automated-bulk-sms-services'
                            />
                            {/* ) : (
                  <h1 style={{fontSize:'12px',color:'#7c8087'}}>loading..</h1>
                )} */}
                        </div>
                    </div>
                </div>
            </div>
            {/* banner end */}
            {/* diminish section start  */}
            <h1
                className='vib-container-sub-heading 
                       vib-res-center
                       vib-res-margin-top-100-50
                       smssecondheading
                       '
                style={{ fontSize: '40px' }}>
                Diminish Communication Boundaries With <span className='vib-blue'>Bulk SMS</span>
            </h1>
            <div className="vib-inner-section vib-container" style={{ marginTop: '50px' }}>

                <div className="vib-content-section bottom-margin-50" style={{ marginBottom: '0px' }}>
                    <h5 className='vib-bold-para vib-res-left'
                        style={{
                            marginTop: '10px',
                            fontSize: '24px',
                            lineHeight: '36px',
                            fontWeight: '400',
                            color: '#7C8087'
                        }}>
                        Delivering 30 Million+ Cloud-SMS Every Month, Vibtree
                        provides the #1 Business SMS platform that is reliable
                        and easy to use. With our bulk SMS plans, businesses
                        can enhance their customer engagement and
                        organize marketing campaigns using
                        promotional bulk SMS services.
                    </h5>
                </div>
                <div className="vib-img-section">
                    {/* {onScreen ? ( */}
                    {/* <img src={chatting} alt="tailored-&-automated-bulk-sms-services" className='vib-img'/> */}
                    <img onLoad={imagesLoadIndicator}
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/diminish-communication-boundaries-with-bulk-sms.svg' // use normal <img> attributes as props

                        className='vib-img classForLoaded'
                        alt="diminish-communication-boundaries-with-bulk-sms"
                    />
                    {/* ) : (
                  <h1 style={{fontSize:'12px',color:'#7c8087'}}>loading..</h1>
                )} */}
                </div>

            </div>
            {/* diminish section end */}
            {/* promotional bulk sms section start  */}
            <h1 id='bulk-sms' className='vib-container-sub-heading vib-res-center smssecondheading' style={{ fontSize: '40px', marginTop: '150px' }}>
                How To Make The Best Use Of Our
                <span className='vib-blue'> Bulk SMS Services</span>
            </h1>
            <div className="vib-inner-section vib-container-narrow">

                <div className="vib-content-section bottom-margin-50">
                    <h1 className='vib-container-sub-heading vib-res-left smssecondheading'>
                        <span className='blue-under-line' style={{ paddingBottom: ' 15px' }}>Pro</span>motional SMS
                    </h1>
                    <h5
                        className='vib-bold-para vib-res-left'
                        style={{
                            marginTop: '80px',
                            fontSize: '24px',
                            lineHeight: '35px',
                            color: '#7C8087',
                            fontWeight: '400'
                        }}>
                        Vibtree is among the top cloud SMS service providers. Ensure higher
                        delivery rates and lower drop rates with our bulk promotional SMS
                        services to influence the desired customers.
                    </h5>
                </div>

                <div className="vib-img-section" style={{ maxWidth: '280px' }}>
                    {/* <img src={sms1} alt="vib-img-section" className='vib-img'/> */}
                    <img onLoad={imagesLoadIndicator}
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/promotional-sms.svg'

                        className='vib-img classForLoaded'
                        alt="promotional-sms"
                    />
                </div>

            </div>

            <div id={'alinktest'} className="vib-inner-section-reverse vib-container-narrow sms-margin-top-10">

                <div className="vib-img-section" style={{ maxWidth: '280px' }}>
                    {/* <img src={transaction} alt="vib-img-section" className='vib-img'/> */}
                    <img onLoad={imagesLoadIndicator}
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/transactional-sms.svg'

                        className='vib-img classForLoaded'
                        alt="transactional-sms"
                    />
                </div>

                <div className="vib-content-section bottom-margin-50 ">
                    <h1 className='vib-container-sub-heading vib-res-right smssecondheading'>
                        Transactional <span className='blue-under-line' style={{ paddingBottom: ' 15px' }}>SMS</span>
                    </h1>

                    <h5
                        className='vib-bold-para vib-res-right'
                        style={{
                            marginTop: '80px',
                            fontSize: '24px',
                            lineHeight: '35px',
                            color: '#7C8087',
                            fontWeight: '400'
                        }}>
                        Personalized transactional SMS that empowers you to deliver instant messages
                        when prompted, automatically, offered by one of the best cloud-based bulk SMS providers.
                    </h5>
                </div>

            </div>

            <div id='otp-service' className="vib-inner-section vib-container-narrow  sms-margin-top-10">

                <div className="vib-content-section bottom-margin-50">
                    <h1 className='vib-container-sub-heading vib-res-left smssecondheading'>
                        <span className='blue-under-line' style={{ paddingBottom: ' 15px' }}>OTP</span> SMS
                    </h1>
                    <h5
                        className='vib-bold-para vib-res-left'
                        style={{
                            marginTop: '80px',
                            fontSize: '24px',
                            lineHeight: '35px',
                            color: '#7C8087',
                            fontWeight: '400'
                        }}>
                        Highly crucial for maintaining user privacy, safety and security, we offer
                        OTP SMS that helps you implement two-factor authentication to ensure user
                        data security.
                    </h5>
                </div>

                <div className="vib-img-section" style={{ maxWidth: '280px' }}>
                    {/* <img src={otp3} alt="vib-img-section" className='vib-img'/> */}
                    <img onLoad={imagesLoadIndicator}
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/otp-sms.svg'

                        className='vib-img classForLoaded'
                        alt="otp-sms"
                    />
                </div>

            </div>
            {/* promotional bulk sms section end */}
            {/* cloud section start  */}
            <div className="vib-container">
                <h1 className='vib-container-heading vib-res-center cloudenableheading lineheightcloud'
                    style={{ fontWeight: '600', fontSize: '40px', maxWidth: '1000px' }}>
                    Cloud Enabled Messaging Solutions To <span className="vib-blue">Fuel Your Business Reach</span>
                </h1>
                <h5
                    className='vib-bold-para vib-res-center'
                    style={{
                        fontSize: '24px',
                        lineHeight: '35px',
                        maxWidth: '1000px',
                        fontWeight: '400'
                    }}>
                    Our list of SMS services is vast and spreads across different business operations to
                    help you engage customers and serve them with a personalized touch. Reach your
                    customers with personalized transactional SMS and promotional bulk SMS at much less
                    cost per message, users can send more than 10000+ SMS everyday.
                </h5>

                <div className="sms-cloud-flex">
                    <div className="sms-cloud-flex-item">
                        {/* <img src={bulk} alt="bulkSmsImage" /> */}
                        <img onLoad={imagesLoadIndicator}
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/bulk-sms-campaigns.svg'

                            alt="bulk-sms-campaigns"
                            className='vib-res-card-img-first classForLoaded'
                        />
                        <h6 className='vib-sub-sub-heading' >Bulk SMS Campaigns</h6>
                        <p>
                            Get the highest output from your promotional
                            campaigns by sending unlimited messages for your
                            brand with a unique number to connect.
                        </p>
                    </div>

                    <div className="sms-cloud-flex-item">
                        {/* <img src={cloudOtp} alt="bulkSmsImage" style={{padding:'45px'}}/> */}
                        <img onLoad={imagesLoadIndicator}
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/otp-verification.svg'
                            alt="otp-verification"
                            style={{ padding: '45px' }}
                            className='vib-res-card-img classForLoaded'
                        />
                        <h6 className='vib-sub-sub-heading'>OTP Verification</h6>
                        <p>
                            Send OTP SMS for two-factor authentication to ensure your
                            customers privacy and security during important transaction
                            and login.
                        </p>
                    </div>

                    <div className="sms-cloud-flex-item">
                        {/* <img src={billing} alt="bulkSmsImage" style={{padding:'45px'}}/> */}
                        <img onLoad={imagesLoadIndicator}
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/billing-and-payment.svg'
                            alt="billing-and-payment"
                            style={{ padding: '45px' }}
                            className='vib-res-card-img classForLoaded'
                        />
                        <h6 className='vib-sub-sub-heading'>Billing & Payment</h6>
                        <p>
                            Ensure timely payment for services by sending gentle reminders and
                            notifications to your customers about the upcoming payments.
                        </p>
                    </div>

                    <div className="sms-cloud-flex-item">
                        {/* <img src={bell} alt="bulkSmsImage" style={{padding:'45px'}}/> */}
                        <img onLoad={imagesLoadIndicator}
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/appointment-remainders.svg'
                            alt="appointment-remainders"
                            style={{ padding: '45px' }}
                            className='vib-res-card-img classForLoaded'
                        />
                        <h6 className='vib-sub-sub-heading'>Appointment Reminders</h6>
                        <p>
                            Send personalized appointment reminders to your clients to enhance
                            your customer retention and gain better ROI.
                        </p>
                    </div>

                    <div className="sms-cloud-flex-item">
                        {/* <img src={smile} alt="bulkSmsImage" style={{padding:'45px'}}/> */}
                        <img onLoad={imagesLoadIndicator}
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/survey-and-feedback.svg'
                            alt="survey-and-feedback"
                            style={{ padding: '45px' }}
                            className='vib-res-card-img classForLoaded'
                        />
                        <h6 className='vib-sub-sub-heading'>Survey & Feedback</h6>
                        <p>
                            Conduct feedback and surveys through bulk SMS links to offer a
                            better user experience to your customers and improve your services.
                        </p>
                    </div>

                    <div className="sms-cloud-flex-item">
                        {/* <img src={status} alt="bulkSmsImage" style={{padding:'45px'}}/> */}
                        <img onLoad={imagesLoadIndicator}
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/status-update.svg'
                            alt="status-update"
                            style={{ padding: '45px' }}
                            className='vib-res-card-img classForLoaded'
                        />
                        <h6 className='vib-sub-sub-heading'>Status Update</h6>
                        <p>
                            Timely update your customers about the status of their order,
                            from order confirmation, to shipment up till the delivery.
                        </p>
                    </div>
                </div>
            </div>
            {/* cloud section end */}
            {/* why choose section start  */}
            <div className="vib-container">

                <h1 className='vib-container-heading vib-res-center' style={{ fontWeight: '600', fontSize: '40px' }}>
                    Why Choose Us For SMS-Based Business <br />Solutions <span className="vib-blue">On Cloud?</span>
                </h1>

            </div>

            <div className="part-why-left-top" style={{ marginTop: '0px', padding: '0px 0px' }}>

                <div className="sms-part-why-com">
                    <img onLoad={imagesLoadIndicator}
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/real-time-sms-tracking.svg'
                        className='partner-why-part classForLoaded'
                        alt="real-time-sms-tracking"
                        style={{ padding: '22px' }}
                    />
                    <h3 className='vib-sub-sub-heading' style={{ fontSize: '24px', marginTop: '0px', marginBottom: '5px' }}>Real-Time SMS Tracking</h3>
                    <p style={{ fontSize: '16px' }}>Get instant delivery confirmation on the messages in real-time
                        to track the overall status of the SMS.
                    </p>
                </div>

                <div className="part-why-cus">
                    <img onLoad={imagesLoadIndicator}
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/easy-api-integration.svg'
                        className='partner-why-part classForLoaded'
                        alt="easy-api-integration"
                        style={{ padding: '26px' }}
                    />
                    <h3 className='vib-sub-sub-heading' style={{ fontSize: '24px', marginTop: '0px', marginBottom: '5px' }}>Easy API Integration </h3>
                    <p style={{ fontSize: '16px' }}>
                        Integrate our simple to use bulk SMS API in your software to let
                        customers connect with you seamlessly through in-app messaging.
                    </p>
                </div>

            </div>


            <div className="part-why-inner vib-container " style={{ marginTop: '10px' }}>

                <div className="part-why-left" style={{ marginTop: '0px', padding: '0px 0px' }}>

                    <div className="sms-part-why-com sms-height-res">
                        <img onLoad={imagesLoadIndicator}
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/affordable-pricing-models.svg'
                            className='partner-why-part classForLoaded'
                            alt="affordable-pricing-models"
                        />
                        <h3
                            className='vib-sub-sub-heading'
                            style={{
                                fontSize: '24px',
                                marginTop: '0px',
                                marginBottom: '5px'
                            }}>
                            Affordable Pricing Models
                        </h3>
                        <p style={{ fontSize: '16px' }}>Only pay for the services that you use as we customize the
                            SMS bulk pricing plans for our clients based on their needs.
                        </p>
                    </div>

                    <div className="part-why-cus">
                        <img onLoad={imagesLoadIndicator}
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/available-internationally.svg'
                            className='partner-why-part classForLoaded'
                            alt="available-internationally"
                            style={{ padding: '26px' }}
                        />
                        <h3 className='vib-sub-sub-heading' style={{ fontSize: '24px', marginTop: '0px', marginBottom: '5px' }}>Available Internationally</h3>
                        <p style={{ fontSize: '16px' }}>
                            Reach customers around the World as our bulk SMS services
                            expand to more than 20 countries around the Globe.
                        </p>
                    </div>

                </div>

                <div className="sms-part-why-middle">

                    <div className="sms-part-why-middle-img">
                        {/* <img src={bigGirl} alt="search" className='sms-partner-why-part-middle' style={{marginLeft:'10px'}} /> */}
                        <img onLoad={imagesLoadIndicator}
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/vibtree-sms.svg'
                            className='sms-partner-why-part-middle classForLoaded'
                            alt="vibtree-sms"
                            style={{ marginLeft: '10px' }}
                        />
                    </div>

                </div>

                <div className="part-why-right">

                    <div className="part-why-right">
                        <img onLoad={imagesLoadIndicator}
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/custom-made-templates.svg'
                            className='partner-why-part classForLoaded'
                            alt="custom-made-templates"
                        />
                        <h3
                            className='vib-sub-sub-heading'
                            style={{
                                fontSize: '24px',
                                marginTop: '0px',
                                marginBottom: '5px'
                            }}>Custom-Made Templates</h3>
                        <p style={{ marginBottom: '35px', fontSize: '16px' }}>Create customized templates for using our Bulk SMS services for
                            transactions based on your needs.
                        </p>
                    </div>

                    <div className="part-why-right">
                        {/* <img src={scale} alt="search" className='partner-why-part'/> */}
                        <img onLoad={imagesLoadIndicator}
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/scalable-flexible-and-reliable.svg'
                            className='partner-why-part classForLoaded'
                            alt="scalable-flexible-and-reliable"
                        />
                        <h3 className='vib-sub-sub-heading' style={{ fontSize: '24px', marginTop: '0px', marginBottom: '5px' }}>Scalable, Flexible & Reliable</h3>
                        <p style={{ fontSize: '16px' }}>
                            Our services are built for scale to offer a high level of flexibility
                            as our solutions grow along with your business.
                        </p>
                    </div>

                </div>
            </div>

            <div className="part-why-left-top" style={{ marginTop: '0px', padding: '0px 0px', marginBottom: '50px' }}>

                <div className="sms-part-why-com">

                    {/* <img src={analytics} alt="search" className='partner-why-part' style={{padding:'26px'}}/> */}
                    <img onLoad={imagesLoadIndicator}
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/analytics-and-reporting.svg'
                        className='partner-why-part classForLoaded'
                        alt="analytics-and-reporting"
                        style={{ padding: '26px' }}
                    />
                    <h3 className='vib-sub-sub-heading' style={{ fontSize: '24px', marginTop: '0px', marginBottom: '5px' }}>Analytics & Reporting</h3>
                    <p>With real-time reporting and analytics monitoring,
                        track the total delivered and read SMS by the customers.
                    </p>
                </div>

                <div className="part-why-cus">
                    {/* <img src={girl} alt="search" className='partner-why-part'/> */}
                    <img onLoad={imagesLoadIndicator}
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/bulk-sms/available-24-7.svg'
                        className='partner-why-part classForLoaded'
                        alt="available-24-7"
                    />
                    <h3 className='vib-sub-sub-heading' style={{ fontSize: '24px', marginTop: '0px', marginBottom: '5px' }}>Available 24x7</h3>
                    <p>
                        Use our Bulk SMS for transactional or promotional updates
                        to your customers round the clock.
                    </p>
                </div>

            </div>

            {/* why choose section end */}



            {/* call us now section start  */}
            <BookACall />
            {/* call us now section end */}


            {/* FAQ section start  */}
            <div className="vib-container">
                <h1 className='vib-container-heading vib-res-center' style={{ width: '100%', fontSize: '33px' }}>
                    Frequently Asked Questions: <span className="vib-blue">Cloud-Based Messaging </span>
                </h1>
            </div>
            <div className="vib-container-narrow sms-accordin-margin-top" style={{ marginTop: '50px', marginBottom: '130px' }}>
                <Accordion defaultActiveKey="0" style={{ width: '100%', padding: '20px' }}>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="0"
                            onClick={handleClick1}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white',
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> What do you mean by Bulk SMS?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accFirst === 'yes' ? 'rotate(90deg)' : accFirst === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> Bulk SMS allows businesses to broadcast transactional
                                or promotional information to a large volume of targeted
                                customers through short messaging service.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="1"
                            onClick={handleClick2}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> What is transactional SMS?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accSecond === 'yes' ? 'rotate(90deg)' : accSecond === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> A transactional message is usually sent as a response when a user
                                interacts with your website or service or business. A few basic examples
                                include account alerts, shipping notifications, billing alerts,
                                and identity validation. These messages may be a little critical by
                                nature; but it can also be used additionally for brand promotion
                                as well. Bulk SMS services offer both transactional and promotional
                                messages.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEvent: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="2"
                            onClick={handleClick3}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> What is promotional SMS?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accThird === 'yes' ? 'rotate(90deg)' : accThird === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> Promotional SMS, bulk SMS is referred to a message sent by a brand to
                                customers and prospects; informing them about discounts, promotions, product
                                offers etc, also called as SMS bulk marketing. Generally promotional campaigns
                                are run by marketers to enhance customer engagement and ultimately increase
                                sales. Bulk SMS services offer both transactional and promotional messages.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="3"
                            onClick={handleClick4}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> What is difference between promotional SMS and transactional SMS?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accFourth === 'yes' ? 'rotate(90deg)' : accFourth === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> Transactional SMS are used to forward informational messages,
                                OTPs, booking and order alerts to registered customers.
                                These messages are not directly intended for marketing of
                                products or business; whereas promotional SMS are used to
                                inform new and existing customers about ongoing offers,
                                promotions, discounts and upcoming products. Promotional
                                SMS may be or may not be solicited by the customers.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="4"
                            onClick={handleClick5}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> How much does cloud-SMS service cost?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accFive === 'yes' ? 'rotate(90deg)' : accFive === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> At Vibtree, all our bulk SMS prices & packages are fully customizable.
                                We only charge for the services you need based on your business requirements.
                                Our bulk SMS packs are highly flexible and can be adjusted according to
                                the SMS services you choose.  You can book a demo session for a customized
                                pricing plan or start your free trial to explore pricing.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="5"
                            onClick={handleClick6}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> What are the most common uses of cloud-based Bulk SMS Services?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accSix === 'yes' ? 'rotate(90deg)' : accSix === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="5">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> Bulk messaging services can be used for several marketing, sales, customer support
                                and brand awareness projects. Organizations often use bulk SMS services to send greetings,
                                wishes, reminders, acknowledgements, announcements, offers and updates etc. to stay in touch
                                with leads and existing customers. Businesses use Bulk SMS for transactional and promotional
                                activities.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="6"
                            onClick={handleClick7}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> Why should I use Bulk SMS?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accSeven === 'yes' ? 'rotate(90deg)' : accSeven === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="6">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> With Bulk Messaging or cloud SMS, you can reach a huge customer base with less
                                effort, intervention, time engagement and expenses. Our services are completely
                                customizable, scalable, flexible and highly reliable based on the communication
                                needs of your business.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="7"
                            onClick={handleClick8}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> Can I use Cloud SMS service with Other cloud telephony services?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accEight === 'yes' ? 'rotate(90deg)' : accEight === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="7">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> Cloud-SMS or Bulk SMS can be easily used with our business cloud telephony
                                suite to enhance your business operations. Users can use bundled cloud
                                telephony services to customize the services to match their business
                                requirements. Our cloud telephony business suite offers full cloud
                                phone features like IVR or voice menu, call recording, call monitoring,
                                call flows, call tracking, contact center, lead center, cloud number,
                                smart caller, voice messenger and much more.  <br /><br />
                                To get more information on cloud SMS, email us
                                at <a href="mailto:info@vibtree.com" className="vib-h-b-u" style={{ color: 'inherit' }}>info@vibtree.com.</a>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div>
            {/* FAQ section end */}
        </div>
    )
}
