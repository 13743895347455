import React, { useState} from 'react';
import { webApi } from '../../url';
import axios from 'axios';

export default function CategoryAddComponent(){
    const [ categoryName, setCategoryName ] = useState('');
    const [ showSuccess, setShowSuccess ] = useState(false);
    const [ showError, setShowError ] = useState(false);
    // eslint-disable-next-line
    const [ categoryResponse, setCategoryResponse] = useState([]);
    // console.log('item',item);

    const clearInputs = () =>{
        setCategoryName('');
      }

      async function postCategory(e) {
        e.preventDefault();
        const formdata = {
            Category_Name : categoryName,
        }
        
        try {
          // fetch data from a url endpoint
          const response = await axios.post(`${webApi}/Categories`, formdata);
          return (
              // eslint-disable-next-line
            setCategoryResponse(response.data),
            clearInputs(),
            setShowSuccess(true)
          );
        } catch(error) {
          setShowError(true)
          // appropriately handle the error
        }
      }

  
    return(

        <span>



        <a href className="btn btn-outline-primary btn-sm admin-blog-create-category-hover" 
        title="Edit"  
        data-toggle="modal" 
        data-target="#CategoryAddComponent" 
        style={{marginTop: "10px", 
                marginRight: "10px", 
                padding: "5px 20px",
                color:'#007bff'}}>
            Create Category
        </a>

        <div className="modal fade" id="CategoryAddComponent" tabIndex="-1" aria-labelledby="CategoryAddComponent" aria-hidden="true">
           
        <form onSubmit={postCategory}>
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Create Category</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                
                            <div className="form-group text-left">
                                <label>Category Name</label>
                                <input type="text" className="form-control" value={categoryName} onChange={(event)=>setCategoryName(event.target.value)} id="categoryName" required />
                            </div>
                            <div className={showSuccess === true ? 'alert alert-success alert-dismissible fade show' : 'alert alert-success alert-dismissible fade d-none'} role="alert">
                                <strong>Success!</strong> Category Created
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className={showError === true ? 'alert alert-danger alert-dismissible fade show' : 'alert alert-danger alert-dismissible fade d-none'}  role="alert">
                                <strong>Failed!</strong> Please try Again
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            
                            
                           
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary">Create</button>
                            
                </div>
                </div>
            </div>
            </form>
            </div>

        


        </span>

        
    )
}