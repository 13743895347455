import React, {useEffect} from 'react';
import { useHistory, NavLink } from "react-router-dom";
import CategoryAddComponent from './Category/CategoryAddComponent';



export default function BlogNav(){
    
    const auth = localStorage.getItem('vibauth-b');


    let history = useHistory();


    useEffect(() => {
        // eslint-disable-next-line
        if(auth != 'authenticated'){
            history.push("/blog/admin")
        } 
        // eslint-disable-next-line
    }, [])

    const logout = () =>{
        localStorage.removeItem('vibauth-b');
        history.push("/blog/admin")
    }


    return(
        <div>
      
           <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{padding: "10px 0"}}>
            <div className="container-fluid">
                <a className="navbar-brand" href>Blogs</a>
            
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto mb-2 mb-lg-0 text-right" >

                    <li className="nav-item">
                    <NavLink className="btn btn-outline-primary btn-sm" to="/blog/admin/BlogList/" activeClassName="active"  style={{marginTop: "10px", padding: "5px 20px"}}>
                        Blog List
                    </NavLink>
                    </li>

                    <li className="nav-item" style={{marginLeft: "auto"}}>
                    <NavLink className="btn btn-outline-primary btn-sm" to="/blog/admin/CreateBlog/" activeClassName="active" style={{marginTop: "10px", padding: "5px 20px"}}>
                        Create Blog
                    </NavLink>
                    </li>

                    <li className="nav-item" style={{marginLeft: "auto"}}>
                    <NavLink className="btn btn-outline-primary btn-sm" to="/blog/admin/Category/" activeClassName="active" style={{marginTop: "10px", padding: "5px 20px"}}>
                        Category List
                    </NavLink>
                    </li>

                    <li>
                     <CategoryAddComponent/>
                    </li>

                    <li className="nav-item" style={{marginLeft: "auto"}}>
                    <NavLink className="btn btn-outline-primary btn-sm" to="/blog/admin/author/" activeClassName="active" style={{marginTop: "10px", padding: "5px 20px"}}>
                        Author
                    </NavLink>
                    </li>

                    <li className="nav-item" style={{marginLeft: "auto"}}>
                    <a href className="btn btn-outline-primary btn-sm" onClick={logout} style={{marginTop: "10px", padding: "5px 20px"}}>
                        Logout
                    </a>
                    </li>
                
                </ul>
                
                </div>
              </div>
            </nav>



        </div>
    )
}