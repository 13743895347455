import React, {useEffect} from 'react';
import { useHistory} from "react-router-dom";
import BlogViewComponent from './Blogs/BlogViewComponent';
import BlogNav from './BlogNav';

export default function BlogAdminMain(){

    
    const auth = localStorage.getItem('vibauth-b');

    let history = useHistory();

    useEffect(() => {
        // eslint-disable-next-line
        if(auth != 'authenticated'){
            history.push("/blog/admin")
        } 
        // eslint-disable-next-line
    }, [])

    

    return(
        <div>
      
        <BlogNav />
        <BlogViewComponent />

        </div>
    )
}