import React, { useContext, useEffect, useState } from 'react'
import '../homePage/commoncss.css'
import './footer.css'

import { Link, withRouter } from 'react-router-dom'
import SignUpModel from '../navbar/components/SignUpModel'
import { vibContext } from '../../App'
import usflag from './../../images/industry/usflag.png'

function getWindowDimensions() {
  const { innerWidth: widthprice, innerHeight: height } = window;
  return {
    widthprice,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


function FooterBase() {
  const { height, widthprice } = useWindowDimensions();
  const [ref_page_url, setref_page_url] = useState('');
  const [utm_source_s, setutm_source] = useState('');
  const [utm_medium_s, setutm_medium] = useState('');
  const [utm_campaign_s, setutm_campaign] = useState('');
  const [utm_term_s, setutm_term] = useState('');
  const [utm_content_s, setutm_content] = useState('');
  const [publisherid_s, setpublisherid] = useState('');

  const [modalShow, setModalShow] = useState(false);

  const footerContext = useContext(vibContext);

  let refferrerUrl = document.referrer
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    setref_page_url(refferrerUrl)
    setutm_source(params.utm_source)
    setutm_medium(params.utm_medium)
    setutm_campaign(params.utm_campaign)
    setutm_term(params.utm_term)
    setutm_content(params.utm_content)
    setpublisherid(params.publisherid)
    // eslint-disable-next-line 
  }, []);
  return (
    <div>
      <div>
        <footer>
          <div className="container-fluid">

            {/* <div className="card"
              style={{
                pointerEvents: "none",
                animation: 'none',
                position: 'relative',
                top: '-88px',
                maxWidth: '1150px',
                margin: 'auto',
                borderRadius: '8px'
              }}>
              <div className="row no-margin align-items-center"
                style={{ marginLeft: '0px', marginRight: '0px' }}>
                <div className="col-md col-12" >
                  <h3 style={{
                    fontSize: '25px',
                    fontWeight: '700',
                    color: '#3D3D3D',
                    marginBottom: '5px'
                  }}>
                    Ready to get started?
                  </h3>
                  <h6>Our consultants are on the stand by 24x7 </h6>
                </div>
                <div className="col-md-auto col-12 border-adjustment"
                  style={{
                    pointerEvents: "auto",
                    margin: 'auto',
                    maxWidth: '409px',
                    padding: '0px',
                    position: 'relative'
                  }}>
                  <a href='https://calendly.com/vibtree-abhishek/30min?month=2022-12' target='_blank'
                    className="hvr-bounce-to-bottom 
                                      hover-btn 
                                      shadow 
                                      preventHover
                                      border-adjustment
                                      foo-button-effects"
                    
                    style={{
                      pointerEvents: "auto",
                      margin: 'auto',
                      fontSize: '22px',
                      fontWeight: '300',
                      letterSpacing: '1px',
                      padding: '21px 30px'
                    }}>
                    Book Free Consultation</a>
                  <SignUpModel
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                 
                </div>
              </div>
            </div> */}

            <div className="foo-new-footer-section">
              <div className="foo-first-row">
                <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/footer/vibtree-white-logo.svg' alt="vibtree-white-logo" />
                <h3 className='foo-first-row-text'>Talk to Sales :
                  <br className='foo-break indian-flag-infront' />

                  {widthprice > 600 ? <>
                    &nbsp;  <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/book-a-call/india.svg' alt="indian-flag" className="indian-flag-front" style={{ width: '30px' }} />&nbsp;1800 572 4590

                    &nbsp;  <img src={usflag} alt="indian-flag" className="indian-flag-front" style={{ width: '30px', height: "22px" }} />&nbsp;786 864 1868
                  </> : <>
                    &nbsp;  <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/book-a-call/india.svg' alt="indian-flag" className="indian-flag-front" style={{ width: '30px' }} />&nbsp;1800 572 4590
                    <br />
                    <img src={usflag} alt="indian-flag" className="indian-flag-front" style={{ width: '30px', height: "22px" }} />&nbsp;786 864 1868
                  </>}

                </h3>
                <div></div>
              </div>
              <hr className='foo-divider' style={{ marginTop: '0px' }} />

              <div className="foo-second-row">
                <div className="foo-vibtree foo-l-r-margin">
                  <h5 className="foo-vibtree-heading">Vibtree</h5>
                  <Link to='/company/about/'><p>About Us</p></Link>
                  <Link to='/company/contact/'><p>Contact Us</p></Link>
                  <Link to='/company/work-with-us/'><p>Work With Us</p></Link>
                  <Link to='/company/partner-with-us/'><p>Partner With Us</p></Link>
                  <div className="foo-just-space" style={{ marginTop: '50px' }}></div>
                  <Link to={{ pathname: 'https://app.vibtree.com/register' }} target="_blank"><p>Signup</p></Link>
                  <Link to={{ pathname: 'https://app.vibtree.com/login' }} target="_blank"><p>Login</p></Link>
                  <p>Support</p>
                  <Link to='/sitemap/'><p>Sitemap</p></Link>
                </div>
              
                <div className="foo-products foo-vibtree foo-l-r-margin">
                  <h5 className="foo-vibtree-heading">Products</h5>
                  <Link to='/products/voice/'><p>Voice</p></Link>
                  <Link to='/products/bulk-sms/'><p>SMS</p></Link>
                  <Link to='/products/ivr-studio/'><p>IVR Studio</p></Link>
                  <Link to='/products/call-tracking/'><p>Call Tracking</p></Link>
                  <Link to='/products/cloud-phone/'><p>Cloud Phone</p></Link>
                  <Link to='/products/cloud-number/'><p>Cloud Number</p></Link>
                  <p style={{ width: '0px', height: '0px', overflow: 'hidden' }}><Link to='/unsubscribe/'><p>unsubscribe</p></Link></p>
                </div>
                <div className="foo-services foo-vibtree foo-l-r-margin-services">
                  <h5 className="foo-vibtree-heading">Services</h5>
                  <Link to='/products/bulk-sms/' onClick={() => footerContext.vibDispatch('otp-service')}>
                    <p>OTP Service</p>
                  </Link>
                  <Link to='/products/bulk-sms/' onClick={() => footerContext.vibDispatch('bulk-sms')}>
                    <p>Bulk SMS</p>
                  </Link>
                  <Link to='/products/cloud-number/' onClick={() => footerContext.vibDispatch('toll-free-number')}>
                    <p>Toll-Free Number</p>
                  </Link>
                  <Link to='/products/cloud-number/' onClick={() => footerContext.vibDispatch('virtual-numbers')}>
                    <p>Virtual Numbers</p>
                  </Link>
                  <Link to='/products/cloud-number/' onClick={() => footerContext.vibDispatch('call-forwarding')}>
                    <p>Call Forwarding</p>
                  </Link>
                  <Link to='/products/cloud-phone/' onClick={() => footerContext.vibDispatch('call-recording')}>
                    <p>Call Recording</p>
                  </Link>
                  <Link to='/products/cloud-phone/' onClick={() => footerContext.vibDispatch('call-monitoring')}>
                    <p>Call Monitoring</p>
                  </Link>
                  <Link to='/products/cloud-phone/' onClick={() => footerContext.vibDispatch('click-to-call')}>
                    <p>Click to Call</p>
                  </Link>
                  <Link to='/products/voice/' onClick={() => footerContext.vibDispatch('voice-broadcasting')}>
                    <p>Voice Broadcasting</p>
                  </Link>
                  <Link to='/products/voice/' onClick={() => footerContext.vibDispatch('geo-based-routing')}>
                    <p>Geo-Based Routing</p>
                  </Link>
                  <Link to='/products/ivr-studio/' onClick={() => footerContext.vibDispatch('ivr-hosting')}>
                    <p>IVR Hosting</p>
                  </Link>
                  <Link to='/products/ivr-studio/' onClick={() => footerContext.vibDispatch('drag-and-drop-ivr')}>
                    <p>Drag-and-Drop IVR</p>
                  </Link>
                  <Link to='/products/ivr-studio/' onClick={() => footerContext.vibDispatch('virtual-receptionist')}>
                    <p>Virtual Receptionist</p>
                  </Link>

                </div>
                <div className="foo-policies">
                  <div className="foo-vibtree foo-vibtree-policies">
                    <h5 className="foo-vibtree-heading">Our Policies</h5>
                    <Link to='/privacy-policy/'>
                      <p>Privacy Policy</p>
                    </Link>
                    <Link to='/terms-of-use/'>
                      <p>Terms of Use</p>
                    </Link>
                    <Link to='/refund-policy/'>
                      <p>Refund and Cancellation Policy</p>
                    </Link>
                  </div>
                  {/* form start  */}
                  <h2 className='foo-subscribe-text'>Subscribe To Our Newsletter</h2>
                  {/* wasted almost 4 hours on silly email input and button alignment  */}
                  <div className="foo-input-wrapper" style={{ width: '100%' }}>
                    {/* eslint-disable-next-line  */}
                    <form
                      autoComplete="false"
                      role="form"
                      method="post"
                      action="https://launcher.vibtree.in/form/submit?formId=15"
                      id="mauticform_vibtreesignup"
                      data-mautic-form="vibtreesignup"
                      encType="multipart/form-data"
                      style={{ maxWidth: '100%' }}>
                      <div className="input-group mb-3 banner-input foo-custom-input-group-now">
                        <div className="input-group-prepend left">
                          <span className="input-group-text toremove-background foo-custom" id="basic-addon1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="21"
                              fill="currentColor"
                              className="bi bi-envelope"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                            </svg>
                          </span>
                        </div>

                        {/* <div className="banner-button-center"> */}
                        <input
                          id="mauticform_input_vibtreesignup_email"
                          name="mauticform[email]"
                          type="email"
                          className="form-control shadow foo-input-custom"
                          placeholder="Enter Your Email Address"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          required
                          style={{ maxHeight: '34px', overflow: 'hidden', maxWidth: '170px', fontSize: '14px', padding: '5px 0px 5px 0px' }}
                        />
                        <input id="mauticform_input_vibtreesignup_ref_page_url" name="mauticform[ref_page_url]" value={ref_page_url} className="mauticform-hidden" type="hidden" />
                        <input id="mauticform_input_vibtreesignup_service" name="mauticform[service]" value="footer" className="mauticform-hidden" type="hidden" />
                        <input id="mauticform_input_vibtreesignup_utm_source" name="mauticform[utm_source]" value={utm_source_s} className="mauticform-hidden" type="hidden" />
                        <input id="mauticform_input_vibtreesignup_utm_medium" name="mauticform[utm_medium]" value={utm_medium_s} className="mauticform-hidden" type="hidden" />
                        <input id="mauticform_input_vibtreesignup_utm_campaign" name="mauticform[utm_campaign]" value={utm_campaign_s} className="mauticform-hidden" type="hidden" />
                        <input id="mauticform_input_vibtreesignup_utm_term" name="mauticform[utm_term]" value={utm_term_s} className="mauticform-hidden" type="hidden" />
                        <input id="mauticform_input_vibtreesignup_utm_content" name="mauticform[utm_content]" value={utm_content_s} className="mauticform-hidden" type="hidden" />
                        <input id="mauticform_input_vibtreesignup_publisherid" name="mauticform[publisherid]" value={publisherid_s} className="mauticform-hidden" type="hidden"></input>

                        <input type="hidden" name="mauticform[formId]" id="mauticform_vibtreesignup_id" value="15" />
                        <input type="hidden" name="mauticform[return]" id="mauticform_vibtreesignup_return" value="" />
                        <input type="hidden" name="mauticform[formName]" id="mauticform_vibtreesignup_name" value="vibtreesignup"></input>
                        <span className="mauticform-errormsg" style={{ display: 'none' }}>This is required.</span>

                        <div className='banner-second-button'>
                          <button
                            name="mauticform[submit]"
                            id="mauticform_input_vibtreesignup_submit"
                            className="hvr-bounce-to-bottom1 hvr-bounce-to-bottom1-footer design1 foo-b-s"
                            type="submit"
                            style={{ maxHeight: '34px', borderRadius: '6px 6px 6px 6px', border: 'none', padding: '5px auto 10px auto !important' }}
                          >
                            Get Subscribe
                          </button>
                        </div>

                        <div className="input-group-append right footer-disappear-class-button">
                          <button
                            name="mauticform[submit]"
                            id="mauticform_input_vibtreesignup_submit"
                            className="hvr-bounce-to-bottom1 hvr-bounce-to-bottom1-footer design1 foo-b-s"
                            type="submit"
                            style={{
                              maxHeight: '34px',
                              borderRadius: '0px 6px 6px 0px',
                              border: 'none',
                              padding: '5px',
                              maxWidth: '200px'
                            }}>
                            Subscribe
                          </button>
                        </div>

                        {/* </div> */}

                      </div>
                    </form>
                  </div>
                  {/* form end  */}
                </div>

              </div>
              <hr className='foo-divider' />
              <div className="foo-third-row">
                <div className="foo-third-first">
                  <h2 className="foo-third-first-h2">Want to try Vibtree's <br />Voice Quality?</h2>
                  <a href='tel:18005724590' className="foo-third-first-p  vib-text-link">Give us a call on
                    &nbsp;  <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/book-a-call/india.svg' alt="indian-flag" className="indian-flag-front" style={{ width: '20px' }} />&nbsp;1800 572 4590  </a>
                </div>
                <div className="foo-third-second">
                  <div className="foo-third-second-icons">
                    <a href="https://www.facebook.com/vibtree"><img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/footer/facebook-icon.svg' alt="facebook-icon" className="" /></a>
                    <a href="https://www.instagram.com/vibtree/"><img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/footer/instagram-icon.svg' alt="instagram-icon" className="" /></a>
                    <a href="https://twitter.com/vibtree"><img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/footer/twitter-icon.svg' alt="twitter-icon" className="" /></a>
                    <a href="https://www.linkedin.com/company/vibtree/"><img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/footer/linkedin-icon.svg' alt="linkedin-icon" className="" /></a>
                    <a href="https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ"><img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/footer/youtube-icon.svg' alt="youtube-icon" className="" /></a>
                  </div>
                  <h2 className='foo-third-second-adress'>ANO-314, 3rd Floor, Astra Towers,<br />
                    Action Area: 2C/1, New town, Kolkata-700161
                  </h2>
                  <a href='mailto:info@vibtree.com' className='foo-third-second-email vib-text-link'>
                    Write To Us: Info@vibtree.com
                  </a>
                </div>
              </div>
            </div>

          </div>
        </footer>
      </div>


    </div>
  )
}
export default withRouter(FooterBase);
