import React, { useContext, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { useState } from 'react'
import { Helmet } from "react-helmet";
import './cloudNumber.css'

import BookYourFreeDemoPopUp from './BookYourFreeDemoPopUp';

import { vibContext } from 'src/App';
import LoaderComponent from 'src/LoaderComponent';
import GetStarted from 'src/GetStarted';

export default function CloudNumber() {

  // accordin states start
  const [accFirst, setaccFirst] = useState('yes');
  const [accSecond, setaccSecond] = useState('no');
  const [accThird, setaccThird] = useState('no');
  const [accFourth, setaccFourth] = useState('no');
  const [accFive, setaccFive] = useState('no');
  const [accSix, setaccSix] = useState('no');
  const [accSeven, setaccSeven] = useState('no');
  const [accEight, setaccEight] = useState('no');
  const [accNine, setaccNine] = useState('no');
  const [accTen, setaccTen] = useState('no');
  const [accEleven, setaccEleven] = useState('no');
  const [accTwelve, setaccTwelve] = useState('no');
  // accordin states end

  // feature states start 
  const [feaFirst, setfeaFirst] = useState('active');
  const [feaSecond, setfeaSecond] = useState('notActive');
  const [feaThird, setfeaThird] = useState('notActive');
  // feature states end 

  // get states start 
  const [get1, setget1] = useState('active');
  const [get2, setget2] = useState('notActive');
  const [get3, setget3] = useState('notActive');
  const [get4, setget4] = useState('notActive');
  const [get5, setget5] = useState('notActive');
  const [get6, setget6] = useState('notActive');
  // get states end 
  const [modalShow, setModalShow] = useState(false);

  const [ImagesLoadCount, setImagesLoadCount] = useState(0);
  const [loader, setloader] = useState(true);

  const get1g ='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/get1g.svg'
  const get2g ='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/get2g.svg'
  const get3g ='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/get3g.svg'
  const get4g ='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/get4g.svg'
  const get5g ='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/get5g.svg'
  const get6g ='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/get6g.svg'
  
  const get1c ='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/get1c.svg'
  const get2c ='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/get2c.svg'
  const get3c ='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/get3c.svg'
  const get4c ='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/get4c.svg'
  const get5c ='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/get5c.svg'
  const get6c ='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/get6c.svg'

  const cnContext= useContext(vibContext);
  const contextId=cnContext.vibState;
  useEffect(() => {
    const element = document.getElementById(contextId);
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0
      });
    }, 100);
  },[contextId]);

  let refferrerUrl=document.referrer
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

// accordin start 
var handleClick1=()=>{
  setaccSecond('no')
  setaccThird('no')
  setaccFourth('no')
  setaccFive('no')
  setaccSix('no')
  setaccSeven('no')
  setaccEight('no')
  setaccNine('no');
  setaccTen('no');
  setaccEleven('no');
  setaccTwelve('no');
  return(
    accFirst==='yes' ? setaccFirst('no') : accFirst==='no' ? setaccFirst('yes') : null
  )
}
var handleClick2=()=>{
  setaccFirst('no');
  setaccThird('no');
  setaccFourth('no');
  setaccFive('no');
  setaccSix('no');
  setaccSeven('no');
  setaccEight('no');
  setaccNine('no');
  setaccTen('no');
  setaccEleven('no');
  setaccTwelve('no');
  return(
    accSecond==='yes' ? setaccSecond('no') : accSecond==='no' ? setaccSecond('yes') : null
  )
}
var handleClick3=()=>{
  setaccFirst('no');
  setaccSecond('no');
  setaccFourth('no');
  setaccFive('no');
  setaccSix('no');
  setaccSeven('no');
  setaccEight('no');
  setaccNine('no');
  setaccTen('no');
  setaccEleven('no');
  setaccTwelve('no');
  return(
    accThird==='yes' ? setaccThird('no') : accThird==='no' ? setaccThird('yes') : null
  )
}
var handleClick4=()=>{
  setaccFirst('no');
  setaccSecond('no');
  setaccThird('no');
  setaccFive('no');
  setaccSix('no');
  setaccSeven('no');
  setaccEight('no');
  setaccNine('no');
  setaccTen('no');
  setaccEleven('no');
  setaccTwelve('no');
  return(
    accFourth==='yes' ? setaccFourth('no') : accFourth==='no' ? setaccFourth('yes') : null
  )
}
var handleClick5=()=>{
  setaccFirst('no');
  setaccSecond('no');
  setaccThird('no');
  setaccFourth('no');
  setaccSix('no');
  setaccSeven('no');
  setaccEight('no');
  setaccNine('no');
  setaccTen('no');
  setaccEleven('no');
  setaccTwelve('no');
  return(
    accFive==='yes' ? setaccFive('no') : accFive==='no' ? setaccFive('yes') : null
  )
}
var handleClick6=()=>{
  setaccFirst('no')
  setaccSecond('no')
  setaccThird('no')
  setaccFourth('no')
  setaccFive('no')
  setaccSeven('no')
  setaccEight('no')
  setaccNine('no');
  setaccTen('no');
  setaccEleven('no');
  setaccTwelve('no');
  return(
    accSix==='yes' ? setaccSix('no') : accSix==='no' ? setaccSix('yes') : null 
  )
}
var handleClick7=()=>{
  setaccFirst('no');
  setaccSecond('no');
  setaccThird('no');
  setaccFourth('no');
  setaccFive('no');
  setaccSix('no');
  setaccEight('no');
  setaccNine('no');
  setaccTen('no');
  setaccEleven('no');
  setaccTwelve('no');
  return(
    accSeven==='yes' ? setaccSeven('no') : accSeven==='no' ? setaccSeven('yes') : null
  )
}
var handleClick8=()=>{
  setaccFirst('no');
  setaccSecond('no');
  setaccThird('no');
  setaccFourth('no');
  setaccFive('no');
  setaccSix('no');
  setaccSeven('no');
  setaccNine('no');
  setaccTen('no');
  setaccEleven('no');
  setaccTwelve('no');
  return(
    accEight==='yes' ? setaccEight('no') : accEight==='no' ? setaccEight('yes') : null
  )
}
var handleClick9=()=>{
  setaccFirst('no');
  setaccSecond('no');
  setaccThird('no');
  setaccFourth('no');
  setaccFive('no');
  setaccSix('no');
  setaccSeven('no');
  setaccEight('no');
  setaccNine('no');
  setaccTen('no');
  setaccEleven('no');
  setaccTwelve('no');
  return(
    accNine==='yes' ? setaccNine('no') : accNine==='no' ? setaccNine('yes') : null
  )
}
var handleClick10=()=>{
  setaccFirst('no');
  setaccSecond('no');
  setaccThird('no');
  setaccFourth('no');
  setaccFive('no');
  setaccSix('no');
  setaccSeven('no');
  setaccEight('no');
  setaccNine('no');
  setaccEleven('no');
  setaccTwelve('no');
  return(
    accTen==='yes' ? setaccTen('no') : accTen==='no' ? setaccTen('yes') : null
  )
}
var handleClick11=()=>{
  setaccFirst('no');
  setaccSecond('no');
  setaccThird('no');
  setaccFourth('no');
  setaccFive('no');
  setaccSix('no');
  setaccSeven('no');
  setaccEight('no');
  setaccNine('no');
  setaccEleven('no');
  setaccTwelve('no');
  return(
    accEleven==='yes' ? setaccEleven('no') : accEleven==='no' ? setaccEleven('yes') : null
  )
}
var handleClick12=()=>{
  setaccFirst('no');
  setaccSecond('no');
  setaccThird('no');
  setaccFourth('no');
  setaccFive('no');
  setaccSix('no');
  setaccSeven('no');
  setaccEight('no');
  setaccNine('no');
  setaccEleven('no');
  setaccTwelve('no');
  return(
    accTwelve==='yes' ? setaccTwelve('no') : accTwelve==='no' ? setaccTwelve('yes') : null
  )
}
// accordin end 

// slider start 
const handleFeaClickOne=()=>{
  setfeaFirst('active');
  setfeaSecond('notActive');
  setfeaThird('notActive');
}
const handleFeaClickTwo=()=>{
  setfeaFirst('notActive');
  setfeaSecond('active');
  setfeaThird('notActive');
}
const handleFeaClickThree=()=>{
  setfeaFirst('notActive');
  setfeaSecond('notActive');
  setfeaThird('active');
}
// slider end 

// get functions start
const handleClickGet1 =()=>{
      setget1('active')
      setget2('notActive')
      setget3('notActive')
      setget4('notActive')
      setget5('notActive')
      setget6('notActive')
} 
const handleClickGet2 =()=>{
      setget1('notActive')
      setget2('active')
      setget3('notActive')
      setget4('notActive')
      setget5('notActive')
      setget6('notActive')
} 
const handleClickGet3 =()=>{
      setget1('notActive')
      setget2('notActive')
      setget3('active')
      setget4('notActive')
      setget5('notActive')
      setget6('notActive')
} 
const handleClickGet4 =()=>{
      setget1('notActive')
      setget2('notActive')
      setget3('notActive')
      setget4('active')
      setget5('notActive')
      setget6('notActive')
} 
const handleClickGet5 =()=>{
      setget1('notActive')
      setget2('notActive')
      setget3('notActive')
      setget4('notActive')
      setget5('active')
      setget6('notActive')
} 
const handleClickGet6 =()=>{
      setget1('notActive')
      setget2('notActive')
      setget3('notActive')
      setget4('notActive')
      setget5('notActive')
      setget6('active')
} 
const handleClickArrow =()=>{
 return(
   get1==='active'?handleClickGet2():
   get2==='active'?handleClickGet3():
   get3==='active'?handleClickGet4():
   get4==='active'?handleClickGet5():
   get5==='active'?handleClickGet6():handleClickGet1()
 );
}
const handleClickArrowFirst =()=>{
 return(
   get6==='active'?handleClickGet5():
   get5==='active'?handleClickGet4():
   get4==='active'?handleClickGet3():
   get3==='active'?handleClickGet2():
   get2==='active'?handleClickGet1():handleClickGet6()
 );
}
// get functions end 

  // image loading spinner start 
  const imagesLoadIndicator=()=>{
    const images = document.getElementsByClassName('classForLoaded');

    setImagesLoadCount(ImagesLoadCount+1);

    if (ImagesLoadCount===images.length-1) {
      setloader(false)
    }

  }
  // image loading spinner end

  return (
    
    <div style={{paddingBottom:'200px'}}>
        <Helmet>
        <title>Global Virtual Phone Numbers: Business VoIP Toll Free Number</title>
        {/* <link rel="icon" href={favicon} /> */}
        <meta name="description" content="Get local Toll Free virtual phone number for business calls today! We provide Virtual Numbers, DID number & VoIP Toll-Free Numbers via API or WEB for 20+ countries" />
        <link rel="canonical" href="https://www.vibtree.com/products/cloud-number/" />
        {/* og tags start  */}
        {/* <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Global Virtual Phone Numbers: Business VoIP Toll Free Number" />
        <meta property="og:description" content="Get local Toll Free virtual phone number for business calls today! We provide Virtual Numbers, DID number & VoIP Toll-Free Numbers via API or WEB for 20+ countries" />
        <meta property="og:url" content="https://www.vibtree.com/products/cloud-number/" />
        <meta property="og:site_name" content="Vibtree Technologies - Award winning cloud telephony service" />
        <meta property="og:image" content='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/global-business-local-calls.svg'/>
        <meta property="og:image:secure_url" content='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/global-business-local-calls.svg' />
        <meta property="og:image:width" content="700" />
        <meta property="og:image:height" content="700" />
        <meta property="og:image:alt" content="Award winning cloud telephony service"/>
        <meta property="og:image:type" content="image/svg" /> */}
        {/* og tags end  */}
        {/* twitter tag start  */}
        {/* <meta name= "twitter:site" content="@vibtree"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Global Virtual Phone Numbers: Business VoIP Toll Free Number" />
        <meta name="twitter:description" content="Get local Toll Free virtual phone number for business calls today! We provide Virtual Numbers, DID number & VoIP Toll-Free Numbers via API or WEB for 20+ countries" />
        <meta name="twitter:image" content='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/global-business-local-calls.svg' /> */}
        {/* twitter tag end  */}
        <script  type="application/ld+json">
        {`{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "Vibtree Technologies",
            "legalName": "Vibtree Technologies",
            "alternateName": "www.vibtree.com",
            "description": "Get local Toll Free virtual phone number for business calls today! We provide Virtual Numbers, DID number & VoIP Toll-Free Numbers via API or WEB for 20+ countries",
            "url": "https://www.vibtree.com/products/cloud-number/",
            "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
            "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "18005724590",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/vibtree",
              "https://twitter.com/vibtree",
              "https://www.instagram.com/vibtree",
              "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
              "https://www.linkedin.com/company/vibtree",
              "https://www.vibtree.com"
            ]}`}
        </script>

        <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "products"
                    }
                },{
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@id": "https://www.vibtree.com/products/cloud-number/",
                        "name": "cloud-number"
                    }
                }]
            }`}
        </script>
        
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What is a cloud number?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Cloud number is a DID number (Direct inward dialing) or Virtual 
            number is used to provide local telephone numbers for customers 
            on a worldwide basis. Also called as DDI number in Europe, 
            it is used to provide access from regular telephones to 
            non-PSTN destinations such as VoIP."
              }
            }, {
              "@type": "Question",
              "name": "How Can I buy your services?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "It is easy to buy cloud numbers or other cloud telephony 
            services offered by Vibtree online. Simply login to your account 
            and through your dashboard you can order the services you need 
            and scale with your business. For more information or help for 
            purchasing services online, email us at sales@vibtree.com."
              }
            }, {
              "@type": "Question",
              "name": "What is a Virtual number?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": " A virtual number is a telephone number without a directly 
            associated traditional telephone line. Virtual phone numbers 
            do not require a SIM card or a physical address. It uses 
            secure internet line to place real telephone calls between 
            2 numbers. Virtual numbers accept calls using VoIP 
            (Voice Over Internet Protocol), you can instantly buy and activate 
            local, virtual numbers for your business communication, by using 
            Vibtree cloud numbers."
              }
            }, {
              "@type": "Question",
              "name": "What is the use of DID number or Virtual number?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "By using Vibtree cloud numbers- DID numbers or virtual numbers, 
            users can make international calls on local call rates. 
            Say, if you live in India, and you have your clients or 
            relatives in some other country, for example- US. Since 
            traditional international calling is otherwise costly; 
            it will cost you much more to manage your leads or stay in 
            touch with your relatives. This will not only restrict the 
            usage but also affect your business operations and customer 
            experience. So why not get a DID number in the US to make calls 
            to your US business consumers or potential customers or relatives 
            at relatively cheaper rates? Similarly, users from other countries 
            can buy Indian VoIP number or US VoIP numbers instantly from our 
            portal. 
            Incoming calls on your virtual DID number can be forwarded to 
            landline, mobile phones anywhere in the world, at much lower 
            rates per minute. 
            Incoming calls on your virtual DID number can be forwarded to landline, 
            mobile phones anywhere in the world, at much lower rates per minute. 
            To get more information on purchasing DID numbers, 
            email us at sales@vibtree.com."
              }
            }, {
              "@type": "Question",
              "name": "Is it possible to link a DID number to a standard telephone number?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes. This method is referred to as PSTN forwarding which allows you 
            to receive calls that are made to your DID numbers on the mobile phones 
            or regular landlines, anywhere around the World at lower costs per minute. 
            This doesn't need a PC, Internet or VoIP services and what's better 
            is that you can actually forward a few selected or all your DID numbers 
            to the same PSTN number. 
            To get more information on this, you can email us at support@vibtree.com."
              }
            }, {
              "@type": "Question",
              "name": "How can I buy additional channels for my DIDs?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Capacity of your DID number can be increased any time by making a 
            direct purchase through your online account or by contacting our 
            sales team for additional volume at sales@vibtree.com.   
            To get more information on this, you can email us at support@vibtree.com."
              }
            }, {
              "@type": "Question",
              "name": "Is it possible to port my number from other providers in to Vibtree cloud numbers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Vibtree currently does not support porting in numbers from 
            other providers. To learn more about porting 
            numbers, write to us at support@vibtree.com.  
            We are on a constant journey to grow our services, so we 
            keep working towards adding new regions to our coverage. 
            If you do not find a country or region of your choice on the website, 
            write to us and we can serve you with your requirements."
              }
            }, {
              "@type": "Question",
              "name": "Can I buy a number of my choice?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Selecting and purchasing your DID number or virtual number 
            online is quite an easy process. For purchasing numbers you 
            will need to enter the country, city name or prefix, 
            and the type of number required; however you can search 
            for a specific phone number on our user panel. We have a 
            huge listing of numbers available worldwide, so you can 
            easily find the number you are looking for. For more 
            information or help on this, write to us on support@vibtree.com."
              }
            }, {
              "@type": "Question",
              "name": "Can I send SMS using the Vibtree cloud numbers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, Vibtree cloud numbers can be bundled with other cloud 
            telephony services like cloud SMS easily. 
            This will allow you to send SMS using your cloud number."
              }
            }, {
              "@type": "Question",
              "name": "How much does it cost to get a DID number or a virtual number?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The complete price list for Vibtree 
            cloud numbers can be found on the user's online account at"
              }
            }, {
              "@type": "Question",
              "name": "What are the end-user restrictions for DID numbers or virtual numbers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "End user restrictions are mentioned during the purchase process. 
            For more information on this, please email us at support@vibtree.com."
              }
            }, {
              "@type": "Question",
              "name": "Trying to get USA VoIP Number?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Call on 1800 573 4590 now!"
              }
            }]
          }`}
        </script>
     
        </Helmet>
        {/* body schema tag start  */}
        <body itemScope itemType="https://schema.org/WebPage"></body>
        {/* body schema tag end  */}
        {loader===true ? <LoaderComponent/> : null}
        
    {/* banner start  */}
      <div className="banner sms-banner" 
      style={{
        position:"relative",
        top:"-20px", 
        minHeight:"600px",
        paddingTop:"50px",
        overflow:'hidden',
        paddingRight:'0px',
        paddingLeft:'0px'}}>
          <div className="container-fluid">
            <div className="row align-items-center vh-100-middle  voice-allow-overflow vib-flex-wrap-reverse pb-5">
              <div className="col-lg-7 for-padding-remove voice-allow-overflow"  style={{marginBottom:'-20px'}}>
              <h1 className='vib-container-heading vib-res-left voice-allow-overflow' 
              style={{marginTop:'60px',fontSize:'50px',lineHeight:'70px'}}>
              Global Business, 
              <span className="vib-blue"> Local Calls</span>
              </h1>
                <h5 className='vib-bold-para vib-res-left  voice-allow-overflow' 
                style={{marginBottom:'30px',fontSize:'28px',marginTop:'20px',lineHeight:'40px'}}>
                With Vibtree's low-cost high-coverage Cloud Numbers around the Globe.
                </h5>
                
                <GetStarted/>

                {/* <div className="row mt-3 bannerresfor" style={{Height:"300px"}}>
                  <div className="col-12 p-0 banner-logo-slider" style={{position:"relative",left:"-13px",top:"20px",maxHeight:"170px"}}>
                    <div className="responsive-banner-logos">

                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-5 banner-breaking-margin-1000" style={{display:'flex',justifyContent:'center',marginTop:'50px'}}>
                <img 
                onLoad={imagesLoadIndicator} 
                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/global-business-local-calls.svg' 
                className="img-fluid bannerdivimg classForLoaded" 
                alt='global-business-local-calls'/>
              </div>
            </div>
          </div>
        </div>
    {/* banner end  */}

    {/* cloud number start  */}
    <div className="vib-container">
      <div className="cn-num-left-left" style={{maxWidth:'730px'}}>

        <h1 id='call-forwarding' className='vib-container-sub-heading vib-res-left px-3' style={{fontSize:'33px',marginTop:'50px'}}>
        What are<span className='vib-blue'> Vibtree Cloud Numbers ?</span>
        </h1> 
        <h5 className='vib-bold-para vib-res-left px-3' 
        style={{marginTop:'10px',fontSize:'24px',lineHeight:'36px',marginLeft:'0px',marginRight:'0px'}}>
          Vibtree cloud number is also known as Virtual Number or 
          DID Number. It can be a Local, Mobile or a Tollfree Number. 
          By using Vibtree’s Cloud Number one can make or receive 
          calls from anywhere. Forget about bulky Landline Phone, 
          Handsets or Costly Smartfones. No need of sim cards or 
          costly server setup. You are just a click away to 
          experiance the magic of cloud number. <br /><br /> 
          Choose local, national, mobile, toll-free & Virtual 
          numbers or DID numbers from our readily available cloud 
          number listings and activate immediately by using our 
          web portal or via the API.  <br /><br />
          {/* <span  className='vib-blue'>Read More &gt;</span> */}
        </h5> 

      </div>

      <div className="cn-num-left-right 
                      vib-res-neg-margin-top-disappear-50
                      cn-padding-top-res" 
           style={{maxWidth:'490px',display:'flex',alignItems:'center'}}>
         <img 
               onLoad={imagesLoadIndicator} 
               src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/what-are-vibtree-cloud-numbers.png' 
               alt="what-are-vibtree-cloud-numbers" 
               className='img-fluid classForLoaded'/>
      </div>
    </div>
    {/* cloud number end  */}

    {/* our coverage start*/}
    <div className="vib-container coverage-image-top">
    <h1 className='vib-container-sub-heading vib-res-center coverage-image-top' style={{fontSize:'50px',marginTop:'50px'}}>
       Our<span className='vib-blue'> Coverage</span>
    </h1>
    <h5 className='vib-bold-para vib-res-center px-3' 
        style={{fontSize:'26px', lineHeight:'35px',fontWeight:'normal'}}>
          Call Globally and Pay Locally with virtual business numbers and manage your leads <br />with our state of the art cloud telephony solutions. 
    </h5>
    <div className="coverage-image vib-res-margin-top-disappear-i  coverage-image-top" style={{display:'flex',justifyContent:'center'}}>
      <img 
           onLoad={imagesLoadIndicator} 
           src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/vibtree-coverage.svg' 
           alt="vibtree-coverage" 
           className='img-fluid classForLoaded'/>
    </div>
    </div>
    {/* our coverage end  */}

    {/* features start  */}
    <div className="vib-container-narrow vib-res-margin-top-200-100" 
         style={{overflowX:'hidden'}}>
        <h1 
        id='virtual-numbers'
        className='vib-container-heading vib-res-center' 
        style={{
          fontWeight:'bold',
          fontSize:'34px',
          margin:'auto',
          overflow: 'hidden'
          }}>
           Top Highlights of<span className="vib-blue px-3">Vibtree Cloud Numbers</span> 
        </h1>
        <h5 className='vib-bold-para vib-res-center px-3' 
        style={{fontSize:'26px', lineHeight:'35px',fontWeight:'normal'}}>
          We offer premium quality global voice calling services combined with the largest <br />
          international coverage of our cloud numbers with local, national, mobile 
          and toll-free virtual numbers. 
        </h5>

        {/* features slider start  */}
        <div className="fea-slider-container">
          <div className="fea-slider-side-indicators-container 
                          vib-res-margin-top-50-50"
               style={{overflow:"hidden"}}>
            <div className="fea-slider-side-indicators">
              <div className="clo-top-line"></div>
              {/* <div className="clo-top-line-appear"></div> */}
                <div className="first-ver-indicator" 
                style={{marginTop:'20px'}} 
                onClick={handleFeaClickOne}> 
                   <p 
                   className={feaFirst==='notActive'?'vib-bold-para':
                              feaFirst==='active'? 'vib-bold-para active-word-class':null} 
                   style={{marginTop:'0px'}}>Virtual Numbers</p> 
                   <div 
                   className={feaFirst==='notActive'?'hor-line':
                              feaFirst==='active'? 'hor-line active-hor-class':null}>
                   </div>
                </div>

                <div className="first-ver-indicator" 
                // style={{marginTop:'20px'}} 
                onClick={handleFeaClickTwo}> 
                   <p 
                   className={feaSecond==='notActive'?'vib-bold-para':
                              feaSecond==='active'? 'vib-bold-para active-word-class':null} 
                   style={{marginTop:'0px'}}>Toll Free Numbers</p> 
                   <div 
                   className={feaSecond==='notActive'?'hor-line':
                              feaSecond==='active'? 'hor-line active-hor-class':null}>
                   </div>
                </div>

                <div className="first-ver-indicator fea-margin-bottom" 
                // style={{marginTop:'20px'}} 
                onClick={handleFeaClickThree}> 
                   <p 
                   className={feaThird==='notActive'?'vib-bold-para':
                              feaThird==='active'? 'vib-bold-para active-word-class':null} 
                   style={{marginTop:'0px'}}>Business System</p> 
                   <div 
                   className={feaThird==='notActive'?'hor-line':
                              feaThird==='active'? 'hor-line active-hor-class':null}>
                   </div>
                </div>
              {/* <div className="clo-top-line-appear"></div> */}
              <div className="clo-bottom-line"></div>
            </div>
          </div>
          <div className="fea-slider-content vib-res-margin-top-100-50" style={{overflow:'visible'}}>
            <div className="fea-slider-img-container"
                 style={{position:'relative'}}>
            <img onLoad={imagesLoadIndicator} 
            style={{width:'700px',height:'auto',overflow:'visible'}}
            className='classForLoaded'
            src={feaFirst==='active'? 
                 'https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/virtual-numbers.svg':
                 feaSecond==='active'? 
                 'https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/toll-free-numbers.svg':
                 feaThird==='active'? 
                 'https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/business-system.svg':
                 'https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/virtual-numbers.svg'} 
            alt="virtual-numbers" />

                {feaFirst==='active'?

                <h5 className='vib-bold-para vib-res-center px-3 cn-top-highlights-extra-length' 
                style={{fontSize:'18px', lineHeight:'33px',fontWeight:'normal'}}>
                  Instantly buy and activate local, virtual numbers for your business 
                  communication over VoIP, by using Vibtree cloud numbers. 
                  Now work global but call local with our ready to use virtual 
                  numbers that builds up your reachability.  
                </h5>:

                feaSecond==='active'?

                <h5 className='vib-bold-para vib-res-center px-3 cn-top-highlights-extra-length' 
                style={{fontSize:'18px', lineHeight:'33px',fontWeight:'normal'}}>
                  Vibtree offers Toll-Free numbers with a distinct 3-digit 
                  area code so that your customers can reach your business 
                  without paying a single penny. Some of the common toll-free 
                  numbers are 1800 numbers. 
                </h5>:

                feaThird==='active'?

                <h5 className='vib-bold-para vib-res-center px-3 cn-top-highlights-extra-length' 
                style={{fontSize:'18px', lineHeight:'33px',fontWeight:'normal'}}>
                  Combine your business cloud numbers like toll free virtual numbers 
                  & DID numbers with other cloud telephony services like call recording, 
                  cloud phone, IVR Studio(drag & drop IVR), smart caller(auto dialer), 
                  call monitoring, call forwarding, and audio playback etc. 
                </h5>
                
                :null}
               
            </div>
            <div className="fea-dot-indicators">
               <div className={feaFirst==='notActive'?'firstDot':
                              feaFirst==='active'? 'firstDot active-dot-class':null} 
                              onClick={handleFeaClickOne}>
               </div>
               <div className={feaSecond==='notActive'?'secondDot':
                              feaSecond==='active'? 'secondDot active-dot-class':null} 
                              onClick={handleFeaClickTwo}>
               </div>
               <div className={feaThird==='notActive'?'thirdDot':
                              feaThird==='active'? 'thirdDot active-dot-class':null} 
                              onClick={handleFeaClickThree}>
                </div>
            </div>
            </div>
          </div>
        {/* features slider end  */}
    </div>    
    {/* features end  */}

    {/* get your local start  */}
    <div className="vib-container vib-res-margin-top-300-250" style={{marginTop:"300px !important"}}>
        <h1 className='vib-container-heading vib-res-center vib-res-margin-top-100-50' style={{fontWeight:'700',fontSize:'50px'}}>
        Why Choose Vibtree To Get Your <br />
        <span className="vib-blue">Local Phone Numbers?</span> 
        </h1>
    </div>
        {/* slider start  */}
        <div className="clo-get-slider">

          <div className="clo-get-slider-navbar  vib-res-margin-top-100-50">
            <div className="clo-three-first">

            <div 
            className={get1==='active'?'clo-get-nav-img-con clo-get-nav-img-con-active':
                       get1==='notActive'?'clo-get-nav-img-con':null}
            onClick={handleClickGet1}>
            {/* onClick={handleClickGet1} */}
             {/* <div className="clo-img-bigger-size" style={{width:'45px',height:'45px'}}> */}
              <img onLoad={imagesLoadIndicator} className='classForLoaded' src={get1==='active'?get1c:get1==='notActive'?get1g:null} alt="hand full of money"/>
             {/* </div> */}
              <div 
              className={get1==='active'?"clo-get-blue-con clo-get-blue-con-active":
                         get1==='notActive'?"clo-get-blue-con":null}>
              </div>
            </div>

            <div 
            className={get2==='active'?'clo-get-nav-img-con clo-get-nav-img-con-active':
                       get2==='notActive'?'clo-get-nav-img-con':null} 
            onClick={handleClickGet2}>
              <img onLoad={imagesLoadIndicator} className='classForLoaded' src={get2==='active'?get2c:get2==='notActive'?get2g:null} alt="hand full of money" />
              <div 
              className={get2==='active'?"clo-get-blue-con clo-get-blue-con-active":
                         get2==='notActive'?"clo-get-blue-con":null}></div>
            </div>

            <div 
            className={get3==='active'?'clo-get-nav-img-con clo-get-nav-img-con-active clo-get-nav-img-con01':
                       get3==='notActive'?'clo-get-nav-img-con clo-get-nav-img-con01':null}
            onClick={handleClickGet3}>
              <img onLoad={imagesLoadIndicator} className='classForLoaded' src={get3==='active'?get3c:get3==='notActive'?get3g:null} alt="hand full of money" />
              <div className={get3==='active'?"clo-get-blue-con clo-get-blue-con-active":get3==='notActive'?"clo-get-blue-con":null}></div>
            </div>
            </div>

            <div className="clo-three-first">

            <div 
            className={get4==='active'?'clo-get-nav-img-con clo-get-nav-img-con-active clo-get-nav-img-con1':
                       get4==='notActive'?'clo-get-nav-img-con clo-get-nav-img-con1':null}
            onClick={handleClickGet4}>
              <img onLoad={imagesLoadIndicator} className='classForLoaded' src={get4==='active'?get4c:get4==='notActive'?get4g:null} alt="hand full of money" />
              <div className={get4==='active'?"clo-get-blue-con clo-get-blue-con-active":get4==='notActive'?"clo-get-blue-con":null}></div>
            </div>

            <div 
            className={get5==='active'?'clo-get-nav-img-con clo-get-nav-img-con-active':
                       get5==='notActive'?'clo-get-nav-img-con':null}
             onClick={handleClickGet5}>
              <img onLoad={imagesLoadIndicator} className='classForLoaded' src={get5==='active'?get5c:get5==='notActive'?get5g:null} alt="hand full of money" />
              <div className={get5==='active'?"clo-get-blue-con clo-get-blue-con-active":get5==='notActive'?"clo-get-blue-con":null}></div>
            </div>

            <div 
            className={get6==='active'?'clo-get-nav-img-con clo-get-nav-img-con-active':
                       get6==='notActive'?'clo-get-nav-img-con':null}
             onClick={handleClickGet6}>
              <img onLoad={imagesLoadIndicator} className='classForLoaded' src={get6==='active'?get6c:get6==='notActive'?get6g:null} alt="hand full of money" />
              <div className={get6==='active'?"clo-get-blue-con clo-get-blue-con-active":get6==='notActive'?"clo-get-blue-con":null}></div>
            </div>

            </div>
          </div>

          <div className="clo-get-slider-img" style={{marginTop:'30px'}}>
             <div className="clo-img-cont">
               
            {get1==='active'?
            <img 
                 onLoad={imagesLoadIndicator} 
                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/local-phone-numbers-pay-as-you-go.svg' 
                 alt="local-phone-numbers-pay-as-you-go" 
                 className='clo-demo classForLoaded'/>:

             get2==='active'?<img 
                                 onLoad={imagesLoadIndicator} 
                                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/local-phone-numbers-plans-and-pricing.svg' 
                                 alt="local-phone-numbers-plans-and-pricing" 
                                 className='clo-demo classForLoaded'/>: 

             get3==='active'?<img 
                                 onLoad={imagesLoadIndicator} 
                                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/local-phone-numbers-call-forwarding.svg' 
                                 alt="local-phone-numbers-call-forwarding" 
                                 className='clo-demo classForLoaded' 
                                 style={{padding:'40px',position:'relative',top:'-50px'}}/>: 

             get4==='active'?<img 
                                 onLoad={imagesLoadIndicator} 
                                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/local-phone-numbers-world-wide-coverage.svg' 
                                 alt="local-phone-numbers-world-wide-coverage" 
                                 className='clo-demo classForLoaded'
                                 style={{padding:'40px',position:'relative',top:'-50px'}}/>:

             get5==='active'?<img 
                                 onLoad={imagesLoadIndicator} 
                                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/local-phone-numbers-simple-portal-easy-to-buy.svg' 
                                 alt="local-phone-numbers-simple-portal-easy-to-buy" 
                                 className='clo-demo classForLoaded'/>: 

             get6==='active'?<img 
                                 onLoad={imagesLoadIndicator} 
                                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/local-phone-numbers-integrate-with-other-apps.svg' 
                                 alt="local-phone-numbers-integrate-with-other-apps" 
                                 className='clo-demo classForLoaded'/>:
             
             null} 
              
             
             </div>
              <div 
              className="clo-get-arrow-small-first" 
              onClick={handleClickArrowFirst}
              style={get1==='active'?{visibility:'hidden'}:{visibility:'visible'}}>
                <img 
                     onLoad={imagesLoadIndicator} 
                     className='classForLoaded' 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/rightArrow.svg' 
                     alt="right arrow" 
                     style={{transform:'rotate(180deg)'}}/>
              </div>
              <div 
              className="clo-get-arrow-small" 
              onClick={handleClickArrow}
              style={get6==='active'?{visibility:'hidden'}:{visibility:'visible'}}>
                <img 
                     onLoad={imagesLoadIndicator} 
                     className='classForLoaded' 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/rightArrow.svg' 
                     alt="right arrow" />
              </div>
              <div className="clo-get-bottom-button">
                {get1==='active'?<p>Pay-As-You-Go</p>:
                 get2==='active'?<p>Plans & Pricing</p>: 
                 get3==='active'?<p>Call Forwarding</p>: 
                 get4==='active'?<p>Worldwide Coverage</p>: 
                 get5==='active'?<p className='cn-font-size-15-600'>
                                    Simple Portal- Easy To Buy
                                 </p>: 
                 get6==='active'?<p className='cn-font-size-15-600'>
                                    Integrate With Other Apps
                                 </p>:
                 null}
              </div>
          </div>
        </div>
        {/* slider end  */}
    <div 
    // className="vib-container clo-get-position-up"
    // className={positionUp==="down"?"vib-container":
    //            positionUp==="up"?"vib-container clo-get-position-up":null} 
    className="vib-container"
    style={{marginTop:'0px'}}>
    {get1==='active'?
        <h5 className='vib-bold-para vib-res-center px-3' 
            style={{fontSize:'26px', 
            lineHeight:'35px',
            maxWidth:'1120px',
            fontWeight:'400'}}>
        Get your virtual toll free numbers or VoIP toll free numbers without a prior commitment. 
        Simply pay as you go according to the services or bundled 
        services that you are using to enhance your business operations and 
        communication.   
        </h5>:get2==='active'?

        <h5 className='vib-bold-para vib-res-center px-3' 
        style={{fontSize:'26px', lineHeight:'35px',maxWidth:'1120px'}}>
        Flexible pricing plans make it easier for you to club various services like cloud phone, 
        cloud numbers, call recording, call flows, cloud SMS, smart caller and others based on 
        your changing business requirements.    
        </h5>:get3==='active'?

        <h5 className='vib-bold-para vib-res-center px-3' 
        style={{fontSize:'26px', lineHeight:'35px',maxWidth:'1120px'}}>
        Our services are compatible with all landlines, mobile phones, IP phones and adapters, 
        which ensure easy call forwarding with our business class telephony 
        services around the World.    
        </h5>:get4==='active'?

        <h5 className='vib-bold-para vib-res-center px-3' 
        style={{fontSize:'26px', lineHeight:'35px',maxWidth:'1120px'}}>
        We offer local, mobile, VoIP toll-free numbers, and virtual numbers or DID numbers 
        for more than 20 countries worldwide. This makes it easy for you to manage your 
        International clients at local costs. 
        </h5>:get5==='active'?

        <h5 className='vib-bold-para vib-res-center px-3' 
        style={{fontSize:'26px', lineHeight:'35px',maxWidth:'1120px'}}>
        Vibtree has an easy to use portal from where you can pick your choice of local, 
        mobile, national virtual number and buy instantly. Activation only takes a few 
        hours and you are ready to get on with your business as usual.    
        </h5>:get6==='active'?

        <h5 className='vib-bold-para vib-res-center px-3' 
        style={{fontSize:'26px', lineHeight:'35px',maxWidth:'1120px'}}>
        Enhance your business operations by using cloud numbers with our other cloud 
        telephony services that will help you boost your customer experience and manage 
        your leads with less spend per lead.    
        </h5>:null}
    </div>
    {/* get your local end  */}

    {/* how to get start  */}
     <h1 className='vib-container-sub-heading vib-res-center' style={{fontSize:'33px',marginTop:'150px'}}>
         How To Get Your <span className='vib-blue'> Cloud Numbers</span> Instantly ?         
     </h1>
        <div className="vib-inner-section vib-container">

            <div className="vib-content-section">
              <h1 className='vib-container-sub-heading vib-res-left font-montesarrat-bold-700' 
                  style={{fontSize:'68px',color:'#D3D5D8'}}>
                 #1
              </h1>
              <h5 className='vib-bold-para vib-res-left' 
              style={{ fontSize:'26px',
                       fontWeight:'600',
                       color:'#464646',
                       marginTop:'0px',
                       lineHeight:'35px'}}>
              Signup on Vibtree's Cloud Telephony<br />
              Platform 
              </h5>
            </div>

            <div className="vib-img-section-num" style={{maxWidth:'620px',overflowX:'scroll'}}>
                <img 
                      onLoad={imagesLoadIndicator} 
                      src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/cloud-numbers-instantly-signup-on-vibtree-cloud-telephony.svg' 
                      alt="cloud-numbers-instantly-signup-on-vibtree-cloud-telephony" 
                      className='vib-img classForLoaded' 
                      style={{minWidth:'600px'}}/>
            </div>

        </div>

        <div className="vib-inner-section-reverse 
                        vib-container
                        sms-margin-top-10">

            <div className="vib-img-section-num" style={{maxWidth:'620px',overflowX:'scroll'}}>
                  <img 
                      onLoad={imagesLoadIndicator} 
                      src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/cloud-numbers-instantly-buy-your-local-toll-free-number.svg' 
                      alt="cloud-numbers-instantly-buy-your-local-toll-free-number" 
                      className='vib-img classForLoaded'  
                      style={{minWidth:'600px'}}/>

            </div>

            <div className="vib-content-section">
              <h1 className='vib-container-sub-heading vib-res-left font-montesarrat-bold-700'
                  style={{fontSize:'68px',color:'#D3D5D8', lineHeight:'35px'}}>
                 #2
              </h1>

              <h5 
              id='toll-free-number'
              className='vib-bold-para vib-res-left' 
              style={{ fontSize:'26px',
                       fontWeight:'600',
                       color:'#464646',
                       marginTop:'20px'}}>
                  Buy Your Local/Toll-free Number(s) 
              </h5>
            </div>

        </div>

        <div className="vib-inner-section 
                        vib-container  
                        sms-margin-top-10">

            <div className="vib-content-section ">
              <h1 className='vib-container-sub-heading vib-res-left font-montesarrat-bold-700'
                  style={{fontSize:'68px',color:'#D3D5D8'}}>
                #3
              </h1>
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{ fontSize:'26px',
                       fontWeight:'600',
                       color:'#464646',
                       marginTop:'0px',
                       lineHeight:'35px'}}>
                  Start receiving calls/Text instantly
              </h5>
            </div>

            <div className="vib-img-section-num" style={{maxWidth:'620px',overflowX:'scroll'}}>
                <img 
                     onLoad={imagesLoadIndicator} 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/cloud-number/cloud-numbers-instantly-start-receiving-calls-text-instantly.svg' 
                     alt="cloud-numbers-instantly-start-receiving-calls-text-instantly" 
                     className='vib-img classForLoaded'  
                     style={{minWidth:'600px'}}/>
            </div>

        </div>
    {/* how to get end */}

    {/* Book Your Free Demo section start*/}
    <section className="book-a-call vib-res-margin-top-100-50" style={{overflow:'hidden'}}>
        <div className="container-fluid">
          <div className="row book-for-display-center d-flex vib-justify-content-evenly ">
            <div className="col-lg-6 text-start book-for-half">
              <div className="wrap">
                <h2 className="text-start heading-extra-length-180 vib-res-left vib-container-sub-heading" style={{fontSize:'33px',fontWeight:'700'}}>
                Looking For More Customer Management Tools On Cloud?
                </h2>
                <p className='vib-bold-para  heading-extra-length vib-res-left vib-container-sub-heading' style={{fontSize:'33px',lineHeight:'45px',marginTop:'10px',fontWeight:'400'}}>
                Explore our cloud telephony business suite
                </p>
                <div className="about-button-center">

                  <p href className="hvr-bounce-to-bottom1 design1" 
                      style={{
                        backgroundColor:'#FFCE42',
                        color:'black',
                        fontWeight:'300',
                        fontSize:'26px',
                        padding:'5px',
                        width:'290px',
                        marginBottom:'50px'}}
                      onClick={() => setModalShow(true)}>
                      Book Your Free Demo
                  </p>
                  <BookYourFreeDemoPopUp
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                  />

                  </div>
              </div>
            </div>
            <div className="d-flex"
                 style={{display:'flex'}}>
              <img onLoad={imagesLoadIndicator}
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/let-your-business-be-heard.svg' 
                    className="img-fluid 
                               book-image-now 
                               book-img-disappear 
                               vib-sms-left-margin-auto
                               classForLoaded" 
                    alt="let-your-business-be-heard"
                      />
              {/* <center><img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/let-your-business-be-heard.svg' alt="callnow" className='book-image-now book-img-appear'/></center> */}
              {/* <center> */}
              <img onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/let-your-business-be-heard.svg' 
              alt="let-your-business-be-heard" 
              className='book-image-now book-img-appear classForLoaded'
              style={{margin:'auto'}}
              />
              {/* </center> */}
            </div>
          </div>
        </div>
      </section>
    {/* Book Your Free Demo end */}

    {/* FAQ section start  */}
    <div className="vib-container vib-res-margin-top-200-100">
      <h1 className='vib-container-heading vib-res-center' style={{width:'100%',fontSize:'33px'}}>
      Frequently Asked Questions:<span className="vib-blue"> Cloud Numbers</span>
      </h1>
    </div> 
    <div className="vib-container-narrow sms-accordin-margin-top" style={{marginTop:'50px'}}>
      <Accordion defaultActiveKey="0" style={{width:'100%', padding:'20px'}}>

      <Card className='accordin-card' style={{marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="0"
        onClick={handleClick1} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white',
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> What is a cloud number?</span>
           <div className="accordin-right-icon" 
           style={{transform: accFirst==='yes' ? 'rotate(90deg)':accFirst==='no'? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> Cloud number is a DID number (Direct inward dialing) or Virtual 
            number is used to provide local telephone numbers for customers 
            on a worldwide basis. Also called as DDI number in Europe, 
            it is used to provide access from regular telephones to 
            non-PSTN destinations such as VoIP.  
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className='accordin-card' style={{marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="1" 
        onClick={handleClick2} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> How Can I buy Vibtree cloud numbers for business?</span>
           <div className="accordin-right-icon" 
           style={{transform: accSecond==='yes' ? 'rotate(90deg)':accSecond==='no'? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> It is easy to buy cloud numbers or other cloud telephony 
            services offered by Vibtree online. Simply login to your account 
            and through your dashboard you can order the services you need 
            and scale with your business. For more information or help for 
            purchasing services online, email us at <a href="mailto:sales@vibtree.com" className="vib-h-b-u" style={{color:'inherit'}}>sales@vibtree.com.</a>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      
      <Card className='accordin-card' style={{marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="1" 
        onClick={handleClick2} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> What is a toll-free number?</span>
           <div className="accordin-right-icon" 
           style={{transform: accSecond==='yes' ? 'rotate(90deg)':accSecond==='no'? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> Toll free numbers are a 
            type of cloud numbers that allow customers to call your business 
            without paying any call charges. A toll free number is normally a 
            1800 series number that anyone across the country can dial it without 
            being charged for it. Help your business build a strong brand presence 
            with special cloud numbers like toll free, virtual numbers 
            and vanity numbers. 
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className='accordin-card' style={{marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="2" 
        onClick={handleClick3} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> What is a Virtual number?</span>
           <div className="accordin-right-icon" 
           style={{transform: accThird==='yes' ? 'rotate(90deg)':accThird==='no'? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> A virtual number is a telephone number without a directly 
            associated traditional telephone line. Virtual phone numbers 
            do not require a SIM card or a physical address. It uses 
            secure internet line to place real telephone calls between 
            2 numbers. Virtual cloud numbers accept calls using VoIP 
            (Voice Over Internet Protocol), you can instantly buy and activate 
            local, virtual numbers for your business communication, by using 
            Vibtree cloud numbers.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className='accordin-card' style={{marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="3" 
        onClick={handleClick4} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> What is the use of DID number or Virtual number?</span>
           <div className="accordin-right-icon" 
           style={{transform: accFourth==='yes' ? 'rotate(90deg)':accFourth==='no'? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> By using Vibtree cloud numbers- DID numbers or virtual numbers, 
            users can make international calls on local call rates. 
            Say, if you live in India, and you have your clients or 
            relatives in some other country, for example- US. Since 
            traditional international calling is otherwise costly; 
            it will cost you much more to manage your leads or stay in 
            touch with your relatives. This will not only restrict the 
            usage but also affect your business operations and customer 
            experience. So why not get a DID number in the US to make calls 
            to your US business consumers or potential customers or relatives 
            at relatively cheaper rates? Similarly, users from other countries 
            can buy Indian VoIP number or US VoIP numbers instantly from our 
            portal.  <br /><br />
            Incoming calls on your virtual DID number can be forwarded to 
            landline, mobile phones anywhere in the world, at much lower 
            rates per minute. <br /><br />
            Incoming calls on your virtual DID number can be forwarded to landline, 
            mobile phones anywhere in the world, at much lower rates per minute.<br /> 
            To get more information on purchasing DID numbers, 
            email us at <a href="mailto:sales@vibtree.com" className="vib-h-b-u" style={{color:'inherit'}}>sales@vibtree.com.</a> 
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className='accordin-card' style={{marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="4" 
        onClick={handleClick5} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> Is it possible to link a DID number to a standard telephone number?</span>
           <div className="accordin-right-icon" 
           style={{transform: accFive==='yes' ? 'rotate(90deg)':accFive==='no'? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> Yes. This method is referred to as PSTN forwarding which allows you 
            to receive calls that are made to your DID cloud numbers on the mobile phones 
            or regular landlines, anywhere around the World at lower costs per minute. 
            This doesn't need a PC, Internet or VoIP services and what's better 
            is that you can actually forward a few selected or all your DID numbers 
            to the same PSTN number. <br />
            To get more information on this, you can email us 
            at <a href="mailto:support@vibtree.com" className="vib-h-b-u" style={{color:'inherit'}}>support@vibtree.com.</a> 
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className='accordin-card' style={{marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="5" 
        onClick={handleClick6} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> How can I buy additional channels for my DIDs?</span>
           <div className="accordin-right-icon" 
           style={{transform: accSix==='yes' ? 'rotate(90deg)':accSix==='no'? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="5">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> Capacity of your DID cloud numbers can be increased any time by making a 
            direct purchase through your online account or by contacting our 
            sales team for additional volume at <a href="mailto:sales@vibtree.com" className="vib-h-b-u" style={{color:'inherit'}}>sales@vibtree.com.</a>  <br />  
            To get more information on this, you can email us at <a href="mailto:support@vibtree.com" className="vib-h-b-u" style={{color:'inherit'}}>support@vibtree.com.</a> 
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className='accordin-card' style={{marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="6" 
        onClick={handleClick7} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> Is it possible to port my number from other providers in to Vibtree cloud numbers?</span>
           <div className="accordin-right-icon" 
           style={{transform: accSeven==='yes' ? 'rotate(90deg)':accSeven==='no'? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="6">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> Vibtree currently does not support porting in numbers from 
            other providers. To learn more about porting 
            numbers, write to us at <a href="mailto:support@vibtree.com" className="vib-h-b-u" style={{color:'inherit'}}>support@vibtree.com.</a> <br />  
            We are on a constant journey to grow our services, so we 
            keep working towards adding new regions to our coverage. 
            If you do not find a country or region of your choice on the website, 
            write to us and we can serve you with your requirements.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className='accordin-card' style={{marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="7" 
        onClick={handleClick8} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> Can I buy a cloud numbers of my choice?</span>
           <div className="accordin-right-icon" 
           style={{transform: accEight==='yes' ? 'rotate(90deg)':accEight==='no'? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="7">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> Selecting and purchasing your DID number or virtual number 
            online is quite an easy process. For purchasing numbers you 
            will need to enter the country, city name or prefix, 
            and the type of number required; however you can search 
            for a specific phone number on our user panel. We have a 
            huge listing of cloud numbers available worldwide, so you can 
            easily find the number you are looking for. For more 
            information or help on this, write to us on <a href="mailto:support@vibtree.com" className="vib-h-b-u" style={{color:'inherit'}}>support@vibtree.com.</a>   
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className='accordin-card' style={{marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="8" 
        onClick={handleClick9} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> Can I send SMS using the Vibtree cloud numbers?</span>
           <div className="accordin-right-icon" 
           style={{transform: accNine==='yes' ? 'rotate(90deg)':accNine==='no'? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="8">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> Yes, Vibtree cloud numbers can be bundled with other cloud 
            telephony services like cloud SMS easily. 
            This will allow you to send SMS using your cloud number.  
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      
      <Card className='accordin-card' style={{marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="9" 
        onClick={handleClick10} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> How much does it cost to get a DID number or a virtual number?</span>
           <div className="accordin-right-icon" 
           style={{transform: accTen==='yes' ? 'rotate(90deg)':accTen==='no'? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="9">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> At Vibtree, all our product prices & packages 
            are fully customizable. We only charge for the services you need based on your business 
            requirements. Our service packs are highly flexible and can be adjusted according to the 
            cloud services you choose.  You can book a demo session for a customized pricing plan or 
            start your free trial to explore pricing. 
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className='accordin-card' style={{marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="10" 
        onClick={handleClick11} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> What are the end-user restrictions for DID numbers or virtual numbers?</span>
           <div className="accordin-right-icon" 
           style={{transform: accEleven==='yes' ? 'rotate(90deg)':accEleven==='no'? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="10">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> End user restrictions are mentioned during the purchase process. 
            For more information on this, please email us at <a href="mailto:support@vibtree.com" className="vib-h-b-u" style={{color:'inherit'}}>support@vibtree.com.</a>   
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className='accordin-card' style={{marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="11" 
        onClick={handleClick12} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> Trying to get USA VoIP Number?</span>
           <div className="accordin-right-icon" 
           style={{transform: accTwelve==='yes' ? 'rotate(90deg)':accTwelve==='no'? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="11">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> Call on <a href='tel:18005724590' className="vib-h-b-u" style={{color:'inherit'}}>1800 573 4590</a> now!  
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      </Accordion>
    </div>
    {/* FAQ section end */}
    </div>
  )
}
