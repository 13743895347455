import React, { useEffect, useState } from "react";
import '../navbar.css';
import { Link } from "react-router-dom";

export default function Company(props) { 
  const [cardActiveState, setcardActiveState] = 
  useState({about:'',partner:'',career:''});

  let presentPath= window.location.pathname
  useEffect(() => {
    if (presentPath==='/company/about/') {
      setcardActiveState({about:'active'})
    } else if(presentPath==='/company/partner-with-us/'){
      setcardActiveState({partner:'active'})
    } else if(presentPath==='/company/work-with-us/'){
      setcardActiveState({career:'active'})
    }else{
      setcardActiveState({about:'',partner:'',career:''})
    }
  },[presentPath]);


  var handleOnClick=()=>{
    props.setfold('fold');
  }

  return (
    <div className='for_why_position_company'
         style={{backgroundColor:'#042E76',
                 borderRadius:'10px'}}>
      <div
        className="dropdown-menu company-drop navbar navbar-expand-lg navbar-light"
        aria-labelledby="navbarDropdown"
        style={{backgroundColor:'#042E76',
                borderRadius:'10px',
                width:'99vw'}}
      >
        <div className="container-fluid company-outer-res-margin-top" 
             style={{paddingLeft:'0px',
                     paddingRight:'0px',
                     borderRadius:'10px',
                     paddingTop:'20px'}}>
          <div className="row" >
            <div
              className="col-12 col-md-auto"
              style={{
                flexDirection: "column",
                justifyContent: "flex-end",
                display: "flex",
                paddingLeft:'0px',
                paddingRight:'0px',
                marginBottom:'25px'
              }} 
            >
              <ul className="company_ul" 
              style={{paddingLeft:'0px',display:'flex',flexDirection:'column'}}>
                <li className="company-li">
                  <Link to="/company/about/" 
                        className={cardActiveState.about==='active'?"company-link-blue-active":"company-link-blue"} 
                        onClick={handleOnClick}
                        style={{color:'#FFCE42'}} 
                        title="about-vibtree">
                    About Vibtree
                  </Link>
                    <small style={{color:'#FAFAFA'}}>Streamlining Communication & Business Operations</small>
                </li>
                <li className="company-li">
                  <Link to="/company/partner-with-us/" 
                        className={cardActiveState.partner==='active'?"company-link-blue-active":"company-link-blue"}
                        onClick={handleOnClick}
                        style={{color:'#FFCE42'}} 
                        title="partner-with-us">
                    Partner With Us
                  </Link>
                    <small style={{color:'#FAFAFA'}}>Join Our Channel Partner Program and Grow With Us</small>
                </li>

                <li className="company-li">
                  <Link to="/company/work-with-us/" 
                        className={cardActiveState.career==='active'?"company-link-blue-active":"company-link-blue"} 
                        onClick={handleOnClick}
                        style={{color:'#FFCE42'}} 
                        title="careers">
                    Work With Us
                  </Link>
                    <small style={{color:'#FAFAFA'}}>Join Our Team To Achieve Great Things In Your Career</small>
                </li>
              </ul>
            </div>
            <div
              className="col-12 col-md company-text-container-remove-padding"
              style={{
                flexDirection: "column",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <div className='company-middle-text-alignment'>
                <h2 
                style={{marginTop:'20px'}}
                className='margin-bottom-45-16 company-middle-text'>
                Communicate-on-cloud to build excellent customer experience
                </h2>
                <p className='companypee company-middle-text' style={{marginTop:'20px'}}>
                Serve your market better, resolve your customer's 
                problems and boost your profits with our complete 
                cloud telephony suite. 
                </p>
                <p className='companypee company-middle-text' 
                style={{marginTop:'20px',marginBottom:'0px'}}>
                At Vibtree Technologies, we work together to encourage, 
                motivate and inspire our fellow team members to bring 
                out the best in them. 
                </p>
              </div>
            </div>

            <div
              className="col-auto d-none d-lg-block company-image-disappear"
              style={{
                flexDirection: "column",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-arrows.svg' className="drop-item-img" alt='vibtree-arrows'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
