import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { webApi } from '../../url';
import { useHistory } from "react-router-dom";
import BlogNav from './../BlogNav';
import CategoryEditComponent from './CategoryEditComponent';



 export default function CategoryViewComponent(){

    
    const auth = localStorage.getItem('vibauth-b');


    let history = useHistory();


    useEffect(() => {
        // eslint-disable-next-line
        if(auth != 'authenticated'){
            history.push("/blog/admin")
        } 
        // eslint-disable-next-line
    }, [])

    const [ blogCategoryList, setBlogCategoryList] = useState([]);
    // eslint-disable-next-line
    const [ categoryResponse, setCategoryResponse] = useState([]);
    const [ categoryName, setCategoryName ] = useState('');
    // eslint-disable-next-line
    const [ showSuccess, setShowSuccess ] = useState(false);

    // eslint-disable-next-line
    const [ showError, setShowError ] = useState(false);
    const [ showDeleteSuccess, setShowDeleteSuccess ] = useState(false);
    const [ showDeleteError, setShowDeleteError ] = useState(false);
     
    async function getBlogsCategory() {
        try {
          // fetch data from a url endpoint
          const response = await axios.get(`${webApi}/Categories`);
          return (
            setBlogCategoryList(response.data)
          );
        } catch(error) {
          console.log("error", error);
          // appropriately handle the error
        }
      }

      // eslint-disable-next-line
      const clearInputs = () =>{
        setCategoryName('');
      }

    //   async function postCategory(e) {
    //     e.preventDefault();
    //     const formdata = {
    //         Category_Name : categoryName,
    //     }
    //     try {
    //       // fetch data from a url endpoint
    //       const response = await axios.post(`${webApi}/Categories`, formdata);
    //       return (
    //         setCategoryResponse(response.data),
    //         clearInputs(),
    //         setShowSuccess(true)
    //       );
    //     } catch(error) {
    //       setShowError(true)
    //       // appropriately handle the error
    //     }
    //   }

      async function deleteCategory(id) {
        
            try {
                // fetch data from a url endpoint
                // eslint-disable-next-line
                const response = await axios.delete(`${webApi}/Categories/${id}`);
                return (
                    setShowDeleteSuccess(true)
                    );
                } catch(error) {
                    setShowDeleteError(true)
                }
            
        }

        

      

      useEffect(() => {
        getBlogsCategory();
      }, [categoryResponse, showDeleteSuccess])
    
     return(
         <span>
            <BlogNav />
             <div className="container-fuild p-4 card mt-3">
                  
                 <div className="row">
                     <div className="col-lg-12">

                     <div className={showDeleteSuccess === true ? 'alert alert-success alert-dismissible fade show' : 'alert alert-success alert-dismissible fade d-none'} role="alert">
                                    <strong>Success!</strong> Category Deleted
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className={showDeleteError === true ? 'alert alert-danger alert-dismissible fade show' : 'alert alert-danger alert-dismissible fade d-none'}  role="alert">
                                    <strong>Failed!</strong> Category Deleted
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                        <table className="table">
                            <thead style={{background: "rgb(229, 241, 255)"}}>
                                <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Category Name
                                </th>
                            
                                <th>
                                    Action
                                </th>
                                </tr>
                            </thead>
                            <tbody>

                                {blogCategoryList.map((item, index) => {
                                    return(
                                        <tr>
                                            <td>{index+1}</td>
                                            <td>
                                               {item.Category_Name}
                                            </td>
                                            
                                            <td>
                                                <CategoryEditComponent categoryName={categoryName} setCategoryName={setCategoryName} item={item} />
                                               
                                                <a href className="btn btn-link" title="Delete" data-toggle="tooltip" onClick={(event) => deleteCategory(item.id)}>
                                                    <i className="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            
                            </tbody>
                        </table>
                     </div>
                   
                 </div>
             </div>
            
         </span>
     )
 }