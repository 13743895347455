import React, { useEffect, useState } from 'react'
import Slider from "react-slick";

export default function WorkWithUsSlick() {
  const [windowWidth, setwindowWidth] = useState(3);

  const noOfSlidesToShow =()=>{
    //  var width = window.innerWidth
     return(
      window.innerWidth >  1176 ? setwindowWidth(3) : 
      window.innerWidth <= 1176 ? setwindowWidth(1) :null
     )
   }

  useEffect(() => {
    window.addEventListener('resize',noOfSlidesToShow);
    noOfSlidesToShow();
    return()=>(
      window.removeEventListener('resize',noOfSlidesToShow)
    )
  });

    var settings = {
        infinite: true,
        arrows:false,
        speed: 500,
        slidesToShow: windowWidth,
        centerMode: true,
        pauseOnHover: true,
        adaptiveHeight: true,
        lazyLoad: true,
        autoplaySpeed:4000,
        autoplay:true
      };

  return (
    <Slider {...settings}>
      <div className='px-lg-4 manage-ratio'>
      <div className="overlay" style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/sreeju-r.jpg' 
      alt="sreeju-r" 
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>

      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/danish-asghar.jpg' 
      alt="danish-asghar" 
      style={{outline:'none',overflow:'visible'}} />
      </div>
      </div>

      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/soumya-sarkar.jpg' 
      alt="soumya-sarkar"  
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>

      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/rahul-krishna.jpg' 
      alt="rahul-krishna"  
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>

      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/ishani-sen.jpg'
      alt="ishani-sen"  
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>

      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/sneha-gupta.jpg'
      alt="sneha-gupta"  
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>

      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/sahitri-ghosal.jpg' 
      alt="sahitri-ghosal"  
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>

      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/reetesh-jaiswal.jpg'
      alt="reetesh-jaiswal"  
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>

      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/rajap-roy.jpg' 
      alt="rajap-roy"  
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>

      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/anchal-prakash.jpg' 
      alt="anchal-prakash"  
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>

      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/pooja-jain.jpg' 
      alt="pooja-jain"  
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>

      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/dhananjaya-k-r.jpg'
      alt="dhananjaya-k-r"  
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>
      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/bhumika-pahwa.jpg' 
      alt="bhumika-pahwa"  
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>

      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/ayush-yadav.jpg' 
      alt="ayush-yadav"  
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>

      <div className='px-lg-4 manage-ratio'>
      <div className="overlay"  style={{overflow:'visible'}}>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/sudipta-paul.jpg'
      alt="sudipta-paul"  
      style={{outline:'none',overflow:'visible'}}/>
      </div>
      </div>
    </Slider>
  )
}
