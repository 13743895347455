import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import './pricing.css'
import ScrollHandler from 'src/ScrollHandler';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { incrementscrollchoose } from 'src/redux/scrollcss';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                // backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#2166d1',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));





export default function StickyHeadTableStarter() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [SwitchState, setSwitchState] = useState(false);
    const [inrState, setInrState] = useState(false);
    const handleChange = (e) => {
        setSwitchState(e.target.checked)
    }

    const handleCurrencyChange = (e) => {
        console.log("setInrState", e.target.checked)
        setInrState(e.target.checked)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    // let refi = useRef()
    let scrollcss;
    const [scrollclass, setscrollclass] = useState('scrolltable')
    const dispatch = useDispatch()
    const ScrollHandler = (event) => {
        if (event.currentTarget.scrollTop + 190 >= event.currentTarget.offsetHeight) {
            console.log("scrollname")
            scrollcss = 'scrollbartable'
            setscrollclass('scrollbartable')
            dispatch(incrementscrollchoose('scrollbartable'))
        } else {
            scrollcss = 'scrolltable'
            setscrollclass('scrolltable')
            dispatch(incrementscrollchoose('scrolltable'))
        }
        // const scrolltop = refi.current.scrollTop
        console.log("scrolltop", event.currentTarget.scrollTop + 190)
        console.log("scrolltopheight", event.currentTarget.offsetHeight)

    }
    const scrolluseselector = useSelector(state => state.scrollchooseSlice.value)
    console.log('scrollcss', scrolluseselector)

    const [anullyprice, setannuallyprice] = useState(false)
    const [priceind, setpriceprice] = useState(true)
    const handleAnually = (event) => {
        console.log("handleAnually", event.target.checked)
        setannuallyprice(event.target.checked)
    }
    const handleprice = (event) => {
        console.log("handleAnual", event.target.checked)
        setpriceprice(event.target.checked)
    }

    return (
        <>


            <Paper
                sx={{ width: '100%', overflow: 'hidden' }}
            // className={`${scrolluseselector == "scrolltable" ? 'scrolltable' : "scrollbartable"}`}
            // className={`${scrollclass == "scrolltable" ? 'scrolltable' : "scrollbartable"}`}
            >
                <TableContainer
                // onScroll={ScrollHandler}
                // sx={{ maxHeight: 1040 }}
                >
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                        // ref={refi}

                        style={{ width: "80%", marginLeft: "10%", }}>

                        <TableHead
                            style={{ position: "sticky", top: "0px" }}
                        // className='sticky top-20 bg-white border-bottom' data-v-2cba3159
                        >
                            <TableRow>
                                <TableCell className='mainheader'

                                >
                                    Plans
                                </TableCell>
                                <TableCell className='mainheader'>
                                    Starter
                                </TableCell>
                                {/* <TableCell className='mainheader'>
                                    Standard
                                </TableCell>
                                <TableCell className='mainheader'>
                                    Professional
                                </TableCell>
                                <TableCell className='mainheader'>
                                    Enterprise
                                </TableCell> */}
                            </TableRow>
                            <TableRow
                                className='sticky top-20 bg-white border-bottom' data-v-2cba3159

                            >
                                <TableCell data-v-2cba3159>
                                    Pricing

                                    <FormGroup>
                                        <FormControlLabel
                                            control={<IOSSwitch sx={{ m: 1 }}
                                            // defaultChecked
                                            />}
                                            label="Billed Annually"
                                            onChange={handleAnually}

                                        />
                                        <div>

                                            <FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1, }} defaultChecked />}
                                                // label="USD ($)"
                                                onChange={handleprice}
                                                style={{ marginRight: "0px" }}
                                            />
                                            {/* "USD ($)" */}
                                            <span>
                                                {priceind == true ? "USD ($)" : "INR (₹)"}
                                            </span>

                                        </div>
                                    </FormGroup>

                                </TableCell>
                                <TableCell data-v-2cba3159>
                                    <h1 style={{ display: "inline-block" }}>
                                        {(priceind == true && anullyprice == true) ? <>
                                            {/* $9 */}
                                            $98
                                        </> : (priceind == true && anullyprice == false) ? <>
                                            $9
                                        </> :
                                            (anullyprice == true && priceind == false) ? <>
                                                {/* $98 */}
                                                ₹8100
                                            </> : (priceind == false && anullyprice == false) ? <>
                                                ₹750
                                            </> :
                                                <>
                                                    ₹8100
                                                    {/* 750 */}
                                                </>}
                                    </h1>
                                    <span style={{ color: "#707684" }}>
                                        /agent/{anullyprice == false ? <>month</> : <>annum</>}                                  </span>

                                    <br />
                                    {/* <span style={{ color: "#707684" }}>
                                        upto 2 agents
                                    </span> */}

                                    <Button variant='contained' style={{ marginTop: "10px" }} className='freetrailbutton'>Start Free Trial</Button>
                                </TableCell>



                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow style={{ backgroundColor: "#f9fafb" }}>


                                <TableCell >
                                    <div
                                        className='channelbold'
                                    >
                                        Channels
                                    </div>
                                </TableCell>
                                <TableCell

                                // style={{ color: "black", fontweight: "400" }}
                                >


                                </TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell>
                                    Voice
                                </TableCell>
                                <TableCell align='center'>

                                    <DoneIcon
                                        className='tickmarkicon'
                                    />

                                </TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    SMS
                                </TableCell>
                                <TableCell align='center'>

                                    <DoneIcon
                                        className='tickmarkicon'
                                    />

                                </TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Whatsapp
                                </TableCell>
                                <TableCell align='center'>

                                    <div
                                        className='crosstickicon'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300" width="1em" height="1em" viewBox="0 0 20 20" data-v-ca945699=""><path fill="currentColor" d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"></path></svg>

                                    </div>
                                </TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Facebook
                                </TableCell>
                                <TableCell align='center'>

                                    <div
                                        className='crosstickicon'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300" width="1em" height="1em" viewBox="0 0 20 20" data-v-ca945699=""><path fill="currentColor" d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"></path></svg>

                                    </div>
                                </TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Instagram
                                </TableCell>
                                <TableCell align='center'>

                                    <div
                                        className='crosstickicon'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300" width="1em" height="1em" viewBox="0 0 20 20" data-v-ca945699=""><path fill="currentColor" d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"></path></svg>

                                    </div>
                                </TableCell>

                            </TableRow>
                            {/* <TableRow>
                                <TableCell>
                                    Telegram
                                </TableCell>
                                <TableCell align='center'>
                                    <div
                                        className='crosstickicon'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300" width="1em" height="1em" viewBox="0 0 20 20" data-v-ca945699=""><path fill="currentColor" d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"></path></svg>

                                    </div>
                                </TableCell>

                            </TableRow> */}
                            <TableRow>
                                <TableCell>
                                    Viber
                                </TableCell>
                                <TableCell align='center'>

                                    <div
                                        className='crosstickicon'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300" width="1em" height="1em" viewBox="0 0 20 20" data-v-ca945699=""><path fill="currentColor" d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"></path></svg>

                                    </div>

                                </TableCell>

                            </TableRow>




                            <TableRow style={{ backgroundColor: "#f9fafb" }}>
                                {/* <TableCell style={{ width: "300px" }}>


                                </TableCell> */}
                                {/* <TableCell></TableCell> */}
                                <TableCell>  <div
                                    className='channelbold1'
                                >
                                    Productivity
                                </div></TableCell>
                                <TableCell> </TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell>Notes</TableCell>
                                <TableCell align='center'>
                                    <DoneIcon
                                        className='tickmarkicon'
                                    />
                                </TableCell>



                            </TableRow>

                            <TableRow>

                                <TableCell>Tags</TableCell>
                                <TableCell align='center'>
                                    <DoneIcon
                                        className='tickmarkicon'
                                    />
                                </TableCell>



                            </TableRow>

                            <TableRow>

                                <TableCell>Custom Views</TableCell>
                                <TableCell align='center'>
                                    <DoneIcon
                                        className='tickmarkicon'
                                    />
                                </TableCell>



                            </TableRow>

                            <TableRow>

                                <TableCell>Custom Filters</TableCell>
                                <TableCell align='center'>
                                    <DoneIcon
                                        className='tickmarkicon'
                                    />
                                </TableCell>



                            </TableRow>
                            <TableRow>

                                <TableCell>Business Hours</TableCell>
                                <TableCell align='center'>
                                    <DoneIcon
                                        className='tickmarkicon'
                                    />
                                </TableCell>



                            </TableRow>
                            <TableRow>

                                <TableCell>Automation</TableCell>
                                <TableCell align='center'>
                                    <div
                                        className='crosstickicon'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300" width="1em" height="1em" viewBox="0 0 20 20" data-v-ca945699=""><path fill="currentColor" d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"></path></svg>

                                    </div>

                                </TableCell>



                            </TableRow>

                            <TableRow style={{ backgroundColor: "#f9fafb" }}>
                                {/* <TableCell style={{ width: "300px" }}>

                                </TableCell> */}
                                {/* <TableCell></TableCell> */}
                                <TableCell  > <div
                                    className='channelbold1'
                                >
                                    CRM
                                </div>
                                </TableCell>
                                <TableCell  >
                                </TableCell>

                            </TableRow>


                            <TableRow>

                                <TableCell>Contacts</TableCell>
                                <TableCell align='center'>
                                    <DoneIcon
                                        className='tickmarkicon'
                                    />
                                </TableCell>



                            </TableRow>
                            <TableRow>

                                <TableCell>Contact Groups</TableCell>
                                <TableCell align='center'>
                                    <DoneIcon
                                        className='tickmarkicon'
                                    />
                                </TableCell>



                            </TableRow>
                            <TableRow>

                                <TableCell>Custom Fields</TableCell>
                                <TableCell align='center'>
                                    <DoneIcon
                                        className='tickmarkicon'
                                    />
                                </TableCell>



                            </TableRow>
                            <TableRow>

                                <TableCell>Import Contacts</TableCell>
                                <TableCell align='center'>
                                    <DoneIcon
                                        className='tickmarkicon'
                                    />
                                </TableCell>



                            </TableRow>
                            <TableRow>

                                <TableCell>Contact Notes</TableCell>
                                <TableCell align='center'>
                                    <DoneIcon
                                        className='tickmarkicon'
                                    />
                                </TableCell>



                            </TableRow>



                            <TableRow style={{ backgroundColor: "#f9fafb" }}>
                                {/* <TableCell style={{ width: "300px" }}>


                                </TableCell> */}
                                {/* <TableCell></TableCell> */}
                                <TableCell > <div
                                    className='channelbold1'
                                >
                                    Reports
                                </div></TableCell>
                                <TableCell > </TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell>Dashboard</TableCell>
                                <TableCell align='center'>
                                    <DoneIcon
                                        className='tickmarkicon'
                                    />
                                </TableCell>



                            </TableRow>
                            <TableRow>

                                <TableCell>Downloadable Reports</TableCell>
                                <TableCell align='center'>
                                    <div
                                        className='crosstickicon'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300" width="1em" height="1em" viewBox="0 0 20 20" data-v-ca945699=""><path fill="currentColor" d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"></path></svg>

                                    </div>

                                </TableCell>



                            </TableRow>


                            <TableRow style={{ backgroundColor: "#f9fafb" }}>
                                {/* <TableCell style={{ width: "300px" }}>

                                </TableCell> */}
                                {/* <TableCell></TableCell> */}
                                <TableCell> <div
                                    className='channelbold1'
                                >
                                    Others
                                </div>
                                </TableCell>
                                <TableCell>
                                </TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell>Live Chat Support</TableCell>
                                <TableCell align='center'>
                                    <DoneIcon
                                        className='tickmarkicon'
                                    />
                                </TableCell>



                            </TableRow>
                            <TableRow>

                                <TableCell>Dedicated Account Manager</TableCell>
                                <TableCell align='center'>
                                    <div
                                        className='crosstickicon'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300" width="1em" height="1em" viewBox="0 0 20 20" data-v-ca945699=""><path fill="currentColor" d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"></path></svg>

                                    </div>

                                </TableCell>


                            </TableRow>
                            <TableRow>

                                <TableCell>Free Conversation</TableCell>
                                <TableCell align='center'>
                                    500
                                </TableCell>



                            </TableRow>
                            <TableRow>

                                <TableCell>Conversation Limit</TableCell>
                                <TableCell align='center'>
                                    5000
                                </TableCell>



                            </TableRow>




                            <TableRow>
                                <TableCell>
                                    Conversation Pricing
                                </TableCell>
                                <TableCell align='center'>
                                    <Button variant='contained' className='freetrailbutton'>Start Free Trial</Button>
                                </TableCell>

                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper >
        </>
    );
}