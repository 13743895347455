// export const crmBaseUrl = 'https://dev.vibtree.com/api'
export const crmBaseUrl = 'https://prodback.vibtree.com/api'

export const config = {
    bucketName: 'vibtreedan',
    dirName: 'blog/vibtree-dynamic-blog-images',
    region: 'eu-central-1',
    accessKeyId: 'AKIA2MTCLJCGTIXETVUX',
    secretAccessKey: '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',
    s3Url: 's3://vibtreedan/blog/vibtree-dynamic-blog-images/',
}