import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import callicon from './../../../images/industry/call-icon.svg'
import messengericon from './../../../images/industry/messenger.svg'
import smsicon from './../../../images/industry/sms-icon.svg'
// import telegramicon from './../../../images/industry/telegramnew.svg'
import vibericon from './../../../images/industry/viber-icon.svg'
import whatsappicon from './../../../images/industry/whatsapp-icon.svg'



export default function FirstDropDown(props) {
  const [cardActiveState, setcardActiveState] =
    useState({ voice: '', sms: '', callTracking: '', cloudPhone: '', cloudNumber: '', ivrStudio: '' });

  let presentPath = window.location.pathname
  useEffect(() => {
    if (presentPath === '/products/voice/') {
      setcardActiveState({ voice: 'active' })
    } else if (presentPath === '/products/bulk-sms/') {
      setcardActiveState({ sms: 'active' })
    } else if (presentPath === '/products/call-tracking/') {
      setcardActiveState({ callTracking: 'active' })
    } else if (presentPath === '/products/cloud-phone/') {
      setcardActiveState({ cloudPhone: 'active' })
    } else if (presentPath === '/products/cloud-number/') {
      setcardActiveState({ cloudNumber: 'active' })
    } else if (presentPath === '/products/ivr-studio/') {
      setcardActiveState({ ivrStudio: 'active' })
    } else {
      setcardActiveState({ voice: '', sms: '', callTracking: '', cloudPhone: '', cloudNumber: '', ivrStudio: '' })
    }
  }, [presentPath]);

  var handleOnClickWhy = () => {
    props.setfoldWhy('fold');
  }
  return (
    <div className='first-drop-down' style={{ maxHeight: '90vh', overflowY: 'scroll' }}>

      <Link
        to="/products/voice/"
        className='company-link-blue'
        onClick={handleOnClickWhy}
        title="voice">
        <div className="first-divs">
          <div className={cardActiveState.voice === 'active' ? "first-div-card card-active-state-first" : "first-div-card"}>
            {/* <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/voice.svg' alt="voice" style={{ padding: '15px' }} /> */}
            <img src={callicon} alt="voice" style={{ padding: '15px' }} />
            <h3 style={{ color: '#464646' }}>VOICE</h3>
            <p style={{ color: '#7C8087' }}>Make and Receive calls, automate incoming calls, call recordings and more.
            </p>
          </div>
        </div>
      </Link>

      <Link
        to="/products/bulk-sms/"
        className='company-link-blue'
        onClick={handleOnClickWhy}
        title="sms">
        <div className="first-divs">
          <div className={cardActiveState.sms === 'active' ? "first-div-card card-active-state-first" : "first-div-card"}>
            <img src={smsicon} alt="smsphone" style={{ padding: '13px' }} />
            {/* <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/smsphone.svg' alt="smsphone" style={{ padding: '13px' }} /> */}
            <h3 style={{ color: '#464646' }}>SMS</h3>
            <p style={{ color: '#7C8087' }}>Send and Receive text messages, automate sms and more.</p>
          </div>
        </div>
      </Link>
      <Link
        to="/products/bulk-sms/"
        className='company-link-blue'
        onClick={handleOnClickWhy}
        title="sms">
        <div className="first-divs">
          <div className={cardActiveState.sms === 'active' ? "first-div-card card-active-state-first" : "first-div-card"}>
            {/* <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/smsphone.svg' alt="smsphone" style={{ padding: '13px' }} /> */}
            <img src={whatsappicon} alt="smsphone" style={{ padding: '13px' }} />
            <h3 style={{ color: '#464646' }}>WHATSAPP</h3>
            <p style={{ color: '#7C8087' }}>Manage your whatsapp business interaction and more.</p>
          </div>
        </div>
      </Link>
      {/* <Link
        to="/products/bulk-sms/"
        className='company-link-blue'
        onClick={handleOnClickWhy}
        title="sms">
        <div className="first-divs">
          <div className={cardActiveState.sms === 'active' ? "first-div-card card-active-state-first" : "first-div-card"}>

            <img src={telegramicon} alt="smsphone" style={{ padding: '13px' }} />
            <h3 style={{ color: '#464646' }}>TELEGRAM</h3>
            <p style={{ color: '#7C8087' }}>Stay connected with your customers on  telegram</p>
          </div>
        </div>
      </Link> */}
      <Link
        to="/products/bulk-sms/"
        className='company-link-blue'
        onClick={handleOnClickWhy}
        title="sms">
        <div className="first-divs">
          <div className={cardActiveState.sms === 'active' ? "first-div-card card-active-state-first" : "first-div-card"}>
            {/* <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/smsphone.svg' alt="smsphone" style={{ padding: '13px' }} /> */}
            <img src={vibericon} alt="smsphone" style={{ padding: '13px' }} />
            <h3 style={{ color: '#464646' }}>VIBER</h3>
            <p style={{ color: '#7C8087' }}>Manage your Viber Customer interactions from Vibtree</p>
          </div>
        </div>
      </Link>
      <Link
        to="/products/bulk-sms/"
        className='company-link-blue'
        onClick={handleOnClickWhy}
        title="sms">
        <div className="first-divs">
          <div className={cardActiveState.sms === 'active' ? "first-div-card card-active-state-first" : "first-div-card"}>
            {/* <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/smsphone.svg' alt="smsphone" style={{ padding: '13px' }} /> */}
            <img src={messengericon} alt="smsphone" style={{ padding: '13px' }} />
            <h3 style={{ color: '#464646' }}>MESSENGER</h3>
            <p style={{ color: '#7C8087' }}> Stay connected with your customers in  facebook and instagram</p>
          </div>
        </div>
      </Link>

      {/* <Link 
      to="/products/call-tracking/" 
      className='company-link-blue' 
      onClick={handleOnClickWhy}
      title="call-tracking">
      <div className="first-divs">
        <div className={cardActiveState.callTracking==='active'?"first-div-card card-active-state-first":"first-div-card"}>
          <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/call-tracking-outline.svg' alt="call-tracking-outline" />
          <h3 style={{color:'#464646'}}>CALL TRACKING</h3>
          <p style={{color:'#7C8087'}}>Put your money on the right marketing campaigns 
          with incoming call tracking software to power up your sales</p>
        </div>
      </div>
      </Link>

      <Link 
           to="/products/ivr-studio/" 
           className='company-link-blue' 
           onClick={handleOnClickWhy}
           title="ivr-studio">
      <div className="first-divs">
        <div className={cardActiveState.ivrStudio==='active'?"first-div-card card-active-state-first":"first-div-card"}>
          <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/ivr-studio.svg' alt="ivr-studio"  style={{padding:'5px'}}/>
          <h3 style={{color:'#464646'}}>IVR Studio</h3>
          <p style={{color:'#7C8087'}}>Build intuitive conversation flows with our IVR 
          flow builder to enhance customer interaction and experience.</p>
        </div>
      </div>
      </Link>

      <Link 
           to="/products/cloud-phone/" 
           className='company-link-blue' 
           onClick={handleOnClickWhy}
           title="cloud-phone">
      <div className="first-divs">
        <div className={cardActiveState.cloudPhone==='active'?"first-div-card card-active-state-first":"first-div-card"}>
          <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/cloudPhone.svg' alt="cloudPhone"/>
          <h3 style={{color:'#464646'}}>CLOUD PHONE</h3>
          <p style={{color:'#7C8087'}}>
          Set up a virtual phone system that allows you to make, 
          manage and monitor calls from your browser.</p>
        </div>
      </div>
      </Link>

      <Link 
           to="/products/cloud-number/" 
           className='company-link-blue' 
           onClick={handleOnClickWhy}
           title="cloud-number">
      <div className="first-divs">
        <div className={cardActiveState.cloudNumber==='active'?"first-div-card card-active-state-first":"first-div-card"}>
          <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/cloudNumber.svg' alt="cloudNumber"  style={{padding:'15px'}}/>
          <h3 style={{color:'#464646'}}>CLOUD NUMBER</h3>
          <p style={{color:'#7C8087'}}>
          'Work Global-Call Local' with Toll 
          Free phone numbers, virtual numbers 
          & DIDs for 20+ countries.</p>
        </div>
      </div>
      </Link> */}

    </div>
  )
}
