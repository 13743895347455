import React, { useContext, useEffect, useState } from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css';
import './workWithUs.css'

import { vibContext } from 'src/App'

import { Helmet } from 'react-helmet';

import favicon from '../../images/vibtree.ico'
import WorkWithUsSlick from './WorkWithUsSlick';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Form } from 'react-bootstrap';
import { linkedinJobPage } from '../Blog/url';

import LoaderComponent from 'src/LoaderComponent';


export default function Careers() { 
  const [whichIsActive, setwhichIsActive] = useState('students');
  const [show, setShow] = useState(false);
  const [formInputs, setformInputs] = useState({name:'',email:'',post:'',document:''});
  const [ImagesLoadCount, setImagesLoadCount] = useState(0);
  const [loader, setloader] = useState(true);

  const cpContext= useContext(vibContext);
  const contextId=cpContext.vibState;
  useEffect(() => {
    const element = document.getElementById(contextId);
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0
      });
    }, 100);
  },[contextId]);

  const fileChange=(files)=>{
    var fileData = new FormData();
    fileData.append('file',files[0]);
    fileData.append('upload_preset','work-with-us')
  }

  const formChange=(e)=>{
    const { name, value }= e.target;
    
    setformInputs(prev=>{
      return({...prev, [name]:value})
    })
    }

  // image loading spinner start 
  const imagesLoadIndicator=()=>{
    const images = document.getElementsByClassName('classForLoaded');

    setImagesLoadCount(ImagesLoadCount+1);

    if (ImagesLoadCount===images.length-1) {
      setloader(false)
    }

  }
  // image loading spinner end

  return (
    <div className='pt-lg-5'>
      {/* seo start  */}
      <Helmet>
        <title>Jobs at Vibtree- Outstanding solutions need an outstanding team</title>
        {/* <link rel="icon" href={favicon} /> */}
        <meta name="description" content="Find your career at Vibtree and help us create the best cloud telephony solutions. We believe in working with the best and our focus is to bring your passion, skills and happiness together. " />
        <link rel="canonical" href="https://www.vibtree.com/company/work-with-us/" />
        <script  type="application/ld+json">
        {`{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "Vibtree Technologies",
            "legalName": "Vibtree Technologies",
            "alternateName": "www.vibtree.com",
            "description": "Find your career at Vibtree and help us create the best cloud telephony solutions. We believe in working with the best and our focus is to bring your passion, skills and happiness together. ",
            "url": "https://www.vibtree.com/company/work-with-us/",
            "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
            "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "18005724590",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/vibtree",
              "https://twitter.com/vibtree",
              "https://www.instagram.com/vibtree",
              "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
              "https://www.linkedin.com/company/vibtree",
              "https://www.vibtree.com"
            ]}`}
        </script>

        <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "company"
                    }
                },{
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@id": "https://www.vibtree.com/company/work-with-us/",
                        "name": "work-with-us"
                    }
                }]
            }`}
        </script>
        
        </Helmet>
        {/* body schema tag start  */}
        <body itemScope itemType="https://schema.org/WebPage"></body>
        {/* body schema tag end  */}
      {/* seo end  */}

      {loader===true ? <LoaderComponent/> : null}
      
    {/* banner start  */}
    <div className="banner sms-banner mx-lg-5" 
        style={{
          position:"relative",
          top:"0px", 
          minHeight:"600px",
          paddingTop:"50px",
          overflow:'hidden',
          paddingRight:'0px',
          paddingLeft:'0px'}}>
          <div className="container-fluid">
            <div className="row align-items-center vh-100-middle  voice-allow-overflow vib-flex-wrap-reverse pb-5">
            <div className="col-lg-6 for-padding-remove voice-allow-overflow"  style={{marginBottom:'-20px'}}>
              <h1 className='vib-container-heading vib-res-left voice-allow-overflow banner-heading-width' 
              style={{marginTop:'60px',fontSize:'50px',lineHeight:'75px',fontWeight:'700'}}>
              We Are <span className="vib-blue">Expanding</span> Our Team!
              </h1>
                <h5 className='vib-bold-para 
                               vib-res-left  
                               voice-allow-overflow 
                               banner-heading-width' 
                    style={{marginBottom:'30px',
                            fontSize:'28px',
                            marginTop:'20px',
                            lineHeight:'36px'}}>
                Come help us create the future of Communications
                </h5>

                    <a 
                       href={linkedinJobPage}
                       className="hvr-bounce-to-bottom1 
                                    design1 
                                    big-blog-button 
                                    wo-butt 
                                    vib-bold-para
                                    vib-res-button-height" 
                       style={{
                         backgroundColor:'#0046BB',
                         width:'200px',
                         margin:'0 0 25px 0',
                         height:'55px'}}
                       >
                            Join Our Team
                    </a>
                   
                  <div className="badges-wrapper">
                    <a className='badges-ambition-box' href='https://www.ambitionbox.com/overview/vibtree-technologies-overview?utm_source=employer-dashboard&utm_campaign=vibtree-technologies&utm_medium=badges'><img src='https://employer.ambitionbox.com/api/badge/752387?badge-type=ratings-detailed' alt="ambitionbox-badge"/></a>
                    <a className='badges-glassdoor-box' href='https://www.glassdoor.co.in/Overview/Working-at-Vibtree-EI_IE4295544.11,18.htm'><img src='https://www.glassdoor.co.in/api/widget/verticalStarRating.htm?e=4295544' alt='glassdoor-badges'/></a>
                  </div>

                <Modal show={show} onHide={() => setShow(false)} className='work-with-us-form'>

                  {/* <Form method="POST" action='mailto:hr@vibtree.com'> */}
                  <Form>
                  <Modal.Body className='mx-auto text-center' style={{fontWeight:'bold'}}>Send us your resume and we will get back to you when we have a suitable opening</Modal.Body>
                    <Form.Group className="mb-3 mx-5" controlId="formBasicEmail">
                      <Form.Label>Name</Form.Label>
                      <Form.Control name='name' required type="text" value={formInputs.name} onChange={formChange} placeholder="Enter Name" />
                      <Form.Text className="text-muted">
                      {''}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3 mx-5" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control name='email' required type="email" value={formInputs.email} onChange={formChange} placeholder="Enter email" />
                      <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3 mx-5" controlId="formBasicEmail">
                      <Form.Label htmlFor='wwu-post'>Position applying for</Form.Label>
                      <Form.Control id='wwu-post' name='post' required type="text" value={formInputs.post} onChange={formChange}/>
                      <Form.Text className="text-muted">
                      {''}
                      </Form.Text>
                    </Form.Group>
                  
                    <Form.Group controlId="formFile" className="mb-3 mx-5">
                      <Form.Label>Upload your CV- word or pdf</Form.Label>
                      <input type="file" onChange={(e)=>{fileChange(e.target.files)}}/>
                    </Form.Group>
                    <Button variant="primary mx-5 my-4" type="submit">
                      Submit
                    </Button>
                  </Form> 
                </Modal>

              </div>
              <div className="col-lg-6" style={{display:'flex',justifyContent:'center',marginTop:'50px'}}>
                <img onLoad={imagesLoadIndicator} 
                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/we-are-expanding-our-team.svg'
                className="img-fluid
                bannerdivimg  
                vib-sms-left-margin-auto
                wo-ban-img
                classForLoaded" 
                alt='we-are-expanding-our-team'
                /> 
              </div>
            </div>
          </div>
    </div>
    {/* banner end  */}

    {/* join our team start  */}
    <div className="mx-lg-5 vib-res-margin-top-100-50">
      <div className="mx-lg-5 d-flex flex-column justify-content-between">
        <p className="wo-small-text float-left mr-auto mb-lg-4">JOIN OUR TEAM</p>
        <div className="wo-flex-con 
                        d-flex 
                        justify-content-lg-around
                        justify-content-center 
                        position-relative
                        flex-wrap">
                        
          <div className="wo-left 
                          d-flex 
                          flex-column 
                          justify-content-between 
                          mx-md-3 
                          mx-sm-3"
          style={{maxHeight:'316px'}}>

            <div className={`vib-bold-para wo-left2 ${whichIsActive==='students'?'wo-card-active':null}`}
                 onClick={()=>setwhichIsActive('students')}>
            1. Students & Recent Graduates
             <img  onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/small-dots.svg' className="wo-dots-left1 classForLoaded" alt='dots'/>
             <div className={`wo-active-blue ${whichIsActive==='students'?'wo-background-blue':null}`}></div>
            </div>

            <div className={`vib-bold-para wo-left2 ${whichIsActive==='experienced'?'wo-card-active':null}`}
                 onClick={()=>setwhichIsActive('experienced')}>
            2. Experienced Professionals
             <img  onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/small-dots.svg' className="wo-dots-left2 classForLoaded" alt='dots'/>
             <div className={`wo-active-blue ${whichIsActive==='experienced'?'wo-background-blue':null}`}></div>
            </div>

          </div>

          { whichIsActive==='students'?

          <div className="wo-right 
                          h-100 
                          d-flex 
                          flex-column 
                          justify-content-sm-between 
                          m-md-3 m-sm-3">
          <h2 className='vib-container-sub-heading vib-res-left mx-3 vib-sm-mt-50'>Students & Recent Graduates</h2>
          <h6 className='vib-bold-para vib-res-left mx-3'> 
          Kick start your career with Vibtree's special program 
          for students where we offer a variety of internships 
          and full-time opportunities. 
          </h6>
          <a 
              className="hvr-bounce-to-bottom1 
                          design1 
                          big-blog-button 
                          mb-3 
                          vib-res-button-height 
                          vib-bold-para 
                          vib-mx-auto
                          mx-3" 
              href={linkedinJobPage}
          style={{
            backgroundColor:'#0046BB',
            width:'200px',
            margin:'0',
            height:'55px'}}>
               Apply Now
          </a>

          </div>  

          : 

          <div className="wo-right 
                          h-100 
                          d-flex 
                          flex-column 
                          justify-content-sm-between  
                          mx-md-3 
                          mx-sm-3">
          
          <h2 className='vib-container-sub-heading vib-res-left mx-3 vib-sm-mt-50'>Experienced Professionals</h2>
          <h6 className='vib-bold-para vib-res-left mx-3'>
          Looking for opportunities to enhance your skills 
          and take your career to the next level? 
          We are here for you!  
          </h6>
          <a className="hvr-bounce-to-bottom1 
                          design1 
                          big-blog-button 
                          mb-3 
                          vib-res-button-height 
                          vib-bold-para 
                          vib-mx-auto
                          mx-3"
             href={linkedinJobPage} 
               style={{
                 backgroundColor:'#0046BB',
                 width:'200px',
                 margin:'0',
                 height:'55px'}}>
                    Apply Now
          </a>
          
          </div>
         
          }
             
        </div>
       
      </div>
    </div>
    {/* join our team end  */}


    {/* what vibtree values start  */}
    <div className="vib-container d-flex flex-column align-items-center">
        <h1 className='vib-container-heading vib-res-center' 
            style={{fontWeight:'600',fontSize:'40px',maxWidth:'1000px'}}>
        What <span className="vib-blue">Vibtree</span> Values
        </h1>
        <h5 
        className='vib-bold-para vib-res-center' 
        style={{fontSize:'22px', 
                lineHeight:'35px',
                maxWidth:'1000px',
                fontWeight:'400'}}>
        We bring your Passions, Skills and Happiness together
        </h5>

        <div className="sms-cloud-flex">
          <div className="sms-cloud-flex-item" style={{boxShadow:'none',pointerEvents:'none'}}>
            {/* <img src={bulk} alt="bulkSmsImage" /> */}
            <img onLoad={imagesLoadIndicator}
                      // alt={image.alt}
                      // height={image.height}
                      src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/trust.svg'
                      effect='blur'
                      // width={image.width} 
                      alt="trust"
                      style={{padding:'45px'}}
                      className='vib-res-card-img-first classForLoaded'
                      />
            <h6 style={{fontSize:'22px'}} className='vib-sub-sub-heading'>Trust</h6>
            <p>
            We use trust as the founding stone in our corporate 
            culture. Our trust in our talented teammates gives 
            them the confidence to try new ideas and do their 
            best. Trusting each other is what helps us grow as 
            an organization and offer the best services to our clients.  
            </p>
          </div>

          <div className="sms-cloud-flex-item"  style={{boxShadow:'none',pointerEvents:'none'}}>
            {/* <img src={cloudOtp} alt="bulkSmsImage" style={{padding:'45px'}}/> */}
            <img onLoad={imagesLoadIndicator}
                      src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/innovation.svg'
                      effect='blur'
                      alt="innovation"
                      style={{padding:'45px'}}
                      className='vib-res-card-img classForLoaded'
                      />
            <h6 style={{fontSize:'21px'}} className='vib-sub-sub-heading'>Innovation</h6>
            <p>
            At Vibtree Technologies, we always encourage fresh ideas. 
            Innovation along with skills is the only driving force here 
            at Vibtree. We offer unlimited support and assistance to every 
            employee in helping them bring their visions to reality.   
            </p>
          </div>

          <div className="sms-cloud-flex-item"  style={{boxShadow:'none',pointerEvents:'none'}}>
            {/* <img src={billing} alt="bulkSmsImage" style={{padding:'45px'}}/> */}
            <img onLoad={imagesLoadIndicator}
                      src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/team-work.svg'
                      effect='blur'
                      alt="team-work"
                      style={{padding:'45px'}}
                      // style={{padding:'45px'}}
                      className='vib-res-card-img-first classForLoaded'
                      />
            <h6 style={{fontSize:'22px'}}  className='vib-sub-sub-heading'>Teamwork</h6>
            <p>
            At Vibtree Technologies, we work together to encourage, 
            motivate and inspire our fellow team members to bring out 
            the best in them. Working here, you will always feel 
            that ideas are valued. Our team culture supports 
            you professionally and also extends compassion 
            during tough times in life.  
            </p>
          </div>

        </div>
      </div>
    {/* what vibtree values end  */}
    
    {/* meet team vibtree start  */}
    <div className="wo-meet vib-res-margin-top-100-50">
    <h2 className='wo-meet-h2 
                   vib-padding-bottom-50-25 
                   vib-container-sub-heading'>
        Meet 
    <span className="vib-blue">#TeamVibtree</span>
    </h2>
    <WorkWithUsSlick/>
    </div>
    {/* meet team vibtree end  */}

    {/* perks starts  */}

    <div className="vib-container vib-margin-bottom-200-100">

    <div className="partner-center-cover" style={{textAlign:'center',width:'100%'}}>
    <h1 className='vib-container-heading vib-res-center vib-padding-bottom-50-25'>
      Perks of Working At <span style={{color:'#0046BB'}}>Vibtree</span>
    </h1>
    </div>
    
    {/* why partner start  */}
    
    <div className="part-why-inner">
    
      <div className="part-why-left">
    
         <div className="part-vib-res-cards-l-to-c">
           <img 
                 onLoad={imagesLoadIndicator} 
                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/complete-cloud-telephony-product-suite.svg' 
                 alt="complete-cloud-telephony-product-suite" 
                 className='partner-why-part classForLoaded' 
                 style={{padding:'26px'}} />
           <h4 
           className='vib-sub-sub-heading' 
           style={{fontSize:'18px',
                   marginTop:'0px',
                   marginBottom:'5px'}}>
           Hybrid Working
           </h4>
           <p>Work from office- work from home- 
           work from anywhere! Yes, we are cool with your 
           choice of place, as long as you get the work done. 
           </p>
         </div>  
    
         <div className="part-why-cus part-vib-res-cards-l-to-c">
           <img 
                 onLoad={imagesLoadIndicator} 
                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/customized-products-and-services.svg'
                 alt="search" 
                 className='partner-why-part classForLoaded'/>
           <h4 
           className='vib-sub-sub-heading' 
           style={{fontSize:'18px',
                  marginTop:'0px',
                  marginBottom:'5px',
                  textAlign:'left'}}>
           Flexible Hours</h4>
           <p>
           We have kept things flexible so you can adjust your 
           working hours to maintain a healthy work-life balance 
           and still be efficient at what you do.
           </p>
         </div>
    
      </div>
    
      <div className="part-why-middle">
    
         <div className="part-why-middle-img">
           <img 
                 onLoad={imagesLoadIndicator} 
                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/work-with-us/perks-of-working-at-vibtree.svg' 
                 alt="search" 
                 className='partner-why-part-middle classForLoaded' 
                 style={{marginLeft:'10px'}} />
         </div>
    
         <div className="part-why-cred">
           <img 
                 onLoad={imagesLoadIndicator} 
                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/customer-credibility.svg' 
                 alt="search" 
                 className='partner-why-part classForLoaded' 
                 style={{padding:'20px'}}/>
           <h4 className='vib-sub-sub-heading' 
           style={{fontSize:'18px',marginTop:'0px',marginBottom:'5px'}}>
           Learning & Development
           </h4>
           <p style={{textAlign:'center'}}>
           We encourage our team to keep getting better at what they like doing, 
           so we focus on regular training sessions to help you learn while you work.
           </p>
         </div>
    
      </div>
    
      <div className="part-why-right">
    
         <div className="part-why-right part-vib-res-cards-r-to-c">
           <img 
                 onLoad={imagesLoadIndicator} 
                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/increased-profit-margin.svg'
                 alt="search" 
                 className='partner-why-part classForLoaded' 
                 style={{padding:'25px',height:'100px'}}/>
           <h4 className='vib-sub-sub-heading' 
           style={{fontSize:'18px',marginTop:'0px',marginBottom:'5px'}}>
           Performance Bonus
           </h4>
           <p>Your hardwork, dedication and success will always be 
           appreciated and rewarded with a performance-based 
           bonus. Your talent is unique and so are you.
           </p>
         </div>
    
         <div className="part-why-right part-vib-res-cards-r-to-c">
           <img 
                 onLoad={imagesLoadIndicator} 
                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/advanced-resources-and-support.svg'
                 alt="search" 
                 className='partner-why-part classForLoaded'/>
           <h4 className='vib-sub-sub-heading' 
               style={{fontSize:'18px',marginTop:'0px',marginBottom:'5px'}}>
               Happy Work Environment
               </h4>
           <p>
           Vibtree office is always a happy place for all the 
           employees, as we work on creating a healthy 
           work environment for you with recreational 
           activities. 
           </p>
         </div>
    
      </div>
    </div>
    </div>

    {/* perks end  */}

    {/* call us now section start  */}
    <section className="book-a-call">
        <div className="container-fluid vib-padding-bottom-100-50">
          <div className="book-for-display-center 
                          d-flex 
                          vib-justify-content-evenly 
                          vib-600-jus-cen
                          flex-wrap">
            <div className="col-lg-6 text-start book-for-half">
              <div className="wrap">
                <h2 
                className="vib-res-left heading-extra-length vib-container-sub-heading" 
                style={{fontSize:'32px',fontWeight:'700',wordSpacing:'1px'}}>
                   Coudn't find the right fit for your interest?
                </h2>
                <p className='vib-bold-para heading-extra-length vib-res-left' 
                   style={{fontSize:'33px',
                           lineHeight:'45px',
                           marginTop:'10px',
                           fontWeight:'400'}}>
                   Drop in your resume with an impressive cover<br /> letter at <a style={{color:'#ffff'}} className='sms-inherit vib-h-y-u' href="mailto:hr@vibtree.com">hr@vibtree.com</a> 
                </p>
                <div className="about-button-center">

                  <a href='tel:18005724590' className="hvr-bounce-to-bottom1 design1" 
                      style={{
                        backgroundColor:'#FFCE42',
                        color:'black',
                        fontWeight:'300',
                        fontSize:'26px',
                        padding:'5px',
                        width:'200px',
                        marginBottom:'50px'}}>
                      Call Us Now
                  </a>

                  </div>
              </div>
            </div>
            <div className="d-flex"
                 style={{display:'flex'}}>
              <img onLoad={imagesLoadIndicator}
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/let-your-business-be-heard.svg' 
                    className="img-fluid 
                               book-image-now 
                               book-img-disappear 
                               vib-sms-left-margin-auto
                               classForLoaded" 
                    alt="callnow"
                      />
              {/* <center><img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/let-your-business-be-heard.svg' alt="callnow" className='book-image-now book-img-appear'/></center> */}
              {/* <center> */}
              <img onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/let-your-business-be-heard.svg' 
              alt="callnow" 
              className='book-image-now book-img-appear classForLoaded'/>
              {/* </center> */}
            </div>
          </div>
        </div>
      </section>
    {/* call us now section end */}

    </div>
  )
}
