import React from 'react';
import { hydrate, render } from "react-dom";
import './index.css';
import App from './App';
import { BrowserRouter  as Router } from 'react-router-dom';


const app =( 
  <Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>
)
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}


