import React from 'react'

export default function HaveMoreQuestions() {
  return (
    <>
      
    {/* have more questions start  */}
    <h6 className='p-have v-mt-150-80'>Have more questions?</h6>
    <h4 className='p-contact-our vib-margin-top-30-0'>Contact our support team now</h4>

    <div className="p-footer vib-res-margin-top-100-50">

      <a href='tel:1800 572 4590'>
        <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/call-us.png" alt="call-us" />
        <div>
          <h6>Call Us</h6>
          <p>Toll Free: 1800 572 4590</p>
        </div>
      </a>

      <a href=''>
        <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/live-chat.png" alt="live-chat" />
        <div>
          <h6>Live chat: Online</h6>
          <p>Chat with our customer support team 24x7</p>
        </div>
      </a>

      <a href='mailto:info@vibtree.com'>
        <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/email-us.png" alt="email-us" />
        <div>
          <h6>Email Us</h6>
          <p>Email us anytime, you will receive a prompt response</p>
        </div>
      </a>

    </div>
    {/* have more questions end  */}
      
    </>
  )
}
