import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import './callTracking.css'
import SimpleSliderCallTracking from './SimpleSliderCallTracking'
import favicon from '../../images/vibtree.ico'
import { Accordion, Card } from 'react-bootstrap'
import { vibContext } from 'src/App'
import LoaderComponent from 'src/LoaderComponent'


export default function CallTracking() {
  const [accFirst, setaccFirst] = useState('yes');
  const [accSecond, setaccSecond] = useState('no');
  const [accThird, setaccThird] = useState('no');
  const [accFourth, setaccFourth] = useState('no');
  const [accFive, setaccFive] = useState('no');
  const [accSix, setaccSix] = useState('no');

  const [ImagesLoadCount, setImagesLoadCount] = useState(0);
  const [loader, setloader] = useState(true);

  const ctContext= useContext(vibContext);
  const contextId=ctContext.vibState;

  useEffect(() => {
    const element = document.getElementById(contextId);
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0
      }); 
    }, 100);
  },[contextId]);

  var handleClick1=()=>{
    setaccSecond('no')
    setaccThird('no')
    setaccFourth('no')
    setaccFive('no')
    setaccSix('no')
    return(
      accFirst==='yes' ? setaccFirst('no') : accFirst==='no' ? setaccFirst('yes') : null
    )
  }
  var handleClick2=()=>{
    setaccFirst('no');
    setaccThird('no');
    setaccFourth('no');
    setaccFive('no');
    setaccSix('no');
    return(
      accSecond==='yes' ? setaccSecond('no') : accSecond==='no' ? setaccSecond('yes') : null
    )
  }
  var handleClick3=()=>{
    setaccFirst('no');
    setaccSecond('no');
    setaccFourth('no');
    setaccFive('no');
    setaccSix('no');
    return(
      accThird==='yes' ? setaccThird('no') : accThird==='no' ? setaccThird('yes') : null
    )
  }
  var handleClick4=()=>{
    setaccFirst('no');
    setaccSecond('no');
    setaccThird('no');
    setaccFive('no');
    setaccSix('no');
    return(
      accFourth==='yes' ? setaccFourth('no') : accFourth==='no' ? setaccFourth('yes') : null
    )
  }
  var handleClick5=()=>{
    setaccFirst('no');
    setaccSecond('no');
    setaccThird('no');
    setaccFourth('no');
    setaccSix('no');
    return(
      accFive==='yes' ? setaccFive('no') : accFive==='no' ? setaccFive('yes') : null
    )
  }
  var handleClick6=()=>{
    setaccFirst('no')
    setaccSecond('no')
    setaccThird('no')
    setaccFourth('no')
    setaccFive('no')
    return(
      accSix==='yes' ? setaccSix('no') : accSix==='no' ? setaccSix('yes') : null 
    )
  }

    // image loading spinner start 
    const imagesLoadIndicator=()=>{
      const images = document.getElementsByClassName('classForLoaded');
  
      setImagesLoadCount(ImagesLoadCount+1);
  
      if (ImagesLoadCount===images.length-1) {
        setloader(false)
      }
  
    }
    // image loading spinner end

  return (
    <div>
      <Helmet>
        <title>Get access to call analytics with Incoming Call tracking software</title>
        {/* <link rel="icon" href={favicon} /> */}
        <meta name="description" content="Vibtree Call Tracking Software offers tracking solutions to track leads through inbound call analytics and optimize marketing performance for PPC, SEO and offline Ad campaigns"/>
        <link rel="canonical" href="https://www.vibtree.com/products/call-tracking/" />
        {/* og tags start  */}
        {/* <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Get access to call analytics with Incoming Call tracking software" />
        <meta property="og:description" content="Vibtree Call Tracking Software offers tracking solutions to track leads through inbound call analytics and optimize marketing performance for PPC, SEO and offline Ad campaigns" />
        <meta property="og:url" content="https://www.vibtree.com/products/call-tracking/" />
        <meta property="og:site_name" content="Vibtree Technologies - Award winning cloud telephony service" />
        <meta property="og:image" content='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/power-up-your-sales-with-best-call-tracking-data.svg'/>
        <meta property="og:image:secure_url" content='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/power-up-your-sales-with-best-call-tracking-data.svg' />
        <meta property="og:image:width" content="700" />
        <meta property="og:image:height" content="700" />
        <meta property="og:image:alt" content="Award winning cloud telephony service"/>
        <meta property="og:image:type" content="image/svg" /> */}
        {/* og tags end  */}
        {/* twitter tag start  */}
        {/* <meta name= "twitter:site" content="@vibtree"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Get access to call analytics with Incoming Call tracking software" />
        <meta name="twitter:description" content="Vibtree Call Tracking Software offers tracking solutions to track leads through inbound call analytics and optimize marketing performance for PPC, SEO and offline Ad campaigns" />
        <meta name="twitter:image" content='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/power-up-your-sales-with-best-call-tracking-data.svg' /> */}
        {/* twitter tag end  */}
        <script  type="application/ld+json">
        {`{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "Vibtree Technologies",
            "legalName": "Vibtree Technologies",
            "alternateName": "www.vibtree.com",
            "description": "Vibtree Call Tracking Software offers tracking solutions to track leads through inbound call analytics and optimize marketing performance for PPC, SEO and offline Ad campaigns",
            "url": "https://www.vibtree.com/products/call-tracking/",
            "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
            "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "18005724590",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/vibtree",
              "https://twitter.com/vibtree",
              "https://www.instagram.com/vibtree",
              "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
              "https://www.linkedin.com/company/vibtree",
              "https://www.vibtree.com"
            ]}`}
        </script>

        <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "products"
                    }
                },{
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@id": "https://www.vibtree.com/products/call-tracking/",
                        "name": "call-tracking"
                    }
                }]
            }`}
        </script>
      </Helmet>
        {/* body schema tag start  */}
        <body itemScope itemType="https://schema.org/WebPage"></body>
        {/* body schema tag start  */}

      {loader===true ? <LoaderComponent/> : null}

      <SimpleSliderCallTracking imageLoadCountFunction={imagesLoadIndicator} />
      
      {/* call-tracking-software-start  */}
      <div className="call-tracking-software-start vib-res-margin-top-100-50 px-3">
         <h1 className='ct-ss-h1'><span className='vib-color-yellow'>Call Tracking Software: </span> <br />
             How Lead Tracking Can Help You Grow Leads? 
         </h1>
         <p className="ct-ss-p">
         Deriving meaningful insights from inbound call sources and monitoring customer services. 
         Asking callers how they got to know about your business is old school and you are definitely on the wrong track if you are still doing it. 
         </p>
         <div className="ct-ss-img-content-wrapper vib-padding-bottom-100-50">
           <div className="ct-ss-content vib-res-margin-top-50-50">
               <div className="ct-ss-cw-p">
               Are you unable to analyze which campaigns are most & least effective?
               </div>
               <div className="ct-ss-cw-p">
               Do you wish to cut down on your Ad campaign budget & get more leads?
               </div>
               <div className="ct-ss-cw-p">
               Are you losing leads because you cannot track all the calls to your business?
               </div>
           </div>
           <div className="ct-ss-img vib-res-margin-top-50-50">
               <img onLoad={imagesLoadIndicator} 
                   src='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/call-tracking-software-how-lead-tracking-can-help-you-grow-leads.svg' 
                   className='classForLoaded'
                   style={{maxWidth:'100%'}}
                   alt="call-tracking-software-how-lead-tracking-can-help-you-grow-leads" />
           </div>
         </div>
      </div>
      {/* call-tracking-software-end  */}

      {/* phone call tracking software start */}
      <div className="vib-container vib-res-margin-top-200-100  px-3">
        <h1 className='vib-container-heading vib-res-center' 
        style={{fontWeight:'700',fontSize:'50px',lineHeight:'69px'}}>
        Phone Call Tracking Software: <br />
        <span className="vib-blue"> Lead Tracking Services We Offer</span> 
        </h1>
        <h5 className='vib-bold-para vib-res-center' 
        style={{fontSize:'24px', lineHeight:'35px',maxWidth:'1080px'}}>
        Vibtree is India's 1st company that allows businesses to purchase call tracking numbers directly 
        from the web panel, without having to set up an entire system for it.     
        </h5>
      </div>
      <div className="vib-res-margin-top-50-50 call-res-center">
        <div className="call-tracking-timeline call-tracking-timeline-margin" 
        style={{paddingLeft:'25px',maxWidth:'1200px'}}>
        
          <div className="call-timeline-card-double-card" style={{position:'relative'}}>
           <div className="timline-common-wrapper1 vib-padding-bottom-50-25" style={{maxWidth:'465px',position:'relative'}}>
              <div className="timeline__middle call-dot-position">
                <div className="timeline__point" 
                style={{width:'18px',height:'18px'}}>
                <div className="timeline-white-dot" style={{width:'6px',height:'6px'}}></div>
                </div>
              </div>
              <h3 className="vib-container-heading vib-blue vib-res-left vib-sub-sub-heading" 
              style={{
                fontSize:'24px',
                fontWeight:'600',
                lineHeight:'29px'}}>
              Track the campaign that directed a customer to call your business
              </h3>
              <h5 className='vib-bold-para vib-res-left' 
              style={{fontSize:'18px', lineHeight:'22px',color:'#464646',marginTop:'25px'}}>
              Our Call Tracking software enables you to run multiple Ad campaigns 
              across various channels and track the most responsive Ad and channel 
              to enhance the lead conversion.      
              </h5>
           </div>

           <div className="ct-middle-line"><div className="ct-middle-grey-line"></div></div>

           <div className="timline-common-wrapper2 vib-padding-bottom-50-25" style={{maxWidth:'465px',position:'relative'}}>
              <div className="timeline__middle call-dot-position">
                <div className="timeline__point" 
                style={{width:'18px',height:'18px'}}>
                <div className="timeline-white-dot" style={{width:'6px',height:'6px'}}></div>
                </div>
              </div>
              <h3 className="vib-container-heading vib-blue vib-res-left vib-sub-sub-heading" 
              style={{
                fontSize:'24px',
                fontWeight:'600',
                lineHeight:'29px'}}>
              Build a valuable database for long-term business strategy designing
              </h3>
              <h5 className='vib-bold-para vib-res-left' 
              style={{fontSize:'18px', lineHeight:'22px',color:'#464646',marginTop:'25px'}}>
              With our phone call recording and tracking software, you create a vast 
              database that helps you in drawing meaningful insights while 
              planning the future strategy for your business.      
              </h5>
           </div>
          </div>


          <div className="call-timeline-card-double-card" style={{position:'relative'}}>
           <div className="timline-common-wrapper1 vib-padding-bottom-50-25" style={{maxWidth:'465px',position:'relative'}}>
              <div className="timeline__middle call-dot-position">
                <div className="timeline__point" 
                style={{width:'18px',height:'18px'}}>
                <div className="timeline-white-dot" style={{width:'6px',height:'6px'}}></div>
                </div>
              </div>
              <h3 className="vib-container-heading vib-blue vib-res-left vib-sub-sub-heading" 
              style={{
                fontSize:'24px',
                fontWeight:'600',
                lineHeight:'29px'}}>
              Analyze Inbound call data to find the high-value customers
              </h3>
              <h5 className='vib-bold-para vib-res-left' 
              style={{fontSize:'18px', lineHeight:'22px',color:'#464646',marginTop:'25px'}}>
              Using our call tracking services you can get better insights on the inbound 
              call data and call briefs in order to identify the highest 
              responding segment of customers.      
              </h5>
           </div>

           <div className="ct-middle-line"><div className="ct-middle-grey-line"></div></div>

           <div className="timline-common-wrapper2 vib-padding-bottom-50-25" style={{maxWidth:'465px',position:'relative'}}>
              <div className="timeline__middle call-dot-position">
                <div className="timeline__point" 
                style={{width:'18px',height:'18px'}}>
                <div className="timeline-white-dot" style={{width:'6px',height:'6px'}}></div>
                </div>
              </div>
              <h3 className="vib-container-heading vib-blue vib-res-left vib-sub-sub-heading" 
              style={{
                fontSize:'24px',
                fontWeight:'600',
                lineHeight:'29px'}}>
              Decide how to use your Ads budget in the most profitable manner
              </h3>
              <h5 className='vib-bold-para vib-res-left' 
              style={{fontSize:'18px', lineHeight:'22px',color:'#464646',marginTop:'25px'}}>
              With our phone call Tracking software, you can trace the highest call 
              generating ads and make wise decisions when it comes to 
              investing in the best performing Ads.       
              </h5>
           </div>
          </div>


          <div className="call-timeline-card-double-card" style={{position:'relative'}}>
           <div className="timline-common-wrapper1 vib-padding-bottom-50-25" style={{maxWidth:'465px',position:'relative'}}>
              <div className="timeline__middle call-dot-position">
                <div className="timeline__point" 
                style={{width:'18px',height:'18px'}}>
                <div className="timeline-white-dot" style={{width:'6px',height:'6px'}}></div>
                </div>
              </div>
              <h3 className="vib-container-heading vib-blue vib-res-left vib-sub-sub-heading" 
              style={{
                fontSize:'24px',
                fontWeight:'600',
                lineHeight:'29px'}}>
              Ensure higher rate of conversion by routing the calls to the right executive
              </h3>
              <h5 className='vib-bold-para vib-res-left' 
              style={{fontSize:'18px', lineHeight:'22px',color:'#464646',marginTop:'25px'}}>
              By using the best call tracking software and services, you can route the 
              customer to the appropriate service agent for a more 
              contextual conversation.       
              </h5>
           </div>

           <div className="ct-middle-line"><div className="ct-middle-grey-line"></div></div>

           <div className="timline-common-wrapper2 vib-padding-bottom-50-25" style={{maxWidth:'465px',position:'relative'}}>
              <div className="timeline__middle call-dot-position">
                <div className="timeline__point" 
                style={{width:'18px',height:'18px'}}>
                <div className="timeline-white-dot" style={{width:'6px',height:'6px'}}></div>
                </div>
              </div>
              <h3 className="vib-container-heading vib-blue vib-res-left vib-sub-sub-heading" 
              style={{
                fontSize:'24px',
                fontWeight:'600',
                lineHeight:'29px'}}>
              Enhance your campaign strategy to amplify the lead generation
              </h3>
              <h5 className='vib-bold-para vib-res-left' 
              style={{fontSize:'18px', lineHeight:'22px',color:'#464646',marginTop:'25px'}}>
              By not wasting your budget on underperforming marketing campaigns, you can 
              enhance the lead generation with all your focus on the ads that 
              offer high-value leads.        
              </h5>
           </div>
          </div>

        </div>
      </div>
      {/* phone call tracking software end */}

      {/* how to set up call tracking start  */}
      <div className="vib-container vib-res-margin-top-200-100 px-3">
        <h1 className='vib-container-heading vib-res-center ' style={{fontWeight:'700',fontSize:'33px'}}>
        How To Set-Up Call Tracking System To Enhance<span className="vib-blue"> Your ROI?</span> 
        </h1>
        <h5 className='vib-bold-para vib-res-center' style={{fontSize:'24px', lineHeight:'35px',maxWidth:'1080px'}}>
        As far-reaching as the features seem, it is not rocket science to start using our 
        phone call tracking software, especially if you choose Vibtree special 
        ready-to-go call tracking services.   
        </h5>
      </div>

     <h1 className='vib-container-sub-heading vib-res-center  px-3' style={{fontSize:'33px',marginTop:'150px'}}>
     Here's our <span className='vib-blue'> 3-step guide </span>to get you started-         
     </h1>
        <div className="vib-inner-section vib-container px-3">

            <div className="vib-content-section" style={{maxWidth:'500px'}}>
              <h1 className='vib-container-sub-heading vib-res-left font-montesarrat-bold-700' 
                  style={{fontSize:'68px',color:'#D3D5D8'}}>
                 #1
              </h1>
              <h5 className='vib-bold-para vib-res-left' style={{ fontSize:'26px',color:'#464646',marginTop:'0px'}}>
              Visit our website and choose & buy 
              a unique virtual number for 
              incoming call metrics tracking 
              </h5>
              <h5 className='vib-bold-para vib-res-left' 
              style={{ 
                fontSize:'18px',
                lineHeight:'22px',
                fontWeight:'400',
                color:'#464646',
                marginTop:'0px'}}>
              We are one of the very first companies in India offering a 
              ready-to-go call tracking set-up that allows you to directly 
              choose a number to track the calls for important user insights. 
              </h5>
            </div>

            <div className="vib-img-section-num vib-res-margin-top-50-50" style={{maxWidth:'620px',overflowX:'scroll'}}>
                <img 
                     onLoad={imagesLoadIndicator} 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/visit-our-website-and-choose-and-buy-a-unique-virtual-number-for-incoming-call-metrics-tracking.svg' 
                     alt="visit-our-website-and-choose-and-buy-a-unique-virtual-number-for-incoming-call-metrics-tracking" 
                     className='vib-img classForLoaded' 
                     style={{minWidth:'600px'}}/>
            </div>

        </div>

        <div className="vib-inner-section-reverse vib-container sms-margin-top-10 vib-res-margin-top-100-50 px-3">

            <div className="vib-img-section-num vib-res-margin-top-50-50" style={{maxWidth:'620px',overflowX:'scroll'}}>
                    <img 
                         onLoad={imagesLoadIndicator} 
                         src='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/set-targets-and-target-groups-for-call-tracking.svg' 
                         alt="set-targets-and-target-groups-for-call-tracking" 
                         className='vib-img classForLoaded'  
                         style={{minWidth:'600px'}}/>

            </div>

            <div className="vib-content-section"  style={{maxWidth:'500px'}}>
              <h1 className='vib-container-sub-heading vib-res-left font-montesarrat-bold-700'
                  style={{fontSize:'68px',color:'#D3D5D8'}}>
                 #2
              </h1>

              <h5 className='vib-bold-para vib-res-left' style={{ fontSize:'26px',color:'#464646',marginTop:'0px'}}>
              Set Targets & Target groups For 
              Call Tracking
              </h5>
              <h5 className='vib-bold-para vib-res-left' 
              style={{ 
                fontSize:'18px',
                lineHeight:'22px',
                fontWeight:'450',
                color:'#464646',
                marginTop:'0px'}}>
              We allow you to forward your calls to a single target 
              or multiple targets based on your requirements. 
              At this step you can choose a phone number, or a 
              PBX to route your calls from the customers.  
              </h5>
            </div>

        </div>

        <div className="vib-inner-section vib-container  sms-margin-top-10 vib-res-margin-top-100-50 px-3">

            <div className="vib-content-section " style={{maxWidth:'500px'}}>
              <h1 className='vib-container-sub-heading vib-res-left font-montesarrat-bold-700'
                  style={{fontSize:'68px',color:'#D3D5D8'}}>
                #3
              </h1>
              <h5 className='vib-bold-para vib-res-left' style={{ fontSize:'26px',color:'#464646',marginTop:'0px'}}>
              You are now in-charge of all your 
              call tracking data and can easily 
              control your Ads.
              </h5>
              <h5 className='vib-bold-para vib-res-left' 
              style={{ 
                fontSize:'18px',
                lineHeight:'22px',
                fontWeight:'450',
                color:'#464646',
                marginTop:'0px'}}>
              After completing your payment, you have the power to manage 
              the inbound call data to derive meaningful outcomes that will 
              help you scale-up your campaigns in a more efficient manner.   
              </h5>
            </div>

            <div className="vib-img-section-num vib-res-margin-top-50-50" style={{maxWidth:'620px',overflowX:'scroll'}}>
                <img 
                    onLoad={imagesLoadIndicator} 
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/you-are-now-in-charge-of-all-your-call-tracking-data-and-can-easily-control-your-ads.svg'
                    alt="you-are-now-in-charge-of-all-your-call-tracking-data-and-can-easily-control-your-ads" 
                    className='vib-img classForLoaded'  
                    style={{minWidth:'600px'}}
                   />
            </div>

        </div>
    {/* how to set up call tracking end */}

    {/* why do we need vibtree call tracking start  */}
      <div className="vib-container vib-res-margin-top-200-100 px-3">
        <h1 className='vib-container-heading vib-res-center' 
        style={{fontWeight:'700',fontSize:'48px',lineHeight:'69px'}}>
        Why Do You Need <span className="vib-blue">Vibtree Phone Call Tracking</span>  & <br />
        Recording Software For Your Business Growth?
        </h1>
        <h5 className='vib-bold-para vib-res-center' 
        style={{fontSize:'24px', lineHeight:'35px',maxWidth:'1080px'}}>
        Vibtree has designed a highly advanced market analytics call tracking software, 
        keeping in mind the questions that many businesses ask- how to 
        do call tracking to optimize their marketing budget and effort?   
        </h5>
      </div>

      <div className="vib-res-margin-top-50-50 call-res-center px-3">
        <div className="call-tracking-timeline call-tracking-timeline-margin" 
        style={{paddingLeft:'25px'}}>
        
          <div className="call-timeline-card vib-padding-bottom-50-25" style={{maxWidth:'550px',position:'relative'}}>
              <div className="timeline__middle call-dot-position">
                <div className="timeline__point" 
                style={{width:'18px',height:'18px'}}>
                <div className="timeline-white-dot" style={{width:'6px',height:'6px'}}></div>
                </div>
              </div>
              <h3 className="vib-container-heading vib-blue vib-res-left vib-sub-sub-heading" 
              style={{
                fontSize:'24px',
                fontWeight:'600',
                lineHeight:'29px'}}>
              Comprehensive analytics to help you track your call performance. 
              </h3>
          </div>

          <div className="call-timeline-card vib-padding-bottom-50-25" style={{maxWidth:'550px',position:'relative'}}>
              <div className="timeline__middle call-dot-position">
                <div className="timeline__point" 
                style={{width:'18px',height:'18px'}}>
                <div className="timeline-white-dot" style={{width:'6px',height:'6px'}}></div>
                </div>
              </div>
              <h3 className="vib-container-heading vib-blue  vib-res-left vib-sub-sub-heading" 
              style={{
                fontSize:'24px',
                fontWeight:'600',
                lineHeight:'29px'}}>
              Real-time reporting for inbound traffic assessment to enhance marketing strategies.
              </h3>
          </div>

          <div className="call-timeline-card vib-padding-bottom-50-25" style={{maxWidth:'550px',position:'relative'}}>
              <div className="timeline__middle call-dot-position">
                <div className="timeline__point" 
                style={{width:'18px',height:'18px'}}>
                <div className="timeline-white-dot" style={{width:'6px',height:'6px'}}></div>
                </div>
              </div>
              <h3 className="vib-container-heading vib-blue vib-res-left vib-sub-sub-heading" 
              style={{
                fontSize:'24px',
                fontWeight:'600',
                lineHeight:'29px'}}>
              Services to track key metrics like call volume, 
              call source, call recordings and conversions 
              per campaign. 
              </h3>
          </div>

          <div className="call-timeline-card vib-padding-bottom-50-25" style={{maxWidth:'550px',position:'relative'}}>
              <div className="timeline__middle call-dot-position">
                <div className="timeline__point" 
                style={{width:'18px',height:'18px'}}>
                <div className="timeline-white-dot" style={{width:'6px',height:'6px'}}></div>
                </div>
              </div>
              <h3 className="vib-container-heading vib-blue  vib-res-left vib-sub-sub-heading" 
              style={{
                fontSize:'24px',
                fontWeight:'600',
                lineHeight:'29px'}}>
              Audience tracking to understand success components 
              to augment lead conversions. 
              </h3>
          </div>

        </div>
      </div>
    {/* why do we need vibtree call tracking end  */}

     {/* looseing leads and loseing money start  */}
    <section className="book-a-call vib-res-margin-top px-3">
        <div className="container-fluid">
          <div className="row book-for-display-center d-flex vib-justify-content-evenly">
            <div className="col-lg-6 text-start book-for-half">
              <div className="wrap">
                <h2 
                className="text-start heading-extra-length vib-container-heading" 
                style={{fontSize:'33px',fontWeight:'700'}}>
                Losing Leads & Losing Money?
                </h2>
                <p 
                className='vib-bold-para heading-extra-length' 
                style={{fontSize:'33px',
                        lineHeight:'45px',
                        marginTop:'10px',
                        fontWeight:'400'}}>
                   Talk to our specialists to understand our call tracking <br /> solution can help you track leads and gain ROI.
                </p>
                <div className="about-button-center">

                  <a  href='tel:18005724590' className="hvr-bounce-to-bottom1 design1" 
                      style={{
                        backgroundColor:'#FFCE42',
                        color:'black',
                        fontWeight:'300',
                        fontSize:'26px',
                        padding:'5px',
                        width:'300px',
                        marginBottom:'50px'}}>
                      Get Free Consultation
                  </a>

                  </div>
              </div>
            </div>
            <div className="d-flex"
                 style={{display:'flex'}}>
              <img onLoad={imagesLoadIndicator} 
                  src='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/losing-leads-and-losing-money.svg'
                  className="img-fluid 
                             book-image-now 
                             book-img-disappear
                             vib-sms-left-margin-auto
                             classForLoaded" 
                  alt="callnow"
                  style={{marginTop:'80px'}}
                  />
              <img onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/losing-leads-and-losing-money.svg' 
              alt="losing-leads-and-losing-money" 
              className='book-image-now book-img-appear classForLoaded' style={{margin:'auto'}}/>
            </div>
          </div>
        </div>
      </section>
     {/* looseing leads and loseing money end  */}

        {/* accordin start  */}
    <div className="vib-container"> 
         <h1 className='ct-acc-h1'>Frequently Asked Questions:<span className='vib-blue'> Call Tracking</span></h1>
    </div>
    <div className="vib-container-narrow sms-accordin-margin-top" style={{marginTop:'50px',marginBottom:'130px'}}>
    <Accordion defaultActiveKey="0" style={{width:'100%', padding:'20px'}}>

        <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="0"
          onClick={handleClick1} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white',
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> HOW DOES AN INCOMING CALL TRACKING SOFTWARE WORK?</span>
             <div className="accordin-right-icon" 
             style={{transform: accFirst==='yes' ? 'rotate(90deg)':accFirst==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span> Call tracking software helps you figure 
              out call source by call tracking of your campaigns and understand which advertisement 
              is performing well. This software allows business owners to attach separate 
              numbers to separate campaigns to track the leads. Businesses can choose 
              to have custom local number or toll-free numbers associated with different ads, 
              so when an interested lead calls in, our call tracking software will help you in 
              tracking calls of your customers to get meaningful insights about your campaign plans. 
              This will help you make informed decisions about how to target the right audience and 
              still save on marketing budget. 
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="1" 
          onClick={handleClick2} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> HOW DO I GET A CALL TRACKING NUMBER?</span>
             <div className="accordin-right-icon" 
             style={{transform: accSecond==='yes' ? 'rotate(90deg)':accSecond==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span> Vibtree is one of the top cloud telephony 
              companies that allows users to buy call tracking number from the portal instantly. 
              We are the best toll free number provider in India and once you pick Vibtree as your 
              end-to-end solution provider for call tracking system, you can purchase your call 
              tracking number from our portal in a simple and quick step.  
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEvent:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="2" 
          onClick={handleClick3} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> HOW MUCH DOES A CALL TRACKING SOFTWARE IN INDIA COST?</span>
             <div className="accordin-right-icon" 
             style={{transform: accThird==='yes' ? 'rotate(90deg)':accThird==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span> At Vibtree Technologies all our 
              pricing packages are fully customizable. We only charge for the services 
              you need based on your business requirements. Our pricing plans are highly 
              flexible and can be adjusted according to the call tracking services you choose. 
              You can book a demo session for a customized pricing plan or start your free trial 
              to explore pricing.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="3" 
          onClick={handleClick4} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> WHAT ARE THE KEY BUSINESS PERFORMANCE METRICS CAN BE TRACKED WITH THIS INCOMING CALL TRACKING SOFTWARE?</span>
             <div className="accordin-right-icon" 
             style={{transform: accFourth==='yes' ? 'rotate(90deg)':accFourth==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span>  Based on your business needs, 
              you can track call sources, call volumes, call recordings, and conversions 
              per campaign with our phone call tracking software.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="4" 
          onClick={handleClick5} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span>  WILL YOU OFFER TRAINING TO OUR AGENTS FOR USING THIS SYSTEM?</span>
             <div className="accordin-right-icon" 
             style={{transform: accFive==='yes' ? 'rotate(90deg)':accFive==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span> We offer all the support and assistance 
              needed by the users right from starting to onboarding, training and 
              integration of our call tracking system.  
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="5" 
          onClick={handleClick6} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> CAN I USE CLOUD SMS SERVICE WITH OTHER CLOUD TELEPHONY SERVICES?</span>
             <div className="accordin-right-icon" 
             style={{transform: accSix==='yes' ? 'rotate(90deg)':accSix==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span>  Call metrics tracking or lead 
              tracking can be easily used with our business cloud telephony 
              suite to enhance your business operations. Users can use bundled 
              cloud telephony services to customize the services to match their 
              business requirements. Our cloud telephony business suite offers 
              full cloud phone features like IVR or voice menu, call recording, 
              call monitoring, call flows, cloud SMS, contact center, lead center, 
              cloud number, smart caller, voice messenger and much more. <br />
              To get more information on purchasing DID numbers, email us at 
              <a style={{color:'inherit'}} className='vib-h-b-u' href="mailto:sales@vibtree.com">sales@vibtree.com.</a> <br />
              If you have any further questions you'd like an answer for, please feel free to 
              call us on our Toll Free Number 
               <a style={{color:'inherit'}} className='vib-h-b-u' href="tel:18005724590"> 18005724590</a> 
              or write to us: 
               <a style={{color:'inherit'}} className='vib-h-b-u' href="mailto:info@vibtree.com"> info@vibtree.com.</a>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
    </Accordion>
    </div>
    {/* accordin start  */}


    </div>
  )
}
