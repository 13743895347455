import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import LoaderComponent from 'src/LoaderComponent';
import './../voice/voice.css'
import businesshour from './../../images/industry/businesshour.png'
import contacttags from './../../images/industry/contacttags.svg'
import contactnotes from './../../images/industry/contactnotes.svg'
import sharedcontact from './../../images/industry/sharedcontacticon.webp'
// import callrouting from './../../images/industry/callrouting.png'
import confrence from './../../images/styles/conference-call-svgrepo-com.svg'
import internationalnumber from './../../images/styles/international-calls-svgrepo-com.svg'
import tollfreenumber from './../../images/styles/toll-free-icon.svg'
import workinghours from './../../images/styles/working-hours-icon.svg'
import contactstatus from './../../images/styles/contactstatus.svg'
import contactimport from './../../images/styles/contactimport.svg'
import contactgroup from './../../images/styles/contactgroup.svg'
import contactmanage from './../../images/styles/contactmanage.svg'
import callrouting from './../../images/styles/callrouting.svg'
import blocklist from './../../images/styles/blocklist.svg'
import managecontacts from './../../images/styles/Managenumber.png'
import warmtransfer from './../../images/styles/warmtransfer.svg'
import chatbot from './../../images/styles/chatbot.png'
import universalinbox from './../../images/styles/universalinbox.svg'
import smsicon from './../../images/styles/smsicon.svg'
import privatenotes from './../../images/styles/privatenotes.svg'
import facebook from './../../images/styles/facebook.svg'
import whatsapp from './../../images/styles/whatsapp.svg'
import viber from './../../images/styles/viber.svg'
// import telegram from './../../images/styles/telegram.svg'
import contactgroupca from './../../images/styles/contactgroupca.png'
import conferencecall from './../../images/styles/conferencecall.png'
import setting from './../../images/styles/settings.svg'
import automation from './../../images/styles/automation.png'
import assign from './../../images/styles/assign.png'
import voicebot from './../../images/styles/voicebot.svg'
import { Button } from '@mui/material';
import Group from './../../images/Group.png'
import BookACall from '../homePage/BookACall';
import Featureslaptop from './Featurelaptop';
import Featuresmobile from './Fetauremobile';



function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const Features = () => {
    const [loader, setloader] = useState(true);

    const { height, width } = useWindowDimensions();
    const [ImagesLoadCount, setImagesLoadCount] = useState(0);
    // image loading spinner start 
    const imagesLoadIndicator = () => {
        const images = document.getElementsByClassName('classForLoaded');

        setImagesLoadCount(ImagesLoadCount + 1);

        if (ImagesLoadCount === images.length - 1) {
            setloader(false)
        }

    }
    return (
        <>
            {
                width > 600 ? <>
                    <Featureslaptop />
                </> : <>
                    <Featuresmobile />
                </>
            }
        </>

    )
}

export default Features