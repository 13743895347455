import {createSlice} from "@reduxjs/toolkit"


export const breadcrumbSlice = createSlice({
    name: 'breadcrumb',

    initialState:{
        page:'',
        dropdown:'',
        dropdownToggle:false
    },

    reducers: {
    
    currentPage: (state, action)=>{
         state.page = action.payload
    },

    dropDownOpen: (state, action)=>{
        state.dropdown = action.payload
    },

    dropdownToggleAction: (state, action)=>{
        state.dropdownToggle = action.payload
    }
    
    }
})

export const {currentPage, dropDownOpen, dropdownToggleAction} = breadcrumbSlice.actions
export default breadcrumbSlice.reducer

