import React from 'react'
import './commonThankUn.css'
import { Helmet } from 'react-helmet'

export default function ThankYou() {
  return (
    <div className='thank-you' style={{marginBottom:'150px'}}>
    <Helmet>
    </Helmet>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/thank-you-and-unsubscribe/thank-you.svg' alt="thank-you" className='thank-you-img'/>
      <h1 className='vib-container-heading vib-res-center vib-blue-small' 
          style={{fontSize:'50px',lineHeight:'75px',fontWeight:'700'}}>
          Thank you for getting in touch! 
      </h1>
      <h5 className='vib-bold-para 
                     vib-res-center' 
          style={{fontSize:'23px',
                  marginTop:'35px',
                  lineHeight:'32px',
                  fontWeight:'500',
                  maxWidth:'1130px'}}>
        We appreciate you contacting Vibtree. We have sent a confirmation 
        email to the ID provided by you. If not received in inbox, please 
        check your junk or spam folder and whitelist our email so you do not 
        miss out on any future correspondence.  <br />
        Have a great day!
      </h5>
    </div>
  )
}
