import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { crmBaseUrl } from './utils/config';
import validator from "validator";
import { useEffect } from 'react';
import './style1.css'
export default function GetStarted() {
  const [emailExists, setEmailExists] = useState(true);
  const [inputEmail, setinputEmail] = useState('');
  const [isEmailValid, setEmailValidity] = useState(null);

  const [ref_page_url, setref_page_url] = useState('');
  const [utm_source_s, setutm_source] = useState('');
  const [utm_medium_s, setutm_medium] = useState('');
  const [utm_campaign_s, setutm_campaign] = useState('');
  const [utm_term_s, setutm_term] = useState('');
  const [utm_content_s, setutm_content] = useState('');
  const [publisherid_s, setpublisherid] = useState('');

  let refferrerUrl = document.referrer
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    setref_page_url(refferrerUrl)
    setutm_source(params.utm_source)
    setutm_medium(params.utm_medium)
    setutm_campaign(params.utm_campaign)
    setutm_term(params.utm_term)
    setutm_content(params.utm_content)
    setpublisherid(params.publisherid)
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (isEmailValid) {
      emailCheck()
    }
  }, [inputEmail]);

  async function emailCheck() {
    try {
      const response = await axios.get(`${crmBaseUrl}/email_exist?email_address=${inputEmail}`);
      if (response.data.data === true) {
        setEmailExists(false)
      } else if (response.data.data === false) {
        setEmailExists(true)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <>
      {/* eslint-disable-next-line */}
      <form
        autoComplete="false"
        role="form"
        method="post"
        action="https://launcher.vibtree.in/form/submit?formId=15"
        id="mauticform_vibtreesignup"
        data-mautic-form="vibtreesignup"
        encType="multipart/form-data"
      >
        <div className="input-group mb-3 banner-input">
          <div className="input-group-prepend left">
            <span className="input-group-text" id="basic-addon1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                fill="currentColor"
                className="bi bi-envelope"
                viewBox="0 0 16 16"
              >
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
              </svg>
            </span>
          </div>

          <input
            id="mauticform_input_vibtreesignup_email"
            name="mauticform[email]"
            type="email"
            className="form-control home-banner-input"
            placeholder="Enter Your Email ID"
            aria-label="Username"
            aria-describedby="basic-addon1"
            required
            style={{ maxHeight: '55px' }}
            onChange={(e) => {
              setinputEmail(e.target.value);
              setEmailValidity(validator.isEmail(e.target.value));
            }}

          />

          <div className="input-group-append middle border-radius-banner inputcheckwrigt">
            <span className="input-group-text border-radius-banner">
              {(() => {
                if (inputEmail == '') {
                  return
                }
                if ((isEmailValid === true && emailExists === true)) {
                  return <div>
                    <i className="bi bi-check2-circle" style={{ color: "green" }}></i>
                  </div>;
                }
                return <div>
                  <i className="bi bi-x-circle" style={{ color: "red" }}></i>
                </div>;
              })()}
            </span>
          </div>


          <input id="mauticform_input_vibtreesignup_ref_page_url" name="mauticform[ref_page_url]" value={ref_page_url} className="mauticform-hidden" type="hidden" />
          <input id="mauticform_input_vibtreesignup_service" name="mauticform[service]" value="home" className="mauticform-hidden" type="hidden" />
          <input id="mauticform_input_vibtreesignup_utm_source" name="mauticform[utm_source]" value={utm_source_s} className="mauticform-hidden" type="hidden" />
          <input id="mauticform_input_vibtreesignup_utm_medium" name="mauticform[utm_medium]" value={utm_medium_s} className="mauticform-hidden" type="hidden" />
          <input id="mauticform_input_vibtreesignup_utm_campaign" name="mauticform[utm_campaign]" value={utm_campaign_s} className="mauticform-hidden" type="hidden" />
          <input id="mauticform_input_vibtreesignup_utm_term" name="mauticform[utm_term]" value={utm_term_s} className="mauticform-hidden" type="hidden" />
          <input id="mauticform_input_vibtreesignup_utm_content" name="mauticform[utm_content]" value={utm_content_s} className="mauticform-hidden" type="hidden" />
          <input id="mauticform_input_vibtreesignup_publisherid" name="mauticform[publisherid]" value={publisherid_s} className="mauticform-hidden" type="hidden"></input>

          <input type="hidden" name="mauticform[formId]" id="mauticform_vibtreesignup_id" value="15" />
          <input type="hidden" name="mauticform[return]" id="mauticform_vibtreesignup_return" value="" />
          <input type="hidden" name="mauticform[formName]" id="mauticform_vibtreesignup_name" value="vibtreesignup"></input>
          <span className="mauticform-errormsg" style={{ display: 'none' }}>This is required.</span>

          <div className='banner-second-button'>

            {/* eslint-disable-next-line */}
            <a

              id="mauticform_input_vibtreesignup_submit"
              className="hvr-bounce-to-bottom hover-btn shadow getstartedbutton"
              type="submit"
              style={{ maxHeight: '55px' }}
              href={`https://app.vibtree.com/auth/register?email=${inputEmail}`}
              target='_blank'
            >
              Get Started
            </a>
          </div>

          <div className="input-group-append right">
            {/* eslint-disable-next-line */}
            {(isEmailValid === true && emailExists === true) && inputEmail != '' ? <>
              <a

                id="mauticform_input_vibtreesignup_submit"
                className="hvr-bounce-to-bottom hover-btn shadow getstartedbutton"
                type="submit"
                style={{ maxHeight: '55px' }}
                href={`https://app.vibtree.com/auth/register?email=${inputEmail}`}
                target='_blank'
              >
                Get Started
              </a>
            </> : <>
              <a

                id="mauticform_input_vibtreesignup_submit"
                className="hvr-bounce-to-bottom hover-btn shadow getstartedbutton"
                type="submit"
                style={{ maxHeight: '55px' }}

                target='_blank'
              >
                Get Started
              </a>

            </>}

          </div>

          {/* </div> */}

        </div>
      </form>

      {emailExists === false ? (
        <p
          style={{
            fontWeight: "300",
            marginTop: ".3rem",
            fontSize: ".8rem",
            color: "red",
          }}
          className='emailidalready'
        >
          Email ID already Registered
        </p>
      ) : ''}
    </>
  )
}
