import React, { useContext, useEffect } from 'react'
import { Helmet } from "react-helmet";
import './voice.css'

import { Accordion, Card } from 'react-bootstrap'
import { useState } from 'react'
import { vibContext } from 'src/App';
import VoiceSlider from './VoiceSlider';
import LoaderComponent from 'src/LoaderComponent';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Group from './../../images/Group.png'
import BookACall from '../homePage/BookACall';
function getWindowDimensions() {
    const { innerWidth: widthprice, innerHeight: height } = window;
    return {
        widthprice,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export default function Voicemobile() {
    const [accFirst, setaccFirst] = useState('yes');
    const [accSecond, setaccSecond] = useState('no');
    const [accThird, setaccThird] = useState('no');
    const [accFourth, setaccFourth] = useState('no');
    const [accFive, setaccFive] = useState('no');
    const [accSix, setaccSix] = useState('no');

    const [ImagesLoadCount, setImagesLoadCount] = useState(0);
    const [loader, setloader] = useState(true);

    const voiceContext = useContext(vibContext);
    const contextId = voiceContext.vibState;
    useEffect(() => {
        const element = document.getElementById(contextId);
        setTimeout(() => {
            window.scrollTo({
                behavior: element ? "smooth" : "auto",
                top: element ? element.offsetTop : 0
            });
        }, 100);
    }, [contextId]);

    var handleClick1 = () => {
        setaccSecond('no')
        setaccThird('no')
        setaccFourth('no')
        setaccFive('no')
        setaccSix('no')
        return (
            accFirst === 'yes' ? setaccFirst('no') : accFirst === 'no' ? setaccFirst('yes') : null
        )
    }
    var handleClick2 = () => {
        setaccFirst('no');
        setaccThird('no');
        setaccFourth('no');
        setaccFive('no');
        setaccSix('no');
        return (
            accSecond === 'yes' ? setaccSecond('no') : accSecond === 'no' ? setaccSecond('yes') : null
        )
    }
    var handleClick3 = () => {
        setaccFirst('no');
        setaccSecond('no');
        setaccFourth('no');
        setaccFive('no');
        setaccSix('no');
        return (
            accThird === 'yes' ? setaccThird('no') : accThird === 'no' ? setaccThird('yes') : null
        )
    }
    var handleClick4 = () => {
        setaccFirst('no');
        setaccSecond('no');
        setaccThird('no');
        setaccFive('no');
        setaccSix('no');
        return (
            accFourth === 'yes' ? setaccFourth('no') : accFourth === 'no' ? setaccFourth('yes') : null
        )
    }
    var handleClick5 = () => {
        setaccFirst('no');
        setaccSecond('no');
        setaccThird('no');
        setaccFourth('no');
        setaccSix('no');
        return (
            accFive === 'yes' ? setaccFive('no') : accFive === 'no' ? setaccFive('yes') : null
        )
    }
    var handleClick6 = () => {
        setaccFirst('no')
        setaccSecond('no')
        setaccThird('no')
        setaccFourth('no')
        setaccFive('no')
        return (
            accSix === 'yes' ? setaccSix('no') : accSix === 'no' ? setaccSix('yes') : null
        )
    }

    // image loading spinner start 
    const imagesLoadIndicator = () => {
        const images = document.getElementsByClassName('classForLoaded');

        setImagesLoadCount(ImagesLoadCount + 1);

        if (ImagesLoadCount === images.length - 1) {
            setloader(false)
        }

    }
    // image loading spinner end

    return (
        <div >
            <Helmet>
                <title>How To Use Cloud-Based Voice Call Broadcasting For Business Growth</title>
                <meta name="description" content="Customized Cloud Based Calling Solutions- Cloud contact center, Cloud PBX, Voice broadcasting, Missed-call, Toll-Free number, Drag-and-Drop IVR & Virtual number" />
                <link rel="canonical" href="https://www.vibtree.com/products/voice/" />
                <script type="application/ld+json">
                    {`{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "Vibtree Technologies",
            "legalName": "Vibtree Technologies",
            "alternateName": "www.vibtree.com",
            "description": "Customized Cloud Based Calling Solutions- Cloud contact center, Cloud PBX, Voice broadcasting, Missed-call, Toll-Free number, Drag-and-Drop IVR & Virtual number",
            "url": "https://www.vibtree.com/products/voice/",
            "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
            "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "18005724590",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/vibtree",
              "https://twitter.com/vibtree",
              "https://www.instagram.com/vibtree",
              "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
              "https://www.linkedin.com/company/vibtree",
              "https://www.vibtree.com"
            ]}`}
                </script>

                <script type="application/ld+json">
                    {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "products"
                    }
                },{
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@id": "https://www.vibtree.com/products/voice/",
                        "name": "voice"
                    }
                }]
            }`}
                </script>

                <script type="application/ld+json">
                    {`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What are the uses of cloud based IVR solutions?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our Cloud-based calling or voice solutions help you manage IVR hosting 
              easily. Among the myriad uses of these solutions, you can build Inbound/Outbound 
              call-centers based on cloud telephony, monitor your team's productivity and 
              efficiency for quality, offer order and customer's registration and verification, 
              build personalized services with impactful APIs, automate verification processes 
              for voice OTP or COD and ultimately generate more leads without any special 
              equipment or setup cost."
              }
            }, {
              "@type": "Question",
              "name": "What is Drag-and-Drop IVR?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "With our configurable IVR, using IVR hosting in India becomes easy. 
                You can quickly make 
              changes to your IVR, making it highly flexible and easier 
              for you to manage the interface 
              without any technical assistance and additional costing."
              }
            }, {
              "@type": "Question",
              "name": "How to get a toll free number for business?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Vibtree is the best toll free number provider in India. With Vibtree's 
              Voice broadcasting software you don't have to worry about 'how to get 
              1800 number in India' because getting a domestic toll free number is super 
              easy through our portal and you can get started immediately after your purchase."
              }
            }, {
              "@type": "Question",
              "name": "How do I buy a virtual landline number?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "With Vibtree as your service provider, you can easily buy virtual 
              SMS phone number, virtual Indian phone number, and virtual landline 
              number or domestic toll-free number directly from our portal."
              }
            }, {
              "@type": "Question",
              "name": "What is your pricing structure?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At Vibtree Technologies all our pricing packages are fully customizable. 
              We only charge for the services you need based on your business requirements. 
              Our pricing plans are highly flexible and can be adjusted according to the 
              package you choose.  You can book a demo session for a customized pricing 
              plan or start your free trial to explore pricing."
              }
            }, {
              "@type": "Question",
              "name": "What is your Up-time?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We have an up-time of 99.9% that allows our clients to always stay in 
              touch with their customers and offer the best services."
              }
            }]
          }`}
                </script>
            </Helmet>
            {/* body schema tag start  */}
            <body itemScope itemType="https://schema.org/WebPage"></body>
            {/* body schema tag end  */}
            {loader === true ? <LoaderComponent /> : null}

            <div className="retricting-banner-height">
                {/* <SimpleSliderVoice/> */}
            </div>

            <VoiceSlider ImageLoadCountFunction={imagesLoadIndicator} />
            {/* diminish section start  */}
            <div className="vib-inner-section vib-container canceling-margin-top allcommunicationtext"
                style={{ display: 'flex', justifyContent: 'space-evenly' }}>

                <h1 className='vib-container-sub-heading vib-res-center voicecommunication'
                    style={{ fontSize: '50px', marginTop: '50px', margin: 'auto' }}>
                    <span className='vib-blue'>Voice</span>&nbsp;- Foundation of All Communication
                </h1>
                <div className="vib-content-section bottom-margin-50" style={{ marginBottom: '0px' }}>
                    <h5 className='vib-bold-para vib-res-left vib-res-margin-top-50-50'
                        style={{
                            marginTop: '10px',
                            fontSize: '24px',
                            lineHeight: '36px',
                            margin: 'auto',
                            fontWeight: '400',
                            maxWidth: '615px'
                        }}>
                        Voice calling in the cloud is important for businesses because
                        it allows them to make and receive phone calls over the internet,
                        rather than relying on traditional phone lines. This can provide
                        a number of benefits, including cost-effectiveness to manage multiple numbers,
                        flexibility to work remotely, scalability advanced features, such as call forwarding,
                        voicemail, and call recording.

                        {/* Vibtree ensures the best voice quality for our cloud
            communication suite. Experience the best with
            wide-ranging & customized cloud-based
            calling solutions, because every call <br />is
            important. */}
                    </h5>
                </div>

                <div className="vib-img-section">
                    <img
                        onLoad={imagesLoadIndicator}
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/voice-foundation-of-all-communication.svg'
                        alt="voice-foundation-of-all-communication"
                        className='vib-img classForLoaded' />
                </div>

            </div>
            {/* diminish section end */}
            {/* how voice start  */}
            {/* <div className="vib-inner-section vib-container">

        <h1 id='voice-broadcasting' className='vib-container-sub-heading vib-res-center' style={{ fontSize: '40px', marginBottom: '50px' }}>
          How Voice-Based Communication Serves <span className='vib-blue'>Your Business ? </span>
        </h1>

        <h5 className='vib-bold-para vib-res-left voice-percent mx-3'
          style={{
            marginTop: '10px',
            fontSize: '26px',
            lineHeight: '36px',
            textAlign: 'center',
            fontWeight: '400'
          }}>
          Voice based solutions improve customer engagement and help
          your business growth by offering a better customer experience.
        </h5>

      </div> */}

            {/* <div className="partner-tick" style={{ marginTop: '50px' }}>
        <div className="partner-tick-inside-voice" style={{ maxWidth: '650px' }}>
          <img onLoad={imagesLoadIndicator} className='classForLoaded' src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' alt="tickmark" />
          <p className='vib-bold-para'
            style={{ fontSize: '18px', marginTop: '20px' }}>
            Build cloud-based Inbound/Outbound call centers.
          </p>
        </div>
        <div className="partner-tick-inside-voice" style={{ maxWidth: '550px' }}>
          <img onLoad={imagesLoadIndicator} className='classForLoaded' src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' alt="tickmark" />
          <p className='vib-bold-para' style={{ fontSize: '18px', marginTop: '20px', fontWeight: '400' }}>Build personalized services with impactful APIs.</p>
        </div>
        <div className="partner-tick-inside-voice" style={{ maxWidth: '650px' }}>
          <img onLoad={imagesLoadIndicator} className='classForLoaded' src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' alt="tickmark" />
          <p className='vib-bold-para' style={{ fontSize: '18px', marginTop: '20px', fontWeight: '400' }}>Monitor your team's productivity and efficiency for quality.</p>
        </div>
        <div className="partner-tick-inside-voice" style={{ maxWidth: '550px' }}>
          <img onLoad={imagesLoadIndicator} className='classForLoaded' src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' alt="tickmark" />
          <p className='vib-bold-para' style={{ fontSize: '18px', marginTop: '20px', fontWeight: '400' }}>Automate verification process for voice OTP or COD</p>
        </div>
        <div className="partner-tick-inside-voice" style={{ maxWidth: '650px' }}>
          <img onLoad={imagesLoadIndicator} className='classForLoaded' src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' alt="tickmark" />
          <p className='vib-bold-para' style={{ fontSize: '18px', marginTop: '20px', fontWeight: '400' }}>Offer registration and verification for customers & orders.</p>
        </div>
        <div className="partner-tick-inside-voice" style={{ maxWidth: '550px' }}>
          <img onLoad={imagesLoadIndicator} className='classForLoaded' src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' alt="tickmark" />
          <p className='vib-bold-para' style={{ fontSize: '18px', marginTop: '20px', fontWeight: '400' }}>Generate more leads without any special equipment.</p>
        </div>
      </div> */}
            {/* how voice end  */}
            {/* whats unique start  */}
            <div className="vib-inner-section vib-container">

                {/* <h1 className='vib-container-sub-heading vib-res-center' style={{ fontSize: '40px', marginBottom: '50px' }}>
          <span className='vib-blue'>What's Unique</span> About Vibtree's Voice Features
        </h1>

        <h5 className='vib-bold-para vib-res-left voice-percent mx-3'
          style={{
            marginTop: '10px',
            fontSize: '26px',
            lineHeight: '36px',
            textAlign: 'center',
            maxWidth: '1081px',
            fontWeight: '400'
          }}>
          Vibtree promises the highest voice quality for all our cloud telephony services to allow
          businesses to switch their operations to cloud and offer 24x7 connectivity to their customers.
          Voice over IP can be supremely empowering for a business as it offers feature packed telephony
          services at a fraction of the cost of standard business telephony.
        </h5> */}

                <h1 className='vib-container-sub-heading vib-res-center vib-res-margin-top-50-50 mx-3'
                    style={{ fontSize: '38px', marginBottom: '50px' }}>
                    Here's how Vibtree ensures the best quality services-
                </h1>

                <div className="sms-cloud-flex" style={{ overflow: 'hidden' }}>

                    <div className="sms-cloud-flex-item-voice voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/controlled-metrics.svg'
                            alt="controlled-metrics"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Shared Inbox</h6>
                        <p style={{ fontSize: '15px' }}>
                            Stay organized and collaborate seamlessly with shared voice inboxes. Mange and share Notes and stay updated
                        </p>
                    </div>

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }} >
                        <Link to='/products/cloud-number/'>


                            <img
                                onLoad={imagesLoadIndicator}
                                className='classForLoaded'
                                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/higher-uptime-guaranteed.svg'
                                alt="higher-uptime-guaranteed"
                                style={{ padding: '45px', height: '154px', width: '154px' }} />
                            <h6 style={{ fontSize: '20px' }}>Phone Numbers</h6>
                            <p style={{ fontSize: '15px' }}>
                                No need to get a new SIM card, carry two phones, or be tied to your desk. Get access to International and Local cloud numbers.
                            </p>
                        </Link>
                    </div>

                    <div id='geo-based-routing' className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <Link to='/products/cloud-phone/'>


                            <img
                                onLoad={imagesLoadIndicator}
                                className='classForLoaded'
                                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/geo-based-routing.svg'
                                alt="geo-based-routing"
                                style={{ padding: '45px', height: '154px', width: '154px' }} />
                            <h6 style={{ fontSize: '20px' }}>Cloud Phone</h6>
                            <p style={{ fontSize: '15px' }}>
                                Get all the features of a phone, without a phone. Make and receive calls, send text messages, all with a small widget.
                            </p>
                        </Link>
                    </div>

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/committed-to-voice-quality.svg'
                            alt="committed-to-voice-quality"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Power Dialer</h6>
                        <p style={{ fontSize: '15px' }}>
                            Automate your outbound calls with power dialer and improve agent productivity by refining the wait time between calls to leads and prospects.
                        </p>
                    </div>

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <Link to='/products/ivr-studio/'>
                            <img
                                onLoad={imagesLoadIndicator}
                                className='classForLoaded'
                                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/budget-friendly-solutions.svg'
                                alt="budget-friendly-solutions"
                                style={{ margin: '40px' }} />
                            <h6 style={{ fontSize: '20px' }}>Interactive Voice Response (IVR)</h6>
                            <p style={{ fontSize: '15px' }}>
                                Configure multi level IVR trees to help your callers reach the right team or user. Welcome your callers with custom messages by setting up a text-to-speech recording or uploading an audio .
                            </p>
                        </Link>
                    </div>

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/24x7-support-services.svg'
                            alt="24x7-support-services"
                            style={{ height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Call Recording</h6>
                        <p style={{ fontSize: '15px' }}>
                            Record 2-way audio with consent.  Record automatically or manually, record team's voice or just customer anything is possible with advance call recording system.
                        </p>
                    </div>

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/budget-friendly-solutions.svg'
                            alt="budget-friendly-solutions"
                            style={{ margin: '40px' }} />
                        <h6 style={{ fontSize: '20px' }}> Call Lifecycle</h6>
                        <p style={{ fontSize: '15px' }}>
                            Streamline your communication with a defined call lifecycle process. From call setup to termination, efficiently manage each step for seamless phone conversations with clients and colleagues.
                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/budget-friendly-solutions.svg'
                            alt="budget-friendly-solutions"
                            style={{ margin: '40px' }} />
                        <h6 style={{ fontSize: '20px' }}>Voicemail</h6>
                        <p style={{ fontSize: '15px' }}>
                            Enhance your professional image with a voicemail system. Allow clients and colleagues to leave messages for you when you are unavailable, and easily retrieve and respond to them at your convenience.
                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/budget-friendly-solutions.svg'
                            alt="budget-friendly-solutions"
                            style={{ margin: '40px' }} />
                        <h6 style={{ fontSize: '20px' }}> Call tags and Disposition  </h6>
                        <p style={{ fontSize: '15px' }}>
                            Maximize productivity and organization with call tags and dispositions. Quickly categorize and prioritize calls for follow-up, track and analyze call data for insights, and improve overall call management with these powerful tools.
                        </p>
                    </div>


                </div>

            </div>

            <Button variant='outlined' className='Allfeatures'
            // style={{ borderRadius: "20px", paddingLeft: "35px", paddingRight: "35px", position: "relative", top: "40px", left: "43%" }}
            >All Features</Button>

            {/* whats unique end  */}


            {/* call us now section start  */}
            <BookACall />
            {/* call us now section end */}




            {/* accordin start  */}
            <div className="vib-container">
                <h1 className='vib-container-heading vib-res-center' style={{ width: '100%', fontSize: '33px' }}>
                    Frequently Asked Questions: <span className="vib-blue">Voice-Based Cloud Solutions</span>
                </h1>
            </div>
            <div className="vib-container-narrow sms-accordin-margin-top" style={{ marginTop: '50px', marginBottom: '130px' }}>
                <Accordion defaultActiveKey="0" style={{ width: '100%', padding: '20px' }}>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="0"
                            onClick={handleClick1}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white',
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> What are the uses of cloud based IVR solutions?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accFirst === 'yes' ? 'rotate(90deg)' : accFirst === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> Our Cloud-based calling or voice solutions help you manage IVR hosting
                                easily. Among the myriad uses of these solutions, you can build Inbound/Outbound
                                call-centers based on cloud telephony, monitor your team's productivity and
                                efficiency for quality, offer order and customer's registration and verification,
                                build personalized services with impactful APIs, automate verification processes
                                for voice OTP or COD and ultimately generate more leads without any special
                                equipment or setup cost.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="1"
                            onClick={handleClick2}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> What is Drag-and-Drop IVR?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accSecond === 'yes' ? 'rotate(90deg)' : accSecond === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> With our configurable IVR, using IVR hosting in India becomes easy. You can quickly make
                                changes to your IVR, making it highly flexible and easier for you to manage the interface
                                without any technical assistance and additional costing.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEvent: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="2"
                            onClick={handleClick3}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> How to get a toll free number for business?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accThird === 'yes' ? 'rotate(90deg)' : accThird === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> Vibtree is the best toll free number provider in India. With Vibtree's
                                Voice broadcasting software you don't have to worry about 'how to get
                                1800 number in India' because getting a domestic toll free number is super
                                easy through our portal and you can get started immediately after your purchase.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="3"
                            onClick={handleClick4}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> How do I buy a virtual landline number?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accFourth === 'yes' ? 'rotate(90deg)' : accFourth === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> With Vibtree as your service provider, you can easily buy virtual
                                SMS phone number, virtual Indian phone number, and virtual landline
                                number or domestic toll-free number directly from our portal.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="4"
                            onClick={handleClick5}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> What is your pricing structure?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accFive === 'yes' ? 'rotate(90deg)' : accFive === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> At Vibtree Technologies all our pricing packages are fully customizable.
                                We only charge for the services you need based on your business requirements.
                                Our pricing plans are highly flexible and can be adjusted according to the
                                package you choose.  You can book a demo session for a customized pricing
                                plan or start your free trial to explore pricing.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="5"
                            onClick={handleClick6}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> What is your Up-time?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: accSix === 'yes' ? 'rotate(90deg)' : accSix === 'no' ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="5">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> We have an up-time of 99.9% that allows our clients to always stay in
                                touch with their customers and offer the best services.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div>
            {/* accordin start  */}
        </div>
    )
}
