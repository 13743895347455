import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';

export default function JoinNowPopUp(props) { 
    const [ref_page_url, setref_page_url] = useState('');
  const [utm_source_s, setutm_source] = useState('');
  const [utm_medium_s, setutm_medium] = useState('');
  const [utm_campaign_s, setutm_campaign] = useState('');
  const [utm_term_s, setutm_term] = useState('');
  const [utm_content_s, setutm_content] = useState('');
  const [publisherid_s, setpublisherid] = useState('');


  let refferrerUrl=document.referrer
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    // var handleButton=()=>{
      setref_page_url(refferrerUrl)
      setutm_source(params.utm_source)
      setutm_medium(params.utm_medium)
      setutm_campaign(params.utm_campaign)
      setutm_term(params.utm_term)
      setutm_content(params.utm_content)
      setpublisherid(params.publisherid)
    // }
    // eslint-disable-next-line 
  }, []);
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
      <div id="mauticform_wrapper_career" className="mauticform_wrapper">
      {/* eslint-disable-next-line */}
      <form autoComplete="false" 
            role="form" 
            method="post" 
            action="https://launcher.vibtree.in/form/submit?formId=13" 
            id="mauticform_career" 
            data-mautic-form="career" 
            encType="multipart/form-data" 
            career-form>
        <div className="mauticform-error" id="mauticform_career_error"></div>
        <div className="mauticform-message" id="mauticform_career_message"></div>
        <div className="mauticform-innerform">

            
          <div className="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

            <div id="mauticform_career_first_name" data-validate="first_name" data-validation-type="text" className="mauticform-row mauticform-text mauticform-field-1 mauticform-required">
                <label id="mauticform_label_career_first_name" htmlFor="mauticform_input_career_first_name" className="mauticform-label">First Name</label>
                <input id="mauticform_input_career_first_name" name="mauticform[first_name]" className="mauticform-input" type="text"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>

            <div id="mauticform_career_email" data-validate="email" data-validation-type="email" className="mauticform-row mauticform-email mauticform-field-2 mauticform-required">
                <label id="mauticform_label_career_email" htmlFor="mauticform_input_career_email" className="mauticform-label">Email</label>
                <input id="mauticform_input_career_email" name="mauticform[email]" className="mauticform-input" type="email"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>

            <div id="mauticform_career_mobile" className="mauticform-row mauticform-tel mauticform-field-3">
                <label id="mauticform_label_career_mobile" htmlFor="mauticform_input_career_mobile" className="mauticform-label">Mobile</label>
                <span className="mauticform-helpmessage">Please add country code</span>
                <input id="mauticform_input_career_mobile" name="mauticform[mobile]" placeholder="91 98000 00000" className="mauticform-input" type="tel"/>
                <span className="mauticform-errormsg" style={{display:"none"}}></span>
            </div>

            <div id="mauticform_career_what_is_10_plus_8" data-validate="what_is_10_plus_8" data-validation-type="captcha" className="mauticform-row mauticform-text mauticform-field-4 mauticform-required">
                <label id="mauticform_label_career_what_is_10_plus_8" htmlFor="mauticform_input_career_what_is_10_plus_8" className="mauticform-label">What is 10 plus 8?</label>
                <input id="mauticform_input_career_what_is_10_plus_8" name="mauticform[what_is_10_plus_8]" className="mauticform-input" type="text"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>

            <div id="mauticform_career_resume" data-validate="resume" data-validation-type="file" className="mauticform-row mauticform-file mauticform-field-5 mauticform-required">
                <label id="mauticform_label_career_resume" htmlFor="mauticform_input_career_resume" className="mauticform-label">Resume</label>
                <span className="mauticform-helpmessage">Attach your resume max 1mb. doc, docx, pdf</span>
                <input id="mauticform_input_career_resume" name="mauticform[resume]" className="mauticform-input" type="file"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>
                <input id="mauticform_input_career_ref_page_url" name="mauticform[ref_page_url]" value={ref_page_url} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_career_service" name="mauticform[service]" value="about" className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_career_utm_source" name="mauticform[utm_source]" value={utm_source_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_career_utm_medium" name="mauticform[utm_medium]" value={utm_medium_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_career_utm_campaign" name="mauticform[utm_campaign]" value={utm_campaign_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_career_utm_term" name="mauticform[utm_term]" value={utm_term_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_career_utm_content" name="mauticform[utm_content]" value={utm_content_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_career_publisherid" name="mauticform[publisherid]" value={publisherid_s} className="mauticform-hidden" type="hidden"></input>
            
            <div id="mauticform_career_submit" className="mauticform-row mauticform-button-wrapper mauticform-field-6">
                <button type="submit" name="mauticform[submit]" id="mauticform_input_career_submit" className="mauticform-button btn btn-default">Submit</button>
            </div>
            </div>
        </div>

        <input type="hidden" name="mauticform[formId]" id="mauticform_career_id" value="13"/>
        <input type="hidden" name="mauticform[return]" id="mauticform_career_return"/>
        <input type="hidden" name="mauticform[formName]" id="mauticform_career_name" value="career"/>

        </form>
        </div>
      </Modal>
    );
  }