import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import HaveMoreQuestions from './HaveMoreQuestions'
import IndustryFAQ from './IndustryFAQ'
import VibtreeBenefits from './VibtreeBenefits'

//images
import bannerImage from '../../images/industry/hospitality-industry/cloud-telephony-for-hospitality-industry.png'
import GetStarted from 'src/GetStarted'
import LoaderComponent from 'src/LoaderComponent'
import reduceCost from '../../images/industry/reduced-cost-of-communication.png'
import runPromo from '../../images/industry/hospitality-industry/run-promotional-campaigns.png'
import increasedProd from '../../images/industry/food-and-beverage-industry/personalize-customer-experience.png'
import combinedCalls from '../../images/industry/combined-call-reports.png'
import autoFeedback from '../../images/industry/auto-feedback-and-survey.png'
import drawingIn from '../../images/industry/food-and-beverage-industry/drawing-in-more-leads-with-missed-call-service.png'
import understandCustomer from '../../images/industry/food-and-beverage-industry/understand-customer-feedback-and-expectations-with-crm-Integration.png'
import scrollToTop from 'src/helper/scrollToTop'


export default function Hospitality() {
  const [loader, setloader] = useState(true);

  // image loading spinner start 
  const [ImagesLoadCount, setImagesLoadCount] = useState(0);
  const imagesLoadIndicator=()=>{
    const images = document.getElementsByClassName('classForLoaded');

    setImagesLoadCount(ImagesLoadCount+1);

    if (ImagesLoadCount===images.length-1) {
      setloader(false)
    }
  }
  // image loading spinner end
  
  // scroll to top start 
    useEffect(() => {
      scrollToTop(); 
  }, []);
  // scroll to top end

  return (
    <div style={{marginBottom:'150px',marginTop:'100px'}} >
      <Helmet>
          <title>Hospitality Industry - Cloud Telephony Solutions | Vibtree</title>
          <meta name="description" content="Know how cloud telephony can help hospitality businesses build a smoother customer communication process by integrating easy customer support." />
          <link rel="canonical" href="https://www.vibtree.com/industry/hospitality/" />
      
          <script  type="application/ld+json">
          {`{"@context": "https://schema.org",
              "@type": "Organization",
              "name": "Vibtree Technologies",
              "legalName": "Vibtree Technologies",
              "alternateName": "www.vibtree.com",
              "description": "Know how cloud telephony can help hospitality businesses build a smoother customer communication process by integrating easy customer support.",
              "url": "https://www.vibtree.com/industry/hospitality/",
              "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
              "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "18005724590",
                "contactType": "customer service",
                "contactOption": "TollFree",
                "availableLanguage": "en"
              },
              "sameAs": [
                "https://www.facebook.com/vibtree",
                "https://twitter.com/vibtree",
                "https://www.instagram.com/vibtree",
                "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
                "https://www.linkedin.com/company/vibtree",
                "https://www.vibtree.com"
              ]}`}
          </script>
      
          <script type="application/ld+json">
              {`{
                  "@context": "http://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": [{
                      "@type": "ListItem",
                      "position": 1,
                      "item": {
                          "@id": "https://www.vibtree.com/",
                          "name": "vibtree"
                      }
                  },{
                      "@type": "ListItem",
                      "position": 2,
                      "item": {
                          "@id": "https://www.vibtree.com/industry/hospitality/",
                          "name": "hospitality"
                      }
                  }]
              }`}
          </script> 
          
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "Why is cloud telephony needed in the Education industry?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Cloud telephony system in the education 
              industry facilitates automation of different communication and customer experience 
              operations with features like auto-receptionist, call recording, IVR, bulk SMS, voice 
              broadcast and call tracking etc. It helps your institution or organization streamline 
              the communication with students and parents."
                }
              }, {
                "@type": "Question",
                "name": "Which cloud telephony features can be used for the Education Industry?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "In the times of digitization, 
              cloud telephony solutions are helping the education sector to stay on 
              track when it comes to communication with their students and parents. 
              Operating with limited employees is the major challenge in the times of 
              pandemic and post pandemic, so services like Toll free virtual number, IVR, 
              call tracking, bulk SMS and voice broadcasting prove to be beneficial 
              for the education industry."
                }
              }, {
                "@type": "Question",
                "name": "What are your pricing plans?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our pricing plans are flexible so our customers 
              only pay for the services they use depending on their business needs. Visit our 
              pricing page to understand our different pricing plans."
                }
              }, {
                "@type": "Question",
                "name": "Do you offer customized solutions for different businesses?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we deliver customized solutions depending 
              on your business requirements to ensure that you do not pay for more services than you use. 
              All you need to do is to talk to our consultants to ensure that your plan is fully 
              customized to your needs."
                }
              }]
            }`}
          </script>
      </Helmet>

        {/* body schema tag start  */}
          <body itemScope itemType="https://schema.org/WebPage"></body>
        {/* body schema tag start  */}

    
        {loader===true ? <LoaderComponent/> : null} 
    
        {/* banner start  */}
        <div className="banner sms-banner" style={{position:"relative",top:"0px", minHeight:"600px",overflow:'hidden'}}>
          <div className="container-fluid margin-top-res-sms">
            <div className="row vh-100-middle justify-content-center-1000 banner-breaking-padding-1000" style={{flexWrap:'wrap-reverse'}}>
              <div className="col-lg-6 for-padding-remove" >
              <h1 className='vib-container-heading 
                             vib-res-left 
                             vib-blue 
                             voice-allow-overflow 
                             banner-heading-width' 
                  style={{marginTop:'60px',fontSize:'50px',lineHeight:'70px'}}>
              Cloud Telephony For Food <br />& Beverage Industry
              </h1>
                <h5 
                className='vib-bold-para 
                           vib-res-left 
                           voice-allow-overflow 
                           banner-heading-width' 
                style={{marginBottom:'40px',
                        fontSize:'24px',
                        fontWeight:'400',
                        lineHeight:'40px',
                        color:'#7C8087'}}>
                Vibtree Communication Solutions Uniquely Curated For <br />F&B Industry 
                </h5>
                
                <GetStarted/> 
                
              </div>
              <div className="col-lg-6 banner-break-point-breaker-1000" style={{display:'flex',justifyContent:'center'}}>
        
                  <img onLoad={imagesLoadIndicator}
                      src={bannerImage} // use normal <img> attributes as props
                      
                      className="img-fluid 
                                 bannerdivimg 
                                 vib-sms-left-margin-auto
                                 mb-5
                                 classForLoaded" 
                      alt='tailored-and-automated-bulk-sms-services'
                      />
              </div>
            </div>
          </div>
        </div>
        {/* banner end  */}
        
        {/* simplifying Hospitality start  */}
        <h2 className='ind-sim-h2 
                       vib-res-margin-top-200-100'>
          Simplifying Hospitality Industry With Cloud Telephony Solutions
        </h2>

        <article className='ind-sim-article vib-res-margin-top-50-50'>
        A strong communication channel has become critical for the hospitality 
        industry in the times when the customer base is expanding and the 
        customer-business dynamics are evolving post pandemic. 
        Cloud telephony based communication automates communication 
        with customers through services like Reminder SMS, 
        IVR, virtual number and more. 
        </article>

        <div className="ind-wrapper vib-res-margin-top-100-50">
            <figure className="ind-wrapper-item">
              <img src={reduceCost} alt="personalize-customer-experience" />
              <figcaption>Travel & hotel booking reminders</figcaption>
            </figure>

            <figure className="ind-wrapper-item">
              <img src={runPromo} alt="reduced-cost-of-communication" />
              <figcaption>Run promotional campaigns</figcaption>
            </figure>

            <figure className="ind-wrapper-item">
              <img src={increasedProd} alt="promote-offers-and-discounts" />
              <figcaption>Personalize customer experience</figcaption>
            </figure>

            <figure className="ind-wrapper-item">
              <img src={combinedCalls} alt="combined-call-reports" />
              <figcaption>Track marketing<br /> efforts</figcaption>
            </figure>

            <figure className="ind-wrapper-item">
              <img src={autoFeedback} alt="auto-feedback-and-survey" />
              <figcaption>Quick customer surveys</figcaption>
            </figure>
        </div>
        {/* simplifying  Hospitality  end */}

        
        {/* how is vibtree resolving  Hospitality  industry start  */}
        <h2 className='ind-sim-h2 
                       vib-res-margin-top-200-100'>
         How is Vibtree Solving Problems in the Hospitality Industry ?
        </h2> 

        <div className="vib-inner-section 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap-reverse'}}>
            <div className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-left vib-res-margin-top-50-50' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  A Unified Platform To Centralize <br/>
              <span className='blue-under-line' style={{paddingBottom:' 15px'}}>Custom</span>er Communication
              </h1>  
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'36px',
                      color:'#7C8087',
                      fontWeight:'normal',
                      maxWidth:'600px'}}>
                    Automatically respond to all the incoming calls 
                    with Vibtree's auto-response IVR feature. Give your 
                    customers a single number to enquire about the booking, 
                    making reservations or cancellation on a call. A single 
                    platform that helps your customers get quick query 
                    resolution by rerouting calls to the right department 
                    using smartly built call flows on IVR.  
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image" style={{maxWidth:'500px'}}>
                <img 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/industry/hospitality/a-unified-platform-to-centralize-customer-communication.png'
                     alt="a-unified-platform-to-centralize-customer-communication" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>

        <div className="vib-inner-section-reverse 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap'}}>

            <div className="vib-img-section ivr-zig-zag-image"  style={{maxWidth:'500px'}}>
                    <img 
                         src='https://vibtreedan.s3.eu-central-1.amazonaws.com/industry/hospitality/send-travel-and-hotel-booking-reminders-with-SMS-and-IVR-service.png'
                         alt="send-travel-and-hotel-booking-reminders-with-SMS-and-IVR-service" 
                         className='vib-img classForLoaded'
                         onLoad={imagesLoadIndicator}
                         />

            </div>
             
            
            <div id='virtual-receptionist' className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-right  vib-res-margin-top-50-50'
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Send Travel & Hotel Booking<br /> 
                  Reminders With SMS & IV<span className='blue-under-line' style={{paddingBottom:' 15px'}}>R Service</span>
              </h1> 

              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                    Send automatic reminders to customers based on their 
                    travel plans and itinerary for updating them with booking 
                    confirmation, check-in, checkout and other travel related 
                    details through SMS. With Vibtree cloud SMS service and IVR, 
                    you can personalize the reminders to add useful information 
                    like directions, places to visit or highlights about 
                    the hotel. 
              </h5>
            </div>
        </div>

        <div className="vib-inner-section 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap-reverse'}}>
            <div className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-left vib-res-margin-top-50-50' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Run and monitor promotional<br/>
                  campaigns with multiple Virtual<br/>
                  <span className='blue-under-line' style={{paddingBottom:' 15px'}}>Number</span>s
              </h1>    
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'36px',
                      color:'#7C8087',
                      fontWeight:'normal',
                      maxWidth:'600px'}}>
                Promote events and seasonal offers or updates with 
                SMS and Voice broadcast. Assign different virtual 
                numbers to different campaigns and track their 
                performance by using our call tracking feature. 
                Track the call responses and monitor your campaign 
                success. Ensure round the clock availability and 
                maximize your ROI with virtual numbers by rerouting 
                the incoming calls to your number.  
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image" style={{maxWidth:'500px'}}>
                <img 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/industry/hospitality/run-and-monitor-promotional-campaigns-with-multiple-virtual-numbers.png'
                     alt="run-and-monitor-promotional-campaigns-with-multiple-virtual-numbers" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>

        <div className="vib-inner-section-reverse 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap'}}>

            <div className="vib-img-section ivr-zig-zag-image"  style={{maxWidth:'500px'}}>
                    <img 
                         src='https://vibtreedan.s3.eu-central-1.amazonaws.com/industry/hospitality/market-your-brand-and-offers-with-voice-and-SMS-broadcasting.png'
                         alt="market-your-brand-and-offers-with-voice-and-SMS-broadcasting" 
                         className='vib-img classForLoaded'
                         onLoad={imagesLoadIndicator}
                         />

            </div>
            
            <div id='virtual-receptionist' className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-right  vib-res-margin-top-50-50'
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Market your brand and offers with<br />
                  Voice & SMS broa<span className='blue-under-line' style={{paddingBottom:' 15px'}}>dcasting</span>
              </h1>   
               
              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                    Promote the ongoing offers and discounts to a large customer 
                    group with voice and sms broadcasting services. Record your 
                    own voice message or type a text message and choose what fits 
                    your business needs and schedule the broadcast at a time you like. 
                    Add a click-to-call option with the promotion to redirect the 
                    customers on your call centre.  
              </h5>
            </div>
        </div>

        <div className="vib-inner-section 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap-reverse'}}>
            <div className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-left vib-res-margin-top-50-50' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Help customers reach your<br/>
                  <span className='blue-under-line' style={{paddingBottom:' 15px'}}>busines</span>s with a Toll Free Number
              </h1>        
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'36px',
                      color:'#7C8087',
                      fontWeight:'normal',
                      maxWidth:'600px'}}>
                    Let your customers find you on a single number 
                    rather than having to dial multiple mobile numbers. 
                    Have a single toll free number for your business where 
                    customers can call anytime to get the resolution for 
                    their query without paying any charges for the call.   
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image" style={{maxWidth:'500px'}}>
                <img 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/industry/hospitality/help-customers-reach-your-business-with-a-toll-free-number.png'
                     alt="help-customers-reach-your-business-with-a-toll-free-number" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>

        <div className="vib-inner-section-reverse 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap'}}>

            <div className="vib-img-section ivr-zig-zag-image"  style={{maxWidth:'500px'}}>
                    <img 
                         src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/give-voice-to-your-business-to-get-notified-by-your-custormers.svg'
                         alt="give-voice-to-your-business-to-get-notified-by-your-custormers" 
                         className='vib-img classForLoaded'
                         onLoad={imagesLoadIndicator}
                         />

            </div>
            
            <div id='virtual-receptionist' className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-right  vib-res-margin-top-50-50'
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Improve customer support service<br />
                  with smart route<span className='blue-under-line' style={{paddingBottom:' 15px'}}>d IVR</span>
              </h1>     
                
              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                 Streamline your customer query resolution with IVR. 
                 Offer your customers a custom designed smart IVR and 
                 redirect their calls to the appropriate department 
                 automatically. Allow your customers to make bookings 
                 over a call without having your agents or managers 
                 intervene. The auto receptionist feature will help 
                 you answer your customers incoming calls to ensure 
                 complete customer satisfaction.
              </h5>
            </div>
        </div>

        <div className="vib-inner-section 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap-reverse'}}>
            <div className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-left vib-res-margin-top-50-50' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Drawing in more leads with<br/>
                  <span className='blue-under-line' style={{paddingBottom:' 15px'}}>missed c</span>all service
              </h1>          
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'36px',
                      color:'#7C8087',
                      fontWeight:'normal',
                      maxWidth:'600px'}}>
                    Missed call services can be used for multiple processes 
                    in the hospitality industry, mainly lead generation. 
                    Advertise your missed call number on your campaigns, 
                    collect leads through missed calls and follow-up 
                    with them accordingly.   
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image" style={{maxWidth:'500px'}}>
                <img 
                     src={drawingIn}
                     alt="monitor-campaigns-with-analytics-dashboard" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>
      
        <div className="vib-inner-section-reverse 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap'}}>

            <div className="vib-img-section ivr-zig-zag-image"  style={{maxWidth:'500px'}}>
                    <img 
                         src='https://vibtreedan.s3.eu-central-1.amazonaws.com/industry/hospitality/understand-customer-feedback-and-expectations-with-crm-integration.png'
                         alt="understand-customer-feedback-and-expectations-with-crm-integration" 
                         className='vib-img classForLoaded'
                         onLoad={imagesLoadIndicator}
                         />

            </div>
            
            <div id='virtual-receptionist' className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-right  vib-res-margin-top-50-50'
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Understand customer feedback &<br />
                  expectations with CRM Int<span className='blue-under-line' style={{paddingBottom:' 15px'}}>egration</span>
              </h1>      
                
              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                  Integrate IVR with CRM to offer personalized 
                  support to your customers when they contact 
                  your business. Gain in-depth understanding of 
                  your customer-business interaction and seamlessly 
                  follow the lead to customer cycle on your CRM platform. 
                  Vibtree also offers custom CRM built specifically 
                  according to your business needs.
              </h5>
            </div>
        </div>

        <div className="vib-inner-section 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap-reverse'}}>
            <div className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-left vib-res-margin-top-50-50' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Plan and organize quick<br/>
                  <span className='blue-under-line' style={{paddingBottom:' 15px'}}>custome</span>r surveys
              </h1>          
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'36px',
                      color:'#7C8087',
                      fontWeight:'normal',
                      maxWidth:'600px'}}>
                    Broadcast feedbacks and surveys in a single attempt 
                    with voice or text messages. Encourage quick responses 
                    by allowing your customers to press keypad buttons 
                    without spending a lot of time. Schedule automated 
                    post-service calls to get instant feedback for 
                    your services.   
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image" style={{maxWidth:'500px'}}>
                <img 
                     src={understandCustomer}
                     alt="monitor-campaigns-with-analytics-dashboard" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>
        {/* how is vibtree resolving  food and beverage  industry end */}

         <VibtreeBenefits/>
         
         <IndustryFAQ/>
         
         <HaveMoreQuestions/>

    </div>
  )
}
