import React, { useContext, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { useState } from 'react'
import { Helmet } from "react-helmet";
import './sms.css'

import favicon from '../../images/vibtree.ico'
import ScrollHandler from '../../ScrollHandler';
import { vibContext } from 'src/App';
import GetStarted from 'src/GetStarted';
import Group from './../../images/Group.png'
import Smslaptop from './Smslaptop';
import Smsmobile from './Smsmobile';



function getWindowDimensions() {
  const { innerWidth: widthprice, innerHeight: height } = window;
  return {
    widthprice,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default function Sms() {
  const [inputEmail, setinputEmail] = useState('');
  const { height, widthprice } = useWindowDimensions();
  const [accFirst, setaccFirst] = useState('yes');
  const [accSecond, setaccSecond] = useState('no');
  const [accThird, setaccThird] = useState('no');
  const [accFourth, setaccFourth] = useState('no');
  const [accFive, setaccFive] = useState('no');
  const [accSix, setaccSix] = useState('no');
  const [accSeven, setaccSeven] = useState('no');
  const [accEight, setaccEight] = useState('no');

  const [ImagesLoadCount, setImagesLoadCount] = useState(0);
  const [loader, setloader] = useState(true);

  const smsContext = useContext(vibContext);
  const contextId = smsContext.vibState;
  useEffect(() => {
    const element = document.getElementById(contextId);
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 100
      });
    }, 100);
  }, [contextId]);

  // let email = (new URLSearchParams(window.location.search)).get("emailid");

  var handleClick1 = () => {
    setaccSecond('no')
    setaccThird('no')
    setaccFourth('no')
    setaccFive('no')
    setaccSix('no')
    setaccSeven('no')
    setaccEight('no')
    return (
      accFirst === 'yes' ? setaccFirst('no') : accFirst === 'no' ? setaccFirst('yes') : null
    )
  }
  var handleClick2 = () => {
    setaccFirst('no');
    setaccThird('no');
    setaccFourth('no');
    setaccFive('no');
    setaccSix('no');
    setaccSeven('no');
    setaccEight('no');
    return (
      accSecond === 'yes' ? setaccSecond('no') : accSecond === 'no' ? setaccSecond('yes') : null
    )
  }
  var handleClick3 = () => {
    setaccFirst('no');
    setaccSecond('no');
    setaccFourth('no');
    setaccFive('no');
    setaccSix('no');
    setaccSeven('no');
    setaccEight('no');
    return (
      accThird === 'yes' ? setaccThird('no') : accThird === 'no' ? setaccThird('yes') : null
    )
  }
  var handleClick4 = () => {
    setaccFirst('no');
    setaccSecond('no');
    setaccThird('no');
    setaccFive('no');
    setaccSix('no');
    setaccSeven('no');
    setaccEight('no');
    return (
      accFourth === 'yes' ? setaccFourth('no') : accFourth === 'no' ? setaccFourth('yes') : null
    )
  }

  var handleClick5 = () => {
    setaccFirst('no');
    setaccSecond('no');
    setaccThird('no');
    setaccFourth('no');
    setaccSix('no');
    setaccSeven('no');
    setaccEight('no');
    return (
      accFive === 'yes' ? setaccFive('no') : accFive === 'no' ? setaccFive('yes') : null
    )
  }
  var handleClick6 = () => {
    setaccFirst('no')
    setaccSecond('no')
    setaccThird('no')
    setaccFourth('no')
    setaccFive('no')
    setaccSeven('no')
    setaccEight('no')
    return (
      accSix === 'yes' ? setaccSix('no') : accSix === 'no' ? setaccSix('yes') : null
    )
  }
  var handleClick7 = () => {
    setaccFirst('no');
    setaccSecond('no');
    setaccThird('no');
    setaccFourth('no');
    setaccFive('no');
    setaccSix('no');
    setaccEight('no');
    return (
      accSeven === 'yes' ? setaccSeven('no') : accSeven === 'no' ? setaccSeven('yes') : null
    )
  }
  var handleClick8 = () => {
    setaccFirst('no');
    setaccSecond('no');
    setaccThird('no');
    setaccFourth('no');
    setaccFive('no');
    setaccSix('no');
    setaccSeven('no');
    return (
      accEight === 'yes' ? setaccEight('no') : accEight === 'no' ? setaccEight('yes') : null
    )
  }

  // image loading spinner start 
  const imagesLoadIndicator = () => {
    const images = document.getElementsByClassName('classForLoaded');

    setImagesLoadCount(ImagesLoadCount + 1);

    if (ImagesLoadCount === images.length - 1) {
      setloader(false)
    }

  }
  // image loading spinner end

  return (
    <>
      {
        widthprice ? <>
          <Smslaptop />
        </> : <>
          <Smsmobile />
        </>
      }
    </>
  )
}
