import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';

export default function RefundPolicy() {

    const scrollToTop=()=>{
        window.scrollTo({top:0, behavior:'smooth'})
    }

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <div className='px-lg-5 
                    px-md-4 
                    px-sm-3 
                    vib-margin-bottom-200-100 
                    vib-res-margin-top-200-100 
                    m-auto
                    terms-of-use'
         style={{maxWidth:'1100px',paddingRight:'10px',paddingLeft:'10px'}}>

          
          <Helmet>
      
          <meta name="robots" content="noindex , nofollow"/>
          
          </Helmet>

          <h1 className='vib-res-center vib-container-heading' style={{textAlign:'center'}}>Refund And Cancelation Policy</h1>

          <p className='vib-bold-para vib-res-left font-weight-300'>
            We understand you might be skeptical about trying new technology products offered by
            Vibtree (hereafter referred to as 'we', 'us', 'our', 'Vibtree').
          </p>
          
          <p className='vib-bold-para vib-res-left font-weight-300'>
          We are aware that you must be reluctant to trust the company initially and to build your
          trust in our services, we promise to offer a full refund in case you are not satisfied with
          our product and services, anytime within the first 30 (Thirty) days of your service
          activation (only applicable to new purchases, renewal/reactivation is not eligible for a
          refund). This could be for many reasons like, you did not find the product useful for your
          business or you were not satisfied with our services.
          </p>

          <p className='vib-bold-para vib-res-left font-weight-300'>
            In such case, please note:
            This policy is valid for both toll free and Virtual numbers without any customization.
          </p>

          <p className='vib-bold-para vib-res-left font-weight-300'>
          Your rights to the Service granted under this Agreement are personal to you. You may
          not sell, assign, or otherwise transfer or agree to transfer all or any portion of those
          rights without the prior written consent from Vibtree, which could be withheld for any
          reason at our absolute discretion.          
          </p>

          <p className='vib-bold-para vib-res-left font-weight-300'>
          Vibtree provides a 30-day money back guarantee in case we fail to deliver what we had
          committed as part of our Cloud Telephony Solutions.          
          </p>

          <p className='vib-bold-para vib-res-left font-weight-300'>
            Vibtree does not provide refunds in case the customer simply changes their mind or
            wrongly judges our services/solutions. Vibtree advises all customers to test our
            services before ordering any services. Vibtree does not offer any refund of money at any
            stage or at any condition once the transaction has been done or services availed.
          </p>

          <p className='vib-bold-para vib-res-left font-weight-300'>
            Eligible refunds will be processed and credited automatically to the original mode of
            payment i.e. credit card, debit card, net banking or any other mode.  
          </p>

          <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
            Refund / Cancellation Process
          </h2>

          <ul className='pp-information-list vib-res-left' style={{textAlign:'left !important'}}>
            <li>
                <p className='vib-bold-para vib-res-left font-weight-300'>
                    To raise a refund request, please write to us at billing@vibtree.com or call us
                    1800 572 4590 within thirty (30) days of completing your new order.
                </p>
            </li>
            <li>
                <p className='vib-bold-para vib-res-left font-weight-300'>
                    Customers are required to mention the reason for the refund clearly.
                </p>
            </li>
            <li>
                <p className='vib-bold-para vib-res-left font-weight-300'>
                    Customers are required to send a copy of the invoice and Transaction ID (or
                    Screenshot wherever necessary) at billing@vibtree.com
                </p>
            </li>
            <li>
                <p className='vib-bold-para vib-res-left font-weight-300'>
                    Eligible refunds will be provided on a pro-rated basis.
                </p>
            </li>
            <li>
                <p className='vib-bold-para vib-res-left font-weight-300'>
                    No refund will be allowed for texting solutions such as Promotional SMS,
                    Transactional SMS, SMS Short code, Long code (Dedicated/Shared) etc. and will
                    be deducted from your order total, before receiving a pro-rated refund.
                </p>
            </li>
            <li>
                <p className='vib-bold-para vib-res-left font-weight-300'>
                    Refunds will only be provided in case of pre-paid orders.
                </p>
            </li>
            <li>
                <p className='vib-bold-para vib-res-left font-weight-300'>
                    All payments to Vibtree are Non-refundable in case delays/non-delivery of
                    services occurs due to issues at the end of the telecom operator or the customer.
                </p>
            </li>
            <li>
                <p className='vib-bold-para vib-res-left font-weight-300'>
                Eligible refunds will be credited within 30 to 45 business days from the date of
                receiving the request for refund.
              </p>
            </li>
        </ul>
        <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
            Please note:
        </h2>
        <ul>
            <li>
                <p className='vib-bold-para vib-res-left font-weight-300'>
                    For refund, we expect your account doesn’t make excessive usage exceeding the initial
                    usage requested.
                </p>
            </li>
        </ul>

        <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
            This policy is not applicable-                
        </h2>
        <ul className='pp-information-list vib-res-left' style={{textAlign:'left !important'}}>
            <li>
              <p className='vib-bold-para vib-res-left font-weight-300'>
                    On subscriptions bought at discounted rates.
                </p>
            </li>
            <li>
              <p className='vib-bold-para vib-res-left font-weight-300'>
                  If your document-based KYC for Aadhar-based KYC gets rejected for any reason.
                </p>
            </li>
            <li>
              <p className='vib-bold-para vib-res-left font-weight-300'>
                  If you fail to provide complete documentation or information that may be
                  required by us.            
                </p>
            </li>
            <li>
              <p className='vib-bold-para vib-res-left font-weight-300'>
                  If you are being a part of illegal activities under any applicable laws.
                </p>
            </li>
        </ul>          

          </div>
    )
    
}