import React, {useEffect, useState} from 'react';
import axios from 'axios';
// eslint-disable-next-line
import { webApi } from '../../url';
import { useHistory, useLocation, useParams } from "react-router-dom";
import BlogNav from './../BlogNav';
import { CKEditor } from 'ckeditor4-react';
import { deleteFile } from 'react-s3';
import { uploadFile } from 'react-s3';
import { config } from 'src/utils/config';

// const config = {
//     bucketName: 'vibtreedan',
//     dirName: 'blog/vibtree-dynamic-blog-images',
//     region: 'eu-central-1',
//     accessKeyId: 'AKIA2MTCLJCGS64RMHHA',
//     secretAccessKey: '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',
//     s3Url: 's3://vibtreedan/blog/vibtree-dynamic-blog-images/',
// }

 export default function BlogEditComponent(){
    
    const auth = localStorage.getItem('vibauth-b');
    
    const {id} = useParams();

    const fetchInitialStateWithIdRef=async()=>{
          await axios.get(`${webApi}/blogs?id=${id}`)
          .then((res)=>{setBlogTitle(res.data[0].Blog_Title);
                        setFeaturedImage(res.data[0].Featured_image);
                        setBlogContent(res.data[0].Blog_Content);
                        setPublishedBy(res.data[0].Publish_By);
                        setPublishedDate(res.data[0].Publish_Date);
                        setCatagory(res.data[0].Category);
                        setBlogStatus(res.data[0].Status);
                        setBlogThumpContent(res.data[0].Blog_Thump_Content);
                        setseoTitle(res.data[0].meta_title);
                        setseoDescription(res.data[0].meta_description);
                        setspecificUrl(res.data[0].specific_Url)})
          .catch((err)=>{console.log('err',err)})
    }


    let history = useHistory();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    useEffect(() => {
        // eslint-disable-next-line
        if(auth != 'authenticated'){
            history.push("/blog/admin")
        };
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchInitialStateWithIdRef(); 
        // eslint-disable-next-line
    }, [id]);

    var todaysData = new Date();
    var dd = String(todaysData.getDate()).padStart(2, '0');
    var mm = String(todaysData.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = todaysData.getFullYear();
    
    // eslint-disable-next-line
    todaysData =   yyyy + '-' + mm  + '-' +  dd  ;

    const [blogTitle, setBlogTitle] = useState('');
    // eslint-disable-next-line
    const idEdit = query.get("id");
    const [featuredImage, setFeaturedImage] = useState('');
    const [blogContent, setBlogContent] = useState('');
    // const [readMoreLink, setReadMoreLink] = useState(query.get("Read_MoreLinks"));
    const [publishedBy, setPublishedBy] = useState('');
    const [publishedDate, setPublishedDate] = useState('');
    const [catagory, setCatagory] = useState('');
    const [blogStatus, setBlogStatus] = useState('');
    const [ showSuccess, setShowSuccess ] = useState(false);
    const [ showError, setShowError ] = useState(false);
    const [ blogCategoryList, setBlogCategoryList] = useState([]);
    const [blogThumpContent, setBlogThumpContent] = useState('');

    const [seoTitle, setseoTitle] = useState('');
    const [seoDescription, setseoDescription] = useState('');
    const [specificUrl, setspecificUrl] = useState('');
    const [authorList, setauthorList] = useState([]);
    const [disableCreateWileUpload, setdisableCreateWileUpload] = useState(false);

    const clearInputs = () =>{
        setBlogTitle('');
        setFeaturedImage('');
        setBlogContent('');
        // setReadMoreLink('');
        setPublishedBy('');
        setCatagory('');
        setBlogStatus('');
        setBlogThumpContent('');
        setseoTitle('');
        setseoDescription('');
      }


    async function postBlogs(e) {
        e.preventDefault();
        const formdata = {
            Blog_Title : blogTitle,
            Featured_image: featuredImage,
            Category : catagory,
            Publish_By : publishedBy,
            Publish_Date : publishedDate,
            Blog_Content : blogContent,
            Status : blogStatus,
            Blog_Thump_Content: blogThumpContent,
            meta_title : seoTitle,
            meta_description : seoDescription,
            specific_Url: specificUrl
        }
        try {
          // fetch data from a url endpoint
          // eslint-disable-next-line
          const data = await axios.put(`${webApi}/blogs/${id}`, formdata);
          return (
              // eslint-disable-next-line
            clearInputs(),
            setShowSuccess(true)
          );
        } catch(error) {
          setShowError(true)
        }
      }


      async function getBlogsCategory() {
        try {
          // fetch data from a url endpoint
          const response = await axios.get(`${webApi}/Categories`);
          return (
            setBlogCategoryList(response.data)
          );
        } catch(error) {
          console.log("error", error);
          // appropriately handle the error
        }
      }

      const getAuthorList =async()=>{
        await axios.get(`${webApi}/authors`)
        .then((response)=>{setauthorList(response.data.map((item,index)=>{return(item.AuthorName)}))})
        .catch((err)=>{console.log(err)})
      }

      useEffect(() => {
        getBlogsCategory();
        getAuthorList();
      }, [])

      const onEditorChange = (event) =>{
        setBlogContent(event.editor.getData());
      }

      const handleUploadButton=(e)=>{
        setdisableCreateWileUpload(true);
        deleteOldImageInS3(featuredImage);
        uploadFile(e.target.files[0], config)
        .then(data =>{setdisableCreateWileUpload(false);setFeaturedImage(data.location)})
        .catch(err => console.error('err',err))
      }

      const deleteOldImageInS3=(previousImageLink)=>{ 
        const url = previousImageLink
        const slicedUrl = url.split('/');
        const fileName = slicedUrl[slicedUrl.length-1];
        const realFileName = fileName.replace('+',' ');
       deleteFile(realFileName, config)
       .then(data =>console.log('delete file data',data))
       .catch(err => console.error(err))
      }

     
     return(
         <span>
             <BlogNav />
             <div className="container-fluid p-4 card mt-3">
                             
                             <form onSubmit={postBlogs}>
                             <div className="row">
                                 <div className="col-lg-6">
                                 <div className="form-group">
                                <label>Blog Title  </label>
                                <input type="text" className="form-control" value={blogTitle} onChange={(event)=>setBlogTitle(event.target.value)} id="blogTitle" required />
                            </div>
                                 </div>
                                 <div className="col-lg-6">
                                 <div className="form-group">
                                <label>Featured Image Link</label>
                                {/* <input type="text" className="form-control" value={featuredImage} onChange={(event)=>setFeaturedImage(event.target.value)} id="featuredImage" required/> */}
                                <input 
                                        onChange={handleUploadButton}
                                        className="form-control" 
                                        type="file" 
                                        accept="image/*"
                                        id="formFile"
                                        />
                            </div>
                                 </div>
                                 <div className="col-lg-12">
                                 <div className="form-group">
                                <label>Blog Short Description</label>
                                <textarea className="form-control" value={blogThumpContent} onChange={(event)=>setBlogThumpContent(event.target.value)} id="featuredImage" required></textarea>
                            </div>
                                 </div>
                             </div>
                             <div className="row">
                                 <div className="col-lg-12">
                                 <div className="form-group">
                                <label>Blog Content</label>


                                { 
                                blogContent && <CKEditor
                                // value={blogContent}
                                initData={blogContent}
                                // initData={'abc'}
                                onChange={onEditorChange}
                                config={ {
                                        format_tags : 'p;h1;h2;h3;h4;h5;h6;div'
                                       } }
                                />
                                }


                            </div>
                                 </div>
                                 
                             </div>

                             <div className="row">
                                 <div className="col-lg-6">
                                 <div className="form-group">
                                 <label>Published By</label>
                                 {/* <input type="text" className="form-control" value={publishedBy} onChange={(event)=>setPublishedBy(event.target.value)} id="publishedBy" required/> */}
                                 <select className="form-control" value={publishedBy} onChange={(event)=>setPublishedBy(event.target.value)} required>
                                     {authorList.map((item,index)=>{return( <option value={item}>{item}</option>)})}
                                 </select>
                                 </div>
                                 </div>


                                 <div className="col-lg-6">
                                 <div className="form-group">
                                <label>Published Date</label>
                                <input type="date" className="form-control" value={publishedDate} onChange={(event)=>setPublishedDate(event.target.value)} id="publishedDate" required/>
                            </div>
                                 </div>
                            </div>

                            <div className="row">
                                 <div className="col-lg-6">
                                 <div className="form-group">
                                <label>Category</label>
                                <select className="form-control" value={catagory} onChange={(event)=>{setCatagory(event.target.value); console.log(event.target.value)}} id="catagory" required>
                                  
                                   
                                {blogCategoryList.map((item) => {
                                    return(
                                        <option value={item.Category_Name}>{item.Category_Name}</option>
                                    )
                                })}
                            
                                </select>
                            </div>
                                 </div>
                                 <div className="col-lg-6">
                                 <div className="form-group">
                                <label>Status</label>
                                <select className="form-control" value={blogStatus} onChange={(event)=>setBlogStatus(event.target.value)} id="blogStatus">
                                    <option>Draft</option>
                                    <option>Publish</option>
                                </select>
                            </div>
                                 </div>
                            </div>

                            {/* <div className="form-group">
                                <label>Blog Content</label>
                                <input type="text" className="form-control" value={blogContent} onChange={(event)=>setBlogContent(event.target.value)} id="blogContent" required/>
                            </div> */}

                        <div className="row">
                                 <div className="col-lg-6">

                                 <div className={showSuccess === true ? 'alert alert-success alert-dismissible fade show' : 'alert alert-success alert-dismissible fade d-none'} role="alert">
                                    <strong>Success!</strong> Blog Updated
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className={showError === true ? 'alert alert-danger alert-dismissible fade show' : 'alert alert-danger alert-dismissible fade d-none'}  role="alert">
                                    <strong>Failed!</strong> Please try Again
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                    </div>
                                    </div>

                                <div className="row">

                                    <div className="col-lg-12">
                                    <div className="form-group">
                                    <label>Title For Seo</label>
                                    <input type="text" 
                                           className="form-control" 
                                           value={seoTitle} 
                                           onChange={(event)=>setseoTitle(event.target.value)} 
                                           id="publishedBy"/>
                                    </div>
                                    </div>
                                    
                                    <div className="col-lg-12">
                                    <div className="form-group">
                                    <label>Description For Seo</label>
                                    <textarea className="form-control" 
                                              value={seoDescription} 
                                              onChange={(event)=>setseoDescription(event.target.value)}  
                                              id="featuredImage">
                                    </textarea>
                                    </div>
                                    </div>
                                    
                                    <div className="col-lg-12">
                                        <button type="submit" className="btn btn-primary" disabled={disableCreateWileUpload}>Update</button>
                                    </div>
                                    {/* <div className="col-lg-4">
                                    <div className="form-group">
                                    <label>Read More Link</label>
                                    <input type="text" className="form-control" value={readMoreLink} onChange={(event)=>setReadMoreLink(event.target.value)} id="readMoreLink" />
                                    </div>
                                    </div> */}

                                </div>

                         </form>

                     </div>
               
         </span>
     )
 }