import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap'
import { Helmet } from 'react-helmet';
import StickyHeadTableEnterprise from './EnterpriseTable';
import MobileCards from './MobileCards';
import './pricing.css'
import PricingSlider from './PricingSlider';
import StickyHeadTableProfesional from './ProfessionalTable';
import StickyHeadTableStandard from './StandardTable';
import StickyHeadTableStarter from './StarterTable';
import StickyHeadTable from './Table';

const Questionpricing = () => {
    const [SwitchState, setSwitchState] = useState(false);
    const [inrState, setInrState] = useState(false);
    const [tabState, settabState] = useState(0);

    // scroll to top start 
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        scrollToTop();
    }, []);
    // scroll to top end

    const handleChange = (e) => {
        setSwitchState(e.target.checked)
    }

    const handleCurrencyChange = (e) => {
        console.log("setInrState", e.target.checked)
        setInrState(e.target.checked)
    }

    const monthly = {
        oneVirtualNumber: {
            pricing: inrState ? '3,358' : '45$',
            number: '1 Virtual or Toll-free Number',
            minutes: '1000 Minutes Included',
            sms: '1000 SMS Included',
            call_tracking: 'Call Tracking',
            call_forwarding: 'Call Forwarding',
            IVR_studio: 'IVR Studio',
            contacts: 'Contacts',
            cloud_phone: 'Cloud Phone',
            call_recordings: 'Call Recordings for 30 Days',
            additional_local_numbers: `${inrState ? '374' : '5$'} per num.`,
            additional_tollfree_numbers: `${inrState ? '747' : '10$'} per num.`,
            additional_minutes: `${inrState ? '4' : '0.05$'} per min.`,
            additional_SMS: `${inrState ? '2' : '0.015$'} per SMS.`
        },
        twoVirtualNumber: {
            pricing: inrState ? '7,089' : '95$',
            number: '2 Virtual or Toll-free Number',
            minutes: '2500 Minutes Included',
            sms: '2000 SMS Included',
            call_tracking: 'Call Tracking',
            call_forwarding: 'Call Forwarding',
            IVR_studio: 'IVR Studio',
            contacts: 'Contacts',
            cloud_phone: 'Cloud Phone',
            call_recordings: 'Call Recordings for 60 Days',
            additional_local_numbers: `${inrState ? '224' : '3$'} per num.`,
            additional_tollfree_numbers: `${inrState ? '597' : '8$'} per num.`,
            additional_minutes: `${inrState ? '4' : '0.045$'} per min.`,
            additional_SMS: `${inrState ? '1' : '0.01$'} per SMS.`
        },
        fiveVirtualNumber: {
            pricing: inrState ? '10,820' : '145$',
            number: '5 Virtual or Toll-free Number',
            minutes: '5000 Minutes Included',
            sms: '4000 SMS Included',
            call_tracking: 'Call Tracking',
            call_forwarding: 'Call Forwarding',
            IVR_studio: 'IVR Studio',
            contacts: 'Contacts',
            cloud_phone: 'Cloud Phone',
            call_recordings: 'Call Recordings for 90 Days',
            additional_local_numbers: `${inrState ? '150' : '2$'} per num.`,
            additional_tollfree_numbers: `${inrState ? '374' : '5$'} per num.`,
            additional_minutes: `${inrState ? '3' : '0.035$'} per min.`,
            additional_SMS: `${inrState ? '0.67' : '0.009$'} per SMS.`
        }
    }

    const yearly = {
        oneVirtualNumber: {
            pricing: inrState ? '3,034' : '40.5$',
            number: '1 Virtual or Toll-free Number',
            minutes: '1000 Minutes Included',
            sms: '1000 SMS Included',
            call_tracking: 'Call Tracking',
            call_forwarding: 'Call Forwarding',
            IVR_studio: 'IVR Studio',
            contacts: 'Contacts',
            cloud_phone: 'Cloud Phone',
            call_recordings: 'Call Recordings for 30 Days',
            additional_local_numbers: `${inrState ? '336.6' : '5$'} per num.`,
            additional_tollfree_numbers: `${inrState ? '672.3' : '10$'} per num.`,
            additional_minutes: `${inrState ? '3.6' : '0.05$'} per min.`,
            additional_SMS: `${inrState ? '1.8' : '0.015$'} per SMS.`
        },
        twoVirtualNumber: {
            pricing: inrState ? '6,404' : '85.5$',
            number: '2 Virtual or Toll-free Number',
            minutes: '2500 Minutes Included',
            sms: '2000 SMS Included',
            call_tracking: 'Call Tracking',
            call_forwarding: 'Call Forwarding',
            IVR_studio: 'IVR Studio',
            contacts: 'Contacts',
            cloud_phone: 'Cloud Phone',
            call_recordings: 'Call Recordings for 60 Days',
            additional_local_numbers: `${inrState ? '201.6' : '3$'} per num.`,
            additional_tollfree_numbers: `${inrState ? '537.3' : '8$'} per num.`,
            additional_minutes: `${inrState ? '3.6' : '0.045$'} per min.`,
            additional_SMS: `${inrState ? '0.9' : '0.01$'} per SMS.`
        },
        fiveVirtualNumber: {
            pricing: inrState ? '9,774' : '130.5$',
            number: '5 Virtual or Toll-free Number',
            minutes: '5000 Minutes Included',
            sms: '4000 SMS Included',
            call_tracking: 'Call Tracking',
            call_forwarding: 'Call Forwarding',
            IVR_studio: 'IVR Studio',
            contacts: 'Contacts',
            cloud_phone: 'Cloud Phone',
            call_recordings: 'Call Recordings for 90 Days',
            additional_local_numbers: `${inrState ? '135' : '2$'} per num.`,
            additional_tollfree_numbers: `${inrState ? '336.6' : '5$'} per num.`,
            additional_minutes: `${inrState ? '2.7' : '0.035$'} per min.`,
            additional_SMS: `${inrState ? '0.63' : '0.009$'} per SMS.`
        }
    }

    // faq section start 
    const [AccordingState, setAccordingState] = useState(1);
    var handleClick1 = () => {
        AccordingState === 1 ? setAccordingState(0) : setAccordingState(1)
    }
    var handleClick2 = () => {
        AccordingState === 2 ? setAccordingState(0) : setAccordingState(2)
    }
    var handleClick3 = () => {
        AccordingState === 3 ? setAccordingState(0) : setAccordingState(3)
    }
    var handleClick4 = () => {
        AccordingState === 4 ? setAccordingState(0) : setAccordingState(4)
    }
    var handleClick5 = () => {
        AccordingState === 5 ? setAccordingState(0) : setAccordingState(5)
    }
    var handleClick6 = () => {
        AccordingState === 6 ? setAccordingState(0) : setAccordingState(6)
    }
    var handleClick7 = () => {
        AccordingState === 7 ? setAccordingState(0) : setAccordingState(7)
    }
    var handleClick8 = () => {
        AccordingState === 8 ? setAccordingState(0) : setAccordingState(8)
    }
    // faq section end 

    const handleTabClick = (e) => {
        console.log(e.target.name);
        const { name } = e.target;
        name === 'all-in-one' ? settabState(0) :
            name === 'call-tracking' ? settabState(1) :
                name === 'contact-center' ? settabState(2) :
                    name === 'call-forwarding' ? settabState(3) : settabState(0);
    }

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    return (
        <div>
            <h4
                className='p-faq-h4 vib-res-margin-top-100-50'
            >Frequently Asked Questions</h4>
            <div className="vib-container-narrow sms-accordin-margin-top" style={{ marginTop: '50px', marginBottom: '130px' }}>
                <Accordion defaultActiveKey="0" style={{ width: '100%', padding: '20px' }}>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="0"
                            onClick={handleClick1}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white',
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> How does a free trial work?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: AccordingState === 1 ? 'rotate(90deg)' : AccordingState !== 1 ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> Our free trial provides you with
                                a fully functional plan for 7 days. You'll find that there may be some temporary
                                limitations on the software regarding the usage. You get the full experience of
                                all the features of our cloud telephony software based on the features you wish
                                to use. Your free trial experience will help you find exactly what you get when
                                you start using our services for your business.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="1"
                            onClick={handleClick2}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> What happens after my free trial ends?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: AccordingState === 2 ? 'rotate(90deg)' : AccordingState !== 2 ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> After your 7-day free trial,
                                you will be automatically downgraded to our basic free plan, if you do not
                                upgrade to a paid plan of your choice. If you choose to keep your paid plan,
                                you will need to make the payment and complete your account registration process.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEvent: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="2"
                            onClick={handleClick3}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> Can I upgrade or downgrade plans anytime?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: AccordingState === 3 ? 'rotate(90deg)' : AccordingState !== 3 ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> Yes. Vibtree is a pay-as-you-go and
                                cancel anytime service. You can upgrade, downgrade or cancel at anytime.
                                Go to 'pricing' section on your profile to change or cancel your plan anytime.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="3"
                            onClick={handleClick4}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> Do I have to sign a long-term contract?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: AccordingState === 4 ? 'rotate(90deg)' : AccordingState !== 4 ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> Absolutely not! You are not
                                obligated to sign a long-term contract to ensure your commitment.
                                We encourage you to pay for what you use, when you use it. Our services
                                can be easily scaled up or down based on the changing needs of your business.
                                You can cancel anytime you wish to, without owing us a single penny.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="4"
                            onClick={handleClick5}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> What types of payment do you accept?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: AccordingState === 5 ? 'rotate(90deg)' : AccordingState !== 5 ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> We accept all major credit and
                                debit cards including Visa, Master card, American Express and others.
                                If you wish to make payment using any other mode, please write to
                                us on <a href="mailto:info@vibtree.com" className="vib-h-b-u" style={{ color: 'inherit' }}>info@vibtree.com.</a>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="5"
                            onClick={handleClick6}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> Do I need to enter my credit card information to start the free trial?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: AccordingState === 6 ? 'rotate(90deg)' : AccordingState !== 6 ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="5">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> No. You can sign up and use Vibtree cloud telephony services for 7 days without entering your credit card details. At the end of your trial, or when you decide to get on board with us, you will need to pick a payment option.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="6"
                            onClick={handleClick7}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> Are there any set up fees?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: AccordingState === 7 ? 'rotate(90deg)' : AccordingState !== 7 ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="6">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> There are no hidden charges or set up fees with any of our plans.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="7"
                            onClick={handleClick8}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '25px',
                                fontWeight: '600',
                                boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                                overFlow: 'visible',
                                cursor: 'pointer',
                                color: 'black',
                                backgroundColor: 'white'
                            }}>
                            <div className="accordin-top-arrow-container">
                                <span><span style={{ fontWeight: '700' }}>Q.</span> Will my data be private and safe?</span>
                                <div className="accordin-right-icon"
                                    style={{ transform: AccordingState === 8 ? 'rotate(90deg)' : AccordingState !== 8 ? 'rotate(0deg)' : null }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="7">
                            <Card.Body className='vib-bold-para vib-res-left '
                                style={{
                                    marginTop: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    lineHeight: '35px',
                                    backgroundColor: 'none',
                                    border: 'none !important'
                                }}>
                                <span style={{ fontWeight: 'bold' }}>A.</span> We take data security very seriously
                                at Vibtree. Our servers are hosted in a top-notch data center that
                                is protected by 24-hour surveillance to make sure that our
                                application is always updated with the
                                most recent security patches.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div>
        </div>
    )
}

export default Questionpricing