import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import LoaderComponent from 'src/LoaderComponent';
import './../voice/voice.css'
import businesshour from './../../images/industry/businesshour.png'
import contacttags from './../../images/industry/contacttags.svg'
import contactnotes from './../../images/industry/contactnotes.svg'
import sharedcontact from './../../images/industry/sharedcontacticon.webp'
// import callrouting from './../../images/industry/callrouting.png'
import confrence from './../../images/styles/conference-call-svgrepo-com.svg'
import internationalnumber from './../../images/styles/international-calls-svgrepo-com.svg'
import tollfreenumber from './../../images/styles/toll-free-icon.svg'
import workinghours from './../../images/styles/working-hours-icon.svg'
import contactstatus from './../../images/styles/contactstatus.svg'
import contactimport from './../../images/styles/contactimport.svg'
import contactgroup from './../../images/styles/contactgroup.svg'
import contactmanage from './../../images/styles/contactmanage.svg'
import callrouting from './../../images/styles/callrouting.svg'
import blocklist from './../../images/styles/blocklist.svg'
import managecontacts from './../../images/styles/Managenumber.png'
import warmtransfer from './../../images/styles/warmtransfer.svg'
import chatbot from './../../images/styles/chatbot.png'
import universalinbox from './../../images/styles/universalinbox.svg'
import smsicon from './../../images/styles/smsicon.svg'
import privatenotes from './../../images/styles/privatenotes.svg'
import facebook from './../../images/styles/facebook.svg'
import whatsapp from './../../images/styles/whatsapp.svg'
import viber from './../../images/styles/viber.svg'
import telegram from './../../images/styles/telegram.svg'
import contactgroupca from './../../images/styles/contactgroupca.png'
import conferencecall from './../../images/styles/conferencecall.png'
import setting from './../../images/styles/settings.svg'
import automation from './../../images/styles/automation.png'
import assign from './../../images/styles/assign.png'
import voicebot from './../../images/styles/voicebot.svg'
import { Button } from '@mui/material';
import Group from './../../images/Group.png'
import BookACall from '../homePage/BookACall';



function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const Featureslaptop = () => {
    const [loader, setloader] = useState(true);

    const { height, width } = useWindowDimensions();
    const [ImagesLoadCount, setImagesLoadCount] = useState(0);
    // image loading spinner start 
    const imagesLoadIndicator = () => {
        const images = document.getElementsByClassName('classForLoaded');

        setImagesLoadCount(ImagesLoadCount + 1);

        if (ImagesLoadCount === images.length - 1) {
            setloader(false)
        }

    }
    return (
        <div
            style={{
                marginBottom: "200px",
            }}
        >


            <div

                style={{
                    backgroundImage: `url(${Group})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPositionY: "450px",
                    backgroundPositionX: "right",
                    backgroundAttachment: "fixed",
                    backgroundSize: "300px",
                    //  marginTop: "100px",
                    marginTop: "100px",
                    marginBottom: "100px", marginLeft: "5%"
                }}
            >
                <h1
                    className='headpricing pricingfeature'
                // className='pricing-header'
                // style={{
                //     marginTop: "300px",
                //     //  marginLeft: "10%", marginRight: "10%" 
                //     textAlign: "center"
                // }}
                >
                    {/* Features you need to stay productive while delivering great support and achieve more sales. */}
                    Features that help you scale
                </h1>
                <p
                    style={{ marginTop: '10px', textAlign: "center", marginLeft: "20%", marginRight: "20%" }}
                    className='textpricing'
                >Take a look at all of our features below which is essential for you to stay productive while delivering great support and achieve more sales.</p>
                {width > 500 ? <>
                    <div style={{ textAlign: "center" }} className='buttonfeatures'>
                        <a href="https://app.vibtree.com/auth/register" target='_blank'>


                            <Button variant='contained' style={{ textTransform: "none" }}>Try Now</Button> </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        or &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="https://www.facebook.com/groups/vibtree/" target='_blank'><Button variant='outlined' style={{ textTransform: "none" }}>Join Our Community</Button></a>
                    </div>
                </> : <>
                    <div style={{ textAlign: "center" }} className='buttonfeatures'>
                        <a href="https://app.vibtree.com/auth/register" target='_blank'>


                            <Button variant='contained' style={{ textTransform: "none", marginBottom: "20px" }}>Try Now</Button> </a>
                        <br />
                        <a href="https://www.facebook.com/groups/vibtree/" target='_blank'><Button variant='outlined' style={{ textTransform: "none" }}>Join Our Community</Button></a>
                    </div>
                </>}
                {/* <p>Features you need to stay productive while delivering great support and achieve more sales.</p> */}
                {loader === true ? <LoaderComponent /> : null}

                <div className="sms-cloud-flex1 boxfeature"

                // style={{ overflow: 'hidden', marginTop: "400px" }}
                >

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={universalinbox}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Universal Inbox
                            {/* (Coming Soon) */}
                        </h6>
                        <p style={{ fontSize: '15px' }}>
                            Answer all customer queries across multiple channels from one single place in less time. Spend less time on inbox switching and more time on replying to customer conversations.
                        </p>
                    </div>

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={smsicon}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>SMS Shared Inbox</h6>
                        <p style={{ fontSize: '15px' }}>
                            Send SMS, MMS, videos, files, etc. Automatically respond to texts, missed calls, or voicemails with a pre-defined message.

                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={whatsapp}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Whatsapp Shared Inbox</h6>
                        <p style={{ fontSize: '15px' }}>
                            Create a business account for WhatsApp, connect it with Vibtree and start engaging your customers.
                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={facebook}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Facebook and Insta DM</h6>
                        <p style={{ fontSize: '15px' }}>
                            Connect your Facebook page & Instagram DM with Vibtree.
                            Receive and reply to page DMs. Manage your inbox with agents without providing them FB page access.
                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={viber}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Viber Shared Inbox</h6>
                        <p style={{ fontSize: '15px' }}>
                            Connect your Viber bot with Vibtree. Receive and reply to viber conversations.
                        </p>
                    </div>
                    {/* <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={telegram}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Telegram Shared Inbox</h6>
                        <p style={{ fontSize: '15px' }}>
                            Connect your Telegram bot with Vibtree. Receive and reply to telegram conversations.
                        </p>
                    </div> */}


                    <div className="sms-cloud-flex-item-voice voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={tollfreenumber}
                            alt="controlled-metrics"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Toll-Free Numbers</h6>
                        <p style={{ fontSize: '15px' }}>
                            Instantly create toll-free numbers in your local area code or any country in which your business operates.
                        </p>
                    </div>

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }} >
                        <Link to='/products/cloud-number/'>


                            <img
                                onLoad={imagesLoadIndicator}
                                className='classForLoaded'
                                src={internationalnumber}
                                alt="higher-uptime-guaranteed"
                                style={{ padding: '45px', height: '154px', width: '154px' }} />
                            <h6 style={{ fontSize: '20px' }}>International Numbers</h6>
                            <p style={{ fontSize: '15px' }}>
                                Claim local and international numbers for your business in 100+ countries — even if your team works a continent away.
                            </p>
                        </Link>
                    </div>

                    <div id='geo-based-routing' className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <Link to='/products/cloud-phone/'>


                            <img
                                onLoad={imagesLoadIndicator}
                                className='classForLoaded'
                                src={conferencecall}
                                alt="geo-based-routing"
                                style={{ padding: '45px', height: '154px', width: '154px' }} />
                            <h6 style={{ fontSize: '20px' }}>Call Conference</h6>
                            <p style={{ fontSize: '15px' }}>
                                Host conference calls with up to 100 participants and access powerful tools for enhanced collaboration.
                            </p>
                        </Link>
                    </div>

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={workinghours}
                            alt="committed-to-voice-quality"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Business Hours</h6>
                        <p style={{ fontSize: '15px' }}>
                            Set customized schedules to confirm exactly when each of your numbers are available to receive calls.
                        </p>
                    </div>

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <Link to='/products/ivr-studio/'>
                            <img
                                onLoad={imagesLoadIndicator}
                                className='classForLoaded'
                                src={callrouting}
                                alt="budget-friendly-solutions"
                                style={{ padding: '45px', height: '154px', width: '154px' }} />
                            <h6 style={{ fontSize: '20px' }}>Call Routing</h6>
                            <p style={{ fontSize: '15px' }}>
                                Direct calls to the correct teammates every time by customizing distribution and ring rules.
                            </p>
                        </Link>
                    </div>

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={blocklist}
                            alt="24x7-support-services"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Blocklist Numbers</h6>
                        <p style={{ fontSize: '15px' }}>
                            Eliminate spam callers and bots so you can focus more on the conversations your business cares about.

                        </p>
                    </div>

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={warmtransfer}
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Warm Transfer</h6>
                        <p style={{ fontSize: '15px' }}>
                            Give your teammates the option to quickly speak to one another before transferring a live call.
                        </p>
                    </div>

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={contactmanage}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Manage Contacts</h6>
                        <p style={{ fontSize: '15px' }}>
                            Manage Contacts and keep track of your contacts, easily add or delete contacts and sync them across devices.
                        </p>
                    </div>

                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        {/* <img
                        onLoad={imagesLoadIndicator}
                        className='classForLoaded'
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/budget-friendly-solutions.svg'
                        alt="budget-friendly-solutions"
                        style={{ margin: '40px' }} /> */}
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={sharedcontact}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}> Shared Contacts </h6>
                        <p style={{ fontSize: '15px' }}>
                            Create and share selected contacts with your team so everyone can follow the conversation.
                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={contactgroupca}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}> Contact Groups</h6>
                        <p style={{ fontSize: '15px' }}>
                            Organize and manage contacts easily with the group feature - create, update and delete groups with your contacts in one place!
                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={setting}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}> Custom Fields </h6>
                        <p style={{ fontSize: '15px' }}>
                            Custom fields allow you to add extra information to your records, giving you more control and flexibility over your data.
                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        {/* <img
                        onLoad={imagesLoadIndicator}
                        className='classForLoaded'
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/budget-friendly-solutions.svg'
                        alt="budget-friendly-solutions"
                        style={{ margin: '40px' }} /> */}
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={contactnotes}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Contact Notes</h6>
                        <p style={{ fontSize: '15px' }}>
                            Note down important info about your contacts and share amongst team members.

                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>

                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={contacttags}
                            style={{ padding: '45px', height: '154px', width: '154px' }} />

                        <h6 style={{ fontSize: '20px' }}>Contact Tags </h6>
                        <p style={{ fontSize: '15px' }}>
                            Tag contacts to easily categorize and organize in order to quickly find and connect with the right people.
                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={contactstatus}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Contact Status</h6>
                        <p style={{ fontSize: '15px' }}>
                            Quickly check the status of contacts and easily update it to stay organized.
                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={contactimport}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}> Contacts Import </h6>
                        <p style={{ fontSize: '15px' }}>
                            Import your contacts quickly and easily, with the added benefit of duplicate checking.
                        </p>
                    </div>



                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={automation}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Automations</h6>
                        <p style={{ fontSize: '15px' }}>
                            Drag and drop workflow feature allows users to easily create automated processes by dragging and dropping premade steps into a visual interface.
                        </p>
                    </div>


                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={chatbot}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Chatbots (Coming Soon)</h6>
                        <p style={{ fontSize: '15px' }}>
                            Chatbots can quickly and easily provide sales pitches to potential customers, helping you reach more people and boost your sales.
                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={voicebot}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Voicebots (Coming Soon)</h6>
                        <p style={{ fontSize: '15px' }}>
                            Incorporate voicebots into marketing to enable customers to quickly and easily interact with your brand, receive personalized promotional content, and make purchases with voice-activated technology.
                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={privatenotes}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Private Notes and Mentions (Coming Soon)</h6>
                        <p style={{ fontSize: '15px' }}>
                            Collaborate on replying to customer queries with your team members by mentioning them in conversation notes. Mentions will notify them that they have been mentioned by you.
                        </p>
                    </div>
                    <div className="sms-cloud-flex-item-voice  voice-sms-card" style={{ boxShadow: 'none' }}>
                        <img
                            onLoad={imagesLoadIndicator}
                            className='classForLoaded'
                            src={assign}
                            alt="budget-friendly-solutions"
                            style={{ padding: '45px', height: '154px', width: '154px' }} />
                        <h6 style={{ fontSize: '20px' }}>Assign (Coming Soon)</h6>
                        <p style={{ fontSize: '15px' }}>
                            Manually assign conversations to team members who are responsible for handling that query. Delegate with ease and see which team member is working on which query.
                        </p>
                    </div>



                </div>


            </div>

            <BookACall />

        </div>
    )
}

export default Featureslaptop