import React from 'react'
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import GetStarted from 'src/GetStarted'
import bannerImage from '../../images/industry/cloud-telephony-for-education-industry.png'
import LoaderComponent from 'src/LoaderComponent';
import './industry.css';
import increasedProd from '../../images/industry/increased-productivity-with-ivr.png'
import reduceCost from '../../images/industry/reduced-cost-of-communication.png'
import remindersAndNotifications from '../../images/industry/reminders-and-notifications.png'
import combinedCalls from '../../images/industry/combined-call-reports.png'
import autoFeedback from '../../images/industry/auto-feedback-and-survey.png'
import admissionPromotions from '../../images/industry/admission-promotions-with-voice-and-sms-broadcast.png'
import answerParents from '../../images/industry/answer-parents-or-students-calls-through-ivr.png'
import sendNotification from '../../images/industry/send-notification-and-remainders-with-bulk-and-voice-broadcasting.png'
import runCampaigns from '../../images/industry/run-campaigns-with-virtual-and-toll-free-numbers.png'
import monitorCampaigns from '../../images/industry/monitor-campaigns-with-analytics-dashboard.png'
import callTrackingToTrack from '../../images/industry/call-tracking-to-track-incoming-calls-from-campaigns.png'
import VibtreeBenefits from './VibtreeBenefits';
import IndustryFAQ from './IndustryFAQ';
import HaveMoreQuestions from './HaveMoreQuestions';
import scrollToTop from 'src/helper/scrollToTop';

export default function Industry() {
  const [ImagesLoadCount, setImagesLoadCount] = useState(0);
  const [loader, setloader] = useState(true);

    // image loading spinner start 
    const imagesLoadIndicator=()=>{
      const images = document.getElementsByClassName('classForLoaded');
  
      setImagesLoadCount(ImagesLoadCount+1);
  
      if (ImagesLoadCount===images.length-1) {
        setloader(false)
      }
    }
    // image loading spinner end
  
  // scroll to top start 
    useEffect(() => {
        scrollToTop();
    }, []);
  // scroll to top end

  return (
    <div style={{marginBottom:'150px',marginTop:'100px'}} >

        <Helmet>
        <title>Cloud Telephony Solutions | Vibtree</title>
        {/* <link rel="icon" href={favicon} /> */}
        <meta name="description" content="Learn how cloud telephony helps Educational Institutions to streamline their operations through the remote-ready call centres for admission/sales teams" />
        <link rel="canonical" href="https://www.vibtree.com/industry/education/" />

        <script  type="application/ld+json">
        {`{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "Vibtree Technologies",
            "legalName": "Vibtree Technologies",
            "alternateName": "www.vibtree.com",
            "description": "Learn how cloud telephony helps Educational Institutions to streamline their operations through the remote-ready call centres for admission/sales teams",
            "url": "https://www.vibtree.com/industry/education/",
            "logo": 'https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg',
            "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "18005724590",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/vibtree",
              "https://twitter.com/vibtree",
              "https://www.instagram.com/vibtree",
              "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
              "https://www.linkedin.com/company/vibtree",
              "https://www.vibtree.com"
            ]}`}
        </script>

        <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/industry/education/",
                        "name": "education"
                    }
                }]
            }`}
        </script> 
        
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Why is cloud telephony needed in the Education industry?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Cloud telephony system in the education 
            industry facilitates automation of different communication and customer experience 
            operations with features like auto-receptionist, call recording, IVR, bulk SMS, voice 
            broadcast and call tracking etc. It helps your institution or organization streamline 
            the communication with students and parents."
              }
            }, {
              "@type": "Question",
              "name": "Which cloud telephony features can be used for the Education Industry?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "In the times of digitization, 
            cloud telephony solutions are helping the education sector to stay on 
            track when it comes to communication with their students and parents. 
            Operating with limited employees is the major challenge in the times of 
            pandemic and post pandemic, so services like Toll free virtual number, IVR, 
            call tracking, bulk SMS and voice broadcasting prove to be beneficial 
            for the education industry."
              }
            }, {
              "@type": "Question",
              "name": "What are your pricing plans?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our pricing plans are flexible so our customers 
            only pay for the services they use depending on their business needs. Visit our 
            pricing page to understand our different pricing plans."
              }
            }, {
              "@type": "Question",
              "name": "Do you offer customized solutions for different businesses?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, we deliver customized solutions depending 
            on your business requirements to ensure that you do not pay for more services than you use. 
            All you need to do is to talk to our consultants to ensure that your plan is fully 
            customized to your needs."
              }
            }]
          }`}
        </script>

        </Helmet>

        {/* body schema tag start  */}
         <body itemScope itemType="https://schema.org/WebPage"></body>
        {/* body schema tag start  */}

        {loader===true ? <LoaderComponent/> : null}

        {/* banner start  */}
        <div className="banner sms-banner" style={{position:"relative",top:"0px", minHeight:"600px",overflow:'hidden'}}>
          <div className="container-fluid margin-top-res-sms">
            <div className="row vh-100-middle justify-content-center-1000 banner-breaking-padding-1000" style={{flexWrap:'wrap-reverse'}}>
              <div className="col-lg-6 for-padding-remove" >
              <h1 className='vib-container-heading vib-res-left vib-blue' style={{marginTop:'60px',fontSize:'50px',lineHeight:'70px'}}>
              Cloud Telephony For Education Industry
              </h1>
                <h5 
                className='vib-bold-para vib-res-left' 
                style={{marginBottom:'40px',
                        fontSize:'24px',
                        fontWeight:'400',
                        lineHeight:'40px',
                        color:'#7C8087'}}>
                Vibtree Communication Solutions Uniquely<br /> Curated For Education Industry 
                </h5>
                
                <GetStarted/>
                
              </div>
              <div className="col-lg-6 banner-break-point-breaker-1000" style={{display:'flex',justifyContent:'center'}}>

                  <img onLoad={imagesLoadIndicator}
                      src={bannerImage}
                      className="img-fluid 
                                 bannerdivimg 
                                 vib-sms-left-margin-auto
                                 mb-5
                                 classForLoaded" 
                      alt='tailored-and-automated-bulk-sms-services'
                      />
              </div>
            </div>
          </div>
        </div>
        {/* banner end  */}

        {/* simplifying education start  */}
        <h2 className='ind-sim-h2 
                       vib-res-margin-top-200-100'>
          Simplifying Education Industry With Cloud Telephony Solutions
        </h2>

        <article className='ind-sim-article vib-res-margin-top-50-50'>
            Educational institutes are now on the road to 
            adopting a communication-oriented in order to 
            improve the overall quality of their services. 
            Vibtree's cloud telephony services aim to strengthen 
            your communication operations by offering you an all-in-one 
            cloud telephony platform to stay connected with your 
            students & their parents interruption free.
        </article>

        <div className="ind-wrapper vib-res-margin-top-100-50">
            <figure className="ind-wrapper-item">
              <img src={increasedProd} alt="increased-productivity-with-ivr" />
              <figcaption>Increased productivity with IVR</figcaption>
            </figure>

            <figure className="ind-wrapper-item">
              <img src={reduceCost} alt="increased-productivity-with-ivr" />
              <figcaption>Reduced cost of communication</figcaption>
            </figure>

            <figure className="ind-wrapper-item">
              <img src={remindersAndNotifications} alt="increased-productivity-with-ivr" />
              <figcaption>Reminders & Notifications</figcaption>
            </figure>

            <figure className="ind-wrapper-item">
              <img src={combinedCalls} alt="increased-productivity-with-ivr" />
              <figcaption>Combined call<br /> reports</figcaption>
            </figure>

            <figure className="ind-wrapper-item">
              <img src={autoFeedback} alt="increased-productivity-with-ivr" />
              <figcaption>Auto feedback & survey </figcaption>
            </figure>
        </div>
        {/* simplifying education end */}

        {/* how is vibtree resolving education industry start  */}
        <h2 className='ind-sim-h2 
                       vib-res-margin-top-200-100'>
          How is Vibtree Resolving Education Industry Problems?
        </h2>

        <div className="vib-inner-section 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap-reverse'}}>
            <div className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-left vib-res-margin-top-50-50' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Admission Promotions With Voice <br/>
              <span className='blue-under-line' style={{paddingBottom:' 15px'}}> & SMS B</span>roadcast
              </h1> 
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'36px',
                      color:'#7C8087',
                      fontWeight:'normal',
                      maxWidth:'600px'}}>
                Run your admission campaigns and promote 
                other events with virtual/Toll free numbers 
                and increase your reach to your audience. 
                Use SMS and voice broadcasting to ensure 
                that you   
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image" style={{maxWidth:'500px'}}>
                <img 
                     src={admissionPromotions}
                     alt="admission-promotions-with-voice-and-sms-broadcast" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>

        <div className="vib-inner-section-reverse 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap'}}>

            <div className="vib-img-section ivr-zig-zag-image"  style={{maxWidth:'500px'}}>
                    <img 
                         src={answerParents}
                         alt="choose-calling-number" 
                         className='vib-img classForLoaded'
                         onLoad={imagesLoadIndicator}
                         />

            </div>
            
            <div id='virtual-receptionist' className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-right  vib-res-margin-top-50-50'
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Answer Parents/ Students Calls<br />
                  Thr<span className='blue-under-line' style={{paddingBottom:' 15px'}}>ough IVR</span>
              </h1> 

              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                 Help parents/students get connected with the 
                 right department by using a smarty designed 
                 call flow with IVR. Having different extensions 
                 for separate departments and pre-recorded answers 
                 for some commonly asked questions that help 
                 callers get the desired information without 
                 any human intervention.   
              </h5>
            </div>
        </div>

        <div className="vib-inner-section 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap-reverse'}}>
            <div className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-left vib-res-margin-top-50-50' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Send Notifications & Reminders <br/>
                  With Bulk SMS & Voice<br />
                  <span className='blue-under-line' style={{paddingBottom:' 15px'}}>Broadca</span>sting
              </h1>   
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'36px',
                      color:'#7C8087',
                      fontWeight:'normal',
                      maxWidth:'600px'}}>
                Keeping parents/students updated with important 
                reminders and notifications through call & SMS 
                broadcast. Deliver automated fee reminders, late 
                payment messages or confirmation with our bulk SMS 
                feature. Keep students and parents updated on 
                upcoming events or extra classes with pre-scheduled 
                voice or sms broadcast.    
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image" style={{maxWidth:'500px'}}>
                <img 
                     src={sendNotification}
                     alt="send-notification-and-remainders-with-bulk-and-voice-broadcasting" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>

        <div className="vib-inner-section-reverse 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap'}}>

            <div className="vib-img-section ivr-zig-zag-image"  style={{maxWidth:'500px'}}>
                    <img 
                         src={runCampaigns}
                         alt="run-campaigns-with-virtual-and-toll-free-numbers" 
                         className='vib-img classForLoaded'
                         onLoad={imagesLoadIndicator}
                         />

            </div>
            
            <div id='virtual-receptionist' className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-right  vib-res-margin-top-50-50'
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Run Campaigns With Virtual & Toll<br />
                  Free N<span className='blue-under-line' style={{paddingBottom:' 15px'}}>umbers</span>
              </h1>  

              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                 Be available for your students/parents during an emergency 
                 by releasing a toll free or virtual number and rerouting 
                 the incoming calls to your number. Execute campaigns for 
                 social causes like anti-ragging, women safety cell and many 
                 others using different virtual numbers. Use virtual numbers 
                 for sending bulk SMS to your students about important causes.   
              </h5>
            </div>
        </div>

        <div className="vib-inner-section 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap-reverse'}}>
            <div className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-left vib-res-margin-top-50-50' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Monitor Campaigns With<br/>
                  <span className='blue-under-line' style={{paddingBottom:' 15px'}}>Analytic</span>s Dashboard
              </h1>    
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'36px',
                      color:'#7C8087',
                      fontWeight:'normal',
                      maxWidth:'600px'}}>
                You can easily track and monitor the quality of different 
                processes using our comprehensive dashboard that 
                shows real-time call reports. Get detailed analytics 
                on number of calls, call time, call duration and other 
                information for in-depth analysis. Use our call recording 
                feature to understand the callers expectations and 
                improve the call response.     
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image" style={{maxWidth:'500px'}}>
                <img 
                     src={monitorCampaigns}
                     alt="monitor-campaigns-with-analytics-dashboard" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>

        <div className="vib-inner-section-reverse 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap'}}>

            <div className="vib-img-section ivr-zig-zag-image"  style={{maxWidth:'500px'}}>
                    <img 
                         src={callTrackingToTrack}
                         alt="call-tracking-to-track-incoming-calls-from-campaigns" 
                         className='vib-img classForLoaded'
                         onLoad={imagesLoadIndicator}
                         />

            </div>
            
            <div id='virtual-receptionist' className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-right  vib-res-margin-top-50-50'
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Call tracking to track incoming calls<br />
                  from ca<span className='blue-under-line' style={{paddingBottom:' 15px'}}>mpaigns</span>
              </h1>   

              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                 Use our call tracking feature to monitor your 
                 marketing campaigns for admissions. Find out 
                 which advertisement, online or offline is bringing 
                 in the maximum leads and which ads are going unnoticed 
                 by the target audience and optimize your campaign 
                 efforts & budget to draw maximum benefit.  
              </h5>
            </div>
        </div>
        {/* how is vibtree resolving education industry end */}
        
         <VibtreeBenefits/>
         
         <IndustryFAQ/>
         
         <HaveMoreQuestions/>
      
    </div>
  )
}
