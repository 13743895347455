import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { blogDetailUrl, webApi } from '../../url';
import { useHistory } from "react-router-dom";
import BlogNav from './../BlogNav';
import { CKEditor } from 'ckeditor4-react';
import { uploadFile } from 'react-s3';
import { config } from 'src/utils/config';



 export default function BlogAddComponent(){
    
    const auth = localStorage.getItem('vibauth-b');

    let history = useHistory();

    useEffect(() => {
        // eslint-disable-next-line
        if(auth != 'authenticated'){
            history.push("/blog/admin")
        } 
        // eslint-disable-next-line
    }, [])

    var todaysData = new Date();
    var dd = String(todaysData.getDate()).padStart(2, '0');
    var mm = String(todaysData.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = todaysData.getFullYear();
    
    todaysData =   yyyy + '-' + mm  + '-' +  dd  ;

    const [blogTitle, setBlogTitle] = useState('');
    const [featuredImage, setFeaturedImage] = useState('');
    const [blogContent, setBlogContent] = useState('');
    const [blogThumpContent, setBlogThumpContent] = useState('');
    const [publishedBy, setPublishedBy] = useState('');
    const [publishedDate, setPublishedDate] = useState(todaysData);
    const [catagory, setCatagory] = useState('');
    const [blogStatus, setBlogStatus] = useState('Draft');
    const [ showSuccess, setShowSuccess ] = useState(false);
    const [ showError, setShowError ] = useState(false);
    const [authorList, setauthorList] = useState([]);

    const [ blogCategoryList, setBlogCategoryList] = useState([]);

    const [seoTitle, setseoTitle] = useState('');
    const [seoDescription, setseoDescription] = useState('');
    const [specificUrl, setspecificUrl] = useState('');
    const [disableCreateWileUpload, setdisableCreateWileUpload] = useState(false);

    const assignSpecificUrl=()=>{
        // const hyphenString = blogTitle.replace(/\s+/g, '-').toLowerCase();
        const hyphenString = blogTitle.replace(/^\s+/g,'').replace(/\s+$/g, '').replace(/,/g,'').replace(/\s+/g, '-').toLowerCase();
        setspecificUrl(`${blogDetailUrl}${hyphenString}/`);
    }

    useEffect(() => {
        assignSpecificUrl()
        // eslint-disable-next-line
    }, [blogTitle]);

    const clearInputs = () =>{
        setBlogTitle('');
        setFeaturedImage('');
        setBlogContent('');
        setBlogThumpContent('');
        setPublishedBy('');
        setCatagory('');
        setBlogStatus('');
        setseoTitle('');
        setseoDescription('');
      }


    async function postBlogs(e) {
        e.preventDefault();
        const formdata = {
            Blog_Title : blogTitle,
            Featured_image: featuredImage,
            Blog_Thump_Content: blogThumpContent,
            Category : catagory,
            Publish_By : publishedBy,
            Publish_Date : publishedDate,
            Blog_Content : blogContent,
            Status : blogStatus,
            Blog_Claps : 0,
            meta_title : seoTitle,
            meta_description : seoDescription,
            specific_Url: specificUrl,
            blog_views: "0"
        }
        try {
          // fetch data from a url endpoint
          const data = await axios.post(`${webApi}/blogs`, formdata);
          return (
              // eslint-disable-next-line
            console.log('blog data', data),
            clearInputs(),
            setShowSuccess(true)
          );
        } catch(error) {
          setShowError(true)
          // appropriately handle the error
        }
      }

      async function getBlogsCategory() {
        try {
          // fetch data from a url endpoint
          const response = await axios.get(`${webApi}/Categories`);
          return (
            setBlogCategoryList(response.data)
          );
        } catch(error) {
          console.log("error", error);
        }
      }

      const getAuthorList =async()=>{
          await axios.get(`${webApi}/authors`)
          .then((response)=>{setauthorList(response.data.map((item,index)=>{return(item.AuthorName)}))})
          .catch((err)=>{console.log(err)})
      }

      useEffect(() => {
        getBlogsCategory();
        getAuthorList();
      }, [])


    const onEditorChange = (event) =>{
        setBlogContent(event.editor.getData());
      }
    
    const handleUploadButton=(e)=>{
        setdisableCreateWileUpload(true);
        uploadFile(e.target.files[0], config) 
        .then(data =>{setdisableCreateWileUpload(false);setFeaturedImage(data.location)})
        .catch(err => console.error('err',err))
    }

     return(
         <span>
             <BlogNav />
             <div className="container-fluid p-4 card mt-3">
                
                         <form onSubmit={postBlogs}>
                             <div className="row">
                                 <div className="col-lg-6">
                                 <div className="form-group">
                                <label>Blog Title</label>
                                <input type="text" className="form-control" value={blogTitle} onChange={(event)=>setBlogTitle(event.target.value)} id="blogTitle" required />
                                </div>
                                </div>

                                 <div className="col-lg-6">
                                 <div className="form-group">
                                <label>Featured Image Link</label>
                                {/* <input type="text" className="form-control" value={featuredImage} onChange={(event)=>setFeaturedImage(event.target.value)} id="featuredImage" required/> */}
                                <input 
                                        onChange={handleUploadButton}
                                        className="form-control" 
                                        type="file" 
                                        accept="image/*"
                                        id="formFile"
                                        />
                                </div>
                                </div>

                                <div className="col-lg-12">
                                <div className="form-group">
                                <label>Blog Short Description</label>
                                <textarea className="form-control" value={blogThumpContent} onChange={(event)=>setBlogThumpContent(event.target.value)} id="featuredImage" required></textarea>
                                </div>
                                </div>
                             </div>
                             <div className="row">
                                 <div className="col-lg-12">
                                 <div className="form-group">
                                <label>Blog Content</label>

                                <CKEditor
                       
                                    initData={blogContent}
                                    onChange={onEditorChange}
                                    config={ {
                                        format_tags : 'p;h1;h2;h3;h4;h5;h6;div'
                                           } }

                                 />

                            </div>
                                 </div>
                                 
                             </div>

                             <div className="row">
                                 <div className="col-lg-6">
                                 <div className="form-group">
                                 <label>Published By</label>
                                 <select className="form-control" value={publishedBy} onChange={(event)=>setPublishedBy(event.target.value)} required>
                                      <option style={{display:"none"}}>--Choose--</option>
                                     {authorList.map((item,index)=>{return( <option value={item}>{item}</option>)})}
                                 </select>
                                 </div>
                                 </div>

                                 <div className="col-lg-6">
                                 <div className="form-group">
                                <label>Published Date</label>
                                <input type="date" className="form-control" value={publishedDate} onChange={(event)=>setPublishedDate(event.target.value)} id="publishedDate" required/>
                            </div>
                                 </div>
                            </div>

                            <div className="row">
                                 <div className="col-lg-6">
                                 <div className="form-group">
                                <label>Category</label>
                                <select className="form-control" value={catagory} placeholder={null} onChange={(event)=>setCatagory(event.target.value)} id="catagory" required>
                                <option style={{display:"none"}}>--Choose--</option>
                                  
                                {blogCategoryList.map((item) => {
                                    return(
                                        <option value={item.Category_Name}>{item.Category_Name}</option>
                                    )
                                })}
                            
                                </select>
                            </div>
                                 </div>
                                 <div className="col-lg-6">
                                 <div className="form-group">
                                <label>Status</label>
                                <select className="form-control" value={blogStatus} onChange={(event)=>setBlogStatus(event.target.value)} id="blogStatus">
                                    <option>Draft</option>
                                    <option>Publish</option>
                                </select>
                            </div>
                                 </div>
                            </div>

                            {/* <div className="form-group">
                                <label>Blog Content</label>
                                <input type="text" className="form-control" value={blogContent} onChange={(event)=>setBlogContent(event.target.value)} id="blogContent" required/>
                            </div> */}

                            <div className="row">
                                 <div className="col-lg-6">

                                 <div className={showSuccess === true ? 'alert alert-success alert-dismissible fade show' : 'alert alert-success alert-dismissible fade d-none'} role="alert">
                                    <strong>Success!</strong> Category Created
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className={showError === true ? 'alert alert-danger alert-dismissible fade show' : 'alert alert-danger alert-dismissible fade d-none'}  role="alert">
                                    <strong>Failed!</strong> Please try Again
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                    </div>
                            </div>

                            <div className="row">

                            <div className="col-lg-12">
                            <div className="form-group">
                            <label>Title For Seo</label>
                            <input type="text" 
                                   className="form-control" 
                                   value={seoTitle} 
                                   onChange={(event)=>setseoTitle(event.target.value)} 
                                   id="publishedBy" 
                                   required/>
                            </div>
                            </div>
                            
                            <div className="col-lg-12">
                            <div className="form-group">
                            <label>Description For Seo</label>
                            <textarea className="form-control" 
                                      value={seoDescription} 
                                      onChange={(event)=>setseoDescription(event.target.value)}  
                                      id="featuredImage" 
                                      required>
                            </textarea>
                            </div>
                            </div>

                            <div className="col-lg-12">
                            <button type="submit" className="btn btn-primary" disabled={disableCreateWileUpload}>Create</button>
                            </div>
                            </div>

                         </form>
                     </div>
               
         </span>
     )
 }