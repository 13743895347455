
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { webApi } from '../url';
import { Link, useHistory, useParams } from "react-router-dom";
import { blogContext } from '../BlogMain';
import { Spinner, Toast } from 'react-bootstrap';
import Fuse from "fuse.js";
import './blogList.css'
import Pagination from 'src/helper/Pagination';

export default function BlogList(){
    let history = useHistory();
    const [blogList, setBlogList] = useState([]);
    const [blogFilterdList, setblogFilterdList] = useState([]);
    const [finalBlogListToMap, setfinalBlogListToMap] = useState([]);
    // eslint-disable-next-line 
    const [loading, setloading] = useState(false);
    const [storePopularBlogs, setstorePopularBlogs] = useState([]);
    const [popLoader, setpopLoader] = useState(false);
    const [ blogCategoryList, setBlogCategoryList] = useState([]);
    const [resultAccordingToCat, setresultAccordingToCat] = useState([]);
    const [catLoader, setcatLoader] = useState(false);
    const [showToast, setshowToast] = useState(false);
    // eslint-disable-next-line 
    const [show, setShow] = useState(false);
    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(9);

    
    // pagination start 
    const { id } = useParams();

    const redirectToErrorPage =(page)=>{
      // when number of pages increase in blog list, please add Number(page) === 4 ... etc
      if ( page === undefined || Number(page) === 1 || Number(page) === 2 || Number(page) === 3 ) {  
        console.log('blog id', id, typeof id);
        setCurrentPage(Number(id));
        const goToViewPort = document.getElementById('scroll-here-when-paginated');
        goToViewPort.scrollIntoView({ behavior: 'auto', block: 'center' });
      } else {
        window.location.href = 'https://www.vibtree.com/404/'
      }
    }

    useEffect(() => {
      redirectToErrorPage(id);
    }, [id]);

    useEffect(() => {
      setCurrentPage(1);
    }, []);

    const indexOfLastPostPlusOne = currentPage * postsPerPage;
    const indexOfFirstPost = (indexOfLastPostPlusOne-1) - (postsPerPage-1);
    const currentBlogs = blogList.slice(indexOfFirstPost, indexOfLastPostPlusOne);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    // pagination end

    // scroll to top start 
    useEffect(() => {
      setTimeout(() => {
        window.scrollTo({top:0, behavior:'smooth'})
      }, 100);
    }, []);
    // scroll to top end 

    async function getBlogs() {
        try {
          setloading(true);
          const response = await axios.get(`${webApi}/blogs?Status=Publish`);
          setloading(false);
          setBlogList(response.data.reverse())
        } catch(error) {
          console.log("error", error);
        }
      }

    const getPopularBlog =async()=>{
      try {
        setpopLoader(true)
        const popBlog = await axios.get(`${webApi}/blogs`);
        setpopLoader(false)
        const sortedData = popBlog.data.sort((a, b) => parseFloat(b.Blog_Claps) - parseFloat(a.Blog_Claps));
          setstorePopularBlogs([sortedData[0]])
      } catch (error) {
        console.log(error);
      }
    }

    

    async function getBlogsCategory() {
      try {
        const response = await axios.get(`${webApi}/Categories`);
        return (
          setBlogCategoryList(response.data)
        );
      } catch(error) {
        console.log("error", error);
      }
    }

      useEffect(() => {
        getBlogs();
        getPopularBlog();
        getBlogsCategory();
      }, [])

      const blogDetails = (item) =>{
        const testUrl = item.specific_Url
        const slicedString = testUrl.substring(0,testUrl.length-1);
        const getLastItem = slicedString.substring(slicedString.lastIndexOf('/') + 1);
        return(`/blog/blog-details/${getLastItem}/`)
      }

     const shortDescription=(text)=>{
       const pureText = text;
       var finalText = pureText.length > 100 ? pureText.slice(0,200) : pureText
       return(
         finalText
       )
     }

     const readMore=(item)=>{
      const testUrl = item.specific_Url
      const slicedString = testUrl.substring(0,testUrl.length-1);
      const getLastItem = slicedString.substring(slicedString.lastIndexOf('/') + 1);  
      history.push(`/blog/blog-details/${getLastItem}/`);
     }


    //  search according to category start 
    const searchWithCat=async(cat)=>{
      try {
        // fetch data from a url endpoint
        setcatLoader(true)
        const response = await axios.get(`${webApi}/blogs?Category=${cat}`);
        setcatLoader(false)
        if ( response.data.length===0 ) {
          setshowToast(true) 
        } else {
          setshowToast(false)
        }
          setresultAccordingToCat(response.data)
      } catch(error) {
        console.log("error", error);
      }
    }

    const BlogCard=({item})=>{
       return(
        <div className="col-lg-4 mb-5 vib-padding-bottom-50-25 p-lg-5">

        <Link to={blogDetails(item)} className="card blog-list-card">
        <span>

        <img src={item.Featured_image} className="card-img-top" alt={item.Blog_Title} />                            
        <span className="category">
        {item.Category}
        </span>

        <h5 className="card-title vib-container-heading-blog-list vib-res-left" 
            style={{lineHeight: "42px",fontSize:'28px', fontWeight:'600',color: '#464646'}}>
            {item.Blog_Title}
        </h5>

        <p className="card-text text-muted  vib-res-left">
        {/* {item.Blog_Thump_Content} */}
        {shortDescription(item.Blog_Thump_Content)}...
        </p>

        </span>
        </Link>
       
       </div>
       )
    }

    //  search functionality start 
    const fuse = new Fuse(blogList, { keys: ['Blog_Title', 'Blog_Thump_Content', 'meta_title', 'meta_description', 'Blog_Content'] });

    const handleInputChange=(e)=>{
      setblogFilterdList(fuse.search(e.target.value))
    }

    const handleGoClick=()=>{
      setFinalBlogListFunction();
    }

    const setFinalBlogListFunction=()=>{
      blogFilterdList.length === 0 ?  setfinalBlogListToMap(blogList) : setfinalBlogListToMap(blogFilterdList)
    }

    const handleCrossClick =()=>{
      setfinalBlogListToMap([]);
      setblogFilterdList([])
    }
    
    //  search functionality end
    

    // clearing  
    const clearSearchInput=()=>{
      document.getElementById('clearing-search-input').reset();
    }

   
    return(
        <span style={{overflow:'hidden'}}>

          <div className="container-fluid" style={{overflow:'hidden'}}>
            <div className="row d-flex justify-content-between">
              <div className="col-lg-2 text-right rocket-image-disappear" style={{overflow:'visible'}}>
                <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/blog/front-end-images/vibtree-arrows.png" 
                    //  className="img-fluid" 
                     style={{transform: "scale(-1, 1)",width:'400px'}} 
                     alt='blog-list'
                />
              </div>
              <div className="col-lg-7 text-center vib-res-margin-top-50-50 m-1300-auto">
                <h1 className='blog-welcome-h1 vib-container-heading-blog-list'>
                Welcome to <br/><span style={{color: "rgba(0, 70, 187, 1)"}}>“Blogs By Vibtree”</span>
                </h1>
              </div>
              <div className="col-lg-2 text-left rocket-image-disappear" style={{overflow:'visible'}}>
                <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/blog/front-end-images/vibtree-arrows.png" 
                    //  className="img-fluid" 
                     style={{width:'400px',position:'relative',left:'-113%'}} 
                     alt='vibtree-arrows'
                />
              </div>
            </div>
                <h5 className='col-lg-8 mx-auto text-center mt-lg-5 blog-text-sub vib-bold-para'>
                Exclusive compilation of insights on business communication, sales, marketing &amp; support.
                Driven by cloud telephony &amp; call management solutions. 
                </h5>
          </div>

          <form id='clearing-search-input' className="blog-search col-lg-10 mx-auto mt-lg-5">
            <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/blog/front-end-images/vibtree-search.svg' alt="search-icon" className="blog-search-icon" />
            <input 
                  style={{width:'70%'}} 
                  onChange={handleInputChange} 
                  type="text" 
                  className="blog-search-input col-lg-10" 
                  placeholder="Search"/>
            <div 
                 onClick={finalBlogListToMap.length === 0 ? handleGoClick : handleCrossClick} 
                 className="blog-go">
                 {finalBlogListToMap.length === 0 ?
                  'Go' : 
                  <div className='blog-cross-mark' onClick={clearSearchInput}>
                     +
                  </div> 
                 }
            </div>
          </form>

          {finalBlogListToMap.length === 0 ? 
          <>
          <div className="blog-categories 
                          m-auto 
                          w-lg-75 
                          w-md-80 
                          w-sm-90 
                          d-flex 
                          flex-wrap 
                          justify-content-center 
                          align-items-center
                          vib-res-margin-top-50-50">
          {blogCategoryList.map((item,index)=>{
            return(<p className='blog-cat-p m-3' onClick={()=>searchWithCat(item.Category_Name)}>{item.Category_Name}</p>)
          })}
          </div>
          
          <div className="toast-blog-detail-container">
          <Toast onClose={() => setshowToast(false)} 
                                   show={showToast} 
                                   delay={3000} 
                                   autohide
                                   style={{display:'flex', 
                                           justifyContent:'center',
                                           alignItems:'center',
                                           margin:'0px',
                                           maxHeight:'50px',
                                           maxWidth:'80px',
                                           paddingLeft:'10px'}}>
                      <Toast.Body>Blogs Of This Category Are Not Available</Toast.Body>
          </Toast>
          </div>
          </> 
          : null}

          {resultAccordingToCat.length===0 ?

          <>
          { finalBlogListToMap.length === 0 ? (popLoader ? 
          <div className="pop-blog-dim"><Spinner animation="border" variant="primary" style={{margin:'auto'}}/>
          </div>:
            storePopularBlogs.map((item,index)=>{
               return(
                <Link className="blog-big-card 
                                 mb-5
                                 d-flex 
                                 flex-wrap
                                 justify-content-around 
                                 vib-padding-bottom-100-50
                                 vib-padding-bottom-100-50"
                      to={blogDetails(item)}
                      style={{overflow:'hidden',cursor:'pointer'}}>

                  <img src={item.Featured_image} alt={item.Blog_Title} className='mt-5 mb-3 popular-category-blog'/>
      
                  <div className="blog-big-right 
                                  col-lg-6 
                                  d-flex 
                                  flex-column 
                                  justify-content-between 
                                  mb-3">
      
                    <p className="blog-big-tag">{item.Category}</p>
                    <div className="blog-big-title 
                                    vib-container-heading 
                                    vib-res-left">
                    {item.Blog_Title}
                    </div>
                    <div className="blog-big-short_description 
                                    mb-3 
                                    mt-3 
                                    vib-bold-para 
                                    vib-res-left">
                    {shortDescription(item.Blog_Thump_Content)}...
                    </div>
                    <div className="hvr-bounce-to-bottom1 
                                    design1 
                                    big-blog-button 
                                    res-800-c-blog-list" 
                       style={{
                         backgroundColor:'#0046BB',
                         width:'200px',
                         margin:'0'}}
                       onClick={()=>readMore(item)}
                       >
                            Read more
                    </div>
      
                    </div>
                </Link>
              )
            })
          )  : null   }
        


            {  
            finalBlogListToMap.length === 0 ? 
            <div className="container-fluid">
                <div id='scroll-here-when-paginated'></div>
                <div className="row" style={{justifyContent:'space-evenly'}}>
                  {(currentBlogs.map((item) => {
                        return(
                          <BlogCard item={item}/>
                        ) 
                  }))} 
                  </div>

                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={blogList.length}
                    paginate={paginate} 
                    currentPage={currentPage}
                  />
            </div>
                :
            <div className="container-fluid">
                <div id='scroll-here-when-paginated'></div>
                <div className="row" style={{justifyContent:'space-evenly'}}>
                {(finalBlogListToMap.slice(0,9).map((item) => {
                        return(
                           <BlogCard item={item}/>
                        ) 
                }))}
                </div>
            </div>
            }
            </>
            
            :

            <div className="container-fluid">
                <div className="row">
                {
                  catLoader===true?
                      <Spinner className='spinner-z-index' animation="border" variant="primary" style={{margin:'auto'}}/>:
                      resultAccordingToCat.map((item) => {
                        return(
                           <div className="col-lg-4 mb-5 vib-padding-bottom-50-25 p-lg-5">

                           <Link  to={blogDetails(item)} className="card blog-list-card">
                            <span>

                            <img src={item.Featured_image} className="card-img-top" alt={item.Blog_Title} />                            
                            <span className="category">
                            {item.Category}
                            </span>

                            <h5 className="card-title vib-container-heading-blog-list vib-res-left" 
                                style={{lineHeight: "42px",fontSize:'28px',fontWeight:'600',color: '#464646'}}>
                                {item.Blog_Title}
                            </h5>

                            <p className="card-text text-muted  vib-res-left">
                            {shortDescription(item.Blog_Thump_Content)}...
                            </p>

                            </span>
                            </Link>
                           
                           </div> 
                        )
                })}
                </div>
            </div>
          
          }
 
            
        </span>
    )
}