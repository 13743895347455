import React, { useState} from 'react';
import { webApi } from '../../url';
import axios from 'axios';

export default function CategoryEditComponent({item, categoryName, setCategoryName}){

    const [ showEditSuccess, setShowEditSuccess ] = useState(false);
    // eslint-disable-next-line
    const [ showError, setShowError ] = useState(false);

    const getEditData = () =>{
        setCategoryName(item.Category_Name);
    
    }

    async function editCategory() {
        const formEditData = {
            Category_Name : categoryName,
        }
        try {
            // fetch data from a url endpoint
            // eslint-disable-next-line
            const response = await axios.put(`${webApi}/Categories/${item.id}`, formEditData );
            return (
                setShowEditSuccess(true)
                );
            } catch(error) {
                setShowError(true)
            }
        
    }

  
    return(

        <span>



        <a href className="btn btn-link" title="Edit"  data-toggle="modal" data-target="#CategoryEditComponent" onClick={getEditData}>
            <i className="bi bi-pencil"></i>
        </a>

        <div className="modal fade" id="CategoryEditComponent" tabIndex="-1" aria-labelledby="CategoryEditComponent" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                            <div className="form-group">
                                <label>Category Name</label>
                                <input type="text" className="form-control" value={categoryName} onChange={(event)=>setCategoryName(event.target.value)} id="categoryName" required />
                            </div>
               
                <div className={showEditSuccess === true ? 'alert alert-success alert-dismissible fade show' : 'alert alert-success alert-dismissible fade d-none'} role="alert">
                    <strong>Success!</strong> Category Updated
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={editCategory}>Update changes</button>
                </div>
                </div>
            </div>
            </div>

        


        </span>

        
    )
}