import React, { useEffect, useRef, useState } from 'react'
import './navbar.css'
import { Link } from 'react-router-dom'
import Company from './components/Company'
import Partners from './components/Partners'
import FirstDropDown from './components/FirstDropDown'
import SignUpModel from './components/SignUpModel'
import TalkModel from './components/TalkModel'
import SupportModel from './components/SupportModel'
import SchedulePopUp from '../homePage/SchedulePopUp'
import { webApi } from '../Blog/url'
import axios from 'axios'
import Industry from './components/Industry'
import BreadCrumb from 'src/BreadCrumb'
import { useDispatch, useSelector } from 'react-redux'
import { dropdownToggleAction } from 'src/redux/breadcrumbSlice'


export default function NavbarTop() {

  const [forshadow, setforshadow] = useState("noshadow");

  const [whywhy, setwhywhy] = useState('no');
  const [company, setcompany] = useState('no');
  const [partners, setpartners] = useState('no');
  const [industry, setindustry] = useState('no');

  const [blurProducts, setblurProducts] = useState('white');
  const [blurCompany, setblurCompany] = useState('white');
  const [blurPartners, setblurPartners] = useState('white');
  const [blurIndustry, setblurIndustry] = useState('white');

  const [activeItemState, setactiveItemState] = useState({ productState: 'null' });
  const [activeCompanyState, setactiveCompanyState] = useState({ companyState: 'null' });
  const [blogActiveState, setblogActiveState] = useState(false);
  const [industryActiveState, setindustryActiveState] = useState(false);
  const [pricingPageState, setpricingPageState] = useState(false);
  const [featuresstate, setfeaturesstate] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [supportModalShow, setsupportModalShow] = useState(false);
  // eslint-disable-next-line
  const [supportModalShowCalendly, setsupportModalShowCalendly] = useState(false);
  const [storePopularBlogs, setstorePopularBlogs] = useState('');
  const [specificUrl, setspecificUrl] = useState();

  const dispatch = useDispatch();

  var handlewhywhy = () => {
    // setwhysolution('no');
    setcompany('no');
    setpartners('no');
    setindustry('no');
    return (
      whywhy === 'yes' ? setwhywhy('no') : whywhy === 'no' ? setwhywhy('yes') : null
    )
  }

  var handleCompany = () => {
    setwhywhy('no');
    setpartners('no');
    setindustry('no');
    return (
      company === 'yes' ? setcompany('no') : company === 'no' ? setcompany('yes') : null
    )
  }
  var handleIndustry = () => {
    setwhywhy('no');
    setpartners('no');
    setcompany('no');
    return (
      industry === 'yes' ? setindustry('no') : industry === 'no' ? setindustry('yes') : null
    )
  }
  var handlePartners = () => {
    setwhywhy('no');
    setcompany('no');
    setindustry('no');
    return (
      partners === 'yes' ? setpartners('no') : partners === 'no' ? setpartners('yes') : null
    )
  }

  var giveShadow = () => {
    whywhy === 'yes' ? setforshadow("noshadow") :
      company === 'yes' ? setforshadow("noshadow") :
        partners === 'yes' ? setforshadow("noshadow") :
          window.scrollY > 50 ? setforshadow("shadow") : setforshadow("noshadow");
  };
  //  window.addEventListener('scroll', giveShadow)
  useEffect(() => {
    window.addEventListener("scroll", giveShadow);
    giveShadow();
    return () => {
      window.removeEventListener("scroll", giveShadow)
    }
  });

  let whywhyRef = useRef();
  let companyRef = useRef();
  let partnerRef = useRef();
  let industryRef = useRef();

  var whyRefLink = useRef();
  var companyRefLink = useRef();
  var partnersRefLink = useRef();
  var industryRefLink = useRef();

  // var handleMouseDown = (event) => {
  //   if (!whywhyRef.current.contains(event.target) && !whyRefLink.current.contains(event.target)) { setwhywhy('no'); setblurProducts('white'); dispatch(dropdownToggleAction(false)); }
  //   if (!companyRef.current.contains(event.target) && !companyRefLink.current.contains(event.target)) { setcompany('no'); setblurCompany('white'); dispatch(dropdownToggleAction(false)); }
  //   if (!partnerRef.current.contains(event.target) && !partnersRefLink.current.contains(event.target)) { setpartners('no'); setblurPartners('white'); dispatch(dropdownToggleAction(false)); }
  //   if (!industryRef.current.contains(event.target) && !industryRefLink.current.contains(event.target)) { setindustry('no'); setblurIndustry('white'); dispatch(dropdownToggleAction(false)); }
  // }
  var handleMouseDown = (event) => {
    if (!whywhyRef.current.contains(event.target) && !whyRefLink.current.contains(event.target)) { setwhywhy('no'); setblurProducts('white'); dispatch(dropdownToggleAction(false)); }
    // if (!companyRef.current.contains(event.target) && !companyRefLink.current.contains(event.target)) { setcompany('no'); setblurCompany('white'); dispatch(dropdownToggleAction(false)); }
    // if (!partnerRef.current.contains(event.target) && !partnersRefLink.current.contains(event.target)) { setpartners('no'); setblurPartners('white'); dispatch(dropdownToggleAction(false)); }
    // if (!industryRef.current.contains(event.target) && !industryRefLink.current.contains(event.target)) { setindustry('no'); setblurIndustry('white'); dispatch(dropdownToggleAction(false)); }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleMouseDown)
    return () => {
      document.removeEventListener("mousedown", handleMouseDown)
    }
  }, []);

  //active nav items start 
  const webPathFunction = () => {
    let blogWebPath = window.location.pathname.split('/')
    return (
      blogWebPath[1]
    )
  }

  let webPath = window.location.pathname
  console.log("webPath", webPath)
  useEffect(() => {
    if (webPath === '/company/about/') {
      setactiveCompanyState({ companyState: 'blue' })
    } else if (webPath === '/company/partner-with-us/') {
      setactiveCompanyState({ companyState: 'blue' })
    } else if (webPath === '/company/work-with-us/') {
      setactiveCompanyState({ companyState: 'blue' })
    } else {
      setactiveCompanyState({ companyState: 'null' })
    }

    if (webPath === '/products/voice/') {
      setactiveItemState({ productState: 'blue' })
    } else if (webPath === '/products/bulk-sms/') {
      setactiveItemState({ productState: 'blue' })
    } else if (webPath === '/products/call-tracking/') {
      setactiveItemState({ productState: 'blue' })
    } else if (webPath === '/products/cloud-phone/') {
      setactiveItemState({ productState: 'blue' })
    } else if (webPath === '/products/cloud-number/') {
      setactiveItemState({ productState: 'blue' })
    } else if (webPath === '/products/ivr-studio/') {
      setactiveItemState({ productState: 'blue' })
    } else { setactiveItemState({ productState: 'null' }) };


    if (webPathFunction() === 'blog') {
      setblogActiveState(true)
    } else { setblogActiveState(false) };

    if (webPathFunction() === 'industry') {
      setindustryActiveState(true)
    } else { setindustryActiveState(false) };

    if (webPathFunction() === 'pricing') {
      setpricingPageState(true)
    } else { setpricingPageState(false) };
    if (webPathFunction() === 'features') {
      setfeaturesstate(true)
    } else { setfeaturesstate(false) };

  }, [webPath]);

  //active nav items end 

  var setfold = (event) => {
    if (event === 'fold') {
      setcompany('no');
      setblurCompany('white');
    }
  }
  var setfoldWhy = (event) => {
    if (event === 'fold') {
      setwhywhy('no');
      setblurProducts('white');
    }
  }
  var setfoldIndustry = (e) => {
    if (e === 'fold') {
      setindustry('no');
      setblurIndustry('white');
    }
  }

  var setBackgroundBlueProducts = () => {
    setblurCompany('white')
    setblurPartners('white')
    setblurIndustry('white')
    return (
      blurProducts === 'white' ? setblurProducts('blue') : setblurProducts('white')
    )
  }
  var setBackgroundBlueCompany = () => {
    setblurProducts('white')
    setblurPartners('white')
    setblurIndustry('white')
    return (
      blurCompany === 'white' ? setblurCompany('blue') : setblurCompany('white')
    )
  }
  var setBackgroundBlueIndustry = () => {
    setblurProducts('white')
    setblurPartners('white')
    setblurCompany('white')
    return (
      blurIndustry === 'white' ? setblurIndustry('blue') : setblurIndustry('white')
    )
  }
  var setBackgroundBluePartners = () => {
    setblurProducts('white')
    setblurCompany('white')
    setblurIndustry('white')
    return (
      blurPartners === 'white' ? setblurPartners('blue') : setblurPartners('white')
    )
  }

  const getPopularBlog = async () => {
    try {
      const popBlog = await axios.get(`${webApi}/blogs`);

      const sortedData = popBlog.data.sort((a, b) => parseFloat(b.Blog_Claps) - parseFloat(a.Blog_Claps));

      setstorePopularBlogs(sortedData[0].Blog_Title)
      setspecificUrl(sortedData[0].specific_Url)

    } catch (error) {
      console.log(error);
    }
  }

  const blogDetails = (specific_string) => {
    const slicedString = specific_string ? specific_string.substring(0, specific_string.length - 1) : '';
    const getLastItem = slicedString ? slicedString.substring(slicedString.lastIndexOf('/') + 1) : '';

    return (`/blog/blog-details/${getLastItem}/`)
  }


  useEffect(() => {

    getPopularBlog();

    if (webPathFunction() === 'blog') {
      setblogActiveState(true)
    } else { setblogActiveState(false) };

  }, []);

  // toggle dropdown on breadcrumb click start 
  const sliceState = useSelector(state => state.breadcrumbSliceReducer);
  const whichDropDownToToggle = sliceState.dropdown;
  const toggleDropDown = sliceState.dropdownToggle;

  useEffect(() => {
    if (whichDropDownToToggle === 'products') {
      setwhywhy('yes');
      setBackgroundBlueProducts();
    } else if (whichDropDownToToggle === 'company') {
      setcompany('yes');
      setBackgroundBlueCompany();
    } else if (whichDropDownToToggle === 'industry') {
      setindustry('yes');
      setBackgroundBlueIndustry();
    }

    if (toggleDropDown === false) {
      setwhywhy('no');
      setindustry('no');
      setcompany('no');
      setblurProducts('white');
      setactiveItemState({ productState: 'null' })
      dispatch(dropdownToggleAction(false))
    }
  }, [toggleDropDown]);
  // toggle dropdown on breadcrumb click end

  return (

    <div>

      <div ref={whywhyRef} className="nav-toggle-why" style={{ visibility: whywhy === 'yes' ? 'visible' : 'hidden' }}>
        <FirstDropDown whywhy={whywhy} setfoldWhy={setfoldWhy} />
      </div>

      <div ref={companyRef} className="nav-toggle-company" style={{ visibility: company === 'yes' ? 'visible' : 'hidden' }}>
        <Company setfold={setfold} />
      </div>

      <div ref={partnerRef} className="nav-toggle-partners" style={{ visibility: partners === 'yes' ? 'visible' : 'hidden' }}>
        <Partners />
      </div>

      <div ref={industryRef} className="nav-toggle-partners" style={{ visibility: industry === 'yes' ? 'visible' : 'hidden' }}>
        <Industry setfoldIndustry={setfoldIndustry} />
      </div>

      <nav
        className={forshadow === "noshadow" ?
          "navbar navbar-expand-xl navbar-light fixed-top paddu padduscroll firstNav" :
          "navbar navbar-expand-xl navbar-light fixed-top paddu padduscroll paddushadow firstNav"}
        id="top_nav"
        style={{ zIndex: "501", minHeight: '60px' }} >
        <div
          // className="container-fluid"
          className="navbarimg"
        >
          <a className="navbar-brand navbar-logo-hiding" href="/">
            <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg' alt='vibtree-logo' className='res-img-size-nav' />
          </a>
          <center>
            <a className="navbar-logo-image-center" href="/">
              <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg'
                alt='vibtree-logo'
                className='navbar-logo-image'
                style={{ paddingBottom: '10px' }} />
            </a>
          </center>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            style={{ position: "relative" }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent" >
            <ul className="navbar-nav ml-auto"
              itemScope
              itemType="https://schema.org/SiteNavigationElement"
              role="menu">

              <li className="nav-item dropdown position-static"
                itemProp="name"
                role="menuitem"
                style={{ position: 'relative' }}>
                <p className="nav-link dropdown-toggle top-menu-item"
                  id="navbarDropdown" role="button" aria-haspopup="true"
                  aria-expanded="false" style={{ position: 'relative' }}>
                  <div className={blurProducts === 'blue' ?
                    "nav-item-blue-cover" : activeItemState.productState === 'blue' ?
                      "nav-active-item-blue-for-host" : ''}></div>
                  <a
                    href
                    ref={whyRefLink}
                    className='Features'
                    onClick={() => { handlewhywhy(); setBackgroundBlueProducts(); }}
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    style={blurProducts === 'blue' ? { color: 'white' } : activeItemState.productState === 'blue' ? { color: '#0046bb' } : { color: '#636161' }}>
                    Channels
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16" height="16" fill="currentColor"
                      className="bi bi-caret-down-fill navbar_margin_icon"
                      viewBox="0 0 16 16"
                      style={{ color: '#ccc' }}
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </a>
                </p>
              </li>


              <li className="nav-item dropdown position-static"
                // style={{ marginRight: '20px' }}
                itemProp="name"
                role="menuitem">
                <p className="nav-link dropdown-toggle top-menu-item for_white"
                  id="navbarDropdown" role="button" aria-haspopup="true"
                  aria-expanded="false" style={{ position: 'relative' }}>
                  <div className={blurPartners === 'white' ? '' : "nav-item-blue-cover"}></div>
                  {/* <a
                    href
                    ref={partnersRefLink}
                    // onClick={() => { handlePartners(); setBackgroundBluePartners(); }}
                    exact
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    style={blurPartners === 'white' ? { color: '#636161' } : { color: 'white' }}>
                    Pricing

                  </a> */}
                  <a href="/features"
                    className='Features'
                    style={featuresstate === true ? { color: '#0046bb' } : featuresstate === false ? { color: '#636161' } : null}
                    onClick={() => setfeaturesstate(true)}
                  >
                    Features
                  </a>

                  {/* <Link
                    to='/features'
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    className='Features'
                    style={featuresstate === true ? { color: '#0046bb' } : featuresstate === false ? { color: '#636161' } : null}
                    onClick={() => setfeaturesstate(true)}
                  >
                    Features
                  </Link> */}

                </p>
              </li>

              {/* <li className="nav-item dropdown position-static"
                itemProp="name"
                role="menuitem">
                <p className="nav-link dropdown-toggle top-menu-item"
                  role="button" aria-haspopup="true"
                  aria-expanded="false" style={{ position: 'relative' }}>
                  <div className={blurIndustry === 'blue' ?
                    "nav-item-blue-cover" : industryActiveState ?
                      "nav-active-item-blue-for-host" : ''}></div>
                  <a
                    href
                    ref={industryRefLink}
                    onClick={() => { handleIndustry(); setBackgroundBlueIndustry(); }}
                    exact
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    style={blurIndustry === 'blue' ? { color: 'white' } :
                      industryActiveState ? { color: '#0046bb' } : { color: '#636161' }}>
                    Industry
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16" height="16" fill="currentColor"
                      className="bi bi-caret-down-fill navbar_margin_icon"
                      viewBox="0 0 16 16"
                      style={{ color: '#ccc' }}
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </a>
                </p>
              </li> */}

              {/* <li className="nav-item"
                itemProp="name"
                role="menuitem">
                <p className="nav-link dropdown-toggle top-menu-item for_white"
                  id="navbarDropdown" role="button" aria-haspopup="true"
                  aria-expanded="false">
                  <Link
                    to='/blog/'
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    onClick={webPathFunction}
                    style={blogActiveState === true ? { color: '#0046bb' } : blogActiveState === false ? { color: '#636161' } : null}>
                    Features
                  </Link>
                </p>
              </li> */}


              {/* <li className="nav-item"
                itemProp="name"
                role="menuitem">
                <p className="nav-link dropdown-toggle top-menu-item for_white"
                  id="navbarDropdown" role="button" aria-haspopup="true"
                  aria-expanded="false">

                  Features

                </p>
              </li> */}
              <li className="nav-item"
                itemProp="name"
                role="menuitem">
                <p className="nav-link dropdown-toggle top-menu-item for_white"
                  id="navbarDropdown" role="button" aria-haspopup="true"
                  aria-expanded="false">
                  <a href="https://vibtree.tawk.help/" target="_blank" style={{ color: "#636161" }}
                    className='Features'
                  >
                    Help Center
                  </a>
                </p>
              </li>

              {/* <li className="nav-item dropdown position-static"
                itemProp="name"
                role="menuitem">
                <p className="nav-link dropdown-toggle top-menu-item for_white"
                  id="navbarDropdown" role="button" aria-haspopup="true"
                  aria-expanded="false">
                  <Link
                    to='/pricing/'
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    style={pricingPageState === true ? { color: '#0046bb' } : pricingPageState === false ? { color: '#636161' } : null}>
                    Pricing
                  </Link>
                </p>
              </li> */}

              {/* <li className="nav-item dropdown position-static"
                style={{ marginRight: '20px' }}
                itemProp="name"
                role="menuitem">
                <p className="nav-link dropdown-toggle top-menu-item for_white"
                  id="navbarDropdown" role="button" aria-haspopup="true"
                  aria-expanded="false" style={{ position: 'relative' }}>
                  <div className={blurPartners === 'white' ? '' : "nav-item-blue-cover"}></div>
                  <a
                    href
                    ref={partnersRefLink}
                    onClick={() => { handlePartners(); setBackgroundBluePartners(); }}
                    exact
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    style={blurPartners === 'white' ? { color: '#636161' } : { color: 'white' }}>
                    Contact Us
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16" height="16" fill="currentColor"
                      className="bi bi-caret-down-fill navbar_margin_icon"
                      viewBox="0 0 16 16"
                      style={{ color: '#ccc' }}
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </a>
                </p>
              </li> */}
              <li className="nav-item dropdown position-static"
                style={{ marginRight: '20px' }}
                itemProp="name"
                role="menuitem">
                <p className="nav-link dropdown-toggle top-menu-item for_white"
                  id="navbarDropdown" role="button" aria-haspopup="true"
                  aria-expanded="false" style={{ position: 'relative' }}>
                  <div className={blurPartners === 'white' ? '' : "nav-item-blue-cover"}></div>
                  {/* <a
                    href
                    ref={partnersRefLink}
                    // onClick={() => { handlePartners(); setBackgroundBluePartners(); }}
                    exact
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    style={blurPartners === 'white' ? { color: '#636161' } : { color: 'white' }}>
                    Pricing

                  </a> */}
                  <Link
                    to='/pricing/'
                    className='pricing'
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    style={pricingPageState === true ? { color: '#0046bb' } : pricingPageState === false ? { color: '#636161' } : null}
                    onClick={() => setpricingPageState(true)}
                  >
                    Pricing
                  </Link>
                </p>
              </li>

              <li
                className="nav-item navbar-first-button-style"
                itemProp="name"
                role="menuitem">
                {/* <p className="hvr-bounce-to-bottom 
                               hover-btn nav_button_width 
                               navbar-firstbutton-res"
                  onClick={() => setModalShow(true)}>
                  Talk to <br className='comeon' />an <br className='between1100-950' /> Expert
                </p> */}
                <a href="https://app.vibtree.com/" className='signinapp'>
                  <p className="hvr-bounce-to-bottom 
                               hover-btn nav_button_width 
                               navbar-firstbutton-res"
                  // onClick={() => setModalShow(true)}
                  >

                    Sign in


                  </p>
                </a>
              </li>

              <input type="hidden" name="mauticform[formId]" id="mauticform_talktoexpert_id" value="10" />
              <input type="hidden" name="mauticform[return]" id="mauticform_talktoexpert_return" value="" />
              <input type="hidden" name="mauticform[formName]" id="mauticform_talktoexpert_name" value="talktoexpert"></input>
              <TalkModel
                show={modalShow}
                onHide={() => setModalShow(false)}
              />

              <li className="nav-item "
                style={{ position: 'relative', top: '-7px' }}
                itemProp="name"
                role="menuitem">
                {/* eslint-disable-next-line */}
                <a className="hvr-bounce-to-bottom 
                            hover-btn 
                            shadow 
                            dark 
                            nav_button_width"
                  style={{ padding: '13px 40px !important', maxHeight: '70px', border: "1.1px solid #2a5caf" }}
                  href={`https://app.vibtree.com/auth/register`}
                  target='_blank'

                >
                  Create a Free Account
                  {/* <br className='comeon' />For A<br className='between1100-950' /> Trial */}
                </a>
                <SignUpModel
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </li>



            </ul>

          </div>
        </div>

      </nav>

      {/* <div className="top-sticky-nav">
        <div className="container-fluid">
          <div className="row">

            <BreadCrumb />

            <div className="col">
              <p className="pt-2 mb-0 text-center dis1080">
                {storePopularBlogs}
                <Link to={blogDetails(specificUrl)} className="btn btn-link btn-sm" style={{ paddingBottom: '5px', color: 'blue' }} >
                  Learn More
                </Link>
                <SchedulePopUp
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </p>
            </div>
            <div className="col-auto navbar-right-padding">
              <ul className="nav justify-content-end pt-1">
                <li className="nav-item" >
                  <a href='tel:18005724590' className="nav-link" style={{ paddingBottom: '5px' }} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16" style={{ marginRight: "5px" }}>
                      <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg>
                    Call Us</a>
                </li>
                <li className="nav-item" onClick={() => setsupportModalShow(true)} style={{ cursor: 'pointer' }}>
                  <a href className="nav-link" style={{ paddingBottom: '5px' }}>Support</a>
                </li>
                <SupportModel
                  show={supportModalShow}
                  onHide={() => setsupportModalShow(false)}
                />
                <li className="nav-item" onClick={() => setsupportModalShowCalendly(true)}>
                  <a href="https://dev.vibtree.com/" className="nav-link" style={{ paddingBottom: '5px', color: 'blue', cursor: 'pointer' }}>Login</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div> */}

      {/* <ul className="nav d-flex justify-content-center pt-1 for-band-toggle" 
        style={{
                position:'fixed', 
                top:'0px',
                width:'100%',
                zIndex:'3000',
                display:'flex',
                justifyContent:'spaceBetween'}}>
          <li className=""  style={{padding:'0px 0px 0px 0px !important'}}>
            <a className="upper-band-font-size" href='tel:18005724590' style={{padding:'5px',paddingLeft:'10px',color:'black'}} >
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16" style={{marginRight:'3px'}}>
                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
              </svg>
              Call Us</a>
          </li>
          <li className="" style={{padding:'0px 0px 0px 0px !important'}} onClick={() => setsupportModalShow(true)} >
            <a className="upper-band-font-size" href="#null" style={{padding:'5px',paddingLeft:'10px',color:'black'}}>Support</a>
          </li>
          <li className="" style={{padding:'0px 0px 0px 0px !important'}} onClick={() => setsupportModalShowCalendly(true)}>
            <a className="upper-band-font-size" href style={{padding:'5px',paddingLeft:'10px',color:'blue'}}>Login</a>
          </li>
                  <SchedulePopUp
                              show={supportModalShowCalendly}
                              onHide={() => setsupportModalShowCalendly(false)}
                  />
        </ul> */}

    </div>
  )
}
