import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function Industry(props) {
    const [cardActiveState, setcardActiveState] = 
    useState({education:'',food:'',hospitality:''});
  
    let presentPath= window.location.pathname
    useEffect(() => {
      if (presentPath==='/industry/education/') {
        setcardActiveState({education:'active'})
      } else if(presentPath==='/industry/food-and-beverage/'){
        setcardActiveState({food:'active'})
      } else if(presentPath==='/industry/hospitality/'){
        setcardActiveState({hospitality:'active'})
      } else{
        setcardActiveState({education:'',food:'',hospitality:''})
      }
    },[presentPath]);
  
    var handleOnClickWhy=()=>{
      props.setfoldIndustry('fold');
    }
    return (
      <div className='first-drop-down' style={{maxHeight:'90vh', overflowY:'scroll', width:'100%'}}>
        
        <Link 
             to="/industry/food-and-beverage/"
             className='company-link-blue' 
             onClick={handleOnClickWhy} 
             title="food-and-beverage">
        <div className="first-divs">
          <div className={cardActiveState.food==='active'?"first-div-card card-active-state-first":"first-div-card"}>
            <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/voice.svg' alt="voice" style={{padding:'15px'}}/>
            <h3 style={{color:'#464646'}}>Food & Beverage</h3>
            <p style={{color:'#7C8087'}}>Setup a customised calling experience for you callers/customers 
            and seamlessly track and manage call flows.</p>
          </div>
        </div>
        </Link>
        
        <Link 
             to="/industry/hospitality/" 
             className='company-link-blue' 
             onClick={handleOnClickWhy} 
             title="hospitality">
        <div className="first-divs">
          <div className={cardActiveState.hospitality==='active'?"first-div-card card-active-state-first":"first-div-card"}>
            <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/voice.svg' alt="voice" style={{padding:'15px'}}/>
            <h3 style={{color:'#464646'}}>Hospitality</h3>
            <p style={{color:'#7C8087'}}>Setup a customised calling experience for you callers/customers 
            and seamlessly track and manage call flows.</p>
          </div>
        </div>
        </Link>
  
        <Link 
        to="/industry/education/" 
        className='company-link-blue' 
        onClick={handleOnClickWhy}
        title="education">
        <div className="first-divs">
          <div className={cardActiveState.education==='active'?"first-div-card card-active-state-first":"first-div-card"}>
            <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/voice.svg' alt="voice" style={{padding:'15px'}}/>
            <h3 style={{color:'#464646'}}>Education</h3>
            <p style={{color:'#7C8087'}}>Setup a customised calling experience for you 
            callers/customers and seamlessly track and manage call flows.</p>
          </div>
        </div>
        </Link>
  
      </div>
    )
}
