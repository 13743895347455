import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

export default function PrivacyPolicy() {

    const scrollToTop=()=>{
        window.scrollTo({top:0, behavior:'smooth'})
    }

    useEffect(() => {
        scrollToTop();
    }, []);
    
  return (
    <div className='px-lg-5 
                    px-md-4 
                    px-sm-3 
                    vib-margin-bottom-200-100 
                    vib-res-margin-top-200-100 
                    m-auto
                    pri-po' 
         style={{maxWidth:'1100px',paddingRight:'10px',paddingLeft:'10px'}}>

    <Helmet>

    <meta name="robots" content="noindex , nofollow"/>

    </Helmet>
    

    <h1 className='vib-res-center vib-container-heading'>Privacy Policy</h1>
    <h3 className='vib-res-left vib-sub-sub-heading  vib-res-margin-top-50-50'>
        IT IS OUR RESPONSIBILITY TO PROTECT PERSONAL 
        INFORMATION OF OUR CLIENTS, AND WE TAKE 
        THIS RESPONSIBILITY VERY SERIOUSLY.
    </h3>
      
    <p className='vib-bold-para vib-res-left font-weight-300'>
        In return, we expect the same from our users as well. Vibtree is committed to transparency and choice when it comes to protecting our client’s privacy and that of all the users who visit our website.
        <br /> <br />
        This Privacy Policy is framed to outline how Vibtree Technologies LLP (also referred to as “We”, “Us”, “Our” or “Vibtree”) collects, stores, processes and manages the information that the client (also referred to as “User”, “You” or “Your”) provides us. The Policy also highlights your rights in terms of your data and how to contact us in case of any queries. 
        <br /> <br />
        This Policy applies to our users who choose to use our website, subscribe to our newsletter, products & services, register request for demo or trial, fill the website form, request information or our chat support service (altogether referred to as “services”).
        <br /> <br />
        This Privacy Policy is framed to assure the users that any information provided by them through various modes will not be misused sold or leased to third parties by any means or under any circumstances.
    </p>

    <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>The Information We Collect</h2>

    <p className='vib-bold-para vib-res-left font-weight-300'>
        This Privacy Policy mentions two categories of information collected by us- Personally Identifiable Information (referred to as the “PII”) and Non-Personally Identifiable Information(referred to as the “NPII”).
        <br /> <br />
        NPII includes you and your Authorized User(s)’ IP address, cookies, third party’s cookies, backend logs that our techies have access to and web pages that have been viewed by you through your Authorized Users and employees.
        <br /> <br />
        PII is any information that may be used to identify an individual, including, but not limited to, a first and last name, home or other physical address, an E-mail address, phone number or other contact information, whether at work or at home.
        <br /> <br />
        When you use our website or services, we may collect from you the following Personal Identifiable Information:
        <br /> <br />
        <ul className='vib-res-left pp-information-list vib-res-left' style={{textAlign:'left !important'}}>
            <li>First and Last name</li>
            <li>City, State and  Zip Code</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Skype ID</li>
            <li>or, any information that you may choose to share</li>
        </ul>
        <br />
        Please note that, PII and NPII will hereinafter collectively be referred to as the “Information”.
    </p>

    {/* cookies start  */}

    <h3 className='vib-res-left vib-sub-sub-heading vib-res-margin-top-50-50'>Cookies</h3>

    <p className='vib-bold-para vib-res-left font-weight-300'>
        Cookies are small files transferred to your device/computer when you use our website for the first time. Cookies permit us to recognize users and avoid repetitive requests for the same information. The information collected using these cookies is processed by us for analyzing the traffic and other trends. This data is collected based on “implied consent”, where we assume that by using the website, you choose to agree with our terms of usage until you choose to do otherwise.
        <br /><br />
        Cookies are used by us when you use our products and services. These can also be used for showing you advertisements based on your interest.
        <br /><br />
        You can easily opt out of sharing your information through cookies, by changing your browser settings to “do not accept cookies”, browsing in anonymous mode or by not using our website.
        <br /><br />
        Our website also has links to third party sites and services that may use third party tools. The privacy policy of these sites may be different from ours and we are not responsible for their compliance with their policies. Cookies may use certain third party tools to collect user information.
        
    </p>

    {/* cookies end  */}

    {/* google analytics start  */}

    <h3 className='vib-res-left vib-sub-sub-heading vib-res-margin-top-50-50'>Google Analytics</h3>

    <p className='vib-bold-para vib-res-left font-weight-300'>
        We may collect data about your device/browser, IP address and onsite activities in order to analyze and report about your interactions on our website. We may use this data to enhance the user experience on our site. We do not collect your personally identifiable information using cookies. To understand how Google analytics handles your data, read their privacy policy.
        <br /> <br />
        Vibtree may keep a cached copy of the content you serve us as a part of your use of the service for an indeterminate amount of time, including media files and XML files. To assist in development, we may keep copies of HTTP requests and responses in your notification log, available only to you.
        <br /> <br />
        If your web server does not specify that we can cache your content, we will not keep a cached copy of any text or audio data transmitted through our service during calls, but we may still maintain copies in your notification log. If data privacy is a concern, make sure your server does not allow us to cache such content, and contact us so that we may turn off other application logging around requests to and responses from your application. We may keep call log, notification log, billing/ payment, and other log data for at least six months from the date of entry.
    </p>

    {/* google analytics end  */}

    {/* how the information is used start  */}
    <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>How The Information Is Used?</h2>

    <p className='vib-bold-para vib-res-left font-weight-300'>
        We collect, store and use your data for our internal purposes and service delivery, that are entirely covered by the law. We may use your and/or your Authorized User(s) Information for different purposes, as described below-
        <br /><br />

        <ol className='pp-information-list vib-res-left' style={{textAlign:'left !important'}}>
            <li>To register and allow you and/or your Authorized User(s) to use our product and services;</li>
            <li>Granting you and/or your Authorized User(s) access to our Websites, Products and services from third parties;</li>
            <li>Understanding your and/or your Authorized User(s) use of our Website and Product for improved overall use;</li>
            <li>For sending real-time notifications through SMS and/or e-mail regarding missed calls; Call analytics like understanding how your business functions, for providing training and analyzing call patterns, call reporting, agent productivity, team efficiency etc.</li>
            <li>For providing actionable insights like details about the key metrics to optimize your team’s effort and to better understand your and/or your Authorized User(s)’ customer behavior;</li>
            <li>Furnishing access to Customer Data (as defined in Terms of Use) through the customer relationship management tool;</li>
            <li>Providing call making facility from a browser extension offered by us;</li>
            <li>Notification of technical updates or changes in our policies;</li>
            <li>Improvement of the content and functionality of our Website and Product; and/or</li>
            <li>Informing you about our websites, product and services either directly or through a third party.</li>
        </ol>
        
        <br /><br />

            Note: <br />
            <strong style={{color:'#464646',fontWeight:"600"}}>Why do we Collect Personal Data?</strong> 
            <br /><br />
            To give you secure access to our products and services, and this can be done only through your personally identifiable information. <br />
            We also need to understand how you use our product and what are your expectations so that we can continuously work on improving your experience with us.

    </p>
    {/* how the information is used end  */}

    {/* Information shareing and disclosing start */}
    <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>Information Sharing & Disclosing</h2>
    <p className='vib-bold-para vib-res-left font-weight-300'>

            We do not share, rent or sell your data to third parties, except where-
            <br /><br />
            <ul className='pp-information-list vib-res-left' style={{textAlign:'left !important'}}>
                <li>Your consent is obtained explicitly.</li>
                <li>It is mandated by law.</li>
                <li>A third-party is a collaborator or part of Vibtree.</li>
                <li>To provide requisite data to third-party that helps us in delivering our services.</li>
            </ul>
            <br />
            We consider the information provided by you to be a vital part of our relationship with you. There are, however, certain circumstances in which we may share your personally identifiable information with certain third parties without further notice to you, as mentioned below-

    </p>

    {/* Information shareing and disclosing end */}
    
    {/* thirs party application start  */}
    <h3 className='vib-res-left vib-sub-sub-heading vib-res-margin-top-50-50'>Third Party Applications and Services</h3>

    <p className='vib-bold-para vib-res-left font-weight-300'>
            If you choose to use one or more third party applications that work together with Vibtree's service, those applications may upon your permission be given access to data (which may include PII) on our system, generated in connection to your use of our service. Please remember that Vibtree is not responsible for the policies and practices of third party application providers, and we shall not be responsible for how these third party applications choose to use your data. We strictly advise you to go through their privacy policy before giving them permission regarding your data, information or otherwise.
    </p>
    {/* thirs party application end  */}
  
    {/* business transfer start  */}
    <h3 className='vib-res-left vib-sub-sub-heading vib-res-margin-top-50-50'>Business Transfers</h3>

    <p className='vib-bold-para vib-res-left font-weight-300'>
    As we grow our business, we may choose to sell or buy assets or businesses, and in an event of a corporate merger, sale, dissolution, reorganization or any such event, your PII may be a part of assets being transferred. By agreeing to this policy, you confirm that any such transfers may happen and that the new acquirer or successor of Vibtree may continue using your information.
    </p>
    {/* business transfer end  */}

    {/* Agents, Consultants start  */}
    <h3 className='vib-res-left vib-sub-sub-heading vib-res-margin-top-50-50'>
    Agents, Consultants and Related Third Parties: 
    </h3>

    <p className='vib-bold-para vib-res-left font-weight-300'>
    Vibtree, like other businesses, may use the services of a third-party for 
    certain business operations. Examples may include- mailing services, 
    database maintenance and payment processing. We may also employ other 
    companies to perform similar operations and provide them with the 
    information that is needed to perform their functions.
    </p>
    {/* Agents, Consultants end  */}

    {/* Legal Requirements start  */}
    <h3 className='vib-res-left vib-sub-sub-heading vib-res-margin-top-50-50'>
    Legal Requirements and Other Circumstances:
    </h3>

    <p className='vib-bold-para vib-res-left font-weight-300'>
    Vibtree may disclose your PII if needed by the law or in the good faith belief that such actions are necessary to
    <br /><br />
    <ol className='pp-information-list vib-res-left' type='I' style={{textAlign:'left !important'}}>
        <li>Comply with a legal obligation, </li>
        <li>Protect and defend the rights or property of Vibtree,</li>
        <li>Act in urgent circumstances to protect the personal safety of users of our website or services or the public, or</li>
        <li>Protect against legal liability</li>
    </ol>
    </p>
    {/* Legal Requirements end  */}

    {/* Children’s Privacy start  */}
    <h3 className='vib-res-left vib-sub-sub-heading vib-res-margin-top-50-50'>
    Children’s Privacy
    </h3>
    <p className='vib-bold-para vib-res-left font-weight-300'>
    Vibtree is not intended for or directed to users under the age of 18. We do 
    not knowingly use our services to collect data from or market to children 
    below the age of 18. If we become aware that any personally identifiable 
    information about persons below 18 years of age has been shared with us, 
    we will remove or delete the concerned information within a reasonable time.
    </p>
    {/* Children’s Privacy end  */}

    {/* Related Companies: start  */}
    <h3 className='vib-res-left vib-sub-sub-heading vib-res-margin-top-50-50'>
    Related Companies:
    </h3>
    <p className='vib-bold-para vib-res-left font-weight-300'>
    We may choose to share your PII with any entity that is affiliated with us 
    for purposes consistent with this Privacy Policy.
    </p>
    {/* Related Companies: end  */}

    {/* The Security of Your start */}
    <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>The Security of Your Information</h2>
    <p className='vib-bold-para vib-res-left font-weight-300'>
    We are responsible for taking the required precautions to protect your personally 
    identifiable information from any misuse, loss, unauthorized access, leak, disclosure, 
    manipulation, and destruction. <br />
    We follow widely accepted industry standards to protect your personally identifiable 
    information submitted to us, both during transmission and upon receipt. No method or 
    mode of transmission over the Internet or electronic storage is, however, 100% secure. 
    Therefore, while we strive to use commercially acceptable means to protect your personally 
    identifiable information, we cannot guarantee its absolute security.
    </p>
    {/* The Security of Your end */}

    {/* Comments and Forums start  */}
    <h3 className='vib-res-left vib-sub-sub-heading vib-res-margin-top-50-50'>
    Comments and Forums
    </h3>
    <p className='vib-bold-para vib-res-left font-weight-300'>
    If you choose to use any forum or post comments on our website, then any personally 
    identifiable information that you submit or share there can be read, collected, or 
    used by other users of those forums, and could be used to send you unsolicited messages. 
    Vibtree will not be liable for any personally identifiable information you choose to submit 
    in that context or anything that arises from such submissions.
    </p>
    {/* Comments and Forums end  */}

    {/* Links to Third Party Sites start  */}
    <h3 className='vib-res-left vib-sub-sub-heading vib-res-margin-top-50-50'>
    Links to Third Party Sites
    </h3>
    <p className='vib-bold-para vib-res-left font-weight-300'>
    Our website may contain links to other third party sites that may not be owned 
    or controlled by us. Vibtree is not responsible for the privacy policies of such 
    sites. This privacy policy applies
    only to the information collected by this 
    website and not any other third-party website that you may get directed to if you 
    click on any ads or third-party links here. You are encouraged to read their 
    privacy statements before you share any information there.
    </p>
    {/* Links to Third Party Sites end  */}

    {/* Your Consent to Our Privacy start  */}
    <h3 className='vib-res-left vib-sub-sub-heading vib-res-margin-top-50-50'>
    Your Consent to Our Privacy
    </h3>
    <p className='vib-bold-para vib-res-left font-weight-300'>
    By continuing to use our website, you are agreeing to these privacy policies. 
    Our Terms of Service shall precede over any conflicts in the Privacy Policy 
    provision. At our sole discretion we may choose to change this Privacy 
    Policy from time to time, without any prior notification whatsoever. It is 
    your responsibility to revisit these policies from time to time. Your continued 
    usage of this website or our services will constitute your agreement to these 
    privacy policies.
    <br /><br />
    We hold the right to make changes to our privacy policy at our sole discretion 
    and it is the sole responsibility of the user to check this section 
    for regular updates.
    </p>
    {/* Your Consent to Our Privacy end  */}

    {/* What You Can Do? start  */}
    <h3 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
    What You Can Do?
    </h3>
    <p className='vib-bold-para vib-res-left font-weight-300'>
    You as a user have complete control over the information you provide us 
    and if you want us to use your data. At any time, you can choose-

    <ul className='pp-information-list vib-res-left' style={{textAlign:'left !important'}}>
        <li>Not to share your information with us by simply not filling out the contact form and not using our chat support.</li>
        <li>To unsubscribe from our mailing list and notifications to stop receiving any kind of communication from our side.</li>
        <li>To change your consent, provided earlier for specific usage of your data.</li>
        <li>To entirely stop using our website or any other services if you do not agree with our policy of privacy.</li>
        <li>To contact us if you have any queries regarding our privacy policy on 
            <a className="vib-h-b-u" href="mailto:info@vibtree.com"> info@vibtree.com</a></li>
    </ul>

    </p>
    {/* What You Can Do? end  */}

    {/* contact us start  */}
    <p className='vib-bold-para vib-res-left font-weight-300 vib-res-margin-top-50-50'>
    <span className='vib-blue'><strong>Contact Us: </strong></span><br />
    <strong>Vibtree Technologies LLP</strong><br />
    2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, New town, Kolkata, West Bengal<br /> 700161
    <br /><br />
    <span className='vib-blue'><strong>Phone:</strong> </span> <a  className="vib-h-b-u" href="tel:18005724590"> 1800 572 4590 </a> <br /><br />
    <span className='vib-blue'><strong>Email:</strong> </span><a className="vib-h-b-u" href="mailto:info@vibtree.com"> info@vibtree.com</a>
    <br /><br />By continuing to use our website, you agree with our policies.
    </p>

    {/* contact us end  */}

    </div>
  )
}
