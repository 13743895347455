import { Button } from '@mui/material';
import { textAlign } from '@mui/system';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap'
import { Helmet } from 'react-helmet';
import StickyHeadTableEnterprise from './EnterpriseTable';
import MobileCards from './MobileCards';
import './pricing.css'
import PricingSlider from './PricingSlider';
import StickyHeadTableProfesional from './ProfessionalTable';
import StickyHeadTableStandard from './StandardTable';
import StickyHeadTableStarter from './StarterTable';
import StickyHeadTable from './Table';
import Group from './../../images/Group.png'

function getWindowDimensions() {
    const { innerWidth: widthprice, innerHeight: height } = window;
    return {
        widthprice,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


export default function Pricingmobile() {

    const [inrState, setInrState] = useState(false);
    const { height, widthprice } = useWindowDimensions();

    // scroll to top start 
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        scrollToTop();
    }, []);
    // scroll to top end





    // faq section end 


    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);

    return (
        <>
            <Helmet>
                <title>Vibtree Pricing: Checkout our pricing plans & choose the best.</title>
                {/* <link rel="icon" href={favicon} /> */}
                <meta name="description" content="Take a look at different pricing plans for Vibtree cloud telephony services and get a custom quote for your custom selection based on your business needs. " />
                <link rel="canonical" href="https://www.vibtree.com/pricing/" />
                <script type="application/ld+json">
                    {`{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "Vibtree Technologies",
            "legalName": "Vibtree Technologies",
            "alternateName": "www.vibtree.com",
            "description": "Take a look at different pricing plans for Vibtree cloud telephony services and get a custom quote for your custom selection based on your business needs. ",
            "url": "https://www.vibtree.com/pricing/",
            "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
            "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "18005724590",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/vibtree",
              "https://twitter.com/vibtree",
              "https://www.instagram.com/vibtree",
              "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
              "https://www.linkedin.com/company/vibtree",
              "https://www.vibtree.com"
            ]}`}
                </script>

                <script type="application/ld+json">
                    {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/pricing/",
                        "name": "pricing"
                    }
                }]
            }`}
                </script>

                <script type="application/ld+json">
                    {`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "How does a free trial work?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our free trial provides you with 
            a fully functional plan for 7 days. You'll find that there may be some temporary 
            limitations on the software regarding the usage. You get the full experience of 
            all the features of our cloud telephony software based on the features you wish 
            to use. Your free trial experience will help you find exactly what you get when 
            you start using our services for your business."
              }
            }, {
              "@type": "Question",
              "name": "What happens after my free trial ends?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "After your 7-day free trial, 
            you will be automatically downgraded to our basic free plan, if you do not 
            upgrade to a paid plan of your choice. If you choose to keep your paid plan, 
            you will need to make the payment and complete your account registration process."
              }
            }, {
              "@type": "Question",
              "name": "Can I upgrade or downgrade plans anytime?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes. Vibtree is a pay-as-you-go and 
            cancel anytime service. You can upgrade, downgrade or cancel at anytime. 
            Go to 'pricing' section on your profile to change or cancel your plan anytime."
              }
            }, {
              "@type": "Question",
              "name": "Do I have to sign a long-term contract?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Absolutely not! You are not 
            obligated to sign a long-term contract to ensure your commitment. 
            We encourage you to pay for what you use, when you use it. Our services 
            can be easily scaled up or down based on the changing needs of your business. 
            You can cancel anytime you wish to, without owing us a single penny."
              }
            }, {
              "@type": "Question",
              "name": "What types of payment do you accept?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We accept all major credit and 
            debit cards including Visa, Master card, American Express and others. 
            If you wish to make payment using any other mode, please write to 
            us on info@vibtree.com."
              }
            }, {
              "@type": "Question",
              "name": "Do I need to enter my credit card information to start the free trial?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "No. You can sign up and use Vibtree cloud telephony services for 
                7 days without entering your credit card details. At the end of your trial, 
                or when you decide to get on board with us, you will need to pick a payment option."
              }
            }, {
              "@type": "Question",
              "name": "Are there any set up fees?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "There are no hidden charges or set up fees with any of our plans."
              }
            }, {
              "@type": "Question",
              "name": "Will my data be private and safe?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We take data security very seriously 
                         at Vibtree. Our servers are hosted in a top-notch data center that 
                         is protected by 24-hour surveillance to make sure that our 
                         application is always updated with the 
                         most recent security patches."
              }
            }]
          }`}
                </script>
            </Helmet>
            {/* body schema tag start  */}
            <body itemScope itemType="https://schema.org/WebPage"></body>
            {/* body schema tag end  */}


            <div

                className='vib-res-margin-top-200-150 
                   vib-margin-bottom-200-100 
                  '>
                {/* <h1 className='pricing-header'>At Vibtree, you don't pay for what you don't use</h1> */}
                <h1
                    className='headpricing headprice'
                // className='pricing-header'
                >Get the Best Value for Your Money</h1>
                <p
                    className='textpricing pricingtext'
                    // className='pricing-p vib-margin-top-30-0'
                    style={{ marginTop: '10px', textAlign: "center", marginLeft: "20%", marginRight: "20%" }}>
                    Discover how Vibtree can benefit your organization with a 7-day free trial. Unlock the full potential of customer engagement workflows and see what 500+ organizations around the world have experienced.
                </p>
                <p className='clickherepricing'>
                    Looking for custom plans?&nbsp;
                    <a href="https://calendly.com/vibtree-abhishek/30min?month=2022-12"
                        target='_blank'
                        className='anchorpricing'
                    >
                        Click here to know more
                    </a>

                </p>
                {widthprice > 500 ? <>
                    <div style={{ textAlign: "center" }} className='buttonfeatures'>
                        <a href="https://app.vibtree.com/auth/register" target='_blank'>


                            <Button variant='contained' style={{ textTransform: "none" }}>Try Now</Button> </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        or &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="https://www.facebook.com/groups/vibtree/" target='_blank'><Button variant='outlined' style={{ textTransform: "none" }}>Join Our Community</Button></a>
                    </div>
                </> : <>
                    <div style={{ textAlign: "center" }} className='buttonfeatures'>
                        <a href="https://app.vibtree.com/auth/register" target='_blank'>


                            <Button variant='contained' style={{ textTransform: "none", marginBottom: "20px" }}>Try Now</Button> </a>
                        <br />
                        <a href="https://www.facebook.com/groups/vibtree/" target='_blank'><Button variant='outlined' style={{ textTransform: "none" }}>Join Our Community</Button></a>
                    </div>
                </>}
                {/* <div 
          className='pricing-selection-box 
                     vib-res-margin-top-50-50'
          style={{position:'static'}}>
           <button name='all-in-one' onClick={handleTabClick} className={ tabState === 0 ? 'pricing-active' : null } >All-in-One</button>
           <button name='call-tracking' onClick={handleTabClick} className={ tabState === 1 ? 'pricing-active' : null } >Call Tracking</button>
           <button name='contact-center' onClick={handleTabClick} className={ tabState === 2 ? 'pricing-active' : null }>Contact Centre</button>
           <button name='call-forwarding' onClick={handleTabClick} className={ tabState === 3 ? 'pricing-active' : null } >Call Forwarding</button>
      </div> */}
                <div style={{ marginBottom: "100px" }}>

                </div>
                <div className="p-i-wrapper currencycheckbox"
                    style={{ position: 'relative', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: "180px" }}>

                    {/* <input
            type="checkbox"
            className='pricing-checkbox'
            onChange={handleChange}
          />

          <input
            type="checkbox"
            className='pricing-checkbox-currency'
            onChange={handleCurrencyChange}
          /> */}

                </div>

                {/* slider start  */}
                {/* {tabState === 0 ?
          (width > 1040 ?
            <PricingSlider inrState={inrState} pricingData={!SwitchState ? monthly : yearly} width={width} /> :
            <MobileCards inrState={inrState} pricingData={!SwitchState ? monthly : yearly} width={width} />) :
          <div className="pricing-comming-soon-container">
            <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/comming-soon.png" alt="comming-soon" />
            <h2>COMING SOON</h2>
          </div>
        } */}

                {width > 1000 ? <>
                    <StickyHeadTable inrState={inrState} />
                </> : <>
                    <StickyHeadTableStarter />
                    <br />
                    <StickyHeadTableStandard />
                    <br />
                    <StickyHeadTableProfesional />
                    <br />
                    <StickyHeadTableEnterprise />
                </>}



                {/* slider end */}

                {/* choose a plan to start  */}
                {/* <div className="choose-plan-to-start-wrapper">

          <div className="days-plan vib-res-margin-top-100-50">
            <h3>07</h3>
            <h2>Days</h2>
          </div>

          <h2 className='vib-res-margin-top-50-50'>Choose a plan and start your full featured free trial</h2>
          <p className='vib-res-margin-top-30-30 vib-margin-bottom-50-0'>
            We offer all our plans with a cancel-anytime policy,
            7-day FREE trial. Use your Vibtree account to build
            custom call flows, try-out the voice quality, integrate
            with your CRM and much more. Don't worry, your trial
            account will have the same features. Get $5 worth free
            usage credit to test out all our features.
          </p>
          <a href='tel:18005724590' className="hvr-bounce-to-bottom1 design1 start-free-trail-now-button-font vib-res-margin-top-30-30"
            style={{
              backgroundColor: '#0046BB',
              color: 'black',
              fontWeight: '300',
              fontSize: '26px',
              padding: '5px',
              width: '480px',
              marginBottom: '50px'
            }}>
            START FREE TRIAL NOW!
          </a>
        </div> */}
                {/* choose a plan to end */}

                {/* vibtree benifits start  */}

                {/* <h2 className='v-b-s-h2 vib-res-margin-top-100-50 vib-margin-bottom-50-0 vibtree-benefits-padding-bottom' >Vibtree Benefits</h2>

        <div className="v-b-s-flex-container">

          <div>
            <div>
              <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/vibtree-benefits/99.9-percent-uptime.png" alt="99.9-percent-uptime" />
            </div>
            <h6>99.9% Uptime</h6>
          </div>

          <div>
            <div>
              <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/vibtree-benefits/remote-operation.png" alt="remote-operation" />
            </div>
            <h6>Remote Operations</h6>
          </div>

          <div>
            <div>
              <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/vibtree-benefits/flexible-pricing.png" alt="flexible-pricing" />
            </div>
            <h6>Flexible Pricing</h6>
          </div>

          <div>
            <div>
              <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/vibtree-benefits/crm-integration.png" alt="crm-integration" />
            </div>
            <h6>CRM Integration</h6>
          </div>

          <div>
            <div>
              <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/vibtree-benefits/go-live-in-24-hours.png" alt="crm-integration" />
            </div>
            <h6>Go Live in 24 hours</h6>
          </div>

          <div>
            <div>
              <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/vibtree-benefits/no-set-up-cost.png" alt="crm-integration" />
            </div>
            <h6>No Set-up Cost</h6>
          </div>

          <div>
            <div>
              <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/vibtree-benefits/24-7-support.png" alt="crm-integration" />
            </div>
            <h6>24x7 Support</h6>
          </div>

          <div>
            <div>
              <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/vibtree-benefits/customized-solutions.png" alt="crm-integration" />
            </div>
            <h6>Customized Solutions</h6>
          </div>

          <div>
            <div>
              <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/vibtree-benefits/cancel-anytime.png" alt="crm-integration" />
            </div>
            <h6>Cancel Anytime</h6>
          </div>
        </div> */}

                {/* vibtree benifits end  */}

                {/* faq start  */}




                {/* <h4
          className='p-faq-h4 vib-res-margin-top-100-50'
        >Frequently Asked Questions</h4>
        <div className="vib-container-narrow sms-accordin-margin-top" style={{ marginTop: '50px', marginBottom: '130px' }}>
          <Accordion defaultActiveKey="0" style={{ width: '100%', padding: '20px' }}>

            <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                onClick={handleClick1}
                style={{
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '25px',
                  fontWeight: '600',
                  boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                  overFlow: 'visible',
                  cursor: 'pointer',
                  color: 'black',
                  backgroundColor: 'white',
                }}>
                <div className="accordin-top-arrow-container">
                  <span><span style={{ fontWeight: '700' }}>Q.</span> How does a free trial work?</span>
                  <div className="accordin-right-icon"
                    style={{ transform: AccordingState === 1 ? 'rotate(90deg)' : AccordingState !== 1 ? 'rotate(0deg)' : null }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body className='vib-bold-para vib-res-left '
                  style={{
                    marginTop: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    fontSize: '23px',
                    fontWeight: '400',
                    lineHeight: '35px',
                    backgroundColor: 'none',
                    border: 'none !important'
                  }}>
                  <span style={{ fontWeight: 'bold' }}>A.</span> Our free trial provides you with
                  a fully functional plan for 7 days. You'll find that there may be some temporary
                  limitations on the software regarding the usage. You get the full experience of
                  all the features of our cloud telephony software based on the features you wish
                  to use. Your free trial experience will help you find exactly what you get when
                  you start using our services for your business.
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                onClick={handleClick2}
                style={{
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '25px',
                  fontWeight: '600',
                  boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                  overFlow: 'visible',
                  cursor: 'pointer',
                  color: 'black',
                  backgroundColor: 'white'
                }}>
                <div className="accordin-top-arrow-container">
                  <span><span style={{ fontWeight: '700' }}>Q.</span> What happens after my free trial ends?</span>
                  <div className="accordin-right-icon"
                    style={{ transform: AccordingState === 2 ? 'rotate(90deg)' : AccordingState !== 2 ? 'rotate(0deg)' : null }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body className='vib-bold-para vib-res-left '
                  style={{
                    marginTop: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    fontSize: '23px',
                    fontWeight: '400',
                    lineHeight: '35px',
                    backgroundColor: 'none',
                    border: 'none !important'
                  }}>
                  <span style={{ fontWeight: 'bold' }}>A.</span> After your 7-day free trial,
                  you will be automatically downgraded to our basic free plan, if you do not
                  upgrade to a paid plan of your choice. If you choose to keep your paid plan,
                  you will need to make the payment and complete your account registration process.
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card className='accordin-card' style={{ pointerEvent: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                onClick={handleClick3}
                style={{
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '25px',
                  fontWeight: '600',
                  boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                  overFlow: 'visible',
                  cursor: 'pointer',
                  color: 'black',
                  backgroundColor: 'white'
                }}>
                <div className="accordin-top-arrow-container">
                  <span><span style={{ fontWeight: '700' }}>Q.</span> Can I upgrade or downgrade plans anytime?</span>
                  <div className="accordin-right-icon"
                    style={{ transform: AccordingState === 3 ? 'rotate(90deg)' : AccordingState !== 3 ? 'rotate(0deg)' : null }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body className='vib-bold-para vib-res-left '
                  style={{
                    marginTop: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    fontSize: '23px',
                    fontWeight: '400',
                    lineHeight: '35px',
                    backgroundColor: 'none',
                    border: 'none !important'
                  }}>
                  <span style={{ fontWeight: 'bold' }}>A.</span> Yes. Vibtree is a pay-as-you-go and
                  cancel anytime service. You can upgrade, downgrade or cancel at anytime.
                  Go to 'pricing' section on your profile to change or cancel your plan anytime.
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                onClick={handleClick4}
                style={{
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '25px',
                  fontWeight: '600',
                  boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                  overFlow: 'visible',
                  cursor: 'pointer',
                  color: 'black',
                  backgroundColor: 'white'
                }}>
                <div className="accordin-top-arrow-container">
                  <span><span style={{ fontWeight: '700' }}>Q.</span> Do I have to sign a long-term contract?</span>
                  <div className="accordin-right-icon"
                    style={{ transform: AccordingState === 4 ? 'rotate(90deg)' : AccordingState !== 4 ? 'rotate(0deg)' : null }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body className='vib-bold-para vib-res-left '
                  style={{
                    marginTop: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    fontSize: '23px',
                    fontWeight: '400',
                    lineHeight: '35px',
                    backgroundColor: 'none',
                    border: 'none !important'
                  }}>
                  <span style={{ fontWeight: 'bold' }}>A.</span> Absolutely not! You are not
                  obligated to sign a long-term contract to ensure your commitment.
                  We encourage you to pay for what you use, when you use it. Our services
                  can be easily scaled up or down based on the changing needs of your business.
                  You can cancel anytime you wish to, without owing us a single penny.
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="4"
                onClick={handleClick5}
                style={{
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '25px',
                  fontWeight: '600',
                  boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                  overFlow: 'visible',
                  cursor: 'pointer',
                  color: 'black',
                  backgroundColor: 'white'
                }}>
                <div className="accordin-top-arrow-container">
                  <span><span style={{ fontWeight: '700' }}>Q.</span> What types of payment do you accept?</span>
                  <div className="accordin-right-icon"
                    style={{ transform: AccordingState === 5 ? 'rotate(90deg)' : AccordingState !== 5 ? 'rotate(0deg)' : null }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body className='vib-bold-para vib-res-left '
                  style={{
                    marginTop: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    fontSize: '23px',
                    fontWeight: '400',
                    lineHeight: '35px',
                    backgroundColor: 'none',
                    border: 'none !important'
                  }}>
                  <span style={{ fontWeight: 'bold' }}>A.</span> We accept all major credit and
                  debit cards including Visa, Master card, American Express and others.
                  If you wish to make payment using any other mode, please write to
                  us on <a href="mailto:info@vibtree.com" className="vib-h-b-u" style={{ color: 'inherit' }}>info@vibtree.com.</a>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="5"
                onClick={handleClick6}
                style={{
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '25px',
                  fontWeight: '600',
                  boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                  overFlow: 'visible',
                  cursor: 'pointer',
                  color: 'black',
                  backgroundColor: 'white'
                }}>
                <div className="accordin-top-arrow-container">
                  <span><span style={{ fontWeight: '700' }}>Q.</span> Do I need to enter my credit card information to start the free trial?</span>
                  <div className="accordin-right-icon"
                    style={{ transform: AccordingState === 6 ? 'rotate(90deg)' : AccordingState !== 6 ? 'rotate(0deg)' : null }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body className='vib-bold-para vib-res-left '
                  style={{
                    marginTop: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    fontSize: '23px',
                    fontWeight: '400',
                    lineHeight: '35px',
                    backgroundColor: 'none',
                    border: 'none !important'
                  }}>
                  <span style={{ fontWeight: 'bold' }}>A.</span> No. You can sign up and use Vibtree cloud telephony services for 7 days without entering your credit card details. At the end of your trial, or when you decide to get on board with us, you will need to pick a payment option.
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="6"
                onClick={handleClick7}
                style={{
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '25px',
                  fontWeight: '600',
                  boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                  overFlow: 'visible',
                  cursor: 'pointer',
                  color: 'black',
                  backgroundColor: 'white'
                }}>
                <div className="accordin-top-arrow-container">
                  <span><span style={{ fontWeight: '700' }}>Q.</span> Are there any set up fees?</span>
                  <div className="accordin-right-icon"
                    style={{ transform: AccordingState === 7 ? 'rotate(90deg)' : AccordingState !== 7 ? 'rotate(0deg)' : null }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="6">
                <Card.Body className='vib-bold-para vib-res-left '
                  style={{
                    marginTop: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    fontSize: '23px',
                    fontWeight: '400',
                    lineHeight: '35px',
                    backgroundColor: 'none',
                    border: 'none !important'
                  }}>
                  <span style={{ fontWeight: 'bold' }}>A.</span> There are no hidden charges or set up fees with any of our plans.
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card className='accordin-card' style={{ pointerEnter: 'none', pointerOver: 'none', marginBottom: '10px', borderRadius: '5px', overflow: 'visible' }}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="7"
                onClick={handleClick8}
                style={{
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '25px',
                  fontWeight: '600',
                  boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
                  overFlow: 'visible',
                  cursor: 'pointer',
                  color: 'black',
                  backgroundColor: 'white'
                }}>
                <div className="accordin-top-arrow-container">
                  <span><span style={{ fontWeight: '700' }}>Q.</span> Will my data be private and safe?</span>
                  <div className="accordin-right-icon"
                    style={{ transform: AccordingState === 8 ? 'rotate(90deg)' : AccordingState !== 8 ? 'rotate(0deg)' : null }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="7">
                <Card.Body className='vib-bold-para vib-res-left '
                  style={{
                    marginTop: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    fontSize: '23px',
                    fontWeight: '400',
                    lineHeight: '35px',
                    backgroundColor: 'none',
                    border: 'none !important'
                  }}>
                  <span style={{ fontWeight: 'bold' }}>A.</span> We take data security very seriously
                  at Vibtree. Our servers are hosted in a top-notch data center that
                  is protected by 24-hour surveillance to make sure that our
                  application is always updated with the
                  most recent security patches.
                </Card.Body>
              </Accordion.Collapse>
            </Card>

          </Accordion>
        </div> */}
                {/* faq end */}

                {/* have more questions start  */}
                {/* <h6 className='p-have v-mt-150-80'>Have more questions?</h6>
        <h4 className='p-contact-our vib-margin-top-30-0'>Contact our support team now</h4>

        <div className="p-footer vib-res-margin-top-100-50">

          <div>
            <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/call-us.png" alt="call-us" />
            <div>
              <h6>Call Us</h6>
              <p>Toll Free: 1800 572 4590</p>
            </div>
          </div>

          <div>
            <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/live-chat.png" alt="live-chat" />
            <div>
              <h6>Live chat: Online</h6>
              <p>Chat with our customer support team 24x7</p>
            </div>
          </div>

          <div>
            <img src="https://vibtreedan.s3.eu-central-1.amazonaws.com/pricing/email-us.png" alt="email-us" />
            <div>
              <h6>Email Us</h6>
              <p>Email us anytime, you will receive a prompt response</p>
            </div>
          </div>

        </div> */}
                {/* have more questions end  */}

            </div>
        </>
    )
}
