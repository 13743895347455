
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { blogDetailUrl, webApi } from '../url';
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { FacebookButton, LinkedInButton, TwitterButton } from "react-social";
import { OverlayTrigger, Spinner, Toast } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Tooltip from 'react-bootstrap/Tooltip'
import './blogDetails.css'
import ReactTooltip from 'react-tooltip';

export default function BlogDetails(){
  const [reloadPage, setReloadPage] = useState(false);
  const [blogRecentList, setBlogRecentList] = useState([]);
  const [blogDetailData, setBlogDetailData] = useState([]);
  const [show, setShow] = useState(false);
  const [clap, setclap] = useState('');
  const [idClap, setidClap] = useState('');
  const [oldcountclap, setoldcountclap] = useState('');
  const [imageUrl, setimageUrl] = useState('');
  const [seoTitle, setseoTitle] = useState('');
  const [seoDescription, setseoDescription] = useState('');
  const [specificUrl, setspecificUrl] = useState('');
  const [newBlogResponse, setnewBlogResponse] = useState('');
  const [clapToast, setclapToast] = useState(false);
  const [toolTipData, settoolTipData] = useState('');
  const [loading, setloading] = useState(true);
  // eslint-disable-next-line 
  const [loading1, setloading1] = useState(true);
  const [publishedBy, setpublishedBy] = useState('');
  const [authorDetails, setauthorDetails] = useState([]);
  const [commentsList, setcommentsList] = useState([]);
  const [approvedCommentList, setapprovedCommentList] = useState('');
  const [userCommentsToApprove, setuserCommentsToApprove] = useState();
  const [blogTitleForMautic, setblogTitleForMautic] = useState('');
  const [arrowToggleState, setarrowToggleState] = useState(true);
  const [commentForm, setcommentForm] = useState({
    UserName:'',UserEmail:'',BlogComments:''
  });
  const [clapIcon, setclapIcon] = useState(false);
  const [fromWhere, setfromWhere] = useState('');
  const [viewCount, setviewCount] = useState('');
  const [blogDetailApiToggle, setblogDetailApiToggle] = useState(false);

  const { id }=useParams();

  const redirectToErrorPage=(data)=>{
    if (data.length===0) {
      window.location.href ='https://www.vibtree.com/404/'
    }
  }

  async function getBlogDetails() {
    try {
      // fetch data from a url endpoint
      setloading(true);
      const response = await axios.get(`${webApi}/blogs?specific_Url=${specificURL}`)
      setloading(false);
      redirectToErrorPage(response.data);
        // eslint-disable-next-line 
        setBlogDetailData(response.data),
        setidClap(response.data[0].id),
        setoldcountclap(response.data[0].Blog_Claps),
        setimageUrl(response.data[0].Featured_image),
        setseoTitle(response.data[0].meta_title),
        setseoDescription(response.data[0].meta_description),
        setspecificUrl(response.data[0].specific_Url),
        setpublishedBy(response.data[0].Publish_By),
        setblogTitleForMautic(response.data[0].specific_Url),
        setviewCount(`${response.data[0].blog_views}`),
        setblogDetailApiToggle(true)
    } catch(error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    const clapStateTest=localStorage.getItem(`${id}`);
    getBlogDetails();
    scrollToTop();
    if (clapStateTest==='clicked') {
      setclapIcon(true);
    } else {
     setclapIcon(false);
    }
    // eslint-disable-next-line
  }, [id]);

  //  mautic action start 
  
  const [ref_page_url, setref_page_url] = useState('');
  const [utm_source_s, setutm_source] = useState('');
  const [utm_medium_s, setutm_medium] = useState('');
  const [utm_campaign_s, setutm_campaign] = useState('');
  const [utm_term_s, setutm_term] = useState('');
  const [utm_content_s, setutm_content] = useState('');
  const [publisherid_s, setpublisherid] = useState('');
  
  let refferrerUrl=document.referrer
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  
  useEffect(() => {
    // var handleButton=()=>{
      setref_page_url(refferrerUrl)
      setutm_source(params.utm_source)
      setutm_medium(params.utm_medium)
      setutm_campaign(params.utm_campaign)
      setutm_term(params.utm_term)
      setutm_content(params.utm_content)
      setpublisherid(params.publisherid)
    // }
    // eslint-disable-next-line 
  }, []);
  
  //  mautic action end

  // var BlogTitle = id.replace(/-/g, ' ')
  const specificURL=`${blogDetailUrl}${id}/`

  const setAmPm =(hours)=>{
    return (
    hours > 12 ? 'PM' : 'AM'
    )
  }

  const remove24HourFormat =(hours)=>{
    return(
      hours > 12 ? hours - 12 : hours
    )
  }

  var currentdate = new Date(); 
  var Date_Time = currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/" 
                + currentdate.getFullYear() + " @ "  
                + remove24HourFormat(currentdate.getHours()) + ":" 
                + currentdate.getMinutes()
                + ` ${setAmPm(currentdate.getHours())}`


    useEffect(() => { 
      let viewCountLocalStorage = localStorage.getItem(`${idClap}views`)
      if(viewCountLocalStorage === 'viewed'){
        console.log(`${idClap}views`, 'viewed')
      }else
      if(viewCountLocalStorage !== 'viewed'){
        console.log('blog id', idClap)
        UpdateViewCount();
      }
    }, [viewCount])

    const UpdateViewCount = async () => {
      
      const data = {
        "blog_views" : `${ (typeof viewCount) !== 'string' ? 1 :( viewCount !== viewCount ) ? 1 : (parseInt(viewCount) + 1) }`
      }
      await axios.put(`${webApi}/blogs/${idClap}`, data)
      .then( (res)=>{
        setviewCount(`${res.data.blog_views}`);
        localStorage.setItem(`${idClap}views`,'viewed');
      })
      .catch( (res)=>{ console.log('view count error',res) } );
    }

    const getAuthorDetails = async ()=>{
      await axios.get(`${webApi}/authors?AuthorName=${publishedBy}`)
      .then((response)=>{setauthorDetails(response.data[0])})
      .catch((err)=>{console.log('authorDeatailsFetchError',err)})
    }


    useEffect(() => {
      getAuthorDetails();
      // eslint-disable-next-line 
    }, [publishedBy]);

    async function getLatestBlogs() {
      try {
        setloading1(true);
        const response = await axios.get(`${webApi}/blogs?_limit=5&Status=Publish`);
        setloading1(false);
        return (
          setBlogRecentList(response.data)
        );
      } catch(error) {
        console.log("error", error);
      }
    }
  
    const newClapCount= parseInt(oldcountclap) + 1;
    const newClapCountPayload ={
        Blog_Claps:`${newClapCount}`
      }
    
    const putClap=async()=>{
      // getBlogDetails();
      try {
        // fetch data from a url endpoint
        const reponse = await axios.put(`${webApi}/blogs/${idClap}`,newClapCountPayload);
        return (
          setnewBlogResponse(reponse.data.Blog_Claps)
        );
      } catch(error) {
        console.log(error)
      }
    }
      
      useEffect(() => {
        setnewBlogResponse('')
        // getBlogDetails();
        getLatestBlogs();
        arrowToggle();
        // getBlogDetails();
        // eslint-disable-next-line 
      }, [id])

      // const userUnAuthorisedComments=()=>{
      //   return(
      //     usernameLocal ? commentsList.filter((item)=>{return item.UserName==usernameLocal}) : null
      //     )
      //   }
        
      
      const checkUsersUnApprovedComments=(un)=>{
        
        const usernameLocal = localStorage.getItem('commented');
        setuserCommentsToApprove(un.filter((item)=>{return item.UserName===usernameLocal}))
        
      }
     
      const fetchCommentsNotApproved=async()=>{
        await axios.get(`${webApi}/comments?Status=notYetApproved`)
        .then((r)=>{setcommentsList(  r.data.filter((comment)=>{return comment.BlogId===idClap})  );
                    checkUsersUnApprovedComments(  r.data.filter((comment)=>{return comment.BlogId===idClap})  )})
        .catch((err)=>{console.log(err)})
      }

      const fetchCommentsWhichIsApproved=async()=>{
        await axios.get(`${webApi}/comments?Status=Approved`)
        .then((r)=>{setapprovedCommentList(  r.data.filter((comment)=>{return comment.BlogId===idClap})  ); })
        .catch((er)=>{console.log('arrorFromApprovedCommentsFetch',er)})
      }
 
      useEffect(() => {
        setReloadPage(false);
      }, [reloadPage])

      useEffect(() => {
        fetchCommentsNotApproved();
        fetchCommentsWhichIsApproved();
        // eslint-disable-next-line 
      }, [idClap]);
      


      const blogDetails = (item) =>{
        const testUrl = item.specific_Url
        const slicedString = testUrl.substring(0,testUrl.length-1);
        const getLastItem = slicedString.substring(slicedString.lastIndexOf('/') + 1);
        // const hyphenString = spaceString.replace(/\s+/g, '-')
        // setReloadPage(true);
        return(
         `/blog/blog-details/${getLastItem}/`
        )
     }
     
     const copyToClipBoard = async copyMe => {
       navigator.clipboard.writeText(copyMe);
    };


  // clap arrow toggle start 
  const arrowToggle=()=>{
    const clapState = localStorage.getItem(`${id}`);
    if (clapState==='clicked') {
      setarrowToggleState(false)
    } else {
      setarrowToggleState(true)
     }
  }
  // clap arrow toggle end


  //clap counting start
    const clappedFunction =()=>{
     const clapState=localStorage.getItem(`${id}`);
     if (clapState==='clicked') {
       setclap('You have already clapped!')
       setclapToast(true);
       setclapIcon(true);
       setarrowToggleState (false);
     } else {
      localStorage.setItem(`${id}`,'clicked');
      putClap();
      setarrowToggleState (false);
      setclapIcon(true);
     }
  }
  //clap counting end

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} style={{border:'none'}}>
      {toolTipData}
    </Tooltip>
  );

  // const setViewCountToLocalStorage=(viewCount2)=>{
  //   localStorage.setItem(`${id}views`,'viewed'); 
  //   return(
  //     viewCount2 === undefined ? 1 : viewCount2 === 'undefined' ? 1  : viewCount2 === null ? 1 : viewCount2
  //   )
  // }

  // const incrementCountAndSetStorage=(viewCount3)=>{
  //   localStorage.setItem(`${id}views`,'viewed'); 
  //   return(
  //     parseInt(viewCount3)+1
  //   )
  // }
  
  // const increaseCount =(viewCount1)=>{
      
  //       if (viewCount1 === undefined) {
  //         return(
  //           setViewCountToLocalStorage(viewCount1)
  //         )
  //       } else if (viewCount1 === null) {
  //         return(
  //           setViewCountToLocalStorage(viewCount1)
  //         )
  //       } else {
  //         return(
  //           incrementCountAndSetStorage(viewCount1) 
  //         )
  //       }
        
    
  //   }
  
  // const postViewCount=async()=>{
  //   await axios.put(`${webApi}/blogs/${idClap}`,{"blog_views" : increaseCount(viewCount) })
  //   .then( (res)=>{setviewCount(res.data.blog_views); console.log('view count response',res)} )
  //   .catch( (res)=>{console.log('view count error',increaseCount(viewCount) , res)} );
  // }

  // useEffect(() => {
  //   if (localStorage.getItem(`${id}views`) === null) {
  //     postViewCount();
  //   };
  // }, [blogDetailApiToggle]);

  // scroll to top start 


  const scrollToTop=()=>{
    return(
      window.scrollTo({top:0,behavior:'smooth'})
    )
  }
  // scroll to top end 

  const handleChange=(e)=>{
    e.preventDefault();
     const {name,value} = e.target
     setcommentForm(p=>{
       return(
         {...p, [name]:value}
       )
       })
  }

  const postComment=async()=>{
    await axios.post(`${webApi}/comments`, {...commentForm,Date_Time,Status:'notYetApproved',BlogId:idClap})
    .then((r)=>{console.log('postresponse',r);
                localStorage.setItem('commented',commentForm.UserName);
                setcommentForm({UserName:'',UserEmail:'',BlogComments:''}) })
    .catch((er)=>{console.log(er)});
    fetchCommentsNotApproved();
  }

  const approveTheComment=async(id)=>{
    await axios.put(`${webApi}/comments/${id}`,{Status:'Approved'})
    .then((r)=>{console.log('approveTheCommentResponse',r)})
    .catch((er)=>{console.log('approveTheCommentError',er)});
    fetchCommentsNotApproved();
    fetchCommentsWhichIsApproved();
  }

  const DeleteComment=async(id)=>{
    await axios.delete(`${webApi}/comments/${id}`)
    .then((r)=>{console.log('reponseAfterDeletingComment',r)})
    .catch((er)=>{console.log('errorFromDeletingCommenting',er)});
    fetchCommentsNotApproved();
    fetchCommentsWhichIsApproved();
  }
    
    var location = useLocation();
    useEffect(() => {
      const fromWhereState =  location.state ? location.state.fromWhere : null
      setfromWhere(fromWhereState)
    }, [location]);

    const giveRandonColorFirstLetter=(name)=>{
       const letter = name.charAt(0).toUpperCase()
       const randomColor=()=>{
         const randomNumber = Number(Math.random().toString().slice(-1));
         const colorArray = ['#CDF2CA','#FFDEFA','#F9F3DF','#FFC898','#E5DCC3','#C996CC','#90AACB','#FFF9B2','#A2CDCD','#B1E693'];
         return colorArray[randomNumber]
       }
      return[letter, randomColor()]
    }

    return(
    <span style={{overflowX:'hidden'}}>
    {/* seo start */}
          <Helmet>
        <title>{seoTitle}</title>
        {/* <link rel="icon" href={favicon} /> */}
        <meta name="description" content={seoDescription} />
        <link rel="canonical" href={specificUrl} />
        {/* og tags start  */}
        {/* <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:url" content={specificUrl} />
        <meta property="og:site_name" content="Vibtree Technologies - Award winning cloud telephony service" />
        <meta property="og:image" content={imageUrl}/>
        <meta property="og:image:secure_url" content={imageUrl} />
        <meta property="og:image:width" content="700" />
        <meta property="og:image:height" content="700" />
        <meta property="og:image:alt" content="Award winning cloud telephony service"/>
        <meta property="og:image:type" content="image/jpg/" />
        <meta name="image" property="og:image" content={imageUrl}></meta> */}
        {/* og tags end  */}
        {/* twitter tag start  */}
        {/* <meta name= "twitter:site" content="@vibtree"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content={imageUrl} /> */}
        {/* twitter tag end  */}
        <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/blog/",
                        "name": "blog"
                    }
                },{
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@id":${specificUrl},
                        "name": "blog-details"
                    }
                }]
            }`}
        </script>
        </Helmet>
        {/* body schema tag start  */}
        <body itemScope itemType="https://schema.org/WebPage"></body>
        {/* body schema tag start  */}
    {/* seo end */}
            <div className="row m-0 d-flex flex-wrap justify-content-center"  style={{overflowX:'hidden'}}>
               <div className="col-lg-9 vib-padding-bottom-100-50 cancelling-padding-blog-detail"  style={{minHeight:'800px'}}>


                    {loading===true?
                      <Spinner 
                      className='spinner-z-index'
                      animation="border" 
                      variant="primary" 
                      style={{margin:'auto',
                              position:'fixed',
                              top:'50%',
                              left:'40%'}}
                      />:
                      blogDetailData && blogDetailData.map((item) => {
                        return(
                          <span>
                            <h1 className="blog-title vib-padding-bottom-100-50 text-center">
                              {item.Blog_Title}
                            </h1>
                            <div className="row m-0 d-flex justify-content-center">
                                <div className="col-auto mx-auto d-flex justify-content-center blog-detail-padding-and-margin-removal" style={{width:'90%'}}>
                                  <img src={item.Featured_image} 
                                       className="img-fluid 
                                                  blog-img
                                                  blog-detail-main-page
                                                  blog-detail-padding-and-margin-removal
                                                  blog-mobile-image-size" 
                                       style={{maxWidth: '90vw'}}  
                                       alt={item.Blog_Title}
                                       />
                                </div>
                            </div>
                        

                           <div className="responsive-blog-detail-bar vib-res-margin-top-100-50">

                                  <span className="category-tag bd-cat mt-2">
                                  {item.Category}
                                  </span>

                                  <div className="mx-2 mt-2">
                                  <i className="bi bi-person-check"></i>
                                  <small className="text-muted pl-2">
                                  Published By: <span className="text-uppercase">{item.Publish_By}</span> 
                                  </small>
                                  </div>

                                  <div className='mx-2 mt-2'>
                                  <i className="bi bi-calendar2-check"></i>
                                  <small className="text-muted pl-2">
                                  Published On: {item.Publish_Date}
                                  </small>
                                  </div>

                                  <ul className="nav mx-2 mt-2" 
                                      style={{display:'flex',
                                              justifyContent:'space-between',
                                              width:'130px'}}>
                                    <li className="nav-item" style={{display:'flex'}}>
                                      {/* <a className="nav-link active" aria-current="page" href={item.FB_Link} style={{paddingLeft: "0"}}>
                                      <i className="bi bi-facebook"></i>
                                      </a> */}
                                      <OverlayTrigger
                                          placement="bottom"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltip}
                                          style={{border:'none'}}
                                        >
                                      <FacebookButton url={specificUrl} appId={"789393771675028"} 
                                                      className='blog-border-background-none cursor-pointer m-0'
                                                      style={{display:'flex'}}>
                                          <i className="bi bi-facebook"
                                             data-tip="Post Blog Link in Facebook"
                                            //  onMouseLeave={()=>{settoolTipData('')}}
                                          ></i>
                                      </FacebookButton>
                                      </OverlayTrigger>
                                    </li>
                                    <li className="nav-item" style={{display:'flex'}}>
                                      {/* <a className="nav-link" href={item.TW_Link}>
                                      <i className="bi bi-twitter"></i>
                                      </a> */}
                                      {/* <TwitterButton url={specificUrl} appId={"N11RrRKyIzBBnm6TC9Qv1Goo1"}> */}
                                      {/* <OverlayTrigger
                                          placement="bottom"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltip}
                                        > */}
                                      <TwitterButton url={specificUrl} className='blog-border-background-none cursor-pointer m-0'
                                                     style={{display:'flex'}}>
                                          <i className="bi bi-twitter"
                                             data-tip="Post Blog Link in Twitter"></i>
                                      </TwitterButton>
                                      {/* </OverlayTrigger> */}
                                    </li>
      
                                    <li className="nav-item" style={{display:'flex'}}>
                                      {/* <a className="nav-link" href={item.Linkedin_Link}>
                                      <i className="bi bi-linkedin"></i>
                                      </a> */}
                                      {/* <OverlayTrigger
                                          placement="bottom"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltip}
                                        > */}
                                      <LinkedInButton url={specificUrl} className='blog-border-background-none m-0'
                                                      style={{display:'flex'}}>
                                          <i className="bi bi-linkedin"
                                             data-tip="Post Blog Link in LinkedIn"></i>
                                      </LinkedInButton>
                                      {/* </OverlayTrigger> */}
                                    </li>
      
                                    <li className="nav-item" style={{display:'flex'}}>
                                      {/* <a className="nav-link" href onClick={()=>copyToClipBoard(specificUrl)}> */}
                                      {/* <OverlayTrigger
                                          placement="bottom"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltip}
                                        > */}
                                          {/* <FileCopyIcon style={{padding:'3px',color:'black'}}  
                                                        className='cursor-pointer'
                                                        onClick={()=>{copyToClipBoard(specificUrl);  setShow(true)}}
                                                        onMouseEnter={()=>{settoolTipData('Copy Blog Link')}}
                                                        /> */}
                                          <i className="bi bi-files cursor-pointer"
                                             onClick={()=>{copyToClipBoard(specificUrl);  setShow(true)}}
                                             data-tip="Copy Blog Link"
                                             ></i>
                                      {/* </OverlayTrigger> */}
                                      {/* </a> */}
                                    </li>

                                    <ReactTooltip/>
      
                                    {/* <li className="nav-item">
                                      <a className="nav-link" href={item.Insta_Link}>
                                      <i className="bi bi-instagram"></i>
                                      </a>
                                    </li> */}
                                  </ul>


                                  
                                  <div className="copy-status mx-2 mt-2" 
                                       style={{fontSize:'13px',fontWeight:'bold', display:'flex', justifyContent:'space-between', flexDirection:'row', width:'250px'}}>
                                  {/* {copySuccess} */}

                                  <div className="blog-clap-container" style={{position:'relative',overflow:'visible'}}>
                                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/blog/front-end-images/call-tracking-eye.svg'
                                           alt="eye-icon" 
                                           className="clap-img"
                                      />
                                      <div className="blog-count-number mx-3 d-flex">
                                        <p>{viewCount}</p>
                                        <p className='mx-2'>{viewCount && viewCount === '1' ? `View` : `Views` }</p>
                                      </div>
                                  </div>
                                  
                                  <div className="blog-clap-container" style={{position:'relative',overflow:'visible'}}>
                                      <img src={!clapIcon ?
                                                'https://vibtreedan.s3.eu-central-1.amazonaws.com/blog/front-end-images/outlined-clap.svg':
                                                'https://vibtreedan.s3.eu-central-1.amazonaws.com/blog/front-end-images/filled-clap.svg'} 
                                           alt="clap-icon" 
                                           className="clap-img cursor-pointer"
                                           onClick={clappedFunction}
                                           data-tip="Clap To Appreciate Blog"
                                      />
                                      <div className="blog-count-number mx-3 d-flex">
                                        <p>{newBlogResponse?newBlogResponse:item.Blog_Claps}</p>
                                        <p className='mx-2'>claps</p>
                                      </div>
                                      <img style={arrowToggleState===true ? {display:'block'} : {display:'none'}  } 
                                           className='curved-arrow-blog-detail' src='https://vibtreedan.s3.eu-central-1.amazonaws.com/blog/front-end-images/curved-arrow.svg' alt="curved-arrow" />
                                  </div>
                                  
                                  </div>

                           </div>

                           <div className="blog-detail-toast-container">

                              <Toast onClose={() => setclapToast(false)} 
                                     show={clapToast} 
                                     delay={3000} 
                                     autohide
                                     style={{display:'flex', 
                                             justifyContent:'center',
                                             alignItems:'center',
                                             margin:'0px',
                                             maxHeight:'30px'}}>
                                <Toast.Body>{clap}</Toast.Body>
                                {/* <Toast.Header 
                                             style={{display:'flex', 
                                             justifyContent:'center',
                                             alignItems:'center',
                                             marginTop:'5px'}}>
                                </Toast.Header> */}
                              </Toast>

                              <Toast onClose={() => setShow(false)} 
                                     show={show} 
                                     delay={3000} 
                                     autohide
                                     style={{display:'flex', 
                                             justifyContent:'center',
                                             alignItems:'center',
                                             margin:'0px',
                                             maxHeight:'30px'}}>
                                <Toast.Body>Blog Link Copied!</Toast.Body>
                                {/* <Toast.Header 
                                             style={{display:'flex', 
                                             justifyContent:'center',
                                             alignItems:'center',
                                             marginTop:'5px'}}>
                                </Toast.Header> */}
                              </Toast>

                           </div>
                          

                          <div className="row mt-lg-3">
                              <div className="col-12 mt-3 mb-4 blog-detail-content-display" style={{overflowX:'scroll'}}>
                                  {ReactHtmlParser(item.Blog_Content)}
                              </div>
                          </div>

                          <div className="row vib-justify-content-evenly mb-5">

                            {authorDetails &&
                            <div className="col-lg-12 p-0">
                            <div className="col-lg-12 card row ml-0 d-flex flex-wrap" style={{background:'#f2f2f2'}}>

                            <div className="row 
                                            vib-justify-content-evenly  
                                            mt-3 
                                            mb-3
                                            d-flex
                                            vib-blog-dir-row"
                                 style={{flexDirection:'row !important'}}>
                              <div className="col-2 
                                              p-0 
                                              d-flex 
                                              align-items-lg-start 
                                              vib-blog-align-items-center
                                              flex-column 
                                              vib-justify-content-start"
                                   style={{minWidth:'140px'}}>
                              <p className="row mb-3 mx-2" style={{fontWeight:'800'}}>Author Bio:</p>
                                <img src={authorDetails.AuthorImageUrl} 
                                     alt={authorDetails.AuthorName} 
                                     className='mx-2'
                                     style={{width:'100px',height:'100px',borderRadius:'50%'}}/>
                              </div>
                              <div className="col-10 p-0 author-bio-align-center">
                                <h5 className="row 
                                               ml-0 
                                               mt-lg-0 
                                               mt-md-3 
                                               mt-sm-3
                                               vib-res-left"
                                    style={{width:'fit-content'}} >
                                      {authorDetails.AuthorName}
                                </h5>
                                <h6 className="row 
                                               ml-0
                                               vib-res-left"
                                    style={{width:'fit-content'}} >
                                      {authorDetails.Profession}
                                </h6>
                                <p className='mb-0
                                              vib-res-left'>
                                {authorDetails.AuthorBio}
                                </p>
                              </div>
                            </div>
                            </div>
                            </div>}

                          </div>

                          <div className="row d-flex flex-column align-items-center ">


                          {/* form start  */}

                            


                          <form className='card col-lg-11 
                                           col-md-12 
                                           col-sm-12 
                                           p-lg-3 
                                           p-md-3 
                                           p-sm-0
                                           px-sm-0
                                           ml-lg-3 
                                           ml-md-0
                                           ml-sm-0 
                                           mr-lg-3 
                                           mr-md-0 
                                           mr-sm-0 
                                           mb-lg-3 
                                           mt-5
                                           d-flex
                                           flex-wrap
                                           form-container-log-detail-page' 
                          style={{height:'fit-content'}}>
                              
                              <div className="row " style={{maxWidth:'100%'}}>
                              <div className="col blog-detail-outer-border-size" style={{minWidth:'280px'}}>
                                <div className="form-group" style={{maxWidth:'100%'}}>
                                  <label htmlFor="exampleInputPassword1">Enter name</label>
                                  <input value={commentForm.UserName} name='UserName' onChange={handleChange} type="text" className="form-control" placeholder="Name"/>
                                </div>
                                <div className="form-group" style={{maxWidth:'100%'}}>
                                  <label htmlFor="exampleInputEmail1">Email address</label>
                                  <input value={commentForm.UserEmail} name='UserEmail' onChange={handleChange} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"/>
                                  <small id="emailHelp" className="form-text text-muted">We'll never publish or share your email with anyone else.</small>
                                </div>
                              </div>
   
                              <div className="col blog-detail-outer-border-size" style={{minWidth:'280px'}}>
                                <div className="form-group">
                                  <label htmlFor="exampleFormControlTextarea1">Comment:</label>
                                  <textarea value={commentForm.BlogComments} name='BlogComments' onChange={handleChange} className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                </div>
  
                                <div 
                                   onClick={postComment}
                                   className="hvr-bounce-to-bottom1 
                                   design1 
                                   big-blog-button 
                                   vib-bold-para
                                   mb-3
                                   blog-comment-form-res-center" 
                                  //  style={{
                                  //    backgroundColor:'#0046BB',
                                  //    width:'fit-content',
                                  //    margin:'0',
                                  //    height:'40px',
                                  //    position: 'relative',
                                  //    paddingBottom:'20px',
                                  //    paddingTop:'5px',
                                  //    }}
                                   style={{
                                     backgroundColor:'#0046BB',
                                     width:'fit-content',
                                     margin:'0',
                                     position: 'relative',
                                     paddingBottom:'20px',
                                     paddingTop:'5px',
                                     textAlign:'center',
                                     height:'40px'
                                     }}
                                   >
                                      Leave a Comment
                                </div>
                                
                              </div>
                              </div>
                                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                
                              </form>

                          {/* form end  */}


                          <div className="col-lg-11 col-md-12 col-sm-12 m-1 p-0 mx-sm-auto justify-content-center" 
                               style={{maxWidth:'98%'}}>


                          { fromWhere && fromWhere === 'fromAdmin' ?
                            <div className='mb-5'>
                             <h6 className="row">Comments Pending For Approval:</h6>

                                {commentsList && commentsList.map((comment,index)=>{
                                   return(
                              <div className="card 
                                              pr-0 
                                              pl-0 
                                              pt-3
                                              pb-3 
                                              mx-0 
                                              mt-3 
                                              w-100
                                              m-auto 
                                              mb-3
                                              blog-comments-card 
                                              d-flex 
                                              flex-row
                                              flex-wrap 
                                              vib-justify-content-evenly
                                              vib-res-margin-top-50-50">

                                  <div className="blog-comments-card-left d-flex my-3" style={{maxWidth:'250px'}}>
                                      
                                      <div style={{background:giveRandonColorFirstLetter(comment.UserName)[1], width:'50px'}} 
                                           className="avatarDim mx-3">
                                           <h5 className='m-0' style={{color:'#0046bb'}}>{giveRandonColorFirstLetter(comment.UserName)[0]}</h5>
                                      </div>
   
                                      <div className="" style={{width:'fit-content'}}>
                                           <h6 className="" style={{color:'#0046bb',textAlign:'left'}}>{comment.UserName}</h6>
                                           <p className="blockquote-footer m-0 mr-0"
                                              style={{marginRight:'0px !important'}}>{comment.Date_Time}</p>
                                      </div>

                                  </div>

                                  <div className="blog-comments-card-right mx-4">
                                  <p className="m-0">
                                         {comment.BlogComments}
                                  </p>
                                  </div>

                                  <div className="row d-flex justify-content-around mb-3">
                                              <button onClick={()=>{approveTheComment(comment.id)}} type="button" className="btn btn-success mx-2 mt-2">Approve</button>
                                              <button onClick={()=>{DeleteComment(comment.id)}} type="button" className="btn btn-danger mx-2 mt-2">Delete</button>
                                  </div>
                                  
                      
                              </div>
                                 )})}



                            </div>
                          : null
                          }
                          
                          {( (approvedCommentList && approvedCommentList.length !== 0) || (userCommentsToApprove && userCommentsToApprove.length !== 0) ) && <h4 className="row mt-5" style={{textAlign:'center'}}>Comments:</h4> }


                              {userCommentsToApprove && userCommentsToApprove.map((comment,index)=>{
                                 return(
                              <div className="card 
                                              pr-0 
                                              pl-0 
                                              pt-3
                                              pb-3 
                                              mx-0 
                                              mt-3 
                                              w-100  
                                              mb-3
                                              m-auto
                                              blog-comments-card 
                                              d-flex 
                                              flex-row
                                              flex-wrap 
                                              vib-justify-content-evenly
                                              vib-res-margin-top-50-50">

                                  <div className="blog-comments-card-left d-flex my-3" style={{maxWidth:'250px'}}>
                                      
                                      <div style={{background:giveRandonColorFirstLetter(comment.UserName)[1], width:'50px'}} 
                                           className="avatarDim mx-3">
                                           <h5 className='m-0' style={{color:'#0046bb'}}>{giveRandonColorFirstLetter(comment.UserName)[0]}</h5>
                                      </div>
   
                                      <div className="" style={{width:'fit-content'}}>
                                           <h6 className="" style={{color:'#0046bb',textAlign:'left'}}>{comment.UserName}</h6>
                                           <p className="blockquote-footer m-0 mr-0"
                                              style={{marginRight:'0px !important'}}>{comment.Date_Time}</p>
                                      </div>

                                  </div>


                                  <div className="blog-comments-card-right mx-4">
                                         <p className="m-0"  style={{fontWeight:'600',color:'#C8C8C8'}}>
                                         Your Comment Will Be Posted After Moderation...
                                         </p>
                                  </div>

                                  { fromWhere && fromWhere === 'fromAdmin' ?
                                    <div className="row d-flex justify-content-around mb-3">
                                       <button onClick={()=>{DeleteComment(comment.id)}} type="button" className="btn btn-danger">Delete</button>
                                    </div>:null
                                  }
                                  
                      
                              </div>
                                 )})}

                              {approvedCommentList && approvedCommentList.map((comment,index)=>{
                                 return(
                              <div className="card 
                                              pr-0 
                                              pl-0 
                                              pt-3
                                              pb-3 
                                              mx-0 
                                              mt-3 
                                              w-100  
                                              m-auto
                                              blog-comments-card 
                                              d-flex 
                                              flex-row
                                              flex-wrap 
                                              vib-justify-content-evenly
                                              vib-res-margin-top-50-50" style={{marginBottom:'50px'}}>

                                  <div className="blog-comments-card-left d-flex my-3" style={{maxWidth:'250px'}}>
                                      
                                      <div style={{background:giveRandonColorFirstLetter(comment.UserName)[1], width:'50px'}} 
                                           className="avatarDim mx-3">
                                           <h5 className='m-0' style={{color:'#0046bb'}}>{giveRandonColorFirstLetter(comment.UserName)[0]}</h5>
                                      </div>
   
                                      <div className="" style={{width:'fit-content'}}>
                                           <h6 className="" style={{color:'#0046bb',textAlign:'left'}}>{comment.UserName}</h6>
                                           <p className="blockquote-footer m-0 mr-0"
                                              style={{marginRight:'0px !important'}}>{comment.Date_Time}</p>
                                      </div>

                                  </div>

                                  <div className="blog-comments-card-right mx-4">
                                         <p className="m-0">
                                         {comment.BlogComments}
                                         </p>
                                  </div>
                               
                                         { fromWhere && fromWhere === 'fromAdmin' ?
                                           <div className="row d-flex justify-content-around mb-3">
                                              <button onClick={()=>{DeleteComment(comment.id)}} type="button" className="btn btn-danger">Delete</button>
                                           </div>:null
                                         }
                                  
                      
                              </div>
                                 )})}
                                   

                            </div>


                          
                              {/* newsletter start  */}
                                
                              <div className="newsletter-blogdetails 
                                              col-lg-11 
                                              p-lg-5 
                                              p-md-2 
                                              p-sm-2 
                                              m-auto
                                              vib-res-margin-top-100-50"
                                   style={{border:'1px solid rgba(0,0,0,.125)'}}>
                                    <h3 className='vib-sub-sub-heading col-lg-12 mt-0'>
                                    Subscribe to Our Newsletter and stay informed
                                    </h3>
                              
                                    <p className='vib-bold-para  col-lg-12'>
                                    Keep yourself updated with the latest technology and trends with Vibtree.
                                    </p>


                                      {/* eslint-disable-next-line  */}
                                      <form 
                                         className="col-lg-12 
                                                    d-flex 
                                                    flex-column 
                                                    align-items-center 
                                                    justify-content-lg-center
                                                    news-letter-appear-small
                                                    mb-5
                                                    mt-5
                                                    px-0"
                                         autoComplete="false" 
                                         role="form" 
                                         method="post" 
                                         action="https://launcher.vibtree.in/form/submit?formId=15" 
                                         id="mauticform_vibtreesignup" 
                                         data-mautic-form="vibtreesignup" 
                                         encType="multipart/form-data" 
                                         style={{width:'100%'}}>
                                      <input 
                                      placeholder='Enter Your Email Adress' 
                                      className='form-control form-control-lg mb-3' 
                                      name="mauticform[email]"
                                      type="email" 
                                      id="mauticform_input_vibtreesignup_email"
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                      required
                                      />
                                      
                                      <input id="mauticform_input_vibtreesignup_ref_page_url" name="mauticform[ref_page_url]" value={ref_page_url}  className="mauticform-hidden" type="hidden"/>
                                      <input id="mauticform_input_vibtreesignup_service" name="mauticform[service]" value={blogTitleForMautic} className="mauticform-hidden" type="hidden"/>
                                      <input id="mauticform_input_vibtreesignup_utm_source" name="mauticform[utm_source]" value={utm_source_s} className="mauticform-hidden" type="hidden"/>
                                      <input id="mauticform_input_vibtreesignup_utm_medium" name="mauticform[utm_medium]" value={utm_medium_s} className="mauticform-hidden" type="hidden"/>
                                      <input id="mauticform_input_vibtreesignup_utm_campaign" name="mauticform[utm_campaign]" value={utm_campaign_s} className="mauticform-hidden" type="hidden"/>
                                      <input id="mauticform_input_vibtreesignup_utm_term" name="mauticform[utm_term]" value={utm_term_s} className="mauticform-hidden" type="hidden"/>
                                      <input id="mauticform_input_vibtreesignup_utm_content" name="mauticform[utm_content]" value={utm_content_s} className="mauticform-hidden" type="hidden"/>
                                      <input id="mauticform_input_vibtreesignup_publisherid" name="mauticform[publisherid]" value={publisherid_s} className="mauticform-hidden" type="hidden"></input>
                                      
                                      <input type="hidden" name="mauticform[formId]" id="mauticform_vibtreesignup_id" value="15"/>
                                      <input type="hidden" name="mauticform[return]" id="mauticform_vibtreesignup_return" value=""/>
                                      <input type="hidden" name="mauticform[formName]" id="mauticform_vibtreesignup_name" value="vibtreesignup"></input>
                                      <span className="mauticform-errormsg" style={{display: 'none'}}>This is required.</span>
                                         
                                          <button
                                              name="mauticform[submit]"
                                              id="mauticform_input_vibtreesignup_submit"
                                              className="hvr-bounce-to-bottom1 design1 foo-b-s"
                                              type="submit"
                                              style={{maxHeight:'55px',
                                                      borderRadius:'6px 6px 6px 6px',
                                                      border:'none',
                                                      padding:'2px auto 4px auto !important',
                                                      fontSize:'18px',
                                                      width:'50%'}}>
                                              Subscribe Now
                                          </button>
                                      </form>


                                     {/* eslint-disable-next-line  */}
                                       <form 
                                           className="col-lg-12 
                                                      d-flex 
                                                      flex-column 
                                                      align-items-center 
                                                      justify-content-lg-center
                                                      mt-5
                                                      news-letter-disappear"
                                           autoComplete="false" 
                                           role="form" 
                                           method="post" 
                                           action="https://launcher.vibtree.in/form/submit?formId=15" 
                                           id="mauticform_vibtreesignup" 
                                           data-mautic-form="vibtreesignup" 
                                           encType="multipart/form-data" 
                                           >
                                      


                                      <div className="input-group">
                                        <input 
                                        placeholder='Enter Your Email Adress' 
                                        className='form-control form-control-lg' 
                                        name="mauticform[email]"
                                        type="email" 
                                        id="mauticform_input_vibtreesignup_email"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        required/>
                                        <div className="input-group-append">
                                            <button
                                                name="mauticform[submit]"
                                                id="mauticform_input_vibtreesignup_submit"
                                                className="hvr-bounce-to-bottom1 design1 foo-b-s"
                                                type="submit"
                                                style={{maxHeight:'48px',
                                                        borderRadius:'0px 6px 6px 0px',
                                                        border:'none',
                                                        padding:'2px auto 4px auto !important',
                                                        fontSize:'18px',
                                                        width:'15vw'}}>
                                                Subscribe Now
                                            </button>
                                        </div>
                                      </div>


                                        <input id="mauticform_input_vibtreesignup_ref_page_url" name="mauticform[ref_page_url]" value={ref_page_url}  className="mauticform-hidden" type="hidden"/>
                                        <input id="mauticform_input_vibtreesignup_service" name="mauticform[service]" value={blogTitleForMautic} className="mauticform-hidden" type="hidden"/>
                                        <input id="mauticform_input_vibtreesignup_utm_source" name="mauticform[utm_source]" value={utm_source_s} className="mauticform-hidden" type="hidden"/>
                                        <input id="mauticform_input_vibtreesignup_utm_medium" name="mauticform[utm_medium]" value={utm_medium_s} className="mauticform-hidden" type="hidden"/>
                                        <input id="mauticform_input_vibtreesignup_utm_campaign" name="mauticform[utm_campaign]" value={utm_campaign_s} className="mauticform-hidden" type="hidden"/>
                                        <input id="mauticform_input_vibtreesignup_utm_term" name="mauticform[utm_term]" value={utm_term_s} className="mauticform-hidden" type="hidden"/>
                                        <input id="mauticform_input_vibtreesignup_utm_content" name="mauticform[utm_content]" value={utm_content_s} className="mauticform-hidden" type="hidden"/>
                                        <input id="mauticform_input_vibtreesignup_publisherid" name="mauticform[publisherid]" value={publisherid_s} className="mauticform-hidden" type="hidden"></input>
                    
                                        <input type="hidden" name="mauticform[formId]" id="mauticform_vibtreesignup_id" value="15"/>
                                        <input type="hidden" name="mauticform[return]" id="mauticform_vibtreesignup_return" value=""/>
                                        <input type="hidden" name="mauticform[formName]" id="mauticform_vibtreesignup_name" value="vibtreesignup"></input>
                                        <span className="mauticform-errormsg" style={{display: 'none'}}>This is required.</span>

                                    </form>
  

                                </div>
                              
                              {/* newsletter end  */}


                          </div>

                     
                          </span>
                        )
                    })}

                    
                   </div>
                            <div className="col-lg-3 col-md-8 col-sm-10">

                             <div className="recent-blogs sticky-top" style={{zIndex:"0"}}>
                                <h4 className='text-lg-left text-md-center text-sm-center'>Latest Blogs</h4>
                                <hr></hr>


                          {/* loading1===true?
                                        <Spinner 
                                        animation="border" 
                                        variant="primary" 
                                        style={{margin:'auto'}}
                                        />: */}
                        {
                          blogRecentList && blogRecentList.map((item) => {
                          return(
                            <Link 
                            to={blogDetails(item)}
                            className="card blog-list-card mb-lg-0 mb-md-5 mb-sm-5" 
                            style={{minHeight:'160px'}}>
                            {/* <div className="card blog-list-card mb-lg-0 mb-md-5 mb-sm-5" 
                            onClick={(event) => blogDetails(item)}
                            style={{minHeight:'160px'}}> */}
                            <span>
                              <div className="row m-0">
                                <div className="col-lg-4 p-0">
                                  <img src={item.Featured_image} className="card-img-top" alt={item.specific_Url} />
                                </div>
                                <div className="col">
                                
                            <h5 className="card-title text-lg-left text-md-center text-sm-center">{item.Blog_Title}</h5>
                            {/* <span className="category">
                            {item.Category}
                            </span> */}

                            <div className="pushlished-by-con d-flex justify-content-md-center justify-content-sm-center">
                            <i className="bi bi-person-check"></i>
                                
                                <small className="text-muted pl-2">
                                Published By: <span className="text-uppercase">{item.Publish_By}</span> 
                                </small>
                            </div>
                            <hr></hr>
                                </div>
                              </div>
                            </span>
                            </Link>
                        )
                        })}

                             {/* eslint-disable-next-line  */}
                             <form 
                                 className="col-lg-12 
                                            d-flex 
                                            flex-column 
                                            align-items-center 
                                            justify-content-lg-center
                                            mb-5
                                            mt-5
                                            px-0"
                                 autoComplete="false" 
                                 role="form" 
                                 method="post" 
                                 action="https://launcher.vibtree.in/form/submit?formId=15" 
                                 id="mauticform_vibtreesignup" 
                                 data-mautic-form="vibtreesignup" 
                                 encType="multipart/form-data" 
                                 >
                              <input 
                              placeholder='Enter Your Email Adress' 
                              className='form-control form-control-lg mb-3' 
                              name="mauticform[email]"
                              type="email" 
                              id="mauticform_input_vibtreesignup_email"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              required
                              />

                              <input id="mauticform_input_vibtreesignup_ref_page_url" name="mauticform[ref_page_url]" value={ref_page_url}  className="mauticform-hidden" type="hidden"/>
                              <input id="mauticform_input_vibtreesignup_service" name="mauticform[service]" value={blogTitleForMautic} className="mauticform-hidden" type="hidden"/>
                              <input id="mauticform_input_vibtreesignup_utm_source" name="mauticform[utm_source]" value={utm_source_s} className="mauticform-hidden" type="hidden"/>
                              <input id="mauticform_input_vibtreesignup_utm_medium" name="mauticform[utm_medium]" value={utm_medium_s} className="mauticform-hidden" type="hidden"/>
                              <input id="mauticform_input_vibtreesignup_utm_campaign" name="mauticform[utm_campaign]" value={utm_campaign_s} className="mauticform-hidden" type="hidden"/>
                              <input id="mauticform_input_vibtreesignup_utm_term" name="mauticform[utm_term]" value={utm_term_s} className="mauticform-hidden" type="hidden"/>
                              <input id="mauticform_input_vibtreesignup_utm_content" name="mauticform[utm_content]" value={utm_content_s} className="mauticform-hidden" type="hidden"/>
                              <input id="mauticform_input_vibtreesignup_publisherid" name="mauticform[publisherid]" value={publisherid_s} className="mauticform-hidden" type="hidden"></input>
          
                              <input type="hidden" name="mauticform[formId]" id="mauticform_vibtreesignup_id" value="15"/>
                              <input type="hidden" name="mauticform[return]" id="mauticform_vibtreesignup_return" value=""/>
                              <input type="hidden" name="mauticform[formName]" id="mauticform_vibtreesignup_name" value="vibtreesignup"></input>
                              <span className="mauticform-errormsg" style={{display: 'none'}}>This is required.</span>
                                 
                                  <button
                                      name="mauticform[submit]"
                                      id="mauticform_input_vibtreesignup_submit"
                                      className="hvr-bounce-to-bottom1 design1 foo-b-s"
                                      type="submit"
                                      style={{maxHeight:'55px',
                                              borderRadius:'6px 6px 6px 6px',
                                              border:'none',
                                              padding:'2px auto 4px auto !important',
                                              fontSize:'18px'}}>
                                      Subscribe Now
                                  </button>
                            </form>
                    </div>
                    
                    </div>
           </div>         
           
    </span>
    )
}