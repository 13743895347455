import React from 'react';
import { Link } from 'react-router-dom';
import './pagination.css'

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className='p-pagination'>
      <ul className='pagination' style={{justifyContent:'center'}}>
          <li className={`page-item ${pageNumbers[0] === currentPage ? 'disabled' : '' }`}>
          <Link className="page-link" 
                to={`/blog/blog-list/${currentPage-1}`} 
                aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </Link>
          </li>

        {pageNumbers.map(number => (
          <li key={number} 
              className={`page-item ${currentPage === number ? 'active' : ''}`}>
            {/* <a onClick={() => paginate(number)} href={`/blog/blog-list/${number}`} className='page-link'> */}
            <Link to={`/blog/blog-list/${number}`} 
               className='page-link' 
               rel="No-Refresh"
               style={{boxShadow:'none'}}>
              {number}
            </Link>
          </li>
        ))}

          <li className={`page-item ${pageNumbers[pageNumbers.length-1] === currentPage ? 'disabled' : '' }`}>
          <Link className="page-link" to={`/blog/blog-list/${currentPage+1}`} aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </Link>
          </li>

      </ul>
    </nav>
  );
};

export default Pagination;