import React, { useEffect, useState } from 'react'
import './commonThankUn.css'
import { Helmet } from 'react-helmet'


export default function UnSubscribe() {
  const [unsubscribeInput, setunsubscribeInput] = useState('');
  const [resub, setresub] = useState('');

  let email = (new URLSearchParams(window.location.search)).get("emailid");
  useEffect(() => {
    setunsubscribeInput(email)
    setresub(email)
    // eslint-disable-next-line 
  }, []);

  return (
    <div className='unsubscribe vib-res-margin-top-200-100 vib-m-b-200-200'>
    <Helmet>
    </Helmet>
      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/thank-you-and-unsubscribe/we-are-sad-to-see-you-go.svg' alt="we-are-sad-to-see-you-go" className='unsubscribe-image' style={{display:'flex',margin:'150px auto 0px auto'}}/>

      <h1 className='vib-container-sub-heading vib-res-center' 
                style={{fontSize:'50px',lineHeight:'75px',fontWeight:'700',marginTop:'50px'}}> 
                <span className='vib-blue'>We are sad to see you go!</span>
      </h1>
      {/* eslint-disable-next-line  */}
      <form className="email-box vib-res-margin-top-50-50" 
            autoComplete="false" 
            role="form" 
            method="post" 
            action="https://launcher.vibtree.in/form/submit?formId=3" 
            id="mauticform_unsubscribe" 
            data-mautic-form="unsubscribe" 
            encType="multipart/form-data"
            style={{display:'flex',margin:'50px auto 0px auto'}}>

        <label className="un-email-label mauticform-label" 
               id="mauticform_label_unsubscribe_email" 
               htmlFor="mauticform_input_unsubscribe_email">
               Email <span className='vib-red'>*</span>
        </label>

        <input className="un-emai-input mauticform-input" 
               type="email" 
               id="mauticform_input_unsubscribe_email" 
               name="mauticform[email]"
               onChange={e=>setunsubscribeInput(e.target.value)} 
               value={unsubscribeInput}/>
        <button className='un-email-button mauticform-button btn btn-default' 
                type="submit" 
                name="mauticform[submit]" 
                id="mauticform_input_unsubscribe_submit"
                >
                Unsubscribe
        </button>
        <input type="hidden" name="mauticform[formId]" id="mauticform_unsubscribe_id" value="3"/>
        <input type="hidden" name="mauticform[return]" id="mauticform_unsubscribe_return" value=""/>
        <input type="hidden" name="mauticform[formName]" id="mauticform_unsubscribe_name" value="unsubscribe"></input>
      </form>
      <h3 className='un-blue-text vib-res-margin-top-100-50'
          style={{display:'flex',margin:'50px auto 0px auto'}}>
        We have just received your request to unsubscribe. Your email address has been removed
        from our subscriber list and no additional newsletters or promotions will be sent.
      </h3>
      <h3 className='un-grey-text vib-res-margin-top-50-50'
          style={{display:'flex',margin:'50px auto 0px auto'}}>
        If you’d still like to learn about our new products release and ways to boost your business ROI
        with our services, connect with us on our social media pages.
      </h3>
      <div className="un-icons-cover-banner" 
           style={{marginTop:'35px',
                   display:'flex',
                   margin:'50px auto 0px auto',
                   textAlign:'center',
                   justifyContent:'center',
                   minHeight:'100px !important'}}>
          <a href="https://www.facebook.com/vibtree"><img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/thank-you-and-unsubscribe/facebook-icon.svg' alt="facebook-icon" /></a>
          <a href="https://www.instagram.com/vibtree/"><img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/thank-you-and-unsubscribe/instagram.svg' alt="instagram-icon" /></a>
          <a href="https://twitter.com/vibtree"><img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/thank-you-and-unsubscribe/twitter.svg' alt="twitter-icon" /></a>
          <a href="https://www.linkedin.com/company/vibtree/"><img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/thank-you-and-unsubscribe/linkedin.svg' alt="linkedin-icon" /></a>
          <a href="https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ"><img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/thank-you-and-unsubscribe/youtube.svg' alt="youtube-icon" /></a>
      </div>
      <h3 className='un-grey-text vib-res-margin-top-50-50'
          style={{display:'flex',margin:'50px auto 0px auto'}}>
        You may subscribe again by simply visiting our website, should you change your mind.
      </h3>
      <h3 className='un-grey-big-text vib-res-margin-top-50-50'
            style={{display:'flex',margin:'50px auto 0px auto'}}>
      Unsubscribed by mistake? <br />
      Re-subscribe now!
      </h3>
      {/* eslint-disable-next-line  */}
      <form className="email-box vib-res-margin-top-100-50" 
            autoComplete="false" 
            role="form" 
            method="post" 
            action="https://launcher.vibtree.in/form/submit?formId=7" 
            id="mauticform_resubscribe" 
            data-mautic-form="resubscribe" 
            encType="multipart/form-data"
            style={{display:'flex',margin:'50px auto 0px auto'}}>
        <label className="un-email-label" htmlFor="mauticform_input_resubscribe_email">Email<span className='vib-red'>*</span></label>
        <input 
               className="un-emai-input mauticform-input" 
               type="email" 
               value={resub} 
               onChange={e=>setresub(e.target.value)}
               id="mauticform_input_resubscribe_email" 
               name="mauticform[email]"
               />
        <button className='un-email-button'>Subscribe</button>
      </form>
      <h4 className='best-wishes vib-res-margin-top-100-50'
          style={{display:'flex',margin:'50px auto 150px'}}>
      Best Wishes,<br />
      Team Vibtree 
      </h4>

    </div>
  )
}
