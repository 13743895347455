import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import '../navbar.css'


export default function TalkModel(props) {
       
    const [ref_page_url, setref_page_url] = useState('');
    const [utm_source_s, setutm_source] = useState('');
    const [utm_medium_s, setutm_medium] = useState('');
    const [utm_campaign_s, setutm_campaign] = useState('');
    const [utm_term_s, setutm_term] = useState('');
    const [utm_content_s, setutm_content] = useState('');
    const [publisherid_s, setpublisherid] = useState('');
  
  
    let refferrerUrl=document.referrer
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
  
    useEffect(() => {
        setref_page_url(refferrerUrl)
        setutm_source(params.utm_source)
        setutm_medium(params.utm_medium)
        setutm_campaign(params.utm_campaign)
        setutm_term(params.utm_term)
        setutm_content(params.utm_content)
        setpublisherid(params.publisherid)
      // eslint-disable-next-line 
    }, []);
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div id="mauticform_wrapper_talktoexpert" className="mauticform_wrapper">

        {/* eslint-disable-next-line */}
        <form autoComplete="false" 
              role="form" 
              method="post" 
              action="https://launcher.vibtree.in/form/submit?formId=10" 
              id="mauticform_talktoexpert" 
              data-mautic-form="talktoexpert" 
              encType="multipart/form-data" 
              talk-to-expert>
        <div className="mauticform-error" id="mauticform_talktoexpert_error"></div>
        <div className="mauticform-message" id="mauticform_talktoexpert_message"></div>
        <div className="mauticform-innerform">

            
          <div className="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

            <div id="mauticform_talktoexpert_first_name" 
                 data-validate="first_name" 
                 data-validation-type="text" 
                 className="mauticform-row mauticform-text mauticform-field-1 mauticform-required">
                <label id="mauticform_label_talktoexpert_first_name" 
                       htmlFor="mauticform_input_talktoexpert_first_name" 
                       className="mauticform-label">
                First Name
                </label>
                <input id="mauticform_input_talktoexpert_first_name" 
                       name="mauticform[first_name]"
                       className="mauticform-input" 
                       type="text"/>
                <span className="mauticform-errormsg" style={{display:'none'}}>This is required.</span>
            </div>

            <div id="mauticform_talktoexpert_mobile" 
                 data-validate="mobile" 
                 data-validation-type="tel" 
                 className="mauticform-row mauticform-tel mauticform-field-2 mauticform-required">
                <label id="mauticform_label_talktoexpert_mobile" 
                       htmlFor="mauticform_input_talktoexpert_mobile" 
                       className="mauticform-label">
                Mobile
                </label>
                <span className="mauticform-helpmessage">Please add country code</span>
                <input id="mauticform_input_talktoexpert_mobile" 
                       name="mauticform[mobile]"
                       placeholder="91 98000 00000" 
                       className="mauticform-input" 
                       type="tel"/>
                <span className="mauticform-errormsg" style={{display: "none;"}}>This is required.</span>
            </div>

            <div id="mauticform_talktoexpert_what_is_10_plus_8" 
                 data-validate="what_is_10_plus_8" 
                 data-validation-type="captcha" 
                 className="mauticform-row mauticform-text mauticform-field-3 mauticform-required">
                <label id="mauticform_label_talktoexpert_what_is_10_plus_8" 
                       htmlFor="mauticform_input_talktoexpert_what_is_10_plus_8" 
                       className="mauticform-label">
                What is 10 plus 8?
                </label>
                <input id="mauticform_input_talktoexpert_what_is_10_plus_8" 
                       name="mauticform[what_is_10_plus_8]"
                       className="mauticform-input" 
                       type="text"/>
                <span className="mauticform-errormsg" style={{display: "none;"}}>This is required.</span>
            </div>

                <input id="mauticform_input_talktoexpert_ref_page_url" name="mauticform[ref_page_url]" value={ref_page_url} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_talktoexpert_service" name="mauticform[service]" value="navbar" className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_talktoexpert_utm_source" name="mauticform[utm_source]" value={utm_source_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_talktoexpert_utm_medium" name="mauticform[utm_medium]" value={utm_medium_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_talktoexpert_utm_campaign" name="mauticform[utm_campaign]" value={utm_campaign_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_talktoexpert_utm_term" name="mauticform[utm_term]" value={utm_term_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_talktoexpert_utm_content" name="mauticform[utm_content]" value={utm_content_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_talktoexpert_publisherid" name="mauticform[publisherid]" value={publisherid_s} className="mauticform-hidden" type="hidden"/>

            <div id="mauticform_talktoexpert_submit" 
                 className="mauticform-row mauticform-button-wrapper mauticform-field-4">
                <button type="submit" 
                        name="mauticform[submit]" 
                        id="mauticform_input_talktoexpert_submit" 
                        value="" 
                        className="mauticform-button btn btn-default">
                Submit
                </button>
            </div>
            </div>
        </div>

        <input type="hidden" name="mauticform[formId]" id="mauticform_talktoexpert_id" value="10"/>
        <input type="hidden" name="mauticform[return]" id="mauticform_talktoexpert_return" value=""/>
        <input type="hidden" name="mauticform[formName]" id="mauticform_talktoexpert_name" value="talktoexpert"/>

        </form>
        </div>
      </Modal>
    );
  }