import React, { createContext, useReducer } from 'react';
import './Blog.css';
import { Redirect, Route, Switch} from "react-router-dom";
import BlogAdminLogin from './Admin/BlogAdminLogin';
import BlogAdminMain from './Admin/BlogAdminMain';
import BlogAddComponent from './Admin/Blogs/BlogAddComponent';
import CategoryViewComponent from './Admin/Category/CategoryViewComponent';
import BlogList from './BlogList/BlogList';
import BlogEditComponent from './Admin/Blogs/BlogEditComponent';
import BlogDetails from './BlogList/BlogDetails';
import { idReducer } from 'src/reducers';
import { Helmet } from 'react-helmet';
import AuthorSection from './Admin/Blogs/AuthorSection';

export const blogContext= createContext();
const initialState= null

export default function BlogMain(){
    const [state, dispatch] = useReducer(idReducer, initialState);
    return(
        <div className='bog-main-container'>
        {/* seo start */}
        <Helmet>
        <title>Vibtree Blog - Expert Insights On Marketing, Sales & Support</title>
        {/* <link rel="icon" href={favicon} /> */}
        <meta name="description" content="Welcome to Vibtree Blog: Your go to page for insights on use of cloud telephony & call management solutions in marketing, sales & support services." />
        <link rel="canonical" href='https://www.vibtree.com/blog/' />
        <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/blog/",
                        "name": "blog"
                    }
                }]
            }`}
        </script>
        </Helmet>
        {/* body schema tag start  */}
        <body itemScope itemType="https://schema.org/WebPage"></body>
        {/* body schema tag start  */}
            {/* seo end */}
        <blogContext.Provider value={{blogState:state, blogDispatch:dispatch}}>
             <Switch>
                <Route exact path="/blog/admin/" component={BlogAdminLogin}/>
                <Route exact path="/blog/admin/BlogList/"><BlogAdminMain /></Route>
                <Route exact path="/blog/admin/CreateBlog/"><BlogAddComponent /></Route>
                <Route exact path="/blog/admin/EditBlog/:id"><BlogEditComponent /></Route>
                <Route exact path="/blog/admin/Category/"> <CategoryViewComponent /> </Route>
                <Route exact path="/blog/admin/author/"> <AuthorSection/> </Route>
                <Route exact path="/blog/admin/"> <BlogAdminLogin /> </Route>
                <Route path="/blog/blog-list/:id"> <BlogList /> </Route>
                <Route path="/blog/blog-details/:id"> <BlogDetails /> </Route>
                <Route path="/blog/"> <BlogList /> </Route>
                <Redirect to='/404/'/>
            </Switch> 
        </blogContext.Provider>
        </div>
    )
}
