import React from 'react';
import Slider from "react-slick";
import GetStarted from 'src/GetStarted';

export default function SimpleSliderCallTracking({imagesLoadIndicator}) {

var settings = {
    infinite: true,
    speed: 800,
    arrows:true,
    dots:false,
    autoplaySpeed:4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true
  };
  return (
    <Slider {...settings} style={{position:'relative',top:'150px'}} className='ct-slider'>
    <div  className="carousel-item active" style={{height:'auto'}}>
    {/* banner start  */}
        <div className="banner sms-banner" 
        style={{
          position:"relative",
          top:"-100px", 
          minHeight:"600px",
          paddingTop:"50px",
          overflow:'hidden',
          paddingLeft:"0px",
          paddingRight:'0px'}}>
          <div className="container-fluid">
            <div className="row align-items-center  vib-justify-content-evenly voice-allow-overflow vib-flex-wrap-reverse vib-justify-content-evenly">
              <div className="col-lg-7 for-padding-remove voice-allow-overflow">
              <h1 className='vib-container-heading vib-res-left voice-allow-overflow' 
              style={{marginTop:'60px',fontSize:'50px',lineHeight:'70px'}}>
              Power-Up Your Sales With<br /> The Best
              <span className="vib-blue"> Call Tracking Software</span>
              </h1>

                <GetStarted/>

                {/* <div className="row mt-3 bannerresfor" style={{Height:"300px"}}>
                  <div className="col-12 p-0 banner-logo-slider" style={{position:"relative",left:"-13px",top:"20px",maxHeight:"170px"}}>
                    <div className="responsive-banner-logos">

                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-5 ct-sli-breakpoint-img ct-constant-height margin-top-0-ct-new" 
              style={{display:'flex',justifyContent:'center',marginTop:'50px'}}>
                <img 
                      src='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/power-up-your-sales-with-best-call-tracking-data.svg'
                      onLoad={imagesLoadIndicator} 
                      className="img-fluid bannerdivimg vib-sms-left-margin-auto " 
                      alt='power-up-your-sales-with-the-best-call-tracking-software'/>
              </div>
            </div>
          </div>
        </div>
    {/* banner end  */}
      </div>

      <div  className="carousel-item active" style={{height:'auto'}}>
    {/* banner start  */}
        <div className="banner sms-banner" 
        style={{
          position:"relative",
          top:"-100px", 
          minHeight:"600px",
          paddingTop:"50px",
          overflow:'hidden',
          paddingLeft:"0px",
          paddingRight:'0px'}}>
          <div className="container-fluid padding-bottom-100px-ct-new">
            <div className="row align-items-center   voice-allow-overflow vib-flex-wrap-reverse vib-justify-content-evenly">
              <div className="col-lg-7 for-padding-remove voice-allow-overflow">
              <h1 className='vib-container-heading vib-res-left voice-allow-overflow' 
              style={{marginTop:'60px',fontSize:'50px',lineHeight:'70px'}}>
              Track the calls coming from <br />different marketing <br />
              <span className="vib-blue"> campaigns. </span>
              </h1>

              <GetStarted/>

                {/* <div className="row mt-3 bannerresfor" style={{Height:"300px"}}>
                  <div className="col-12 p-0 banner-logo-slider" style={{position:"relative",left:"-13px",top:"20px",maxHeight:"170px"}}>
                    <div className="responsive-banner-logos">

                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-5 ct-sli-breakpoint-img ct-constant-height margin-top-0-ct-new" style={{display:'flex',justifyContent:'center',marginTop:'50px'}}>
                <img 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/track-the-calls-comming-from-different-campaigning-softwares.svg' 
                     onLoad={imagesLoadIndicator} 
                     className="img-fluid bannerdivimg vib-sms-left-margin-auto " 
                     alt='track-the-calls-coming-from-different-marketing-campaigns.'/>
              </div>
            </div>
          </div>
        </div>
    {/* banner end  */}
      </div>

      <div  className="carousel-item active" style={{height:'auto'}}>
    {/* banner start  */}
        <div className="banner sms-banner" 
        style={{
          position:"relative",
          top:"-100px", 
          minHeight:"600px",
          paddingTop:"50px",
          overflow:'hidden',
          paddingLeft:"0px",
          paddingRight:'0px'}}>
          <div className="container-fluid">
            <div className="row align-items-center voice-allow-overflow vib-flex-wrap-reverse vib-justify-content-evenly">
              <div className="col-lg-7 for-padding-remove voice-allow-overflow">
              <h1 className='vib-container-heading vib-res-left voice-allow-overflow heading-extra-length' 
              style={{marginTop:'60px',fontSize:'50px',lineHeight:'70px'}}>
              Discover &
              <span className="vib-blue"> analyze </span>the most<br /> and least engaging<br /> campaigns. The Best
              </h1>

                <GetStarted/>

                {/* <div className="row mt-3 bannerresfor" style={{Height:"300px"}}>
                  <div className="col-12 p-0 banner-logo-slider" style={{position:"relative",left:"-13px",top:"20px",maxHeight:"170px"}}>
                    <div className="responsive-banner-logos">

                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-5 ct-sli-breakpoint-img ct-constant-height margin-top-0-ct-new" style={{display:'flex',justifyContent:'center',marginTop:'50px'}}>
                <img 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/discover-and-analize-the-most-and-least-engaging-campaigns-the-best.svg' 
                     onLoad={imagesLoadIndicator} 
                     className="img-fluid bannerdivimg vib-sms-left-margin-auto " 
                     alt='discover-&-analyze-the-most-and-least-engaging'/>
              </div>
            </div>
          </div>
        </div>
    {/* banner end  */}
      </div>

      <div  className="carousel-item active" style={{height:'auto'}}>
    {/* banner start  */}
        <div className="banner sms-banner" 
        style={{
          position:"relative",
          top:"-100px", 
          minHeight:"600px",
          paddingTop:"50px",
          overflow:'hidden',
          paddingLeft:"0px",
          paddingRight:'0px'}}>
          <div className="container-fluid">
            <div className="row align-items-center   voice-allow-overflow vib-flex-wrap-reverse vib-justify-content-evenly">
              <div className="col-lg-7 for-padding-remove voice-allow-overflow">
              <h1 className='vib-container-heading vib-res-left voice-allow-overflow heading-extra-length' 
              style={{marginTop:'60px',fontSize:'50px',lineHeight:'70px'}}>
               
              <span className="vib-blue"> Offer local </span>& toll-free <br />numbers to boost inbound <br />calls from ads.
              </h1>

                <GetStarted/>

                {/* <div className="row mt-3 bannerresfor" style={{Height:"300px"}}>
                  <div className="col-12 p-0 banner-logo-slider" style={{position:"relative",left:"-13px",top:"20px",maxHeight:"170px"}}>
                    <div className="responsive-banner-logos">

                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-5 ct-sli-breakpoint-img ct-constant-height margin-top-0-ct-new" 
                   style={{display:'flex',justifyContent:'center',marginTop:'50px',marginBottom:'50px'}}>
                <img 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/offer-local-and-toll-free-numbers-to-boost-inbound-calls-from-ads.svg'
                     onLoad={imagesLoadIndicator} 
                     className="img-fluid bannerdivimg vib-sms-left-margin-auto " 
                     alt='offer-local-and-toll-free-number-to-boost-inbound-calls-from-ads'/>
              </div>
            </div>
          </div>
        </div>
    {/* banner end  */}
      </div>

      <div  className="carousel-item active" style={{height:'auto'}}>
    {/* banner start  */}
        <div className="banner sms-banner" style={{position:"relative",top:"-100px", minHeight:"600px",paddingTop:"50px",overflow:'hidden'}}>
          <div className="container-fluid">
            <div className="row align-items-center   voice-allow-overflow vib-flex-wrap-reverse vib-justify-content-evenly">
              <div className="col-lg-8 for-padding-remove voice-allow-overflow">
              <h1 className='vib-container-heading vib-res-left voice-allow-overflow' 
              style={{marginTop:'60px',fontSize:'50px',lineHeight:'70px'}}>
              Provide enhanced customer <br /> experience with<span className="vib-blue"> call <br />tracking data.</span>
              </h1>

                <GetStarted/>

                {/* <div className="row mt-3 bannerresfor" style={{Height:"300px"}}>
                  <div className="col-12 p-0 banner-logo-slider" style={{position:"relative",left:"-13px",top:"20px",maxHeight:"170px"}}>
                    <div className="responsive-banner-logos">

                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-4 ct-sli-breakpoint-img ct-constant-height  margin-top-0-ct-new" style={{display:'flex',justifyContent:'center',marginTop:'50px'}}>
                <img 
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/call-tracking/provide-enhanced-customer-experience-with-call-tracking-data.svg' 
                    onLoad={imagesLoadIndicator} 
                    className="img-fluid bannerdivimg vib-sms-left-margin-auto " 
                    alt='provide-enhanced-customer-experience-with-call-tracking-data'/>
              </div>
            </div>
          </div>
        </div>
    {/* banner end  */}
      </div>
    </Slider>
  )
}
