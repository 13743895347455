import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import './about.css'

import JoinNowPopUp from './JoinNowPopUp'
import { vibContext } from 'src/App'

export default function About(){
  const [modalShow, setModalShow] = useState(false);

  const [ImagesLoadCount, setImagesLoadCount] = useState(0);
  const [loader, setloader] = useState(true);
  
  // scroll to the specific position start 
    const aboutContext= useContext(vibContext);
    const contextId=aboutContext.vibState;
    useEffect(() => {
      const element = document.getElementById(contextId);
      setTimeout(() => {
        window.scrollTo({
          behavior: element ? "smooth" : "auto",
          top: element ? element.offsetTop : 0
        });
      }, 100);
    },[contextId]);
  // scroll to the specific position end

      // image loading spinner start 
  const imagesLoadIndicator=()=>{
    const images = document.getElementsByClassName('classForLoaded');

    setImagesLoadCount(ImagesLoadCount+1);

    if (ImagesLoadCount===images.length-1) {
      setloader(false)
    }

  }
  // image loading spinner end

  return (
    <div className="about-outer-container">
        <Helmet>
        <title>Vibtree: Best Cloud Telephony Services | Contact Center Solutions</title>
        {/* <link rel="icon" href={favicon} /> */}
        <meta name="description" content="Vibtree is the best toll free number provider among the top cloud telephony companies in India for customized & reliable cloud based call center phone systems." />
        <link rel="canonical" href="https://www.vibtree.com/company/about/" />
        <script  type="application/ld+json">
        {`{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "Vibtree Technologies",
            "legalName": "Vibtree Technologies",
            "alternateName": "www.vibtree.com",
            "description": "Vibtree is the best toll free number provider among the top cloud telephony companies in India for customized & reliable cloud based call center phone systems.",
            "url": "https://www.vibtree.com/company/about/",
            "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
            "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "18005724590",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/vibtree",
              "https://twitter.com/vibtree",
              "https://www.instagram.com/vibtree",
              "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
              "https://www.linkedin.com/company/vibtree",
              "https://www.vibtree.com"
            ]}`}
        </script>

        <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "company"
                    }
                },{
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@id": "https://www.vibtree.com/company/about/",
                        "name": "about"
                    }
                }]
            }`}
        </script>
       
        </Helmet>
        {/* body schema tag start  */}
        <body itemScope itemType="https://schema.org/WebPage"></body>
        {/* body schema tag start  */}

        {/* banner start */}
            <div className="vib-container about-banner vib-top-margin" style={{paddingBottom:'70px'}}>
                <div className="row align-items-center vib-flex-wrap-reverse pb-5 pt-5">
                    <div className="col-lg-7">
        
                        <h1 className='vib-container-heading vib-res-left pt-4'>
                            Vibtree is an award winning cloud telephony service provider serving <span className="vib-blue">1000+ users </span> across <span className="vib-blue">20+ countries. </span> 
                        </h1>
                        <h5 className='vib-bold-para vib-res-left banner-heading-width' 
                            style={{fontSize:'25px',
                                    fontWeight:'400',
                                    lineHeight:'32px'}}>
                            A leader in offering enterprise-grade, cloud based business <br />phone systems for SMBs and Enterprise businesses.
                        </h5>
                    
                    </div>
                    <div className="col-lg-5 aboutFirstImg-center">
                        <img 
                             onLoad={imagesLoadIndicator} 
                             src='https://vibtreedan.s3.eu-central-1.amazonaws.com/AboutPage/cloud-telephony-service.png' 
                             className="img-fluid aboutFirstImg classForLoaded"
                             alt='cloud-telephony-service-provider-serving'/>
                    </div>
                </div>
            </div>
        {/* banner end */}
        
              <h1 className='vib-container-heading vib-res-center' style={{marginTop:'150px',marginBottom:'80px'}}>Our <span style={{color:'#0046BB'}}>Story</span></h1>
        {/* our story start  */}
            <div className="timeline max-width-fortimeline">
              <div className="timeline__component">
                <div className="timeline__date timeline__date--right" 
                  style={{
                  display:'flex',
                  justifyContent:'flex-start'
                  }}>
                    <img onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/AboutPage/idea.svg' 
                    alt="idea" 
                    className='timeline-image-width classForLoaded'
                    style={{marginLeft:'auto'}}
                    />
                </div>
              </div>
              <div className="timeline__middle">
                <div className="timeline__point"><div className="timeline-white-dot"></div></div>
              </div>
              <div className="timeline__component timeline__component--bg">
                <h2 className="vib-left vib-container-heading">Idea</h2>
                <p className="vib-bold-para vib-res-left" 
                   style={{marginTop:'0px',
                   fontWeight:'400',
                   fontSize:'20px',
                   lineHeight:'32px'}}>
                Looking at the growing need for customers to get instant 
                response for their queries or problems, through a multitude 
                of mediums that allow them to get in touch with businesses, 
                cloud telephony is the need of the hour. It is essential for 
                a business to cater to the needs of customers and track 
                their activities through different means in order to 
                understand what exactly seems to work for a brand's 
                user engagement.
                </p>
              </div>
              <div className="timeline__component_left timeline__component--bg">
                 <h2 className="vib-container-heading establishment-position" 
                 style={{textAlign:'right'}}>Establishment</h2>
                 <p className="vib-bold-para" 
                 style={{marginTop:'0px',
                         textAlign:'right',
                         fontWeight:'400',
                         fontSize:'20px',
                         lineHeight:'32px'}}>
                 Vibtree was found in 2019 with a resolve to power up customer support, 
                 facilitate remote working, enhance working in teams to speed up the 
                 response time, leading to improved customer service.
                 </p>
              </div>
              <div className="timeline__middle">
                <div className="timeline__point"><div className="timeline-white-dot"></div></div>
              </div>
              <div className="timeline__component">
                <div className="timeline__date" style={{position:'relative',top:'-50px',display:'flex',justifyContent:'flex-end'}}>
                   <img onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/AboutPage/establishment.svg'
                   alt="establishment" 
                   className='timeline-image-width classForLoaded'
                   style={{marginRight:'auto'}}
                   />
                </div>
              </div>
              <div className="timeline__component timeline__component_left">
                <div className="timeline__date timeline__date--right" style={{display:'flex',justifyContent:'flex-start'}}>
                    <img onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/AboutPage/achievements.svg'
                    alt="establishment" 
                    className='timeline-image-width classForLoaded'
                    style={{marginLeft:'auto'}}
                    />
                </div>
              </div>
              <div className="timeline__middle">
                <div className="timeline__point"><div className="timeline-white-dot"></div></div>
              </div>
              <div className="timeline__component timeline__component--bg">
                <h2 className="vib-left vib-container-heading">Achievements</h2>
                <p className="vib-bold-para vib-res-left" 
                   style={{marginTop:'0px',
                           fontWeight:'400',
                           fontSize:'20px',
                           lineHeight:'32px'}}>
                Over the years, we have become an award-winning cloud telephony service 
                provider in the country, serving more than 20+ Countries with an expert 
                team of professionals dedicated to our goals.
                </p>
                <div className="image-section-left">
                    <img onLoad={imagesLoadIndicator} 
                         className='classForLoaded' 
                         src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/banner/business.jpg' 
                         alt="business connect" />
                    <img onLoad={imagesLoadIndicator} 
                         className='classForLoaded' 
                         src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/banner/cio-review.jpg' 
                         alt="CIO Review india" />
                    <img onLoad={imagesLoadIndicator} 
                         className='classForLoaded' 
                         src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/banner/india-500.jpg' 
                         alt="india 500 startup" />
                </div>
              </div>
            </div>

            {/* mobile view start */}
            <div className="timeline-mobile-view-container mobile-appear">
             <div className="timeline-mobile">
               <div className="timeline-mobile-dot"><div className="timeline-mobile-inner-dot"></div></div>
               <h2 className="vib-res-left vib-container-heading">Idea</h2>
                <p className="vib-bold-para vib-res-left" style={{marginTop:'0px'}}>
                Looking at the growing need for customers to get instant 
                response for their queries or problems, through a multitude 
                of mediums that allow them to get in touch with businesses, 
                cloud telephony is the need of the hour. It is essential for 
                a business to cater to the needs of customers and track 
                their activities through different means in order to 
                understand what exactly seems to work for a brand's 
                user engagement.
                </p>
             </div>  

             <div className="timeline-mobile">
               <div className="timeline-mobile-dot"><div className="timeline-mobile-inner-dot"></div></div>
               <h2 className="vib-res-left vib-container-heading" style={{position:'relative'}}>Establishment</h2>
                <p className="vib-bold-para vib-res-left" style={{marginTop:'0px'}}>
                 Vibtree was found in 2019 with a resolve to power up customer support, 
                 facilitate remote working, enhance working in teams to speed up the 
                 response time, leading to improved customer service.
                </p>
             </div> 

             <div className="timeline-mobile">
               <div className="timeline-mobile-dot"><div className="timeline-mobile-inner-dot"></div></div>
               <h2 className="vib-res-left vib-container-heading">Achievements</h2>
                <p className="vib-bold-para vib-res-left" style={{marginTop:'0px'}}>
                 Over the years, we have become an award-winning cloud telephony service 
                 provider in the country, serving more than 20+ Countries with an expert 
                 team of professionals dedicated to our goals.
                </p>
                <div className="image-section">
                    <img onLoad={imagesLoadIndicator} 
                         className='classForLoaded' 
                         src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/banner/business.jpg' 
                         alt="business connect" />
                    <img onLoad={imagesLoadIndicator} 
                         className='classForLoaded' 
                         src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/banner/cio-review.jpg' 
                         alt="CIO Review india" />
                    <img onLoad={imagesLoadIndicator} 
                         className='classForLoaded' 
                         src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/banner/india-500.jpg' 
                         alt="india 500 startup" />
                </div>
             </div> 

            </div>
            {/* mobile view end*/}

        {/* our story end  */}

        {/* consistant communication start */}
        <div className="vib-container-narrow vib-res-margin-top-200-100" id='about-bottom-section-overriding-padding'>
            <div className="vib-container-heading vib-res-center" style={{textAlign:'center', width:'100%', maxWidth:'1065px', margin:'auto'}}>
            Consistent Communication <span className='vib-blue'> 'Customized'</span> For All Businesses!
            </div>
            
            
            <div className="vib-inner-section">

                <div className="vib-content-section bottom-margin-50">
                  <h1 className='vib-container-sub-heading vib-res-left'>
                  <span className='blue-under-line' style={{paddingBottom:' 15px'}}>Our</span> Vision
                  </h1>

                  <h5 className='vib-bold-para vib-res-left' 
                  style={{marginTop:'80px',
                  fontSize:'20px',
                  fontWeight:'400',
                  lineHeight:'30px'}}>
                    Our vision is to be a feature rich Cloud-Based business 
                    communication solution provider with continual progress and 
                    enhancements in our services without losing on our values. 
                    We see ourselves as an ever-growing platform that 
                    offers cloud communication solutions to industries 
                    and businesses from various walks of life.
                  </h5>
                </div>
    
                <div className="vib-img-section vib-lift-80-up negative-margin-80">
                    <img onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/AboutPage/vision.svg' alt="vision" className='vib-img classForLoaded'/>
                </div>

            </div>


            <div className="vib-inner-section-reverse">

                <div className="vib-img-section vib-lift-80-up negative-margin-80">
                    <img onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/AboutPage/mission.svg' alt="mission" className='vib-img classForLoaded'/>

                </div>

                <div className="vib-content-section bottom-margin-50">
                  <h1 className='vib-container-sub-heading vib-res-left'>
                  <span className='blue-under-line' style={{paddingBottom:' 15px'}}>Our</span> Mission
                  </h1>

                  <h5 className='vib-bold-para vib-res-left' 
                  style={{marginTop:'80px',
                          fontSize:'20px',
                          fontWeight:'400',
                          lineHeight:'30px'}}>
                    Our primary mission is to offer our customers just 
                    what they need as we are primarily driven by our quest 
                    to solve business communication problems for our customers. 
                    We love taking on new challenges to offer the best and customized 
                    cloud-based communication solution based on what a business needs.
                  </h5>
                </div>

            </div>

            <div className="vib-inner-section-reverse">

                <div className="vib-img-section">
                    <img onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/AboutPage/abhishek-gupta.png' alt="abhishek-gupta" className='vib-img classForLoaded' style={{overflow:'hidden',marginBottom:'20px'}}/>
                    <h5 className='vib-blue vib-small-heading heading-shadow'>Mr. Abhishek Gupta</h5>
                    <h5 className='vib-small-heading'>Founde<span className='blue-under-line'>r & CE</span>O</h5>
                </div>

                <div className="vib-content-section" style={{paddingBottom:'50px'}}>
                  <h1 className='vib-container-sub-heading vib-res-left'>
                  Our <span style={{color:'#0046BB'}}>CEO</span> 
                  </h1>

                  <h5 className='vib-bold-para vib-res-left'
                      style={{fontSize:'20px',
                              fontWeight:'400',
                              lineHeight:'30px'}}>
                    Having worked in the industry for over a decade, 
                    Abhishek realized that he wanted to play a bigger 
                    role in the growth story of businesses from various 
                    verticals. He wanted to be able to help organizations 
                    streamline their communication channels, offering 
                    better customer experience, facilitating remote working, 
                    enhancing customer engagement, and tracking, analyzing & 
                    monitoring their sales, support & marketing segments 
                    using cloud telephony services. <br /> <br />
                    His expertise & experience in networking solutions and 
                    applications further boosted his interest in offering 
                    state-of-the-art business communication & cloud telephony 
                    solutions. With this intent to speed up the customer 
                    support on different communication channels, 
                    Vibtree was found!
                  </h5>
                </div>

            </div>
            </div>

            {/* leader section start  */}
            <div className="padding-container" style={{backgroundColor:'#0046BB'}}>
            <div className="vib-inner-section">

                <div className="vib-content-section bottom-margin-50">
                  <h1 className='vib-container-sub-heading vib-res-left' style={{color:'#FAFAFA'}}>
                  Join Our <span style={{color:'#FFCE42'}}>Te<span className='white-under-line'>am</span></span> 
                  </h1>

                  <h5 className='vib-bold-para vib-res-left' 
                      style={{color:'#FAFAFA',
                              fontSize:'20px',
                              fontWeight:'400',
                              lineHeight:'32px'}}>
                  We are a growing team of professionals, working remotely 
                  from different parts of the country. We dedicatedly serve 
                  the needs of our customers, ensuring that they get a 
                  personalized experience and they don't overpay for the 
                  services they never use. We are looking forward to 
                  expanding our team to support more industries and 
                  businesses around the World.
                  </h5>
                  <div className="about-button-center">

                  <a href className="hvr-bounce-to-bottom1 design1" 
                      style={{
                        backgroundColor:'#FFCE42',
                        color:'black',
                        fontWeight:'300',
                        fontSize:'26px',
                        padding:'5px',
                        width:'200px',
                        marginBottom:'50px'}}
                      onClick={() => setModalShow(true)}>
                      Join Now
                  </a>
                  <JoinNowPopUp
                               show={modalShow}
                               onHide={() => setModalShow(false)}
                  />

                  </div>
                </div>
    
                <div className="vib-img-section">
                    <img onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/AboutPage/join-our-team.svg' alt="join-our-team" className='vib-img classForLoaded'/>
                </div>

            </div>
            </div>
            {/* leader section end */}
    </div>
  )
}
