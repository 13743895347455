import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

export default function ContactUsPopUp(props) {
    const [ref_page_url, setref_page_url] = useState('');
  const [utm_source_s, setutm_source] = useState('');
  const [utm_medium_s, setutm_medium] = useState('');
  const [utm_campaign_s, setutm_campaign] = useState('');
  const [utm_term_s, setutm_term] = useState('');
  const [utm_content_s, setutm_content] = useState('');
  const [publisherid_s, setpublisherid] = useState('');


  let refferrerUrl=document.referrer
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
      setref_page_url(refferrerUrl)
      setutm_source(params.utm_source)
      setutm_medium(params.utm_medium)
      setutm_campaign(params.utm_campaign)
      setutm_term(params.utm_term)
      setutm_content(params.utm_content)
      setpublisherid(params.publisherid)
    // eslint-disable-next-line 
  }, []);
  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
    <div id="mauticform_wrapper_contactus" className="mauticform_wrapper">
    {/* eslint-disable-next-line  */}
    <form autoComplete="false" 
    role="form" 
    method="post" 
    action="https://launcher.vibtree.in/form/submit?formId=14" 
    id="mauticform_contactus" 
    data-mautic-form="contactus" 
    encType="multipart/form-data" 
    contact-us>
        <div className="mauticform-error" id="mauticform_contactus_error"></div>
        <div className="mauticform-message" id="mauticform_contactus_message"></div>
        <div className="mauticform-innerform">

            
          <div className="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

            <div id="mauticform_contactus_first_name" data-validate="first_name" data-validation-type="text" className="mauticform-row mauticform-text mauticform-field-1 mauticform-required">
                <label id="mauticform_label_contactus_first_name" htmlFor="mauticform_input_contactus_first_name" className="mauticform-label">First Name</label>
                <input id="mauticform_input_contactus_first_name" name="mauticform[first_name]"  className="mauticform-input" type="text"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>

            <div id="mauticform_contactus_last_name" data-validate="last_name" data-validation-type="text" className="mauticform-row mauticform-text mauticform-field-2 mauticform-required">
                <label id="mauticform_label_contactus_last_name" htmlFor="mauticform_input_contactus_last_name" className="mauticform-label">Last Name</label>
                <input id="mauticform_input_contactus_last_name" name="mauticform[last_name]"  className="mauticform-input" type="text"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>

            <div id="mauticform_contactus_email" data-validate="email" data-validation-type="email" className="mauticform-row mauticform-email mauticform-field-3 mauticform-required">
                <label id="mauticform_label_contactus_email" htmlFor="mauticform_input_contactus_email" className="mauticform-label">Email</label>
                <input id="mauticform_input_contactus_email" name="mauticform[email]"  className="mauticform-input" type="email"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>

            <div id="mauticform_contactus_country_code" className="mauticform-row mauticform-text mauticform-field-4">
                <label id="mauticform_label_contactus_country_code" htmlFor="mauticform_input_contactus_country_code" className="mauticform-label">country code</label>
                <input id="mauticform_input_contactus_country_code" name="mauticform[country_code]"  className="mauticform-input" type="text"/>
                <span className="mauticform-errormsg" style={{display:"none"}}></span>
            </div>

            <div id="mauticform_contactus_mobile" data-validate="mobile" data-validation-type="tel" className="mauticform-row mauticform-tel mauticform-field-5 mauticform-required">
                <label id="mauticform_label_contactus_mobile" htmlFor="mauticform_input_contactus_mobile" className="mauticform-label">Mobile</label>
                <span className="mauticform-helpmessage">Please add country code</span>
                <input id="mauticform_input_contactus_mobile" name="mauticform[mobile]"  placeholder="91 98000 00000" className="mauticform-input" type="tel"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>

            <div id="mauticform_contactus_company" className="mauticform-row mauticform-text mauticform-field-6">
                <label id="mauticform_label_contactus_company" htmlFor="mauticform_input_contactus_company" className="mauticform-label">Company</label>
                <input id="mauticform_input_contactus_company" name="mauticform[company]"  className="mauticform-input" type="text"/>
                <span className="mauticform-errormsg" style={{display:"none"}}></span>
            </div>

            <div id="mauticform_contactus_comments" className="mauticform-row mauticform-text mauticform-field-7">
                <label id="mauticform_label_contactus_comments" htmlFor="mauticform_input_contactus_comments" className="mauticform-label">Comments</label>
                <textarea id="mauticform_input_contactus_comments" name="mauticform[comments]" className="mauticform-textarea"></textarea>
                <span className="mauticform-errormsg" style={{display:"none"}}></span>
            </div>

            <div id="mauticform_contactus_what_is_10_plus_8" data-validate="what_is_10_plus_8" data-validation-type="captcha" className="mauticform-row mauticform-text mauticform-field-8 mauticform-required">
                <label id="mauticform_label_contactus_what_is_10_plus_8" htmlFor="mauticform_input_contactus_what_is_10_plus_8" className="mauticform-label">What is 10 plus 12?</label>
                <input id="mauticform_input_contactus_what_is_10_plus_8" name="mauticform[what_is_10_plus_8]"  className="mauticform-input" type="text"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>

                <input id="mauticform_input_contactus_ref_page_url" name="mauticform[ref_page_url]"  className="mauticform-hidden" value={ref_page_url} type="hidden"/>
                <input id="mauticform_input_contactus_service" name="mauticform[service]"  className="mauticform-hidden" value="navbar" type="hidden"/>
                <input id="mauticform_input_contactus_utm_source" name="mauticform[utm_source]"  className="mauticform-hidden" value={utm_source_s} type="hidden"/>
                <input id="mauticform_input_contactus_utm_medium" name="mauticform[utm_medium]"  className="mauticform-hidden" value={utm_medium_s} type="hidden"/>
                <input id="mauticform_input_contactus_utm_campaign" name="mauticform[utm_campaign]"  className="mauticform-hidden" value={utm_campaign_s} type="hidden"/>
                <input id="mauticform_input_contactus_utm_term" name="mauticform[utm_term]"  className="mauticform-hidden" value={utm_term_s} type="hidden"/>
                <input id="mauticform_input_contactus_utm_content" name="mauticform[utm_content]"  className="mauticform-hidden" value={utm_content_s} type="hidden"/>
                <input id="mauticform_input_contactus_publisherid" name="mauticform[publisherid]"  className="mauticform-hidden" value={publisherid_s} type="hidden"/>
            <div id="mauticform_contactus_submit" className="mauticform-row mauticform-button-wrapper mauticform-field-17">
                <button type="submit" name="mauticform[submit]" id="mauticform_input_contactus_submit"  className="mauticform-button btn btn-default">Submit</button>
            </div>
            </div>
        </div>

        <input type="hidden" name="mauticform[formId]" id="mauticform_contactus_id" value="14"/>
        <input type="hidden" name="mauticform[return]" id="mauticform_contactus_return" />
        <input type="hidden" name="mauticform[formName]" id="mauticform_contactus_name" value="contactus"/>

        </form>
</div>
    </Modal>
  )
}
