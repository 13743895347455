import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import scrollToTop from './helper/scrollToTop';

export default function SiteMap() {

    // scroll to top start 
    useEffect(() => {
        scrollToTop();
    }, []);
    // scroll to top end


    return (
        <div style={{ marginTop: '200px', marginBottom: '150px' }}>

            <Helmet>
                <title>vibtree sitemap, call tracking, IVR studio and bulk sms</title>
                <meta name="description" content="vibtree products and about company in single page, pricing and bulk sms" />
                <link rel="canonical" href="https://www.vibtree.com/products/call-tracking/" />
            </Helmet>

            <h1 className='vib-blue' style={{ textAlign: 'center', marginBottom: '70px', fontWeight: '800' }}>SITEMAP</h1>

            <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>

                <ul className="st-products">
                    <h2 className='vib-blue'>Products</h2>
                    <li><Link to='/products/voice/'>Voice</Link></li>
                    <li><Link to='/products/bulk-sms/'>SMS</Link></li>
                    <li><Link to='/products/call-tracking/'>Call Tracking</Link></li>
                    <li><Link to='/products/ivr-studio/'>IVR Studio</Link></li>
                    <li><Link to='/products/cloud-phone/'>Cloud Phone</Link></li>
                    <li><Link to='/products/cloud-number/'>Cloud Number</Link></li>
                </ul>

                <ul className="st-company">
                    <h2 className='vib-blue'>Company</h2>
                    <li><Link to='/company/about/'>About Vibtree</Link></li>
                    <li><Link to='/company/partner-with-us/'>Partner With Us</Link></li>
                    <li><Link to='/company/work-with-us/'>Work With Us</Link></li>
                    <li><Link to='/pricing/'>Pricing</Link></li>
                    <li><Link to='/industry/'>Industry</Link></li>
                    <li><Link to='/privacy-policy/' rel='nofollow'>Privacy Policy</Link></li>
                    <li><Link to='/terms-of-use/' rel='nofollow'>Terms Of Use</Link></li>
                </ul>
                <ul className="st-company">
                    <h2 className='vib-blue'>Company</h2>
                    <li><Link to='/feature/about/'>About Vibtree</Link></li>
                    <li><Link to='/feature/partner-with-us/'>Partner With Us</Link></li>
                    <li><Link to='/feature/work-with-us/'>Work With Us</Link></li>
                    <li><Link to='/pricing/'>Pricing</Link></li>
                    <li><Link to='/industry/'>Industry</Link></li>
                    <li><Link to='/privacy-policy/' rel='nofollow'>Privacy Policy</Link></li>
                    <li><Link to='/terms-of-use/' rel='nofollow'>Terms Of Use</Link></li>
                </ul>

                <ul className="st-blogs">
                    <h2 className='vib-blue'>Blogs</h2>
                    <li><Link to='/blog/'>
                        Blog List
                    </Link></li>
                    <li><Link to='/blog/blog-details/what-is-cloud-telephony-how-does-it-work-and-benefits-to-your-business/'>
                        What Is Cloud Telephony, How Does It Work and Benefits To Your Business
                    </Link></li>
                    <li><Link to='/blog/blog-details/7-top-cloud-based-pbx-providers-2021/'>
                        7 Top Cloud-Based PBX Providers 2021
                    </Link></li>
                    <li><Link to='/blog/blog-details/tips-to-choose-the-right-business-voip-phone-service-providers/'>
                        Tips To Choose The Right Business VoIP Phone Service Providers
                    </Link></li>
                    <li><Link to='/blog/blog-details/7-ways-you-can-use-ivr-for-marketing-campaigns-and-sales/'>
                        7 Ways You Can Use IVR for Marketing Campaigns and Sales
                    </Link></li>
                    <li><Link to='/blog/blog-details/8-ways-to-boost-customer-experience-with-toll-free-number/'>
                        8 Ways To Boost Customer Experience With Toll Free Number
                    </Link></li>
                    <li><Link to='/blog/blog-details/5-industries-that-will-boom-with-the-adoption-of-cloud-telephony/'>
                        5 Industries That Will Boom With The Adoption Of Cloud Telephony
                    </Link></li>
                    <li><Link to='/blog/blog-details/call-tracking-101-a-complete-guide-for-marketers/'>
                        Call Tracking 101- A Complete Guide For Marketers
                    </Link></li>
                    <li><Link to='/blog/blog-details/streamlining-food-and-beverage-industry-with-cloud-telephony-solutions/'>
                        Streamlining Food and Beverage Industry with Cloud Telephony Solutions
                    </Link></li>
                    <li><Link to='/blog/blog-details/how-call-tracking-software-works-myths-busted!/'>
                        How Call Tracking Software Works- Myths Busted!
                    </Link></li>
                    <li><Link to='/blog/blog-details/toll-free-number:-frequently-asked-questions/'>
                        Toll Free Number: Frequently Asked Questions
                    </Link></li>
                    <li><Link to='/blog/blog-details/how-local-numbers-can-be-helpful-for-your-business/'>
                        How local numbers can be helpful for your business
                    </Link></li>
                    <li><Link to='/blog/blog-details/5-type-of-calls-that-can-be-automated-with-interactive-voice-response-(ivr)/'>
                        5 Type of Calls that can be Automated with Interactive Voice Response (IVR)
                    </Link></li>
                    <li><Link to='/blog/blog-details/top-features-of-a-modern-cloud-contact-center-software/'>
                        Top Features Of a Modern Cloud Contact Center Software
                    </Link></li>
                    <li><Link to='/blog/blog-details/ivr:-frequently-asked-questions/'>
                        IVR: Frequently Asked Questions
                    </Link></li>
                    <li><Link to='/blog/blog-details/benefits-of-adopting-cloud-telephony-for-business-in-2022/'>
                        Benefits of Adopting Cloud Telephony For Business in 2022
                    </Link></li>
                    <li><Link to='/blog/blog-details/role-of-cloud-telephony-in-education-sector/'>
                        Role of Cloud Telephony in Education Sector
                    </Link></li>
                </ul>

            </div>
        </div>
    )
}
