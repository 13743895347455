import React, {useEffect, useState} from 'react';
import axios from 'axios';
// eslint-disable-next-line
import { blogDetailUrl, webApi } from '../../url';
import {Link, useHistory} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { FacebookButton, LinkedInButton, TwitterButton } from 'react-social';
import { Spinner, Toast } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip'
import ReactTooltip from 'react-tooltip';

 export default function BlogViewComponent(){


    const auth = localStorage.getItem('vibauth-b');


    let history = useHistory();


    useEffect(() => {
        // eslint-disable-next-line
        if(auth != 'authenticated'){
            history.push("/blog/admin")
        } 
        // eslint-disable-next-line
    }, [])

    const [blogList, setBlogList] = useState([]);
    const [ showDeleteSuccess, setShowDeleteSuccess ] = useState(false);
    const [ showDeleteError, setShowDeleteError ] = useState(false);
    const [show, setShow] = useState(false);
    const [toolTipData, settoolTipData] = useState('');
    const [copyToastGlobal, setcopyToastGlobal] = useState(false);
    const [loading, setloading] = useState(false);

    async function getBlogs() {
        try {
          // fetch data from a url endpoint
          setloading(true);
          const response = await axios.get(`${webApi}/blogs`);
          setloading(false);
          return (
            setBlogList(response.data)
          );
        } catch(error) {
          console.log("error", error);
          // appropriately handle the error
        }
      }

      async function deleteBlog(id) {
        
        try {
            // fetch data from a url endpoint
            // eslint-disable-next-line
            const response = await axios.delete(`${webApi}/blogs/${id}`);
            return (
                setShowDeleteSuccess(true)
                );
            } catch(error) {
                setShowDeleteError(true)
            }
        }

        const editBlogItem = (item) =>{
           // history.push(`/blog/admin/EditBlog/${id}`)
           // history.push(`/blog/admin/EditBlog?id=${item.id}&Blog_Title=${item.Blog_Title}&Featured_image=${item.Featured_image}&Publish_By=${item.Publish_By}&Publish_Date=${item.Publish_Date}&FB_Link=${item.FB_Link}&TW_Link=${item.TW_Link}&YT_Link=${item.YT_Link}&Linkedin_Link=${item.Linkedin_Link}&Insta_Link=${item.Insta_Link}&Category=${item.Category}&Read_MoreLinks=${item.Read_MoreLinks}&Blog_Content=${item.Blog_Content}&Status=${item.Status}&Blog_Thump_Content=${item.Blog_Thump_Content}&meta_description=${item.meta_description}&meta_title=${item.meta_title}&specific_Url=${item.specific_Url}`)
           return(
               `/blog/admin/EditBlog/${item}`
           )
        }

      

      useEffect(() => {
        getBlogs();
      }, [showDeleteSuccess])

      const copyToClipBoard = async copyMe => {
        navigator.clipboard.writeText(copyMe);
       //  setCopySuccess('Blog Link Copied!');
           // alert('Blog Link Copied!');
       //  try {
       //    alert('Blog Link Copied!');
       //   navigator.clipboard.writeText(copyMe);
       //   setCopySuccess('Blog Link Copied!');
       // } catch (err) {
       //   setCopySuccess('Failed to copy!');
       // }
     };

     const readMore=(item)=>{
       const testUrl = item.specific_Url
       const slicedString = testUrl.substring(0,testUrl.length-1);
       const getLastItem = slicedString.substring(slicedString.lastIndexOf('/') + 1);  
       console.log('getLastItem',getLastItem);
       // const hyphenString = spaceString.replace(/\s+/g, '-')
       history.push({pathname:`/blog/blog-details/${getLastItem}/`,state:{fromWhere:'fromAdmin'}});
     }
 
    const separeteClick=(id)=>{
        copyToClipBoard(id.specific_Url);  
            setShow(true)
        // console.log('id>>>',id.id);
            return(
                    <Toast onClose={() => setShow(false)} 
                                     show={show} 
                                     delay={3000} 
                                     autohide
                                     style={{display:'flex', 
                                             justifyContent:'center',
                                             alignItems:'center',
                                             margin:'0px',
                                             maxHeight:'50px',
                                             maxWidth:'80px',
                                             paddingLeft:'10px'}}>
                        <Toast.Body>Blog Link Copied!</Toast.Body>
                    </Toast>
            )
            // if (item.id===id.id) {
            //     setShow(true)
            //     copyToClipBoard(id.specific_Url);
            //     return(
            //         <>
            //         <Toast onClose={() => setShow(false)} 
            //                          show={show} 
            //                          delay={3000} 
            //                          autohide
            //                          style={{display:'flex', 
            //                          justifyContent:'center',
            //                          alignItems:'center',
            //                          margin:'0px',
            //                          maxHeight:'50px',
            //                          maxWidth:'80px',
            //                          paddingLeft:'10px'}}>
            //                     <Toast.Body>Blog Link Copied!</Toast.Body>
            //                   </Toast>
            //         </>
            //     )
            // }
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          {toolTipData}
        </Tooltip>
      );
    
     return(
         <span  style={{minHeight:'100vh'}}>

            {/* global-copy-toast-start */}
            <Toast onClose={() => setcopyToastGlobal(false)} 
                   show={copyToastGlobal} 
                   delay={1000} 
                   autohide
                   style={{position:'fixed',
                           padding:'20px',
                           top:'400px',
                           left:'600px',
                           zIndex:'2000'}}>
              <Toast.Body>Blog Link Copied!</Toast.Body>
            </Toast>
            {/* global-copy-toast-end  */}

            <div className={showDeleteSuccess === true ? 'alert alert-success alert-dismissible fade show' : 'alert alert-success alert-dismissible fade d-none'} role="alert">
                <strong>Success!</strong> Blog Deleted
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className={showDeleteError === true ? 'alert alert-danger alert-dismissible fade show' : 'alert alert-danger alert-dismissible fade d-none'}  role="alert">
                <strong>Failed!</strong> Blog Delete
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

             <table className="table mt-4">
                <thead style={{background: "rgb(229, 241, 255)"}}>
                    <tr>
                    <th>#</th>
                    <th>
                        Title
                    </th>
                    <th>
                        Image
                    </th>
                    <th>
                        published by
                    </th>
                    <th>
                        Date
                    </th>
                    <th style={{minWidth:'140px'}}>
                        Share Blog Links
                    </th>
                    <th>
                        Category
                    </th>
                    <th>
                        Read more & Approve Comments
                    </th>
                    <th>
                        Content
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Action
                    </th>
                    </tr>
                </thead>
                <tbody>

                    {loading===true?
                      <Spinner 
                      animation="border" 
                      variant="primary" 
                      style={{margin:'auto',position:'fixed',left:'50%',top:'60%'}}/>:
                        blogList.map((item,index) => {
                        return(
                            <tr>
                                <td>{index+1}</td>
                                <td>{item.Blog_Title}</td>
                                <td>
                                    <img src={item.Featured_image} className="img-thumbnail" style={{maxWidth: "60px"}} alt={item.Blog_Title}/>
                                    </td>
                                <td>{item.Publish_By}</td>
                                <td>{item.Publish_Date}</td>

                                <td  style={{minWidth:'140px',
                                             display:'flex',
                                             alignItems:'center',
                                             flexDirection:'column'}}>
                                <div className="blog-view-icons-con">

                                {/* <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 0 }}
                                    overlay={renderTooltip}
                                  > */}
                                <FacebookButton url={item.specific_Url} 
                                                appId={"789393771675028"} 
                                                className='blog-border-background-none cursor-pointer'>
                                        <i className="bi bi-facebook"
                                           data-tip="Post Blog Link in Facebook">
                                        </i>
                                </FacebookButton>
                                {/* </OverlayTrigger> */}

                                {/* <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 0 }}
                                    overlay={renderTooltip}
                                  > */}
                                <TwitterButton url={item.specific_Url} 
                                               className='blog-border-background-none cursor-pointer'>
                                        <i className="bi bi-twitter" 
                                           data-tip="Post Blog Link in Twitter">
                                        </i>
                                </TwitterButton>
                                {/* </OverlayTrigger> */}

                                {/* <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 0 }}
                                    overlay={renderTooltip}
                                  > */}
                                <LinkedInButton url={item.specific_Url} className='blog-border-background-none'>
                                    <i className="bi bi-linkedin"
                                       data-tip="Post Blog Link in LinkedIn"></i>
                                </LinkedInButton>
                                {/* </OverlayTrigger> */}

                                </div>
                                
                                {/* <Toast id={item.id} onClose={() => setShow(false)} 
                                     show={show} 
                                     delay={3000} 
                                     autohide
                                     style={{display:'flex', 
                                     justifyContent:'center',
                                     alignItems:'center',
                                     margin:'0px',
                                     maxHeight:'50px',
                                     maxWidth:'80px',
                                     paddingLeft:'10px'}}>
                                <Toast.Body>Blog Link Copied!</Toast.Body>
                              </Toast> */}

                                    {/* <a href={item.Linkedin_Link} className="btn btn-link" target="_blank" title="Linked In" data-toggle="tooltip">
                                        <i className="bi bi-linkedin"></i>
                                    </a>
                                    <a href={item.Insta_Link} className="btn btn-link" target="_blank" title="Instagram" data-toggle="tooltip">
                                        <i className="bi bi-instagram"></i>
                                    </a> */}
                                </td>
                                <td>{item.Category}</td>
                                
                                <td>
                                    <div onClick={()=>readMore(item)} className="btn btn-link" title="Read More Button Link">
                                        <i className="bi bi-arrow-right-square-fill"></i>
                                    </div>
                                </td>
                                <td>
                                    <div style={{width: "300px", maxHeight: "150px", overflowY: "auto"}}>
                                        {ReactHtmlParser(item.Blog_Content)}
                                    </div>
                                </td>
                                <td>{item.Status}</td>
                                <td>
                                    {/* <Link className="btn btn-link" onClick={(event) => editBlogItem(item)}> */}
                                    <Link className="btn btn-link" to={editBlogItem(item.id)}>
                                    {/* <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 0, hide: 0 }}
                                        overlay={renderTooltip}
                                      > */}
                                        <i className="bi bi-pencil"
                                           data-tip="Edit"
                                           onClick={()=>{separeteClick(item)}}></i>
                                    {/* </OverlayTrigger> */}

                                    </Link>

                                    <div className="btn btn-link" onClick={(event) => deleteBlog(item.id)}>
                                    {/* <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 0, hide: 0 }}
                                        overlay={renderTooltip}
                                      > */}
                                        <i className="bi bi-trash"
                                           data-tip="Delete"
                                        onClick={()=>{separeteClick(item)}}></i>
                                    {/* </OverlayTrigger> */}
                                    </div>

                                    <div className="btn btn-link">
                                    {/* <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 0, hide: 0 }}
                                        overlay={renderTooltip}
                                      > */}
                                    {/* <FileCopyIcon style={{padding:'3px',color:'black'}}  
                                                      className='cursor-pointer'
                                                      onClick={()=>{separeteClick(item); setcopyToastGlobal(true)}}
                                                      onMouseEnter={()=>{settoolTipData('Copy Blog Link')}}
                                    /> */}
                                    <i className="bi bi-files"
                                       data-tip="Copy Blog Link"
                                       onClick={()=>{separeteClick(item); setcopyToastGlobal(true)}}></i>
                                    {/* </OverlayTrigger> */}
                                    <ReactTooltip />
                                    </div>

                                    </td>
                            </tr>
                        )
                    })}
                   
                </tbody>
             </table>
         </span>
     )
 }