import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import Banner from './Banner';
import HomeFeatures from './HomeFeatures';
import IndustriesWeServe from './IndustriesWeServe';
import WhyChooseVibtree from './WhyChooseVibtree';
import BookACall from './BookACall';
import Testimonial from './Testimonial';
import ServicesWeOffer from './ServicesWeOffer';
import { Coursel } from './Coursel';
import Group from './../../images/Group.png'
import HomePagelaptop from './Homepagelaptop';
import HomePagemobile from './Homepagemobile';

function getWindowDimensions() {
  const { innerWidth: widthhomepage, innerHeight: heighthomepage } = window;
  return {
    widthhomepage,
    heighthomepage
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


export default function HomePage() {
  const { heighthomepage, widthhomepage } = useWindowDimensions();

  const laptopdiv = () => (
    <div

    >

    </div>
  )

  return (
    <>
      {
        widthhomepage > 600 ? <>
          <HomePagelaptop />
        </> : <>
          <HomePagemobile />
        </>
      }
    </>
  )
}
