import React from "react";
import './commoncss.css'
import SimpleSlider from "./SimpleSlider";
import GetStarted from "src/GetStarted";
import google from './../../images/industry/Google.png'
import trustpilot from './../../images/industry/Trust_Pilot.png'

export default function Banner() {

  return (
    <>
      <div>
        <div className="banner banner-breaking-padding-1000" style={{
          position: "relative",
          // top:"20px",
          // minHeight: "600px",
          // paddingTop:"50px",
          overflow: 'hidden'
        }}>
          <div className="container-fluid home-banner-img-alignment sliderhomepage">
            <div className="row1 align-items-center vh-100-middle vib-flex-wrap-reverse banner-breaking-padding-1000" style={{ justifyContent: 'space-evenly' }}>
              <div className="col-lg-6 for-padding-remove ipadresponsive" >
                <h1 style={{ marginTop: '50px' }} className='communicationtext'>
                  Communicate On <span className='vib-blue'>Cloud</span><br />
                  <span className="anytime-anywhere-anydevice">
                    <span className="highlight banner-about-any anytimetext">Anytime</span>-
                    <span className="highlight banner-about-any anytimetext">Anywhere</span>-
                    <span className="highlight banner-about-any anytimetext">Anydevice</span>
                  </span>
                </h1>
                <h5 style={{ fontSize: "21px" }}>
                  {/* With highly scalable, reliable and tailor-made
                  cloud-based communication suite. */}
                  With highly scalable and reliable shared
                  inbox for your sales and support team.
                </h5>

                <GetStarted />

                {/* <div
                // className="row mt-3 bannerresfor"
                > */}
                <div
                  className="googletrustpilot"
                  // className="col-12 p-0 banner-logo-slider home-slider-position"
                  style={{ display: "flex" }}
                >
                  <div
                    // className="responsive-banner-logos "
                    style={{ cursor: 'pointer', width: "38%" }}>
                    <a href="https://www.google.com/search?q=vibtree&rlz=1C5CHFA_enIN969IN969&sxsrf=ALiCzsbtJI-7vDRHou8is4dykqxn5BhZ7g%3A1671617764475&ei=5NyiY5XQHPLw4-EPl72ZwAY&ved=0ahUKEwiV0rS4vYr8AhVy-DgGHZdeBmgQ4dUDCA8&uact=5&oq=vibtree&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJzIECCMQJzIECCMQJzILCC4QgAQQxwEQrwEyBQgAEIAEMgYIABAeEAo6CggAEEcQ1gQQsANKBAhBGABKBAhGGABQsANYsANglQVoAnABeACAAa8BiAGvAZIBAzAuMZgBAKABAcgBBMABAQ&sclient=gws-wiz-serp#lrd=0x39f89f0400b8c361:0x61e429884bb4f52e,1,,," target="_blank">
                      <img src={google} alt=""
                        //  style={{ width: "190px", height: "160px" }}
                        style={{ width: "77%", height: "87%", marginTop: "-16px" }}
                        className='googleimg'
                      />
                    </a>

                  </div>
                  <div
                    // className="responsive-banner-logos"
                    style={{ cursor: 'pointer', width: "40%" }}>
                    <a href="https://www.trustpilot.com/review/vibtree.com" target="_blank">
                      <img src={trustpilot} alt=""
                        // style={{ width: "190px", height: "165px" }}
                        style={{ width: "80%", height: "100%", marginTop: "-24px" }}
                        className='trustpilotimg'
                      />
                    </a>

                  </div>
                </div>
                {/* <div
                  // className="col-12 p-0 banner-logo-slider home-slider-position"
                  style={{ display: "flex" }}
                >
                  <div
                    // className="responsive-banner-logos "
                    style={{
                      cursor: 'pointer',
                      //  width: "44%"
                    }}>
                    <a href="https://www.google.com/search?q=vibtree&rlz=1C5CHFA_enIN969IN969&sxsrf=ALiCzsbtJI-7vDRHou8is4dykqxn5BhZ7g%3A1671617764475&ei=5NyiY5XQHPLw4-EPl72ZwAY&ved=0ahUKEwiV0rS4vYr8AhVy-DgGHZdeBmgQ4dUDCA8&uact=5&oq=vibtree&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJzIECCMQJzIECCMQJzILCC4QgAQQxwEQrwEyBQgAEIAEMgYIABAeEAo6CggAEEcQ1gQQsANKBAhBGABKBAhGGABQsANYsANglQVoAnABeACAAa8BiAGvAZIBAzAuMZgBAKABAcgBBMABAQ&sclient=gws-wiz-serp#lrd=0x39f89f0400b8c361:0x61e429884bb4f52e,1,,," target="_blank">
                      <img src={google} alt=""
                        //  style={{ width: "190px", height: "160px" }}
                        style={{ width: "200px", height: "160px" }}

                      />
                    </a>

                  </div>
                  <div
                    // className="responsive-banner-logos"
                    style={{ cursor: 'pointer' }}>
                    <a href="https://www.trustpilot.com/review/vibtree.com" target="_blank">
                      <img src={trustpilot} alt=""
                        // style={{ width: "190px", height: "165px" }}
                        style={{ width: "200px", height: "165px" }}
                      />
                    </a>

                  </div>
                </div> */}
                {/* </div> */}

                {/* <div className="trustpilot-widget" style={{ border: "1px solid #ddd", borderRadius: '5px', width: "360px" }} data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="6041ed4e0d40af00014f5877" data-style-height="50px" data-style-width="360px" data-theme="light" data-min-review-count="10" data-without-reviews-preferred-string-id="1">
                  <a href="https://www.trustpilot.com/review/vibtree.com" target="_blank" rel="noopener">Trustpilot</a>
                </div> */}

              </div>
              <div className="col-lg-6 banner-break-point-breaker-1000">
                <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/banner/communicate-on-cloud.png' className="img-fluid bannerdivimg homepageimgfluid" alt='communicate-on-cloud' />
              </div>
            </div>
          </div>
        </div>
      </div>




    </>
  );
}
