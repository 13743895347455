import axios from 'axios';
import React, { useEffect, useState } from 'react'
import BlogNav from '../BlogNav'

export default function AuthorSection() {
    const [form, setform] = useState({AuthorName:'',AuthorImageUrl:'',AuthorBio:'',Profession:''});
    const [success, setsuccess] = useState('');
    const [authList, setauthList] = useState([]);
    const [afterDelete, setafterDelete] = useState([]);
    const [buttonToggle, setbuttonToggle] = useState(false);
    const [idForPut, setidForPut] = useState('');

    const handleChange =(e)=>{
      e.preventDefault();
      const {name,value} = e.target
      setform((prev)=>{ return {...prev, [name]:value}})
    }
    
    const postIt=async()=>{
     await axios.post(`https://vibtree-web.herokuapp.com/authors`,form)
     .then((res)=>{setsuccess('Successfully Added'); 
                   setform({AuthorName:'',AuthorImageUrl:'',AuthorBio:'',Profession:''})})
     .catch((err)=>{setsuccess('Failed To Add')})
    }

    const fetchAuthorsList=async()=>{
        await axios.get(`https://vibtree-web.herokuapp.com/authors`)
        .then((resonse)=>{setauthList(resonse.data)})
        .catch((err)=>{console.log(err)})
    }

    useEffect(() => {
        fetchAuthorsList();
    }, [success,afterDelete]);

    const handleButton=async(id)=>{
        console.log(id);
        await axios.delete(`https://vibtree-web.herokuapp.com/authors/${id}`)
        .then((response)=>{setafterDelete(response.data);})
        .catch((err)=>{console.log(err)})
    }

    const handleEdit=(id)=>{
      // eslint-disable-next-line
        const arrayOfEditItem = authList.filter((item)=>{return item.id==id});
        setform({AuthorName:arrayOfEditItem[0].AuthorName,
                 AuthorImageUrl:arrayOfEditItem[0].AuthorImageUrl,
                 AuthorBio:arrayOfEditItem[0].AuthorBio,
                 Profession:arrayOfEditItem[0].Profession});
        setbuttonToggle(true);
        setidForPut(id);
        scrollToTop();
    }

    const putIt=async()=>{
        await axios.put(`https://vibtree-web.herokuapp.com/authors/${idForPut}`,form)
        .then(()=>{setbuttonToggle(false); setform({AuthorName:'',AuthorImageUrl:'',AuthorBio:'',Profession:''}); fetchAuthorsList();})
        .catch((err)=>{console.log(err)})
    }

    const scrollToTop=()=>{
      window.scrollTo({ top: 0, behavior: 'smooth'})
    }

  return (
    <div className='w-100'>
    <BlogNav/>


    <form className='col-lg-6 m-auto vib-res-margin-top-100-50'>

      <div className="form-group">
        <label htmlFor="exampleFormControlInput1">Name</label>
        <input required name='AuthorName' onChange={handleChange} value={form.AuthorName} type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter The Name"/>
      </div>

      <div className="form-group">
        <label htmlFor="exampleFormControlInput1">Profession</label>
        <input required name='Profession' onChange={handleChange} value={form.Profession} type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter Profession of Author"/>
      </div>

      <div className="form-group">
        <label  htmlFor="exampleFormControlInput1">Photo Url Link</label>
        <input name='AuthorImageUrl' onChange={handleChange} value={form.AuthorImageUrl} type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter Photo Link"/>
      </div>

      <div className="form-group">
        <label htmlFor="exampleFormControlTextarea1">Author Bio</label>
        <textarea required name='AuthorBio' onChange={handleChange} value={form.AuthorBio} placeholder='Short Description About Author' className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
      </div>
      {buttonToggle ?
      <button onClick={putIt} type="submit" className="btn btn-primary">Confirm</button> :
      <button onClick={postIt} type="submit" className="btn btn-primary">Add Author</button>
      }

      <p>{success}</p>

    </form>

        <h3>Authors List:</h3>
        
        <div className="container-fluid d-flex vib-justify-content-evenly flex-wrap">

        {authList.map((item,index)=>{
        return(
        <div className="col-lg-5 card row mx-lg-2 my-5" style={{background:'#f2f2f2'}}>
        <div className="row vib-justify-content-evenly mt-3 mb-3">
          <div className="col-3 p-0 mr-auto d-flex align-items-center">
            <img src={item.AuthorImageUrl} 
                 alt={item.AuthorName} 
                 style={{width:'100px',height:'100px',borderRadius:'50%'}}/>
          </div>
          <div className="col-8 p-0">
            <h5 className="row">{item.AuthorName}</h5>
            <h6 className="row">{item.Profession}</h6>
            <p className='mb-0'>
               {item.AuthorBio}
            </p>
          </div>
        </div>
            <div className="row d-flex justify-content-around mb-3">
               <button onClick={()=>handleEdit(item.id)} type="button" className="btn btn-warning">Edit</button>
               <button onClick={()=>handleButton(item.id)} type="button" className="btn btn-danger">Delete</button>
            </div>
        </div>

        )})}

        </div>


    </div>
  )
}
