import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { vibContext } from "src/App";

const ScrollHandler = ({ location }) => {
 
  var scrollContext=useContext(vibContext);
  useEffect(() => {
    const element = document.getElementById(scrollContext);
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0
      });
    }, 100);
    // eslint-disable-next-line
  }, [location]);

  return null;
};

ScrollHandler.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.element
  }).isRequired
};

export default withRouter(ScrollHandler);


