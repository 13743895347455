import React, { useEffect, useState } from 'react'
import './voice.css'
import Slider from 'react-slick';
import { crmBaseUrl } from './../../utils/config'
import axios from 'axios';
import GetStarted from 'src/GetStarted';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
export default function VoiceSlider({ imagesLoadIndicator }) {
  const [inputEmail, setinputEmail] = useState('');
  const [isEmailValid, setEmailValidity] = useState(null);
  const [emailExists, setEmailExists] = useState(true);
  const slider = React.useRef(null);
  var settings = {
    infinite: true,
    speed: 800,
    arrows: false,
    dots: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  useEffect(() => {
    if (isEmailValid) {
      emailCheck()
    }
  }, [inputEmail]);

  async function emailCheck() {
    try {
      const response = await axios.get(`${crmBaseUrl}/email_exist?email_address=${inputEmail}`);
      if (response.data.data === true) {
        setEmailExists(false)
      } else if (response.data.data === false) {
        setEmailExists(true)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <>

      <Slider ref={slider} {...settings} className='vo-sli vib-1400 vib-res-margin-top-200-150'>

        <div className=' mx-lg-3 mx-lg-2 voiceslider'>
          <div className="wo-sli-element-inner banner" style={{ padding: '0px', marginTop: '0px' }}>
            <div className="vo-new-height">
              <h1 className='vib-container-heading 
                             vib-res-left 
                             voice-allow-overflow 
                             banner-heading-width'
                style={{
                  fontSize: '50px',
                  lineHeight: '75px',
                  fontWeight: 'bold'
                }}>
                Manage Multiple Numbers
                <br />
                with One Inbox
                {/* Give Voice To Your Business<br />
                To Get Noticed By Your <br />
                <span className="vib-blue">Customers</span> */}
              </h1>

              <h5
                className='vib-bold-para 
                           vib-res-left  
                           voice-allow-overflow
                           m-0'
                style={{ fontSize: '25px', lineHeight: '37px' }}>
                {/* With Vibtree - Your Voice Partners */}
                with Vibtree's shared inbox for teams
              </h5>

              {/* form start  */}

              <GetStarted />
              {/* form end  */}
            </div>
            <div className="vo-si-img-con d-flex justify-content-center align-items-center">
              {/* <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/give-voice-to-your-business-to-get-notified-by-your-custormers.svg'
                onLoad={imagesLoadIndicator}
                className="vo-new-img voiceClassTest"
                alt='give-voice-to-your-business-to-get-notified-by-your-custormers'
              /> */}
              <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/lead-with-voice-to-stay-one-step-ahead-of-your-customers.svg'
                onLoad={imagesLoadIndicator}
                className="vo-new-img"
                alt='lead-with-voice-to-stay-one-step-ahead-of-your-customers'
              />
            </div>
          </div>
        </div>

        <div className=' mx-lg-3 mx-lg-2 voiceslider'>
          <div className="wo-sli-element-inner banner" style={{ padding: '0px', marginTop: '0px' }}>
            <div className="vo-new-height">
              <h1 className='vib-container-heading 
                             vib-res-left 
                             voice-allow-overflow 
                             banner-heading-width'
                style={{
                  fontSize: '50px',
                  lineHeight: '75px',
                  fontWeight: 'bold'
                }}>
                <span className="vib-blue"> Automate with IVR </span><br /> To Organize your communication
                {/* <br />Step Ahead Of Your <br />Competitors */}
              </h1>

              <h5
                className='vib-bold-para 
                           vib-res-left  
                           voice-allow-overflow
                           m-0'
                style={{ fontSize: '25px', lineHeight: '37px' }}>
                with Vibtree's Drag and Drop flow builder
              </h5>

              {/* form start  */}
              <GetStarted />
              {/* form end  */}
            </div>
            <div className="vo-si-img-con d-flex justify-content-center align-items-center">
              <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/pick-your-own-pack-to-manage-your-communication.svg'
                onLoad={imagesLoadIndicator}
                className="vo-new-img"
                alt='pick-your-own-pack-to-manage-your-communication'
              />
              {/* <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/lead-with-voice-to-stay-one-step-ahead-of-your-customers.svg'
                onLoad={imagesLoadIndicator}
                className="vo-new-img"
                alt='lead-with-voice-to-stay-one-step-ahead-of-your-customers'
              /> */}
            </div>
          </div>
        </div>



        <div className=' mx-lg-3 mx-lg-2 voiceslider'>
          <div className="wo-sli-element-inner banner" style={{ padding: '0px', marginTop: '0px' }}>
            <div className="vo-new-height">
              <h1 className='vib-container-heading 
                             vib-res-left 
                                voice-allow-overflow 
                             banner-heading-width'
                style={{
                  fontSize: '50px',
                  lineHeight: '75px',
                  fontWeight: 'bold'
                }}>

                Give Voice to your business <br />
                and get notices by your <br />
                <span className="vib-blue">Customer</span>

              </h1>

              <h5
                className='vib-bold-para 
                           vib-res-left  
                           voice-allow-overflow
                           m-0'
                style={{ fontSize: '25px', lineHeight: '37px' }}>
                with Vibtree VoIP and Cloud telephony features
                {/* Choose the cloud telephony services you wish <br />to bundle with our voice solutions */}
              </h5>

              {/* form start  */}
              <GetStarted />
              {/* form end  */}
            </div>
            <div className="vo-si-img-con d-flex justify-content-center align-items-center">
              {/* <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/pick-your-own-pack-to-manage-your-communication.svg'
                onLoad={imagesLoadIndicator}
                className="vo-new-img"
                alt='pick-your-own-pack-to-manage-your-communication'
              /> */}
              <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/give-voice-to-your-business-to-get-notified-by-your-custormers.svg'
                onLoad={imagesLoadIndicator}
                className="vo-new-img voiceClassTest"
                alt='give-voice-to-your-business-to-get-notified-by-your-custormers'
              />
              {/* <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/lead-with-voice-to-stay-one-step-ahead-of-your-customers.svg'
                onLoad={imagesLoadIndicator}
                className="vo-new-img"
                alt='lead-with-voice-to-stay-one-step-ahead-of-your-customers'
              /> */}
            </div>
          </div>
        </div>


      </Slider>
      <ArrowBackIosNewIcon onClick={() => slider?.current?.slickPrev()}
        // style={{ position: "relative", top: "-400px", cursor: "pointer" }} 
        className='leftarrow'

      />
      <ArrowForwardIosIcon onClick={() => slider?.current?.slickNext()}
        // style={{ position: "relative", top: "-400px", left: "96%", cursor: "pointer" }}
        className='rightarrow'
      />
      {/* <button onClick={() => slider?.current?.slickPrev()} >Prev</button>
      <button onClick={() => slider?.current?.slickNext()}>Next</button> */}
    </>
  )
}
