import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'

export default function IndustryFAQ() { 
        //   faq start 
        const [AccordingState, setAccordingState] = useState(1);  
        var handleClick1=()=>{
          AccordingState === 1 ? setAccordingState(0) : setAccordingState(1)
        }
        var handleClick2=()=>{
          AccordingState === 2 ? setAccordingState(0) : setAccordingState(2)
        }
        var handleClick3=()=>{
          AccordingState === 3 ? setAccordingState(0) : setAccordingState(3)
        }
        var handleClick4=()=>{
          AccordingState === 4 ? setAccordingState(0) : setAccordingState(4)
        }
       //   faq end
  return (
    <>
      {/* faq start  */}
      <h4 className='p-faq-h4 vib-res-margin-top-100-50'>Common Questions Our Customers Ask</h4>
      <div className="vib-container-narrow sms-accordin-margin-top" style={{marginTop:'50px',marginBottom:'130px'}}>
      <Accordion defaultActiveKey="0" style={{width:'100%', padding:'20px'}}>

      <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="0"
        onClick={handleClick1} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white',
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> Why is cloud telephony needed in the Education industry?</span>
           <div className="accordin-right-icon" 
           style={{transform: AccordingState=== 1 ? 'rotate(90deg)': AccordingState !== 1 ? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0"> 
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> Cloud telephony system in the education 
            industry facilitates automation of different communication and customer experience 
            operations with features like auto-receptionist, call recording, IVR, bulk SMS, voice 
            broadcast and call tracking etc. It helps your institution or organization streamline 
            the communication with students and parents.  
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="1" 
        onClick={handleClick2} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> Which cloud telephony features can be used for the Education Industry?</span>
           <div className="accordin-right-icon" 
           style={{transform: AccordingState=== 2 ? 'rotate(90deg)': AccordingState !== 2 ? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> In the times of digitization, 
            cloud telephony solutions are helping the education sector to stay on 
            track when it comes to communication with their students and parents. 
            Operating with limited employees is the major challenge in the times of 
            pandemic and post pandemic, so services like Toll free virtual number, IVR, 
            call tracking, bulk SMS and voice broadcasting prove to be beneficial 
            for the education industry. 
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className='accordin-card' style={{pointerEvent:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="2" 
        onClick={handleClick3} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> What are your pricing plans?</span>
           <div className="accordin-right-icon" 
           style={{transform: AccordingState=== 3 ? 'rotate(90deg)': AccordingState !== 3 ? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> Our pricing plans are flexible so our customers 
            only pay for the services they use depending on their business needs. Visit our 
            pricing page to understand our different pricing plans.   
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
        <Accordion.Toggle 
        as={Card.Header} 
        eventKey="3" 
        onClick={handleClick4} 
        style={{
          border:'none',
          borderRadius:'5px',
          fontSize:'25px',
          fontWeight:'600',
          boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
          overFlow:'visible',
          cursor:'pointer',
          color:'black',
          backgroundColor:'white'
          }}>
          <div className="accordin-top-arrow-container">
          <span><span style={{fontWeight:'700'}}>Q.</span> Do you offer customized solutions for different businesses?</span>
           <div className="accordin-right-icon" 
           style={{transform: AccordingState=== 4 ? 'rotate(90deg)': AccordingState !== 4 ? 'rotate(0deg)':null}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
           </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body className='vib-bold-para vib-res-left ' 
          style={{
            marginTop:'0px',
            paddingLeft:'0px',
            paddingRight:'0px',
            fontSize:'23px',
            fontWeight:'400',
            lineHeight:'35px',
            backgroundColor:'none',
            border:'none !important'}}>
            <span style={{fontWeight:'bold'}}>A.</span> Yes, we deliver customized solutions depending 
            on your business requirements to ensure that you do not pay for more services than you use. 
            All you need to do is to talk to our consultants to ensure that your plan is fully 
            customized to your needs.  
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      </Accordion>
      </div>
      {/* faq end */}
    </>
  )
}
