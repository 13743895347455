import React from "react";
import './commoncss.css';

export default function WhyChooseVibtree() {
  return (
    <div style={{overflow:'hidden'}} className="vib-res-margin-top-50-50">
      <h2 style={{
        textAlign:'center', 
        fontSize:'36px',
        fontWeight:'700', 
        lineHeight:'48px',
        margin:'5px'}}>
        Why Customers Choose <span className='vib-blue'>Vibtree?</span>
        </h2>
      <section className="why-choose-vibtree">
        <div className="container-fluid for-class-toggle-whyChoose">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 whychoosevibtrreecenter">
              <div className="wrap">
              </div>
            </div>
          </div>

          <div className="row justify-content-center for-class-toggle-whyChoose mt-4">
            <div className="col-lg-4" style={{padding:'0px'}}>
              <div className="row m-0">
                <div className="col-12 whychoosevibtrreecenter">
                  <div className="why-choose-left-box" style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/customised.png' className="img-fluid whychoosevibtreeimage" alt="customised"/>
                    </div>
                    <h3>Customized Solutions</h3>
                    <p>
                      Personalized cloud-based solutions for your business
                      needs.
                    </p>
                  </div>
                </div>

                <div className="col-12 whychoosevibtrreecenter">
                  <div className="why-choose-left-box">
                    <div className="why-choose-icon ">
                      <img
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/tollfree-outline.png'
                        className="img-fluid whychoosevibtreeimage"
                        alt="tollfree-outline"
                      />
                    </div>
                    <h3>Best Toll Free Number Provider</h3>
                    <p>
                      Buy a virtual number for sms or voice directly from
                      portal.
                    </p>
                  </div>
                </div>

                <div className="col-12 whychoosevibtrreecenter">
                  <div className="why-choose-left-box">
                    <div className="why-choose-icon ">
                      <img
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/live-in-24-hrs.png'
                        className="img-fluid whychoosevibtreeimage"
                        alt="live-in-24-hrs"
                      />
                    </div>
                    <h3>Go Live In 24 Hours</h3>
                    <p>
                      Instant login and setup support to get you started right
                      away.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row">
                <div className="col-12 whychoosevibtrreecenter">
                  <div className="why-choose-center-box">
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/bfsi.png' className="img-fluid whychoosevibtreeimagemiddle" alt="bfsi"/>
                    </div>
                    <h3>Flexible Pricing</h3>
                    <p>
                      Affordable pricing models depending on the services you
                      use. Cancel anytime.{" "}
                    </p>
                  </div>
                </div>

                <div className="col-12 whychoosevibtrreecenter">
                  <img
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/why-choose-vibtree.svg'
                    className="img-fluid why-choose-vibtree-img"
                    alt="why-choose-vibtree"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row m-0">
                <div className="col-12 whychoosevibtrreecenter">
                  <div className="why-choose-right-box">
                    <div className="why-choose-icon ">
                      <img
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/crm-integration.png'
                        className="img-fluid whychoosevibtreeimageother"
                        alt="crm-integration"
                      />
                    </div>
                    <h3>CRM Integration</h3>
                    <p>
                      Easily integrate our solutions into your existing systems
                      for ease of use.{" "}
                    </p>
                  </div>
                </div>

                <div className="col-12 whychoosevibtrreecenter">
                  <div className="why-choose-right-box" style={{minHeight:'200px',marginBottom:'20px'}}>
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/secure.png' className="img-fluid whychoosevibtreeimageother" alt="secure"/>
                    </div>
                    <h3>Secure &amp; Reliable</h3>
                    <p>
                      Highly dependable and secured services with high call
                      volume &amp; 99.9% uptime.
                    </p>
                  </div>
                </div>

                <div className="col-12 whychoosevibtrreecenter">
                  <div className="why-choose-right-box">
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/scalable.png' className="img-fluid whychoosevibtreeimageother" alt="scalable" />
                    </div>
                    <h3>Scalable Solutions</h3>
                    <p>
                      Expanding your business? Our services will grow with you
                      without any hassles.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="why-choose-left-box">
                <div className="why-choose-icon why-choose-media-vib">
                  <img
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/cloud-infrastructure.png'
                    className="img-fluid"
                    alt="cloud-infrastructure"
                  />
                </div>
                <h3>Cloud Infrastructure</h3>
                <p>
                  Additional Infrastructure not needed as all the services are
                  web-based.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="why-choose-center-box">
                <div className="why-choose-icon ">
                  <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/zero-cost.png' className="img-fluid whychoosevibtreeimagemiddle" alt="zero-cost"/>
                </div>
                <h3>No set-up cost</h3>
                <p>
                  Set up your cloud-based solutions without any additional
                  costing involved.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4 whysupport">
              <div className="why-choose-right-box">
                <div className="why-choose-icon ">
                  <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/support.png' className="img-fluid whychoosevibtreeimageother" alt="support"/>
                </div>
                <h3>24x7 Support</h3>
                <p>
                  Always available to assist and support our users to help them
                  offer better customer services.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>



    <div className="whychoose-2nd-toggle">

        <section className="why-first-sec">

                <div className="whyChoose-card">
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/customised.png' className="whyChoose-small-img" alt="customised"/>
                    </div>
                    <h3>Customized Solutions</h3>
                    <p>
                      Personalized cloud-based solutions for your business
                      needs.
                    </p>
                </div>

                <div className="whyChoose-card">
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/tollfree-outline.png' className="whyChoose-small-img" alt="tollfree-outline"/>
                    </div>
                    <h3>Best Toll Free Number Provider</h3>
                    <p>
                      Buy a virtual number for sms or voice directly from
                      portal.
                    </p>
                </div>

                <div className="whyChoose-card">
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/live-in-24-hrs.png' className="whyChoose-small-img" alt="live-in-24-hrs"/>
                    </div>
                    <h3>Go Live In 24 Hours</h3>
                    <p>
                      Instant login and setup support to get you started right
                      away.
                    </p>
                </div>

                <div className="whyChoose-card">
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/bfsi.png' className="whyChoose-small-img" alt="bfsi"/>
                    </div>
                    <h3>Flexible Pricing</h3>
                    <p>
                      Affordable pricing models depending on the services you
                      use. Cancel anytime.
                    </p>
                </div>

        </section>

        <section className="why-second-sec">
                <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/why-choose-vibtree.svg' alt="why-choose-vibtree" className='girl-img-second' />
        </section>

        <section className="why-first-sec">

                <div className="whyChoose-card">
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/crm-integration.png' className="whyChoose-small-img" alt="crm-integration"/>
                    </div>
                    <h3>CRM Integration</h3>
                    <p>
                      Easily integrate our solutions into your existing systems
                      for ease of use.
                    </p>
                </div>

                <div className="whyChoose-card">
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/secure.png' className="whyChoose-small-img" alt="secure"/>
                    </div>
                    <h3>Secure &amp; Reliable</h3>
                    <p>
                      Highly dependable and secured services with high call
                      volume &amp; 99.9% uptime.
                    </p>
                </div>

                <div className="whyChoose-card">
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/scalable.png' className="whyChoose-small-img" alt="scalable"/>
                    </div>
                    <h3>Scalable Solutions</h3>
                    <p>
                      Expanding your business? Our services will grow with you
                      without any hassles.
                    </p>
                </div>

                <div className="whyChoose-card">
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/cloud-infrastructure.png' className="whyChoose-small-img" alt="cloud-infrastructure"/>
                    </div>
                    <h3>Cloud Infrastructure</h3>
                    <p>
                     Additional Infrastructure not needed as all the services are
                     web-based.
                    </p>
                </div>

                <div className="whyChoose-card">
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/zero-cost.png' className="whyChoose-small-img" alt="zero-cost"/>
                    </div>
                    <h3>No set-up cost</h3>
                    <p>
                     Set up your cloud-based solutions without any additional
                     costing involved.
                    </p>
                </div>

                <div className="whyChoose-card">
                    <div className="why-choose-icon ">
                      <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/why-choose-vibtree/support.png' className="whyChoose-small-img" alt="support"/>
                    </div>
                    <h3>24x7 Support</h3>
                    <p>
                     Always available to assist and support our users to help them
                     offer better customer services.
                    </p>
                </div>

        </section>

    </div>

    </div>
  );
}
