import React from "react";
import './commoncss.css'
import { Link } from "react-router-dom";
import callicon from './../../images/industry/call-icon.svg'

import messengericon from './../../images/industry/messenger.svg'
import smsicon from './../../images/industry/sms-icon.svg'
import telegramnewicon from './../../images/industry/telegramnew.svg'
import whatsappicon from './../../images/industry/whatsapp-icon.svg'
import vibericon from './../../images/industry/viber-icon.svg'
import { Button, Grid, Typography } from "@mui/material";



export default function HomeFeatures() {
  return (
    <div>
      <div>
        <section className="home-features home-content-section vib-res-margin-top-100-50">
          <div className="container-fluid">
            <div className="row align-items-center mb-3">
              <div className="col-md col-12">
                {/* <h2 style={{textAlign:'center'}}>
                  We Make
                  <br />
                  <span className='vib-blue'>Conversations</span> Simple
                </h2> */}
                <h2 style={{ textAlign: 'center' }} className='oneapp'>
                  <span className='vib-blue'>One App </span>to rule them all
                </h2>
              </div>
            </div>



            <h5 className='vib-bold-para vib-res-center voice-percent vib-res-margin-top-50-50'
              style={{
                fontSize: '18px',
                lineHeight: '36px',
                fontWeight: '200',
                textAlign: 'center',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}>
              The only app you need to manage all your customer communication channels. Be it Whatsapp, SMS, Viber even your business calls we got all of them under a single roof.
            </h5>
            <div className="row vib-res-margin-top-50-50" style={{ justifyContent: "center", width: "90%", marginLeft: "5%" }}>
              <div>
                <Button variant="outlined"
                  //  style={{ marginRight: "10px", textTransform: "capitalize", marginBottom: "10px", marginLeft: "40px" }}
                  //    onClick={teamSelectin}
                  // className='OnboardingButton'
                  className="buttononeapp"
                  style={{
                    textTransform: "none"
                  }}
                  // style={{
                  //   margin: "0px", padding: "0px", marginRight: "40px", width: "310px", height: "300px", marginBottom: "20px", borderRadius: "10px", textTransform: "none"
                  //   //  backgroundColor: "#eef3fc" 
                  // }}
                  onClick={() => {
                    // handleCss()

                  }}
                >
                  <Link to='/products/voice/'>
                    <div style={{ display: "block" }}>

                      {/* <FormControlLabel value="voice" control={<StyledRadio />} style={{ position: "absolute", right: "2px", top: "0px" }} /> */}

                      <Grid container direction="row">
                        <Grid item xs={12}
                        // style={{ padding: "10px 20px 5px 20px" }}
                        // style={{ width: "100px" }}
                        >

                          {/* <SvgIconStyle src={`/assets/icons/navbar/ic_voice.svg`} style={{ width: '300px', marginLeft: "30%", color: "#2a6cd3" }} /> */}
                          {/* <img src={`/assets/icons/navbar/ic_voice.svg`} style={{ width: '100px', marginLeft: "30%", color: "#2a6cd3" }} /> */}
                          {/* <img src={voicepng} style={{ width: '120px', marginLeft: "33%", color: "#2a6cd3" }} /> */}
                        </Grid>
                      </Grid>
                      <Grid container direction="row" alignItems="center"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Grid item>
                          <img src={callicon}
                            style={{ width: "60px", marginBottom: "10px", }}

                          />
                        </Grid>
                        <Grid item xs>
                          <Typography
                            variant="h3"
                            style={{ color: "#2065d1", fontWeight: "800", fontSize: "20px", marginTop: "15px", marginBottom: "15px" }}
                          >
                            Voice
                          </Typography>

                          <Typography style={{ marginLeft: "10%", fontWeight: 600, fontSize: "13px", color: "gray", width: "80%" }}>
                            Make and receive calls, record it, automate it through IVR, run promotional and transactional campaigns.


                          </Typography>


                        </Grid>
                      </Grid>




                    </div>
                  </Link>
                </Button>
              </div>



              <div>
                <Button variant="outlined"
                  //  style={{ marginRight: "10px", textTransform: "capitalize", marginBottom: "10px", marginLeft: "40px" }}
                  //    onClick={teamSelectin}
                  // className='OnboardingButton'
                  className="buttononeapp"
                  style={{
                    textTransform: "none"
                  }}
                  // style={{
                  //   margin: "0px", padding: "0px", marginRight: "40px", width: "310px", height: "300px", marginBottom: "20px", borderRadius: "10px", textTransform: "none"
                  //   //  backgroundColor: "#eef3fc" 
                  // }}
                  onClick={() => {
                    // handleCss()

                  }}
                >
                  <Link to='/products/bulk-sms/'>
                    <div style={{ display: "block" }}>

                      {/* <FormControlLabel value="voice" control={<StyledRadio />} style={{ position: "absolute", right: "2px", top: "0px" }} /> */}

                      <Grid container direction="row">
                        <Grid item xs={12}
                        // style={{ padding: "10px 20px 5px 20px" }}
                        // style={{ width: "100px" }}
                        >

                          {/* <SvgIconStyle src={`/assets/icons/navbar/ic_voice.svg`} style={{ width: '300px', marginLeft: "30%", color: "#2a6cd3" }} /> */}
                          {/* <img src={`/assets/icons/navbar/ic_voice.svg`} style={{ width: '100px', marginLeft: "30%", color: "#2a6cd3" }} /> */}
                          {/* <img src={voicepng} style={{ width: '120px', marginLeft: "33%", color: "#2a6cd3" }} /> */}
                        </Grid>
                      </Grid>
                      <Grid container direction="row" alignItems="center"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Grid item>
                          <img src={smsicon}
                            style={{ width: "60px", marginBottom: "10px", }}

                          />
                        </Grid>
                        <Grid item xs>
                          <Typography
                            variant="h3"
                            style={{ color: "#2065d1", fontWeight: "800", fontSize: "20px", marginTop: "25px", marginBottom: "15px" }}
                          >
                            SMS
                          </Typography>

                          <Typography style={{ marginLeft: "10%", fontWeight: 600, fontSize: "13px", color: "gray", width: "80%" }}>
                            Send and Receive Text Messages, Run SMS Campaigns and many more.


                          </Typography>


                        </Grid>
                      </Grid>




                    </div>
                  </Link>
                </Button>
              </div>


              <div>
                <Button variant="outlined"
                  //  style={{ marginRight: "10px", textTransform: "capitalize", marginBottom: "10px", marginLeft: "40px" }}
                  //    onClick={teamSelectin}
                  // className='OnboardingButton'
                  className="buttononeapp"
                  style={{
                    textTransform: "none"
                  }}
                  // style={{
                  //   margin: "0px", padding: "0px", marginRight: "40px", width: "310px", height: "300px", marginBottom: "20px", borderRadius: "10px", textTransform: "none"
                  //   //  backgroundColor: "#eef3fc" 
                  // }}
                  onClick={() => {
                    // handleCss()

                  }}
                >
                  <div style={{ display: "block" }}>

                    {/* <FormControlLabel value="voice" control={<StyledRadio />} style={{ position: "absolute", right: "2px", top: "0px" }} /> */}

                    <Grid container direction="row">
                      <Grid item xs={12}
                      // style={{ padding: "10px 20px 5px 20px" }}
                      // style={{ width: "100px" }}
                      >

                        {/* <SvgIconStyle src={`/assets/icons/navbar/ic_voice.svg`} style={{ width: '300px', marginLeft: "30%", color: "#2a6cd3" }} /> */}
                        {/* <img src={`/assets/icons/navbar/ic_voice.svg`} style={{ width: '100px', marginLeft: "30%", color: "#2a6cd3" }} /> */}
                        {/* <img src={voicepng} style={{ width: '120px', marginLeft: "33%", color: "#2a6cd3" }} /> */}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Grid item>
                        <img src={whatsappicon}
                          style={{ width: "60px", marginBottom: "10px", }}

                        />
                      </Grid>
                      <Grid item xs>
                        <Typography
                          variant="h3"
                          style={{ color: "#2065d1", fontWeight: "800", fontSize: "20px", marginTop: "25px", marginBottom: "15px" }}
                        >
                          Whatsapp
                        </Typography>

                        <Typography style={{ marginLeft: "10%", fontWeight: 600, fontSize: "13px", color: "gray", width: "80%" }}>
                          Manage your business inquiries quickly by connecting your WhatsApp number to Vibtree.

                        </Typography>


                      </Grid>
                    </Grid>




                  </div>

                </Button>
              </div>



              <div>
                <Button variant="outlined"
                  //  style={{ marginRight: "10px", textTransform: "capitalize", marginBottom: "10px", marginLeft: "40px" }}
                  //    onClick={teamSelectin}
                  // className='OnboardingButton'
                  className="buttononeapp"
                  style={{
                    textTransform: "none"
                  }}
                  // style={{
                  //   margin: "0px", padding: "0px", marginRight: "40px", width: "310px", height: "300px", marginBottom: "20px", borderRadius: "10px", textTransform: "none"
                  //   //  backgroundColor: "#eef3fc" 
                  // }}
                  onClick={() => {
                    // handleCss()

                  }}
                >
                  <div style={{ display: "block" }}>

                    {/* <FormControlLabel value="voice" control={<StyledRadio />} style={{ position: "absolute", right: "2px", top: "0px" }} /> */}

                    <Grid container direction="row">
                      <Grid item xs={12}
                      // style={{ padding: "10px 20px 5px 20px" }}
                      // style={{ width: "100px" }}
                      >

                        {/* <SvgIconStyle src={`/assets/icons/navbar/ic_voice.svg`} style={{ width: '300px', marginLeft: "30%", color: "#2a6cd3" }} /> */}
                        {/* <img src={`/assets/icons/navbar/ic_voice.svg`} style={{ width: '100px', marginLeft: "30%", color: "#2a6cd3" }} /> */}
                        {/* <img src={voicepng} style={{ width: '120px', marginLeft: "33%", color: "#2a6cd3" }} /> */}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Grid item>
                        <img src={vibericon}
                          style={{ width: "60px", marginBottom: "10px", }}

                        />
                      </Grid>
                      <Grid item xs>
                        <Typography
                          variant="h3"
                          style={{ color: "#2065d1", fontWeight: "800", fontSize: "20px", marginTop: "25px", marginBottom: "15px" }}
                        >
                          Viber
                        </Typography>

                        <Typography style={{ marginLeft: "10%", fontWeight: 600, fontSize: "13px", color: "gray", width: "80%" }}>
                          Manage your Viber conversations with ease and automate interactions with your customers.
                        </Typography>


                      </Grid>
                    </Grid>




                  </div>

                </Button>
              </div>


              {/* <div>
                <Button variant="outlined"
            
                  style={{
                    textTransform: "none"
                  }}
                  className="buttononeapp"
          
                  onClick={() => {
         
                  }}
                >
                  <div style={{ display: "block" }}>

                    <Grid container direction="row">
                      <Grid item xs={12}
      
                      >

                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Grid item>
                        <img src={telegramnewicon}
                          style={{ width: "60px", marginBottom: "10px", }}

                        />
                      </Grid>
                      <Grid item xs>
                        <Typography
                          variant="h3"
                          style={{ color: "#2065d1", fontWeight: "800", fontSize: "20px", marginTop: "25px", marginBottom: "15px" }}
                        >
                          Telegram
                        </Typography>

                        <Typography style={{ marginLeft: "10%", fontWeight: 600, fontSize: "13px", color: "gray", width: "80%" }}>
                          Vibtree effortlessly links with your Telegram account and supports the Telegram BotFather.

                        </Typography>


                      </Grid>
                    </Grid>




                  </div>

                </Button>
              </div>
 */}


              <div>
                <Button variant="outlined"
                  //  style={{ marginRight: "10px", textTransform: "capitalize", marginBottom: "10px", marginLeft: "40px" }}
                  //    onClick={teamSelectin}
                  // className='OnboardingButton'
                  className="buttononeapp"
                  style={{
                    textTransform: "none"
                  }}
                  // style={{
                  //   margin: "0px", padding: "0px", marginRight: "40px", width: "310px", height: "300px", marginBottom: "20px", borderRadius: "10px", textTransform: "none"
                  //   //  backgroundColor: "#eef3fc" 
                  // }}
                  onClick={() => {
                    // handleCss()

                  }}
                >
                  <div style={{ display: "block" }}>

                    {/* <FormControlLabel value="voice" control={<StyledRadio />} style={{ position: "absolute", right: "2px", top: "0px" }} /> */}

                    <Grid container direction="row">
                      <Grid item xs={12}
                      // style={{ padding: "10px 20px 5px 20px" }}
                      // style={{ width: "100px" }}
                      >

                        {/* <SvgIconStyle src={`/assets/icons/navbar/ic_voice.svg`} style={{ width: '300px', marginLeft: "30%", color: "#2a6cd3" }} /> */}
                        {/* <img src={`/assets/icons/navbar/ic_voice.svg`} style={{ width: '100px', marginLeft: "30%", color: "#2a6cd3" }} /> */}
                        {/* <img src={voicepng} style={{ width: '120px', marginLeft: "33%", color: "#2a6cd3" }} /> */}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Grid item>
                        <img src={messengericon}
                          style={{ width: "60px", marginBottom: "10px", }}

                        />
                      </Grid>
                      <Grid item xs>
                        <Typography
                          variant="h3"
                          style={{ color: "#2065d1", fontWeight: "800", fontSize: "20px", marginTop: "25px", marginBottom: "15px" }}
                        >
                          Messenger
                        </Typography>

                        <Typography style={{
                          marginLeft: "10%", fontWeight: 600, fontSize: "13px",
                          color: "gray",
                          width: "80%"
                        }}>
                          Connect your Facebook account with Vibtree, manage your Messenger and Instagram DMs without leaving the Dashboard.

                        </Typography>


                      </Grid>
                    </Grid>




                  </div>

                </Button>
              </div>


            </div>
            <div className="row vib-res-margin-top-50-50">


              {/* <div className="col-lg-6">
                <Link to='/products/voice/'>
                  <div className="row no-margin align-items-center mb-3">
                    <div className="col-auto">
                     
                      <img src={callicon} alt="voice"
                      
                        style={{ width: "80px" }}
                      />
                    </div>
                    <div className="col">
                      <h3 style={{ fontSize: '21px', fontWeight: '500px', color: '#3d3d3d' }}>Voice</h3>
                    
                      <p style={{ fontSize: '13px', fontWeight: '400px', color: '#3d3d3d' }}>
                        Make and receive calls, record it, automate it through IVR, run promotional and transactional campaigns.

                      </p>
                    </div>
                  </div>
                </Link>
              </div> */}

              {/* <div className="col-lg-6">
                <Link to='/products/bulk-sms/'>
                  <div className="row no-margin align-items-center mb-3">
                    <div className="col-auto">
                     
                      <img src={smsicon} alt="sms"
                       
                        style={{ width: "80px" }}

                      />
                    </div>
                    <div className="col">
                      <h3 style={{ fontSize: '21px', fontWeight: '500px', color: '#3d3d3d' }}>SMS</h3>
                     
                      <p style={{ fontSize: '13px', fontWeight: '400px', color: '#3d3d3d' }}>
                        Send and Receive Text Messages, Run SMS Campaigns and many more.

                      </p>
                    </div>
                  </div>
                </Link>
              </div> */}

              {/* <div className="col-lg-6">
                <Link to='/products/call-tracking/'>
                  <div className="row no-margin align-items-center mb-3">
                    <div className="col-auto">
                     
                      <img src={whatsappicon} alt="call-tracking-outline"
                        style={{ width: "87px", marginLeft: "-5px" }}

                      />
                    </div>
                    <div className="col">
                      <h3 style={{ fontSize: '21px', fontWeight: '500px', color: '#3d3d3d' }}>Whatsapp</h3>
                      <p style={{ fontSize: '13px', fontWeight: '400px', color: '#3d3d3d' }}>
                        Manage your business inquiries quickly by connecting your WhatsApp number to Vibtree.
                      </p>
                    </div>
                  </div>
                </Link>
              </div> */}

              {/* <div className="col-lg-6">
                <Link to='/products/ivr-studio/'>
                  <div className="row no-margin align-items-center mb-3">
                    <div className="col-auto">
                   
                      <img src={vibericon} alt="ivr-studio"
                        style={{ width: "75px" }}

                      />
                    </div>
                    <div className="col">
                      <h3 style={{ fontSize: '21px', fontWeight: '500px', color: '#3d3d3d' }}>Viber</h3>
                      <p style={{ fontSize: '13px', fontWeight: '400px', color: '#3d3d3d' }}>
                        Manage your Viber conversations with ease and automate interactions with your customers
                      </p>
                    </div>
                  </div>
                </Link>
              </div> */}

              {/* <div className="col-lg-6">
                <Link to='/products/cloud-phone/'>
                  
                  <div className="row no-margin align-items-center mb-3">
                    <div className="col-auto">
                     
                      <img src={telegramnewicon}
                        alt="cloudPhone"
                        style={{ width: "72px" }}
                      />
                    </div>
                    <div className="col">
                      <h3 className="homefefe"
                        style={{ fontSize: '21px', fontWeight: '500px', color: '#3d3d3d' }}>
                        Telegram
                      </h3>
                      <p style={{ fontSize: '13px', fontWeight: '400px', color: '#3d3d3d' }}>
                        Vibtree effortlessly links with your Telegram account and supports the Telegram BotFather.

                      </p>
                    </div>
                  </div>
                </Link>
          
              </div> */}

              {/* <div className="col-lg-6">
                <Link to='/products/cloud-number/'>
                  <div className="row no-margin align-items-center mb-3">
                    <div className="col-auto">
                  
                      <img src={messengericon} alt="cloudNumber"
                        
                        style={{ width: "72px" }}
                      />
                    </div>
                    <div className="col">
                      <h3 style={{ fontSize: '21px', fontWeight: '500px', color: '#3d3d3d' }}>Messenger</h3>
                      <p style={{ fontSize: '13px', fontWeight: '400px', color: '#3d3d3d' }}>
                        Connect your Facebook account with Vibtree, manage your Messenger and Instagram DMs without leaving the Dashboard.

                      </p>
                    </div>
                  </div>
                </Link>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
