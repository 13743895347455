import React, { useContext, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { useState } from 'react'
import { Helmet } from "react-helmet";
import './cloudPhone.css'

import { vibContext } from 'src/App';
import LoaderComponent from 'src/LoaderComponent';
import GetStarted from 'src/GetStarted';

export default function CloudPhone() { 
  const [accFirst, setaccFirst] = useState('yes');
  const [accSecond, setaccSecond] = useState('no');
  const [accThird, setaccThird] = useState('no');
  const [accFourth, setaccFourth] = useState('no');
  const [accFive, setaccFive] = useState('no');
  const [accSix, setaccSix] = useState('no');

  const [ImagesLoadCount, setImagesLoadCount] = useState(0);
  const [loader, setloader] = useState(true);

  const cpContext= useContext(vibContext);
  const contextId=cpContext.vibState;
  useEffect(() => {
    const element = document.getElementById(contextId);
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0
      });
    }, 100);
  },[contextId]);

  var handleClick1=()=>{
    setaccSecond('no')
    setaccThird('no')
    setaccFourth('no')
    setaccFive('no')
    setaccSix('no')
    return(
      accFirst==='yes' ? setaccFirst('no') : accFirst==='no' ? setaccFirst('yes') : null
    )
  }
  var handleClick2=()=>{
    setaccFirst('no');
    setaccThird('no');
    setaccFourth('no');
    setaccFive('no');
    setaccSix('no');
    return(
      accSecond==='yes' ? setaccSecond('no') : accSecond==='no' ? setaccSecond('yes') : null
    )
  }
  var handleClick3=()=>{
    setaccFirst('no');
    setaccSecond('no');
    setaccFourth('no');
    setaccFive('no');
    setaccSix('no');
    return(
      accThird==='yes' ? setaccThird('no') : accThird==='no' ? setaccThird('yes') : null
    )
  }
  var handleClick4=()=>{
    setaccFirst('no');
    setaccSecond('no');
    setaccThird('no');
    setaccFive('no');
    setaccSix('no');
    return(
      accFourth==='yes' ? setaccFourth('no') : accFourth==='no' ? setaccFourth('yes') : null
    )
  }
  var handleClick5=()=>{
    setaccFirst('no');
    setaccSecond('no');
    setaccThird('no');
    setaccFourth('no');
    setaccSix('no');
    return(
      accFive==='yes' ? setaccFive('no') : accFive==='no' ? setaccFive('yes') : null
    )
  }
  var handleClick6=()=>{
    setaccFirst('no')
    setaccSecond('no')
    setaccThird('no')
    setaccFourth('no')
    setaccFive('no')
    return(
      accSix==='yes' ? setaccSix('no') : accSix==='no' ? setaccSix('yes') : null 
    )
  }

  // image loading spinner start 
  const imagesLoadIndicator=()=>{
    const images = document.getElementsByClassName('classForLoaded');

    setImagesLoadCount(ImagesLoadCount+1);

    if (ImagesLoadCount===images.length-1) {
      setloader(false)
    }
  }
  // image loading spinner end

  return (
    <div style={{paddingBottom:'200px'}}>
        <Helmet>
        <title>Cloud-based phone system: Make calls & Send SMS, from anywhere you want</title>
        {/* <link rel="icon" href={favicon} /> */}
        <meta name="description" content="A Cloud Phone System for small businesses helps you set up a virtual phone that allows you to Make, Manage & Monitor calls from your browser." />
        <link rel="canonical" href="https://www.vibtree.com/products/cloud-phone/" />
        {/* og tags start  */}
        {/* <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Cloud-based phone system: Make calls & Send SMS, from anywhere you want" />
        <meta property="og:description" content="A Cloud Phone System for small businesses helps you set up a virtual phone that allows you to Make, Manage & Monitor calls from your browser." />
        <meta property="og:url" content="https://www.vibtree.com/products/cloud-phone/" />
        <meta property="og:site_name" content="Vibtree Technologies - Award winning cloud telephony service" />
        <meta property="og:image" content={`https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/get-features-of-phone-without-phone.png`}/>
        <meta property="og:image:secure_url" content={`https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/get-features-of-phone-without-phone.png`} />
        <meta property="og:image:width" content="700" />
        <meta property="og:image:height" content="700" />
        <meta property="og:image:alt" content="get-features-of-phone-without-phone"/>
        <meta property="og:image:type" content="image/png" /> */}
        {/* og tags end  */}
        {/* twitter tag start  */}
        {/* <meta name= "twitter:site" content="@vibtree"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cloud-based phone system: Make calls & Send SMS, from anywhere you want" />
        <meta name="twitter:description" content="A Cloud Phone System for small businesses helps you set up a virtual phone that allows you to Make, Manage & Monitor calls from your browser." />
        <meta name="twitter:image" content={`https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/get-features-of-phone-without-phone.png`} /> */}
        {/* twitter tag end  */}
        <script  type="application/ld+json">
        {`{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "Vibtree Technologies",
            "legalName": "Vibtree Technologies",
            "alternateName": "www.vibtree.com",
            "description": "A Cloud Phone System for small businesses helps you set up a virtual phone that allows you to Make, Manage & Monitor calls from your browser.",
            "url": "https://www.vibtree.com/products/cloud-phone/",
            "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
            "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "18005724590",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/vibtree",
              "https://twitter.com/vibtree",
              "https://www.instagram.com/vibtree",
              "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
              "https://www.linkedin.com/company/vibtree",
              "https://www.vibtree.com"
            ]}`}
        </script>

        <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "products"
                    }
                },{
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@id": "https://www.vibtree.com/products/cloud-phone/",
                        "name": "cloud-phone"
                    }
                }]
            }`}
        </script>
        
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What do you mean by Cloud Phone?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Cloud Phone allows businesses to manage inbound and outbound calls and SMS without having to 
              invest in a hard phone. With Vibtree cloud phone, users get all the features of a phone, 
              without the phone."
              }
            }, {
              "@type": "Question",
              "name": "What are inbound calls?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Inbound calls are the types of calls that a customer or prospect makes in order to 
              connect with a contact center or a call center or a business. Customers or 
              callers initiate an inbound call to a call center 
              usually do so to make inquiries about products and services, 
              as well as to make complaints."
              }
            }, {
              "@type": "Question",
              "name": "What are outbound calls?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": " Unlike an inbound call which may be initiated by the customer, an outbound 
              call is a call to a customer on behalf of a business or from a call center. 
              Generally outbound calls are a part of activities that businesses engage inas 
              a part of their business engagement plan. 
              Businesses now use modern technology to facilitate outbound calls, in order 
              to make the process more efficient and effortless. Special cloud telephont 
              tools like predictive dialer, cloud phone, voice broadcasting and auto 
              dialer etc, it is possible to make a large number of calls in a very 
              short time."
              }
            }, {
              "@type": "Question",
              "name": "How much does cloud-Phone service cost?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At Vibtree, all our product prices & packages are fully customizable. 
                 We only charge for the services you need based on your business requirements. 
                 Our service packs are highly flexible and can be adjusted according to the coud 
                 services you choose.  You can book a demo session for a customized pricing plan 
                 or start your free trial to explore pricing."
              }
            }, {
              "@type": "Question",
              "name": "What are the most common uses of cloud-phone Services?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Cloud-phone services are most commonly used by call centers, 
                 sales executives and inside sales specialists. It makes it easier 
                 for them to work remotely and in coordination with their teams."
              }
            }, {
              "@type": "Question",
              "name": "Can I use Cloud Phone service with Other cloud telephony services?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Cloud-Phone can be easily used with our business cloud telephony suite to enhance 
               your business operations. Users can use bundled cloud telephony services to customize 
               the services to match their business requirements. Our cloud telephony business suite 
               offers full features like IVR or voice menu, Bulk-SMS , call monitoring, call flows, 
               call tracking, contact center, lead center, cloud number, smart caller, voice 
               messenger and much more. 
               To get more information on cloud SMS, email us at info@vibtree.com."
              }
            }]
          }`}
        </script>

        </Helmet>
        {/* body schema tag start  */}
       <body itemScope itemType="https://schema.org/WebPage"></body>
       {/* body schema tag end  */}
       {loader===true ? <LoaderComponent/> : null}
       {/* banner start  */}
        <div className="banner sms-banner" 
        style={{
          position:"relative",
          top:"0px", 
          minHeight:"600px",
          paddingTop:"50px",
          overflow:'hidden',
          paddingRight:'0px',
          paddingLeft:'0px'}}>
          <div className="container-fluid">
            <div className="row align-items-center vh-100-middle  voice-allow-overflow vib-flex-wrap-reverse pb-5">
              <div className="col-lg-6 for-padding-remove voice-allow-overflow"  style={{marginBottom:'-20px'}}>
              <h1 className='vib-container-heading vib-res-left voice-allow-overflow banner-heading-width' 
              style={{marginTop:'60px',fontSize:'50px',lineHeight:'75px',fontWeight:'700'}}>
              Get All The Features Of A <br />Phone,<span className="vib-blue"> Without A Phone</span>
              </h1>
                <h5 className='vib-bold-para 
                               vib-res-left  
                               voice-allow-overflow 
                               banner-heading-width' 
                    style={{marginBottom:'30px',
                            fontSize:'28px',
                            marginTop:'20px',
                            lineHeight:'36px',
                            fontWeight:'400'}}>
                Communicate with clients, manage your call <br />center and send 
                text messages, all with a <br />small widget.
                </h5>
                
                <GetStarted/>

                {/* <div className="row mt-3 bannerresfor" style={{Height:"300px"}}>
                  <div className="col-12 p-0 banner-logo-slider" style={{position:"relative",left:"-13px",top:"20px",maxHeight:"170px"}}>
                    <div className="responsive-banner-logos">

                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-6 col-sm-10 cb-banner-img-new-resizeing m-auto" 
              style={{display:'flex',
                      justifyContent:'center',
                      marginTop:'50px',
                      alignItems:'center'}}>
                <img onLoad={imagesLoadIndicator}
                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/get-features-of-phone-without-phone.png' 
                className=
                "bannerdivimg  
                 vib-sms-left-margin-auto
                 cp-banner-img-height
                 img-fluid
                 classForLoaded" 
                alt='get-features-of-phone-without-phone'
                style={{marginTop:'50px'}} />
              </div>
            </div>
          </div>
    </div>
    {/* banner end  */}
    {/* <SimpleSliderCloudPhone/> */}
    {/* internet based start  */}
    <div className="vib-inner-section-cp canceling-margin-top vib-container">
        
        <h1 className='vib-container-sub-heading vib-res-center vib-margin-top-200-50' 
        style={{fontSize:'50px',width:'100vw',fontWeight:'700',lineHeight:'75px'}}>
        <span className='vib-blue'>Cloud Phone </span><br />A Phone System On Your Browser
        </h1>
            <div className="vib-content-section 
                            bottom-margin-50 
                            vib-res-margin-top-100-50" 
                 style={{marginBottom:'0px',maxWidth:'520px'}}>
              <h5 className='vib-bold-para vib-res-left' 
                  style={{marginTop:'10px',
                          fontSize:'24px',
                          lineHeight:'36px',
                          margin:'auto',
                          fontWeight:'400'}}>
              Throw away your 90s bulky hard phones. Get your business 
              phone ready with this easy to set-up calling solution by 
              Vibtree. Make & receive calls and SMS from your desktop 
              and monitor your team seamlessly with this easy to use 
              and flexible browser phone. <br />

              <span className='vib-blue-small' 
                    style={{lineHeight:'70px'}}>
                    App coming soon!! 
              </span>

              </h5>
            </div>
        
            <div className="vib-img-section vib-res-margin-top-100-50 cp-image-padding" style={{width:'auto'}}>
                <img 
                    onLoad={imagesLoadIndicator} 
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/phone-system-on-your-browser.gif' 
                    alt="phone-system-on-your-browser" 
                    className='vib-img classForLoaded' 
                    style={{maxWidth:'580px'}}
                />
            </div>
        
    </div>
    {/* internet based end  */}

    {/*make manage monitor start*/}
    <div className="vib-inner-section canceling-margin-top px-3">
        
        <h1 className='vib-container-sub-heading vib-res-center vib-margin-top-200-50' 
        style={{fontSize:'50px',width:'100vw',fontWeight:'700',lineHeight:'75px'}}>
        <span className='vib-blue'>'Make-Manage-Monitor' 
        </span><br />Calls With Cloud Phone
        </h1>
            <div className="vib-content-section 
                            bottom-margin-50 
                            vib-res-margin-top-100-50" 
                 style={{marginBottom:'0px',maxWidth:'520px'}}>
              <h5 className='vib-bold-para vib-res-left' 
                  style={{marginTop:'10px',
                          fontSize:'24px',
                          lineHeight:'36px',
                          margin:'auto',
                          fontWeight:'400'}}>
              Vibtree's Cloud Phone offers a flexible way of connecting with 
              your customers through your browser phone, just like your mobile 
              phone, without the need of an actual device. Manage and monitor 
              your agent's calls and allow them to work remotely without worrying 
              about giving them a phone device or a sim card. 
              </h5>
            </div>
        
            <div className="vib-img-section vib-res-margin-top-100-50" style={{width:'auto'}}>
                <img onLoad={imagesLoadIndicator} 
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/make-manage-monitor-calls-with-cloud-phone.svg' 
                    alt="make-manage-monitor-calls-with-cloud-phone" 
                    className='vib-img classForLoaded'
                    />
            </div>
        
    </div>

        {/* manage calls start  */}
        <div className="manage-calls-sections-wrapper px-3">
            {/* one-zig-zag-start  */}
        <div className="vib-inner-section">
            <div className="bottom-margin-50" style={{maxWidth:'680px'}}>
              <h1 className='vib-container-sub-heading vib-res-left' 
                  style={{fontSize:'40px',lineHeight:'70px',fontWeight:'700'}}>
              <span className='blue-under-line' style={{paddingBottom:' 15px',lineHeight:'70px'}}>Manag</span>e 
               Calls While On The Move
              </h1>
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
              You can process all your inbound and outbound calls using your 
              phone or your browser to manage all your callers 
              from office or from home.   
              </h5>
            </div>

            <div className="vib-img-section" style={{maxWidth:'356px'}}>
                <img 
                    onLoad={imagesLoadIndicator} 
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/manage-calls-while-on-move.svg' 
                    alt="manage-calls-while-on-move" 
                    className='vib-img classForLoaded'/>
            </div>

        </div>

        <div className="vib-inner-section-reverse sms-margin-top-10">

            <div className="vib-img-section" style={{maxWidth:'356px'}}>
                    <img 
                       onLoad={imagesLoadIndicator} 
                       src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/choose-calling-number.svg' 
                       alt="choose-calling-number" 
                       className='vib-img classForLoaded'/>

            </div>

            <div className="bottom-margin-50" style={{maxWidth:'680px'}}>
              <h1 className='vib-container-sub-heading vib-res-right'
                  style={{fontSize:'40px',lineHeight:'70px',fontWeight:'700'}}>
              Choose Calling N<span className='blue-under-line' style={{paddingBottom:' 15px'}}>umber</span>
              </h1>

              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                 Once you set-up your cloud phone with all your virtual 
                 and mobile numbers, you can easily choose which number 
                 you want to call from.  
              </h5>
            </div>

        </div>
        
        {/* one-zig-zag-end  */}
         {/* two-zig-zag-start  */}
         <div className="vib-inner-section sms-margin-top-10" style={{marginTop:'0px'}}>
            <div className="bottom-margin-50" style={{maxWidth:'680px'}}>
              <h1 id='click-to-call' className='vib-container-sub-heading vib-res-left' 
                  style={{fontSize:'40px',lineHeight:'70px',fontWeight:'700'}}>
                  Slow Internet ?? Don’t Worry <br />
              <span className='blue-under-line' style={{paddingBottom:' 15px'}}>"Call fro</span>m Phone" 
              </h1>
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
              You can now make calls using your phone even<br /> when your 
              internet is slow. The system will place a<br /> call to 
              your team, then to the customer and combine<br /> the 
              calls using the network of your mobile    
              </h5>
            </div>

            <div className="vib-img-section" style={{maxWidth:'356px'}}>
                <img 
                    onLoad={imagesLoadIndicator} 
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/slow-internet-dont-worry-call-from-phone.svg' 
                    alt="slow-internet-dont-worry-call-from-phone" 
                    className='vib-img classForLoaded'/>
            </div>

        </div>

        <div className="vib-inner-section-reverse sms-margin-top-10">

            <div className="vib-img-section" style={{maxWidth:'356px'}}>
                    <img 
                        onLoad={imagesLoadIndicator} 
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/slow-internet-dont-worry-call-from-phone.svg' 
                        alt="use-your-browser-to-call-customers" 
                        className='vib-img classForLoaded'/>

            </div>

            <div id='call-recording' className="bottom-margin-50" style={{maxWidth:'680px'}}>
              <h1 className='vib-container-sub-heading vib-res-right'
                  style={{fontSize:'40px',lineHeight:'70px',fontWeight:'700'}}>
              Use Your Browser To Call <br />Cu
              <span className='blue-under-line' style={{paddingBottom:' 15px'}}>stomers</span>
              </h1>

              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                 Based on WebRTC technology, users can now<br /> make and 
                 receive calls easily with our browser<br /> phone. Cloud 
                 phone is the perfect fit for<br /> WebRTC calls.  
              </h5>
            </div>

        </div>
        
        {/* two-zig-zag-end */}
       {/* four-zig-zag-start  */}
       <div className="vib-inner-section sms-margin-top-10" style={{marginTop:'0px'}}>
            <div className="bottom-margin-50" style={{maxWidth:'680px'}}>
              <h1 className='vib-container-sub-heading vib-res-left' 
                  style={{fontSize:'40px',lineHeight:'70px',fontWeight:'700'}}>
                  View Call History, Just Like<br />
              <span className='blue-under-line' style={{paddingBottom:' 15px'}}> Your Ph</span>one
              </h1>
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
              Just like a normal phone, users can access the call 
              history using the cloud phone, either on their 
              browser or through their phone.     
              </h5>
            </div>

            <div className="vib-img-section" style={{maxWidth:'356px'}}>
                <img 
                    onLoad={imagesLoadIndicator} 
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/view-call-history-just-like-your-phone.svg' 
                    alt="view-call-history-just-like-your-phone" 
                    className='vib-img classForLoaded'/>
            </div>

        </div>

        <div className="vib-inner-section-reverse sms-margin-top-10">

            <div className="vib-img-section-cp">
                    <img 
                        onLoad={imagesLoadIndicator} 
                        src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/call-tags-and-notes.svg' 
                        alt="add-call-tags-and-notes-to-ease-tracking" 
                        className='vib-img classForLoaded'/>

            </div>

            <div className="bottom-margin-50" style={{maxWidth:'680px'}}>
              <h1 className='vib-container-sub-heading vib-res-right'
                  style={{fontSize:'40px',lineHeight:'70px',fontWeight:'700'}}>
              Add Call Tags & Notes To Ease <br />T
              <span className='blue-under-line' style={{paddingBottom:' 15px'}}>racking</span>
              </h1>

              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                 Maintain your call details through call tags & notes 
                 to segment users who answered the calls, disconnected 
                 or were interested in services.  
              </h5>
            </div>

        </div>
        
        {/* four-zig-zag-end  */}
        <div className="vib-inner-section" /*style={{marginTop:'0px'}}*/>
            <div className="bottom-margin-50" style={{maxWidth:'680px'}}>
              <h1 className='vib-container-sub-heading vib-res-left' 
                  style={{fontSize:'40px',lineHeight:'70px',fontWeight:'700'}}>
                  Movable Widget Simplifies <br />
              <span className='blue-under-line' style={{paddingBottom:' 15px'}}>Multitas</span>king
              </h1>
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
              Multitask while you answer calls by simply 
              minimizing or moving the call widget without 
              having to altogether exit from the browser.     
              </h5>
            </div>

            <div className="vib-img-section-cp">
                <img 
                    onLoad={imagesLoadIndicator} 
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/moveable-widget-simplifies-multitasking.gif' 
                    alt="moveable-widget-simplifies-multitasking" 
                    className='vib-img classForLoaded'/>
            </div>

        </div>
        
        </div>
    {/* manage calls end */}
    
    {/* why choose us start  */}
    <div className="vib-container px-3">
        <h1 className='vib-container-heading vib-res-center' 
        style={{fontWeight:'600',fontSize:'50px',lineHeight:'75px'}}>
        What Makes <span className="vib-blue">Vibtree Cloud-Phone</span> The <br />Best Choice For Your Business?  
        </h1>

        <div className="sms-cloud-flex">
          <div className="sms-cloud-flex-item">
            <img 
                onLoad={imagesLoadIndicator} 
                className='classForLoaded'
                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/scalable-flexible-and-reliable.svg' 
                alt="scalable-flexible-and-reliable"  
                style={{padding:'20px'}}/>
            <h6>Scalable, Flexible & Reliable</h6>
            <p>
            Our services are built for scale to offer a high level of flexibility 
            as our solutions are customized to grow along with your business.
            </p>
          </div>

          <div className="sms-cloud-flex-item">
            <img 
                onLoad={imagesLoadIndicator} 
                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/internationally-available.svg' 
                alt="internationally-available" 
                className='classForLoaded'
                style={{padding:'45px'}}/>
            <h6>Internationally Available</h6>
            <p>
            Reach your customers around the World as our services expand to more 
            than 20 countries around the Globe.  
            </p>
          </div>

          <div className="sms-cloud-flex-item">
            <img 
                onLoad={imagesLoadIndicator} 
                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/affordable-pricing-models.svg' 
                alt="affordable-pricing-models" 
                className='classForLoaded'
                style={{padding:'20px'}}/>
            <h6>Affordable Pricing Models</h6>
            <p>
            Only pay for the services that you use as we customize the pricing 
            plans for our clients based on their requirements.   
            </p>
          </div>

          <div id='call-monitoring' className="sms-cloud-flex-item">
            <img 
                onLoad={imagesLoadIndicator} 
                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/real-time-call-monitoring.svg' 
                alt="real-time-call-monitoring" 
                className='classForLoaded'
                style={{padding:'45px'}}/>
            <h6>Real-Time Call Monitoring</h6>
            <p>
            Get instant delivery confirmation on the messages in 
            real-time to track the overall status of the SMS.  
            </p>
          </div>

          <div className="sms-cloud-flex-item">
            <img 
                onLoad={imagesLoadIndicator} 
                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/available-24x7.svg' 
                alt="available-24x7" 
                className='classForLoaded'
                style={{padding:'0px'}}/>
            <h6>Available 24x7</h6>
            <p>
            Use our cloud-based services to reach your customers 
            around the globe, as we are always available at your service.    
            </p>
          </div>

          <div className="sms-cloud-flex-item">
           <img 
                onLoad={imagesLoadIndicator} 
                src='https://vibtreedan.s3.eu-central-1.amazonaws.com/CloudPhone/easy-crm-integration.svg' 
                alt="easy-crm-integration" 
                className='classForLoaded'
                style={{padding:'45px'}}/>
            <h6>Easy CRM Integration</h6>
            <p>
            Integrate our cloud-phone system with your existing CRM or 
            other softwares in order to get overall customer satisfaction. 
            </p>
          </div>
        </div>
    </div>
    {/* why choose us start  */}
    {/* call us now section start  */}
    <section className="book-a-call vib-res-margin-top px-3">
        <div className="container-fluid">
          <div className="row book-for-display-center d-flex vib-justify-content-evenly">
            <div className="col-lg-6 text-start book-for-half">
              <div className="wrap">
                <h2 className="text-start heading-extra-length" style={{fontSize:'33px',fontWeight:'700'}}>
                   Looking for the right fit for your Business?
                </h2>
                <p className='vib-bold-para' style={{fontSize:'33px',lineHeight:'45px',marginTop:'10px',fontWeight:'400'}}>
                   Call Us on <a style={{color:'#ffff'}} className='sms-inherit vib-h-y-u' href="tel:1800 572 4590">
                                 &nbsp;  <img 
                                             onLoad={imagesLoadIndicator} 
                                             src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/book-a-call/india.svg'
                                             alt="indian-flag" 
                                             className="indian-flag-front classForLoaded" 
                                             style={{width:'30px'}}/>&nbsp;
                                 1800 572 4590</a> or<br />
                   write to us - <a style={{color:'#ffff'}} className='sms-inherit vib-h-y-u' href="mailto:info@vibtree.com">info@vibtree.com</a> 
                </p>
                <div className="about-button-center">

                  <a href='tel:18005724590' className="hvr-bounce-to-bottom1 design1" 
                      style={{
                        backgroundColor:'#FFCE42',
                        color:'black',
                        fontWeight:'300',
                        fontSize:'26px',
                        padding:'5px',
                        width:'200px',
                        marginBottom:'50px'}}>
                      Call Us Now
                  </a>

                  </div>
              </div>
            </div>
            <div className="d-flex"
                 style={{display:'flex'}}>
              <img onLoad={imagesLoadIndicator}
                    src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/let-your-business-be-heard.svg' 
                    className="img-fluid 
                               book-image-now 
                               book-img-disappear 
                               vib-sms-left-margin-auto
                               classForLoaded" 
                    alt="let-your-business-be-heard"
                      />
              {/* <center><img onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/let-your-business-be-heard.svg' alt="let-your-business-be-heard" className='book-image-now book-img-appear'/></center> */}
              {/* <center> */}
              <img onLoad={imagesLoadIndicator} src='https://vibtreedan.s3.eu-central-1.amazonaws.com/voice/let-your-business-be-heard.svg' 
              alt="let-your-business-be-heard" 
              className='book-image-now book-img-appear classForLoaded'
              style={{margin:'auto'}}
              />
              {/* </center> */}
            </div>
          </div>
        </div>
      </section>
    {/* call us now section end */}
    {/* FAQ start  */}
    <div className="vib-container">
      <h1 className='vib-container-heading vib-res-center' style={{width:'100%',fontSize:'33px'}}>
         Frequently Asked Questions: <span className="vib-blue">Cloud-Based Messaging </span>
      </h1>
    </div>

    <div className="vib-container-narrow sms-accordin-margin-top" style={{marginTop:'50px'}}>
    <Accordion defaultActiveKey="0" style={{width:'100%', padding:'20px'}}>

        <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="0"
          onClick={handleClick1} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white',
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> What do you mean by Cloud Phone?</span>
             <div className="accordin-right-icon" 
             style={{transform: accFirst==='yes' ? 'rotate(90deg)':accFirst==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span> Cloud Phone allows businesses to manage inbound and outbound calls and SMS without having to 
              invest in a hard phone. With Vibtree cloud phone, users get all the features of a phone, 
              without the phone.  
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="1" 
          onClick={handleClick2} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> What are inbound calls?</span>
             <div className="accordin-right-icon" 
             style={{transform: accSecond==='yes' ? 'rotate(90deg)':accSecond==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span> Inbound calls are the types of calls that a customer or prospect makes in order to 
              connect with a contact center or a call center or a business. Customers or 
              callers initiate an inbound call to a call center 
              usually do so to make inquiries about products and services, 
              as well as to make complaints.   
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEvent:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="2" 
          onClick={handleClick3} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> What are outbound calls?</span>
             <div className="accordin-right-icon" 
             style={{transform: accThird==='yes' ? 'rotate(90deg)':accThird==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
              <span style={{fontWeight:'bold'}}>A.</span> Unlike an inbound call which may be initiated by the customer, an outbound 
              call is a call to a customer on behalf of a business or from a call center. 
              Generally outbound calls are a part of activities that businesses engage inas 
              a part of their business engagement plan. <br /><br />
              Businesses now use modern technology to facilitate outbound calls, in order 
              to make the process more efficient and effortless. Special cloud telephont 
              tools like predictive dialer, cloud phone, voice broadcasting and auto 
              dialer etc, it is possible to make a large number of calls in a very 
              short time.   
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="3" 
          onClick={handleClick4} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> How much does cloud-Phone service cost?</span>
             <div className="accordin-right-icon" 
             style={{transform: accFourth==='yes' ? 'rotate(90deg)':accFourth==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
                 <span style={{fontWeight:'bold'}}>A.</span> At Vibtree, all our product prices & packages are fully customizable. 
                 We only charge for the services you need based on your business requirements. 
                 Our service packs are highly flexible and can be adjusted according to the coud 
                 services you choose.  You can book a demo session for a customized pricing plan 
                 or start your free trial to explore pricing.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="4" 
          onClick={handleClick5} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> What are the most common uses of cloud-phone Services?</span>
             <div className="accordin-right-icon" 
             style={{transform: accFive==='yes' ? 'rotate(90deg)':accFive==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
                 <span style={{fontWeight:'bold'}}>A.</span> Cloud-phone services are most commonly used by call centers, 
                 sales executives and inside sales specialists. It makes it easier 
                 for them to work remotely and in coordination with their teams. 
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
        <Card className='accordin-card' style={{pointerEnter:'none',pointerOver:'none',marginBottom:'10px', borderRadius:'5px',overflow:'visible'}}>
          <Accordion.Toggle 
          as={Card.Header} 
          eventKey="5" 
          onClick={handleClick6} 
          style={{
            border:'none',
            borderRadius:'5px',
            fontSize:'25px',
            fontWeight:'600',
            boxShadow: 'rgb(0 70 187 / 10%) 5px 4px 23px 4px',
            overFlow:'visible',
            cursor:'pointer',
            color:'black',
            backgroundColor:'white'
            }}>
            <div className="accordin-top-arrow-container">
            <span><span style={{fontWeight:'700'}}>Q.</span> Can I use Cloud Phone service with Other cloud telephony services?</span>
             <div className="accordin-right-icon" 
             style={{transform: accSix==='yes' ? 'rotate(90deg)':accSix==='no'? 'rotate(0deg)':null}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-right accordin-right-icon" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
             </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body className='vib-bold-para vib-res-left ' 
            style={{
              marginTop:'0px',
              paddingLeft:'0px',
              paddingRight:'0px',
              fontSize:'23px',
              fontWeight:'400',
              lineHeight:'35px',
              backgroundColor:'none',
              border:'none !important'}}>
               <span style={{fontWeight:'bold'}}>A.</span> Cloud-Phone can be easily used with our business cloud telephony suite to enhance 
               your business operations. Users can use bundled cloud telephony services to customize 
               the services to match their business requirements. Our cloud telephony business suite 
               offers full features like IVR or voice menu, Bulk-SMS , call monitoring, call flows, 
               call tracking, contact center, lead center, cloud number, smart caller, voice 
               messenger and much more. <br />
               To get more information on cloud SMS, email us 
               at <a href="mailto:info@vibtree.com" className="vib-h-b-u" style={{color:'inherit'}}>info@vibtree.com.</a> 
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        
    </Accordion>
    </div>
    {/* FAQ end  */}
    </div>
  )
}
