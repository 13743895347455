// eslint-disable-next-line
import React, { createContext, useEffect, useReducer } from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.js';
import TawkTo from 'tawkto-react';
import './App.css';
import "./css/hover-min.css";
import "./css/style.css";
import './style.css';
import './style1.css';

import { Redirect, Route, Switch } from "react-router-dom";

import NavbarTop from './components/navbar/Navbar';
import HomePage from './components/homePage/HomePage';
import About from './components/aboutPage/About'
import Contact from './components/contactPage/Contact'
import FooterBase from './components/footer/FooterBase'
import PartnerWithUs from './components/partnerWithUsPage/PartnerWithUs1';
import Sms from './components/sms/Sms';
import Voice from './components/voice/Voice';
import CloudPhone from './components/cloudPhone/CloudPhone';
import CloudNumber from './components/cloudNumber/CloudNumber';
import CallTracking from './components/callTracking/CallTracking';
import IvrStudio from './components/ivrStudio/IvrStudio';
import Careers from './components/workWithUs/WorkWithUs';
import ThankYou from './components/thankYouAndUnsubscribe/ThankYou';
import UnSubscribe from './components/thankYouAndUnsubscribe/UnSubscribe';
import Blog from './components/Blog/BlogMain';
import { reducer } from './reducers'
import PrivacyPolicy from './components/footer/components/PrivacyPolicy';
import TermsOfUse from './components/footer/components/TermsOfUse';
import RefundPolicy from './components/footer/components/refundPolicy';
import Pricing from './components/pricing/Pricing';
import SiteMap from './SiteMap';
import Industry from './components/industry/Industry';
import FoodAndBeverage from './components/industry/FoodAndBeverage';
import Hospitality from './components/industry/Hospitality';
import { Provider } from 'react-redux';
import store from './redux/store';
import Error404Page from './Error404Page';
import Features from './components/features/Features';
import Questionpricing from './components/pricing/Questionpricing';
import homepage from './images/homepage.png'
import BookACall from './components/homePage/BookACall';


const initialState = 'null'

// eslint-disable-next-line
export const vibContext = createContext()
function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const spinner = document.getElementById('spinner');
  if (spinner && !spinner.hasAttribute('hidden')) {
    spinner.setAttribute('hidden', 'true');
  }

  useEffect(() => {

    var tawk = new TawkTo('5f3e50511e7ade5df4427904', '1eshsf9u8')

    tawk.onStatusChange((status) => {
      console.log('tawk status', status)
    })

  }, [])

  return (
    <vibContext.Provider value={{ vibState: state, vibDispatch: dispatch }}>
      <Provider store={store}>
        <div className="App backgroundimage"
            style={{
              backgroundImage: `url(${homepage})`,
              backgroundRepeat: "no-repeat",
              // backgroundPositionY: "450px",
              backgroundPositionX: "right",
              backgroundAttachment: "fixed",
            }}
        >
          <NavbarTop />
          <Switch>

            <Route exact path='/'><HomePage /></Route>
            <Route exact path='/pricing/'><Pricing /></Route>
            <Route exact path='/products/voice/'><Voice /></Route>
            <Route exact path='/products/bulk-sms/'><Sms /></Route>
            <Route exact path='/products/call-tracking/'><CallTracking /></Route>
            <Route exact path='/products/ivr-studio/'><IvrStudio /></Route>
            <Route exact path='/products/cloud-phone/'><CloudPhone /></Route>
            <Route exact path='/products/cloud-number/'><CloudNumber /></Route>
            <Route exact path='/company/about/'><About /></Route>
            <Route exact path='/company/contact/'><Contact /></Route>
            {/* <Route exact path='/feature/about/'><About /></Route> */}
            <Route exact path='/company/partner-with-us/'><PartnerWithUs /></Route>
            {/* <Route exact path='/feature/partner-with-us/'><PartnerWithUs /></Route> */}
            <Route exact path='/company/work-with-us/'><Careers /></Route>
            {/* <Route exact path='/feature/work-with-us/'><Careers /></Route> */}
            <Route exact path='/thank-you/'><ThankYou /></Route>
            <Route exact path='/unsubscribe/'><UnSubscribe /></Route>
            <Route exact path='/privacy-policy/'><PrivacyPolicy /></Route>
            <Route exact path='/terms-of-use/'><TermsOfUse /></Route>
            <Route exact path='/refund-policy/'><RefundPolicy /></Route>
            <Route path='/blog/'><Blog /></Route>
            <Route path='/features'><Features /></Route>

            <Route exact path='/sitemap/'><SiteMap /></Route>
            <Route exact path='/industry/education/'><Industry /></Route>
            <Route exact path='/industry/food-and-beverage/'><FoodAndBeverage /></Route>
            <Route exact path='/industry/hospitality/'><Hospitality /></Route>
            <Route exact path='/404/'><Error404Page /></Route>
            <Redirect to='/404/' />

          </Switch>
          <Switch>
            <Route exact path='/pricing/'>
              <BookACall />
              <Questionpricing />
            </Route>
            {/* <Route exact path='/features/'>
              <BookACall />
            </Route> */}

          </Switch>
          {/* <BookACall /> */}
          <FooterBase />
        </div>
      </Provider>
    </vibContext.Provider>
  );
}

export default App;
