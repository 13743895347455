import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";

export default function BlogAdminLogin() {



    const [values, setValues] = React.useState({
        username: '',
        password: '',
        showPassword: false,
    });
    
    const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    };

    // eslint-disable-next-line
    const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
    };

    // eslint-disable-next-line
    const handleMouseDownPassword = (event) => {
    event.preventDefault();
    };


    let history = useHistory();

    const auth = localStorage.getItem('vibauth-b');

    useEffect(() => {
        if(auth === 'authenticated'){
            history.push("/blog/admin/BlogList")
        } 
        // eslint-disable-next-line
    }, [])

    const handleLoginClick = () =>{



        if(values.username === 'admin' && values.password === 'admin123'){
            console.log('login ok');
            localStorage.setItem('vibauth-b','authenticated');
            history.push("/blog/admin/BlogList")
        } else{
            console.log('login not ok');
        }
       
    }

    return (
     <div className="container">
         <div className="row col-lg-6">
            <div className="col-lg-6">
                <div className="card ">
                    <div className="card-body">
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Username</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={values.username}
                                    onChange={handleChange('username')} />
                            <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                        </div>
                        <div className="mb-3">
                            <label  className="form-label">Password</label>
                            <input className="form-control" id="exampleInputPassword1" type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange('password')} />
                        </div>
                        <div className="mb-3 form-check">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                            <label className="form-check-label" >Check me out</label>
                        </div>
                        <button type="submit" className="btn btn-primary" onClick={handleLoginClick}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
         </div>



         {/* <LoginCover>
                <Grid container direction="row"  justify="center"  alignItems="center" style={{height: "100vh"}}>
                    <Grid item sm="6" container direction="row" justify="center"  alignItems="center">
                       
                    <LoginBox>
                        <Grid container direction="row" justify="center"  alignItems="center" spacing={1}>
                            <Grid item xs={12} style={{textAlign: "center"}}>
                                  
                            </Grid>
                            <Grid item xs={12} style={{textAlign: "center"}}>
                                <Typography variant="h6" gutterBottom >
                                    Admin Login
                                </Typography>
                            </Grid>


                            <Grid item xs={12} style={{textAlign: "center"}}>
                                <FormControl className={classes.margin} fullWidth>
                                    <InputLabel htmlFor="input-with-icon-adornment">Username</InputLabel>
                                    <Input
                                    id="input-with-icon-adornment"
                                    fullWidth
                                    value={values.username}
                                    onChange={handleChange('username')}
                                    startAdornment={
                                        <InputAdornment position="start">
                                        <PermIdentityOutlinedIcon />
                                        </InputAdornment>
                                    }
                                    
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} style={{textAlign: "center"}}>
                                <FormControl className={clsx(classes.margin, classes.textField)}>
                                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    startAdornment={
                                        <InputAdornment position="start">
                                        <LockOutlinedIcon />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    
                                />
                                </FormControl>
                            </Grid>
                            
                        </Grid>
                        
                        <Grid>
                            <Grid item xs={12} style={{textAlign: "center"}}>
                                   
                               <Button className={classes.ButtonCustom} onClick={handleLoginClick} >Login</Button>
                            
                              
                            </Grid>
                        </Grid>
                            
                    </LoginBox>


                    </Grid>
                </Grid>
         </LoginCover> */}
     </div>
    );
  }


  