import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import breadcrumbSliceReducer from './breadcrumbSlice'
import scrollchooseSlice from './scrollcss'


export default configureStore({
    reducer: {
        breadcrumbSliceReducer,
        scrollchooseSlice

    },
    middleware: (defaultMiddleware) => defaultMiddleware().concat(logger),
    devTools: process.env.NODE_ENV !== 'production'
})