import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import Banner from './Banner';
import HomeFeatures from './HomeFeatures';
import IndustriesWeServe from './IndustriesWeServe';
import WhyChooseVibtree from './WhyChooseVibtree';
import BookACall from './BookACall';
import Testimonial from './Testimonial';
import ServicesWeOffer from './ServicesWeOffer';
import { Coursel } from './Coursel';
import Group from './../../images/Group.png'

function getWindowDimensions() {
    const { innerWidth: widthhomepage, innerHeight: heighthomepage } = window;
    return {
        widthhomepage,
        heighthomepage
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


export default function HomePagelaptop() {
    const { heighthomepage, widthhomepage } = useWindowDimensions();

    const laptopdiv = () => (
        <div

        >

        </div>
    )

    return (
        <div
            style={{

                backgroundImage: `url(${Group})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionY: "450px",
                backgroundPositionX: "right",
                backgroundAttachment: "fixed",
                backgroundSize: "300px",
            }}
        >
            <Helmet>
                <title>Vibtree: Best Cloud Telephony Services | Contact Center Solutions</title>
                <meta name="description" content="Vibtree is the best toll free number provider among the top cloud telephony companies in India for customized & reliable cloud based call center phone systems." />
                <link rel="canonical" href="https://www.vibtree.com/" />
                <script type="application/ld+json">
                    {`{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "Vibtree Technologies",
            "legalName": "Vibtree Technologies",
            "alternateName": "www.vibtree.com",
            "description": "Vibtree is the best toll free number provider among the top cloud telephony companies in India for customized & reliable cloud based call center phone systems.",
            "url": "https://www.vibtree.com/",
            "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
            "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "18005724590",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/vibtree",
              "https://twitter.com/vibtree",
              "https://www.instagram.com/vibtree",
              "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
              "https://www.linkedin.com/company/vibtree",
              "https://www.vibtree.com"
            ]}`}
                </script>

            </Helmet>
            {/* body schema tag start  */}
            <body itemScope itemType="https://schema.org/WebPage"></body>
            {/* body schema tag start  */}
            {/* <ThankYou/> */}
            <Banner />
            <HomeFeatures />
            <WhyChooseVibtree />
            {/* <ServicesWeOffer/> */}

            <BookACall />
            {/* <IndustriesWeServe /> */}
            <Testimonial />
            <Coursel />

        </div>
    )
}
