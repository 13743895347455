import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import './contact.css'

import { vibContext } from 'src/App'

export default function Contact(){
  const [modalShow, setModalShow] = useState(false);

  const [ImagesLoadCount, setImagesLoadCount] = useState(0);
  const [loader, setloader] = useState(true);
  
  // scroll to the specific position start 
    const aboutContext= useContext(vibContext);
    const contextId=aboutContext.vibState;
    useEffect(() => {
      const element = document.getElementById(contextId);
      setTimeout(() => {
        window.scrollTo({
          behavior: element ? "smooth" : "auto",
          top: element ? element.offsetTop : 0
        });
      }, 100);
    },[contextId]);
  // scroll to the specific position end

      // image loading spinner start 
  const imagesLoadIndicator=()=>{
    const images = document.getElementsByClassName('classForLoaded');

    setImagesLoadCount(ImagesLoadCount+1);

    if (ImagesLoadCount===images.length-1) {
      setloader(false)
    }

  }
  // image loading spinner end

  return (
    <div className="about-outer-container">
        <Helmet>
        <title>Vibtree: Best Cloud Telephony Services | Contact Center Solutions</title>
        {/* <link rel="icon" href={favicon} /> */}
        <meta name="description" content="Vibtree is the best toll free number provider among the top cloud telephony companies in India for customized & reliable cloud based call center phone systems." />
        <link rel="canonical" href="https://www.vibtree.com/company/about/" />
        <script  type="application/ld+json">
        {`{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "Vibtree Technologies",
            "legalName": "Vibtree Technologies",
            "alternateName": "www.vibtree.com",
            "description": "Vibtree is the best toll free number provider among the top cloud telephony companies in India for customized & reliable cloud based call center phone systems.",
            "url": "https://www.vibtree.com/company/about/",
            "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
            "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "18005724590",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/vibtree",
              "https://twitter.com/vibtree",
              "https://www.instagram.com/vibtree",
              "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
              "https://www.linkedin.com/company/vibtree",
              "https://www.vibtree.com"
            ]}`}
        </script>

        <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "company"
                    }
                },{
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@id": "https://www.vibtree.com/company/about/",
                        "name": "about"
                    }
                }]
            }`}
        </script>
       
        </Helmet>
        {/* body schema tag start  */}
        <body itemScope itemType="https://schema.org/WebPage"></body>
        {/* body schema tag start  */}

      
     

            {/* leader section start  */}
            <div className="pb-5 pt-5" style={{backgroundColor:'#0046BB'}}>
            <div className="vib-inner-section">

                <div className="vib-content-section">
                  <h1 className='vib-container-heading text-center' style={{color:'#FAFAFA'}}>
                  Contact Us
                  </h1>

                </div>
    
              
            </div>
            </div>
            <div className="pb-5  text-center">
              <div className='row vib-inner-section'>
              <div className='col-md-2'></div>
                <div className='col-md-4 pb-5'>
                  <h5>Write To Us</h5>
                  <h3>Info@vibtree.com</h3>

                 
                </div>

                <div className='col-md-4 pb-5'>
                 
                  <h5>Give us a call onWrite To Us</h5>
                  <h3>  <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/book-a-call/india.svg' alt="indian-flag" className="indian-flag-front" style={{ width: '20px' }} />&nbsp; 1800 572 4590</h3>

                </div>
                <div className='col-md-2'></div>
              </div>
            </div>
            {/* leader section end */}
    </div>
  )
}
