import { createSlice } from '@reduxjs/toolkit'

export const scrollchooseSlice = createSlice({
    name: 'scroll',
    initialState: {
        value: 'scrolltable',
    },
    reducers: {
        incrementscrollchoose: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementscrollchoose } = scrollchooseSlice.actions

export default scrollchooseSlice.reducer