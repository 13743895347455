import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import GetStarted from 'src/GetStarted';
import LoaderComponent from 'src/LoaderComponent'

//images
import bannerImage from '../../images/industry/food-and-beverage-industry/cloud-telephony-for-food-and-beverage-industry.png'
import reduceCost from '../../images/industry/reduced-cost-of-communication.png'
import autoFeedback from '../../images/industry/auto-feedback-and-survey.png'
import combinedCalls from '../../images/industry/combined-call-reports.png'
import increasedProd from '../../images/industry/food-and-beverage-industry/personalize-customer-experience.png'
import remindersAndNotifications from '../../images/industry/food-and-beverage-industry/promote-offers-and-discounts.png'

import monitorCampaigns from '../../images/industry/monitor-campaigns-with-analytics-dashboard.png'
import admissionPromotions from '../../images/industry/admission-promotions-with-voice-and-sms-broadcast.png'
import helpCustomers from '../../images/industry/food-and-beverage-industry/help-customers-reach-your-business-with-a-toll-free-number.png'
import improveCustomer from '../../images/industry/run-campaigns-with-virtual-and-toll-free-numbers.png'
import drawingIn from '../../images/industry/food-and-beverage-industry/drawing-in-more-leads-with-missed-call-service.png'
import understandCustomer from '../../images/industry/food-and-beverage-industry/understand-customer-feedback-and-expectations-with-crm-Integration.png'
import keepYourCustomer from '../../images/industry/food-and-beverage-industry/keep-your-customer-s-privacy-intact-with-number-masking-service.png'
import orderReservation from '../../images/industry/food-and-beverage-industry/order-reservation-or-booking-confirmation-with-sms-and-ivr-service.png'

import IndustryFAQ from './IndustryFAQ';
import HaveMoreQuestions from './HaveMoreQuestions';
import VibtreeBenefits from './VibtreeBenefits';
import scrollToTop from 'src/helper/scrollToTop';


export default function FoodAndBeverage() {
  const [loader, setloader] = useState(true);
  const [ImagesLoadCount, setImagesLoadCount] = useState(0);
  
    // image loading spinner start 
    const imagesLoadIndicator=()=>{
      const images = document.getElementsByClassName('classForLoaded');
  
      setImagesLoadCount(ImagesLoadCount+1);
  
      if (ImagesLoadCount===images.length-1) {
        setloader(false)
      }
    }
    // image loading spinner end
  
    // scroll to top start 
      useEffect(() => {
        scrollToTop(); 
      }, []);
    // scroll to top end

  return (
    <div style={{marginBottom:'150px',marginTop:'100px'}} >
    <Helmet>
          <title>Food & Beverage Industry - Cloud Telephony Solutions | Vibtree</title>
          <meta name="description" content="Using cloud telephony for food & beverage industry- IVR services, missed call service, toll free numbers, virtual numbers, cloud sms, CRM integration & more." />
          <link rel="canonical" href="https://www.vibtree.com/industry/food-and-beverage/" />
      
          <script  type="application/ld+json">
          {`{"@context": "https://schema.org",
              "@type": "Organization",
              "name": "Vibtree Technologies",
              "legalName": "Vibtree Technologies",
              "alternateName": "www.vibtree.com",
              "description": "Using cloud telephony for food & beverage industry- IVR services, missed call service, toll free numbers, virtual numbers, cloud sms, CRM integration & more.",
              "url": "https://www.vibtree.com/industry/food-and-beverage/",
              "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
              "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "18005724590",
                "contactType": "customer service",
                "contactOption": "TollFree",
                "availableLanguage": "en"
              },
              "sameAs": [
                "https://www.facebook.com/vibtree",
                "https://twitter.com/vibtree",
                "https://www.instagram.com/vibtree",
                "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
                "https://www.linkedin.com/company/vibtree",
                "https://www.vibtree.com"
              ]}`}
          </script>
      
          <script type="application/ld+json">
              {`{
                  "@context": "http://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": [{
                      "@type": "ListItem",
                      "position": 1,
                      "item": {
                          "@id": "https://www.vibtree.com/",
                          "name": "vibtree"
                      }
                  },{
                      "@type": "ListItem",
                      "position": 2,
                      "item": {
                          "@id": "https://www.vibtree.com/industry/food-and-beverage/",
                          "name": "food-and-beverage"
                      }
                  }]
              }`}
          </script> 
          
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "Why is cloud telephony needed in the Education industry?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Cloud telephony system in the education 
              industry facilitates automation of different communication and customer experience 
              operations with features like auto-receptionist, call recording, IVR, bulk SMS, voice 
              broadcast and call tracking etc. It helps your institution or organization streamline 
              the communication with students and parents."
                }
              }, {
                "@type": "Question",
                "name": "Which cloud telephony features can be used for the Education Industry?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "In the times of digitization, 
              cloud telephony solutions are helping the education sector to stay on 
              track when it comes to communication with their students and parents. 
              Operating with limited employees is the major challenge in the times of 
              pandemic and post pandemic, so services like Toll free virtual number, IVR, 
              call tracking, bulk SMS and voice broadcasting prove to be beneficial 
              for the education industry."
                }
              }, {
                "@type": "Question",
                "name": "What are your pricing plans?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our pricing plans are flexible so our customers 
              only pay for the services they use depending on their business needs. Visit our 
              pricing page to understand our different pricing plans."
                }
              }, {
                "@type": "Question",
                "name": "Do you offer customized solutions for different businesses?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we deliver customized solutions depending 
              on your business requirements to ensure that you do not pay for more services than you use. 
              All you need to do is to talk to our consultants to ensure that your plan is fully 
              customized to your needs."
                }
              }]
            }`}
          </script>
      
    </Helmet>

    {/* body schema tag start  */}
      <body itemScope itemType="https://schema.org/WebPage"></body>
    {/* body schema tag start  */}

        {loader===true ? <LoaderComponent/> : null}

        {/* banner start  */}
        <div className="banner sms-banner" style={{position:"relative",top:"0px", minHeight:"600px",overflow:'hidden'}}>
          <div className="container-fluid margin-top-res-sms">
            <div className="row vh-100-middle justify-content-center-1000 banner-breaking-padding-1000" style={{flexWrap:'wrap-reverse'}}>
              <div className="col-lg-6 for-padding-remove" >
              <h1 className='vib-container-heading 
                             vib-res-left 
                             vib-blue 
                             voice-allow-overflow 
                             banner-heading-width' 
                  style={{marginTop:'60px',fontSize:'50px',lineHeight:'70px'}}>
              Cloud Telephony For Food <br />& Beverage Industry
              </h1>
                <h5 
                className='vib-bold-para 
                           vib-res-left 
                           voice-allow-overflow 
                           banner-heading-width' 
                style={{marginBottom:'40px',
                        fontSize:'24px',
                        fontWeight:'400',
                        lineHeight:'40px',
                        color:'#7C8087'}}>
                Vibtree Communication Solutions Uniquely Curated For <br />F&B Industry 
                </h5>
                
                <GetStarted/>

              </div>
              <div className="col-lg-6 banner-break-point-breaker-1000" style={{display:'flex',justifyContent:'center'}}>

                  <img onLoad={imagesLoadIndicator}
                      src={bannerImage} // use normal <img> attributes as props
                      
                      className="img-fluid 
                                 bannerdivimg 
                                 vib-sms-left-margin-auto
                                 mb-5
                                 classForLoaded" 
                      alt='tailored-and-automated-bulk-sms-services'
                      />
              </div>
            </div>
          </div>
        </div>
        {/* banner end  */}

        
        {/* simplifying food and beverage start  */}
        <h2 className='ind-sim-h2 
                       vib-res-margin-top-200-100'>
          Simplifying Food & Beverage Industry With Cloud Telephony Solutions
        </h2>

        <article className='ind-sim-article vib-res-margin-top-50-50'>
          Offer world-class communication and customer service to your customers 
          with our cloud telephony services like IVR, voice broadcasting, 
          missed call services, bulk SMS, toll free numbers and many more.
        </article>

        <div className="ind-wrapper vib-res-margin-top-100-50">
            <figure className="ind-wrapper-item">
              <img src={increasedProd} alt="personalize-customer-experience" />
              <figcaption>Personalize customer experience</figcaption>
            </figure>

            <figure className="ind-wrapper-item">
              <img src={reduceCost} alt="reduced-cost-of-communication" />
              <figcaption>Order/reservation confirmation</figcaption>
            </figure>

            <figure className="ind-wrapper-item">
              <img src={remindersAndNotifications} alt="promote-offers-and-discounts" />
              <figcaption>Promote offers and discounts</figcaption>
            </figure>

            <figure className="ind-wrapper-item">
              <img src={combinedCalls} alt="combined-call-reports" />
              <figcaption>Track your marketing efforts</figcaption>
            </figure>

            <figure className="ind-wrapper-item">
              <img src={autoFeedback} alt="auto-feedback-and-survey" />
              <figcaption>Customer feedback & surveys</figcaption>
            </figure>
        </div>
        {/* simplifying  food and beverage  end */}

        {/* how is vibtree resolving  food and beverage  industry start  */}
        <h2 className='ind-sim-h2 
                       vib-res-margin-top-200-100'>
          How is Vibtree Resolving Food & Beverage Industry Problems?
        </h2> 

        <div className="vib-inner-section 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap-reverse'}}>
            <div className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-left vib-res-margin-top-50-50' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Track, monitor and maximize <br/>
                  marketing ROI with multiple <br/>
              <span className='blue-under-line' style={{paddingBottom:' 15px'}}>Virtual N</span>umbers
              </h1> 
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'36px',
                      color:'#7C8087',
                      fontWeight:'normal',
                      maxWidth:'600px'}}>
                    Assign different virtual numbers to 
                    all your campaigns and track their performance 
                    by using our call tracking feature. Track the 
                    call responses and monitor your campaign success. 
                    Ensure round the clock availability and maximize 
                    your ROI with virtual numbers by rerouting 
                    the incoming calls to your number.  
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image" style={{maxWidth:'500px'}}>
                <img 
                     src={monitorCampaigns}
                     alt="admission-promotions-with-voice-and-sms-broadcast" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>

        <div className="vib-inner-section-reverse 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap'}}>

            <div className="vib-img-section ivr-zig-zag-image"  style={{maxWidth:'500px'}}>
                    <img 
                         src={admissionPromotions}
                         alt="choose-calling-number" 
                         className='vib-img classForLoaded'
                         onLoad={imagesLoadIndicator}
                         />

            </div>
             
            
            <div id='virtual-receptionist' className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-right  vib-res-margin-top-50-50'
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Market your brand and offers with<br />
                  Voice & SMS broad<span className='blue-under-line' style={{paddingBottom:' 15px'}}>casting</span>
              </h1> 

              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                    Promote the ongoing offers and discounts to a large 
                    customer group with voice and sms broadcasting services. 
                    Record your own voice message or type a text message 
                    and choose what fits your business needs and schedule 
                    the broadcast at a time you like.  
              </h5>
            </div>
        </div>

        <div className="vib-inner-section 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap-reverse'}}>
            <div className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-left vib-res-margin-top-50-50' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Help customers reach your <br/>
                  <span className='blue-under-line' style={{paddingBottom:' 15px'}}>busines</span>s with a Toll Free Number
              </h1>    
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'36px',
                      color:'#7C8087',
                      fontWeight:'normal',
                      maxWidth:'600px'}}>
                Let your customers find you on a single number rather than 
                having to dial multiple mobile numbers. Have a single toll 
                free number for your business where customers can call anytime 
                to get the resolution for their query without paying any 
                charges for the call.   
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image" style={{maxWidth:'500px'}}>
                <img 
                     src={helpCustomers}
                     alt="send-notification-and-remainders-with-bulk-and-voice-broadcasting" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>

        <div className="vib-inner-section-reverse 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap'}}>

            <div className="vib-img-section ivr-zig-zag-image"  style={{maxWidth:'500px'}}>
                    <img 
                         src={improveCustomer}
                         alt="run-campaigns-with-virtual-and-toll-free-numbers" 
                         className='vib-img classForLoaded'
                         onLoad={imagesLoadIndicator}
                         />

            </div>
            
            <div id='virtual-receptionist' className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-right  vib-res-margin-top-50-50'
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Improve customer support service<br />
                  with smart ro<span className='blue-under-line' style={{paddingBottom:' 15px'}}>uted IVR</span>
              </h1>  
               
              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                    Streamline your customer query resolution with IVR. 
                    Offer your customers a custom designed smart IVR and 
                    redirect their calls to the appropriate department 
                    automatically. Allow your customers to make bookings 
                    over a call without having your agents or managers 
                    intervene. The auto receptionist feature will help 
                    you answer your customers incoming calls to ensure 
                    complete customer satisfaction.  
              </h5>
            </div>
        </div>

        <div className="vib-inner-section 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap-reverse'}}>
            <div className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-left vib-res-margin-top-50-50' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Drawing in more leads with<br/>
                  <span className='blue-under-line' style={{paddingBottom:' 15px'}}>missed c</span>all service
              </h1>      
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'36px',
                      color:'#7C8087',
                      fontWeight:'normal',
                      maxWidth:'600px'}}>
                    Missed call services can be used for multiple processes 
                    in the F&B industry, mainly lead generation. Advertise 
                    your missed call number on your campaigns, collect leads 
                    through missed calls and follow-up with them accordingly.    
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image" style={{maxWidth:'500px'}}>
                <img 
                     src={drawingIn}
                     alt="monitor-campaigns-with-analytics-dashboard" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>

        <div className="vib-inner-section-reverse 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap'}}>

            <div className="vib-img-section ivr-zig-zag-image"  style={{maxWidth:'500px'}}>
                    <img 
                         src={understandCustomer}
                         alt="call-tracking-to-track-incoming-calls-from-campaigns" 
                         className='vib-img classForLoaded'
                         onLoad={imagesLoadIndicator}
                         />

            </div>
            
            <div id='virtual-receptionist' className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-right  vib-res-margin-top-50-50'
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Understand customer feedback &<br />
                  expectations with CRM Int<span className='blue-under-line' style={{paddingBottom:' 15px'}}>egration</span>
              </h1>   
                
              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                 Integrate IVR with CRM to offer personalized support 
                 to your customers when they contact your business. 
                 Gain in-depth understanding of your customer-business 
                 interaction and seamlessly follow the lead to customer 
                 cycle on your CRM platform. Vibtree also offers custom 
                 CRM built specifically according to your business needs. 
              </h5>
            </div>
        </div>

        <div className="vib-inner-section 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap-reverse'}}>
            <div className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-left vib-res-margin-top-50-50' 
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Keep your customer's privacy<br/>
                  intact with number masking<br/>
                  <span className='blue-under-line' style={{paddingBottom:' 15px'}}>service</span>
              </h1>         
              <h5 
              className='vib-bold-para vib-res-left' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'36px',
                      color:'#7C8087',
                      fontWeight:'normal',
                      maxWidth:'600px'}}>
                    By masking the customers and delivery agents numbers, 
                    you can prevent your customer's contact privacy. Number 
                    masking has become one of the most essential tools for 
                    businesses these days.    
              </h5>
            </div>

            <div className="vib-img-section ivr-zig-zag-image" style={{maxWidth:'500px'}}>
                <img 
                     src={keepYourCustomer}
                     alt="monitor-campaigns-with-analytics-dashboard" 
                     className='vib-img classForLoaded'
                     onLoad={imagesLoadIndicator}
                     />
            </div>

        </div>

        <div className="vib-inner-section-reverse 
                        vib-res-margin-top-100-50 
                        vib-margin-bottom-50-0  
                        px-3"
             style={{display:'flex', flexWrap:'wrap'}}>

            <div className="vib-img-section ivr-zig-zag-image"  style={{maxWidth:'500px'}}>
                    <img 
                         src={orderReservation}
                         alt="call-tracking-to-track-incoming-calls-from-campaigns" 
                         className='vib-img classForLoaded'
                         onLoad={imagesLoadIndicator}
                         />

            </div>
            
            <div id='virtual-receptionist' className="bottom-margin-50" style={{maxWidth:'710px'}}>
              <h1 className='vib-container-sub-heading vib-res-right  vib-res-margin-top-50-50'
                  style={{fontSize:'33px',lineHeight:'49px',fontWeight:'bold'}}>
                  Order reservation or booking<br />
                  confirmation with SMS & IV<span className='blue-under-line' style={{paddingBottom:' 15px'}}>R service</span>
              </h1>    
                
              <h5 
              className='vib-bold-para vib-res-right' 
              style={{marginTop:'40px',
                      fontSize:'24px',
                      lineHeight:'35px',
                      color:'#7C8087',
                      fontWeight:'400'}}>
                  Offer your customers to make bookings and reservations using 
                  IVR and SMS, send booking confirmations through 
                  SMS and IVR services.
              </h5>
            </div>
        </div>
        {/* how is vibtree resolving  food and beverage  industry end */}
        
         <VibtreeBenefits/>
         
         <IndustryFAQ/>
         
         <HaveMoreQuestions/>


    </div>
  )
}
