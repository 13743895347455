import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';


export default function PartnerWithUsPopUp(props) { 
    const [ref_page_url, setref_page_url] = useState('');
    const [utm_source_s, setutm_source] = useState('');
    const [utm_medium_s, setutm_medium] = useState('');
    const [utm_campaign_s, setutm_campaign] = useState('');
    const [utm_term_s, setutm_term] = useState('');
    const [utm_content_s, setutm_content] = useState('');
    const [publisherid_s, setpublisherid] = useState('');
  
  
    let refferrerUrl=document.referrer
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
  
    useEffect(() => {
      // var handleButton=()=>{
        setref_page_url(refferrerUrl)
        setutm_source(params.utm_source)
        setutm_medium(params.utm_medium)
        setutm_campaign(params.utm_campaign)
        setutm_term(params.utm_term)
        setutm_content(params.utm_content)
        setpublisherid(params.publisherid)
      // }
      // eslint-disable-next-line 
    }, []);
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
    <div id="mauticform_wrapper_partnerwithus" className="mauticform_wrapper">
    {/* eslint-disable-next-line  */}
    <form autoComplete="false" 
          role="form" 
          method="post" 
          action="https://launcher.vibtree.in/form/submit?formId=12" 
          id="mauticform_partnerwithus" 
          data-mautic-form="partnerwithus" 
          encType="multipart/form-data" 
          partner-with-us>
        <div className="mauticform-error" id="mauticform_partnerwithus_error"></div>
        <div className="mauticform-message" id="mauticform_partnerwithus_message"></div>
        <div className="mauticform-innerform">
            
          <div className="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

            <div id="mauticform_partnerwithus_first_name" data-validate="first_name" data-validation-type="text" className="mauticform-row mauticform-text mauticform-field-1 mauticform-required">
                <label id="mauticform_label_partnerwithus_first_name" htmlFor="mauticform_input_partnerwithus_first_name" className="mauticform-label">First Name</label>
                <input id="mauticform_input_partnerwithus_first_name" name="mauticform[first_name]"  className="mauticform-input" type="text"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>

            <div id="mauticform_partnerwithus_last_name" data-validate="last_name" data-validation-type="text" className="mauticform-row mauticform-text mauticform-field-2 mauticform-required">
                <label id="mauticform_label_partnerwithus_last_name" htmlFor="mauticform_input_partnerwithus_last_name" className="mauticform-label">Last Name</label>
                <input id="mauticform_input_partnerwithus_last_name" name="mauticform[last_name]"  className="mauticform-input" type="text"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>

            <div id="mauticform_partnerwithus_email" data-validate="email" data-validation-type="email" className="mauticform-row mauticform-email mauticform-field-3 mauticform-required">
                <label id="mauticform_label_partnerwithus_email" htmlFor="mauticform_input_partnerwithus_email" className="mauticform-label">Email</label>
                <input id="mauticform_input_partnerwithus_email" name="mauticform[email]"  className="mauticform-input" type="email"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>

            <div id="mauticform_partnerwithus_mobile" data-validate="mobile" data-validation-type="tel" className="mauticform-row mauticform-tel mauticform-field-4 mauticform-required">
                <label id="mauticform_label_partnerwithus_mobile" htmlFor="mauticform_input_partnerwithus_mobile" className="mauticform-label">Mobile</label>
                <span className="mauticform-helpmessage">Please add country code</span>
                <input id="mauticform_input_partnerwithus_mobile" name="mauticform[mobile]"  placeholder="91 98000 00000" className="mauticform-input" type="tel"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>

            <div id="mauticform_partnerwithus_company" className="mauticform-row mauticform-text mauticform-field-5">
                <label id="mauticform_label_partnerwithus_company" htmlFor="mauticform_input_partnerwithus_company" className="mauticform-label">Company</label>
                <input id="mauticform_input_partnerwithus_company" name="mauticform[company]"  className="mauticform-input" type="text"/>
                <span className="mauticform-errormsg" style={{display:"none"}}></span>
            </div>

            <div id="mauticform_partnerwithus_comments" className="mauticform-row mauticform-text mauticform-field-6">
                <label id="mauticform_label_partnerwithus_comments" htmlFor="mauticform_input_partnerwithus_comments" className="mauticform-label">Comments</label>
                <textarea id="mauticform_input_partnerwithus_comments" name="mauticform[comments]" className="mauticform-textarea"></textarea>
                <span className="mauticform-errormsg" style={{display:"none"}}></span>
            </div>

            <div id="mauticform_partnerwithus_what_is_10_plus_8" data-validate="what_is_10_plus_8" data-validation-type="captcha" className="mauticform-row mauticform-text mauticform-field-7 mauticform-required">
                <label id="mauticform_label_partnerwithus_what_is_10_plus_8" htmlFor="mauticform_input_partnerwithus_what_is_10_plus_8" className="mauticform-label">What is 10 plus 12?</label>
                <input id="mauticform_input_partnerwithus_what_is_10_plus_8" name="mauticform[what_is_10_plus_8]"  className="mauticform-input" type="text"/>
                <span className="mauticform-errormsg" style={{display:"none"}}>This is required.</span>
            </div>

               <input id="mauticform_input_partnerwithus_ref_page_url" name="mauticform[ref_page_url]" value={ref_page_url} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_service" name="mauticform[service]" value="partner-with-us" className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_utm_source" name="mauticform[utm_source]" value={utm_source_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_utm_medium" name="mauticform[utm_medium]" value={utm_medium_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_utm_campaign" name="mauticform[utm_campaign]" value={utm_campaign_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_utm_term" name="mauticform[utm_term]" value={utm_term_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_utm_content" name="mauticform[utm_content]" value={utm_content_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_publisherid" name="mauticform[publisherid]" value={publisherid_s} className="mauticform-hidden" type="hidden"></input>

            <div id="mauticform_partnerwithus_submit" className="mauticform-row mauticform-button-wrapper mauticform-field-8">
                <button type="submit" name="mauticform[submit]" id="mauticform_input_partnerwithus_submit"  className="mauticform-button btn btn-default">Submit</button>
            </div>
            </div>
        </div>

        <input type="hidden" name="mauticform[formId]" id="mauticform_partnerwithus_id" value="12"/>
        <input type="hidden" name="mauticform[return]" id="mauticform_partnerwithus_return" />
        <input type="hidden" name="mauticform[formName]" id="mauticform_partnerwithus_name" value="partnerwithus"/>

        </form>
    </div>
      </Modal>
    );
  }