import { Button } from '@mui/material';
import { textAlign } from '@mui/system';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap'
import { Helmet } from 'react-helmet';
import StickyHeadTableEnterprise from './EnterpriseTable';
import MobileCards from './MobileCards';
import './pricing.css'
import PricingSlider from './PricingSlider';
import StickyHeadTableProfesional from './ProfessionalTable';
import StickyHeadTableStandard from './StandardTable';
import StickyHeadTableStarter from './StarterTable';
import StickyHeadTable from './Table';
import Group from './../../images/Group.png'
import Pricinglaptop from './Pricinglaptop';
import Pricingmobile from './Pricemobile';

function getWindowDimensions() {
  const { innerWidth: widthprice, innerHeight: height } = window;
  return {
    widthprice,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


export default function Pricing() {

  const [inrState, setInrState] = useState(false);
  const { height, widthprice } = useWindowDimensions();

  // scroll to top start 
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToTop();
  }, []);
  // scroll to top end





  // faq section end 


  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <>
      {widthprice > 600 ? <>
        <Pricinglaptop />
      </> : <>
        <Pricingmobile />
      </>}

    </>
  )
}
