import React from 'react'

export default function Error404Page() {
  return (
    <div style={{width:'100%',height:'100vh',display:'flex',justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
      <h1 style={{color:'#0046bb', fontSize:'25px', alignItems:'center', textAlign:'center'}}>404 error, <br />Page Not Found</h1>
      <a href="https://www.vibtree.com/" style={{color:'#0046bb', height:'30px', fontWeight:'bold'}}>Home Page</a>
    </div>
  )
}
