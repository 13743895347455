import React, { useEffect, useState } from "react";
import handleimg from './../../images/industry/handImage.png'
import customer from './../../images/customer.png'
import './commoncss.css'
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default function Testimonial() {
  const { height, width } = useWindowDimensions();
  console.log("width", width)
  return (
    <div>
      <div>
        <section className="testimonial">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-center">
              <div className="col-12">
                <div className="wrap">

                  {width > 1000 ? <>
                    <img src={customer} alt="" className="homepageiamge" />
                  </> : <>
                    <h2 className="">Customer-focus teams </h2>
                    <h2 className="">love Vibtree </h2>
                  </>}


                </div>
              </div>
            </div>

            {/* <div className="row align-items-end">
              <div className="col-md-auto col-12 home-img-to-center-in-mobile-view">
               
                <img src={handleimg} className="phone" alt="phone" style={{ width: "310px", marginLeft: "-65px" }} />
                <div className="card">
                  <div className="card-body">
                    <div className="row no-margin align-items-end">
                      <div className="col-auto">
                        <img src='https://vibtreedan.s3.eu-central-1.amazonaws.com/HomePage/testimonial/quote.svg' className="quote" alt="quote" />
                      </div>
                      <div className="col">
                        <h3>
                          Awesome
                          <br />
                          Experience
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md col-12">
                <div className="card content">
                  <p style={{ fontSize: "13px" }}>
                    Great and functional all the same time. The only thing
                    better that the product is the customer service!!!
                  </p>
                  <h3>Steeve Hope</h3>
                </div>

                <div className="card content">
                  <p style={{ fontSize: "13px" }}>
                    I will recommend VIBTREE to all the business vendors and
                    will call you first if I need anything else. Again, my
                    deepest thanks for a great product and wonderful service
                  </p>
                  <h3>Sanjay Gupta</h3>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    </div>
  );
}
