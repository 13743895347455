import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';

export default function TermsOfUse() {

    const scrollToTop=()=>{
        window.scrollTo({top:0, behavior:'smooth'})
    }

    useEffect(() => {
        scrollToTop();
    }, []);
    
  return (
    <div className='px-lg-5 
                    px-md-4 
                    px-sm-3 
                    vib-margin-bottom-200-100 
                    vib-res-margin-top-200-100 
                    m-auto
                    terms-of-use'
         style={{maxWidth:'1100px',paddingRight:'10px',paddingLeft:'10px'}}>

          
          <Helmet>
      
          <meta name="robots" content="noindex , nofollow"/>
          
          </Helmet>

          <h1 className='vib-res-center vib-container-heading'>Terms of Use Agreement</h1>

          <p className='vib-bold-para vib-res-left font-weight-300'>
                In accordance with the Information Technology Act, 2000, this document is framed as an electric record and does not require any form of a digital or physical signature. 
                <br /><br />
                Under the provisions of applicable laws for regulation, this "Terms of Use" document (hereafter referred to as "Agreement" or "Terms") describes the terms and conditions that direct the access or use of the website <a className="vib-h-b-u" href="https://www.vibtree.com/">www.vibtree.com.</a> 
                <br /><br /><br />
                www.vibtree.com is owned and operated by Vibtree Technologies LLP (referred to as "Vibtree", "Company", "Our", "Us" or "We" hereafter), incorporated under The Companies Act, 1956 with corporate office at 2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, New town, Kolkata, West Bengal 700161 INDIA.
                <br /><br />
                The terms "You", "Your", "User" or "Customer", used in context to these terms of use, whenever necessary, shall highlight any customer (domestic or international) or legal person who intends to or agrees to use our services through the website by accessing the website or creating a member account by registering on our website. 
                <br /><br />
                If you use our products and services within the territory of India, you will be referred to as a "Domestic Customer", and if you use our Product in any jurisdiction outside India, you will be referred to as an "International Customer". 
                <br /><br />
                These terms of use (terms and conditions) apply to your use of this website, and by accessing or otherwise using this website, you agree to be obligated by these Terms of Use. User accounts or membership accounts created by registering on this website and/or purchase or subscription to any product or service on this website; shall be governed by these terms of use. 
                <br /><br />
                At our sole discretion, we may update these terms of use as needed without giving any prior notice. Your continued use of our website, our services, or both; post any such updates, shall declare your acceptance of any changes made herein. We henceforth advise you to keep checking this section to stay updated about the changes made to these terms of use. 
                <br /><br />
                The only exception to this agreement would be that we have a separate contract with your organization that covers the use of our products & services, and in that case, that contract will govern how you use Vibtree's products & services. 
                <br /><br />
                If you have any questions regarding these "Terms of Use", please write to us at  <a className="vib-h-b-u" href="mailto:info@vibtree.com"> info@vibtree.com</a>
          </p>

            {/*  Product Description start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
            1. Product Description
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
        
                Our "Product" in this agreement refers to-
                <br /><br />
                <ol className='pp-information-list vib-res-left' style={{textAlign:'left !important'}}>
                    <li>Our platform and user-interface</li>
                    <li>Telephone numbers that have been allocated to you 
                        by us (from hereon, referred to as 
                        "Vibtree Cloud Numbers");</li>
                    <li>Our mobile application; </li>
                    <li>Browser extension and software development kits; and </li>
                    <li>Any other document provided to you by Vibtree related to 
                        the Product or its usage 
                        (from now on referred to as "Documents").</li>
                </ol>
                <br />
                The use of our products will enable our customers to make, record, control, monitor and track calls, Interactive Voice Response (IVR) calls and send SMS at any time. 
                <br /><br />
                By "Vibtree Cloud Numbers", we mean the telephone numbers provided by the telecommunication service providers to Vibtree. To enable our customers' use of the Product, we offer Vibtree Cloud Numbers to our customers. 
            </p>
        
            {/*  Product Description end */}


            {/*  Right To Use The Product start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
            2. Right To Use The Product
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
            Vibtree grants you the non-transferable, non-exclusive and reversible right to use our products following our terms of use as stated in this agreement. You agree to use the products as per these terms and conditions. 
            <br />
            This agreement is valid for the time of your subscription, and your non-transferable, non-exclusive and revocable right to use the Product will end when this agreement is terminated.  
            <br />
             Clearly Vibtree is not creating or defining any right or interest in the Vibtree Cloud Numbers to favour the customers or authorized users under these terms, other than the limited purpose of using or accessing the Product. 
             <br /><br />
             We are happy to welcome you to Vibtree's services. As a responsible user, you need to understand that you will be sharing our Product with Vibtree's other customers. Please note that you cannot transfer your account to any other user, person or company. If you ever feel the need to do that, do let us know in order to update your contract. 
            </p>
            {/*  Right To Use The Product end */}

            {/*  Limitations And Restrictions start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
            3. Limitations And Restrictions To The Right To Use The Product
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
            You understand and agree to the undermentioned conditions, limitations and restrictions that are applicable to your right to use the Product under this agreement:
                <br /><br />
                (3.1) Our Product and services can only be used by you and/or a person specifically authorized by you as an "authorized User" for the purposes of business communication. 
                <br />
                It is hence clear that domestic customers do not have the permission to resell the right to use our Product. It is also made clear that International customers or its "authorized Users" do not have the permission to resell the right to use our Product, unless any such reselling is permissible by the applicable laws. 
                <br />
                It is also further clarified that in international jurisdiction (outside of India), wherever it is permissible by the law to resell the rights to use our Product, an International customer may resell our Product or the right to use our Product, subject to a separate agreement set between the parties for this purpose. Furthermore, the International customer will not resell the Product until a separate agreement with Vibtree is executed for this purpose. 
                <br />
                User shall assume any liability arising from the misuse of our Product or any breach of the provisions set in this agreement by your authorized user(s). 
                <br /><br />
                (3.2) The Product is not intended to carry our emergency calls to any emergency services or support in doing so. 
                <br />
                When we mention "Emergency Services", we mean law enforcement service, fire service, medical service or any other similar emergency services. 
                <br /><br />
                (3.3) Being a public cloud user, you will be sharing the product/Vibtree's telephony resources with all our other customers, and your experience may vary from other customers depending on the volume of calls and your internet connection. 
                <br /><br />
                (3.4) The Product can handle up to a maximum of 130% of the customer's normal communication volume at any instance of time. Here, "normal communication volume" indicates the average concurrent call or SMS volume as mentioned in your contract at the time of purchase or subscription. 
                <br /><br />
                (3.5) Vibtree is an intermediary with no control over the manner in which a product is used by the customer. You are therefore required to use our Product in line with all the applicable laws. Vibtree denies any claims that the Product complies with all the applicable laws and regulations outside of India. If you are an international customer using the Product outside of India, then it is your responsibility to ensure that your use of our Product is in compliance with the different rules, regulations, and laws that may be applicable in connection to your use of our Product. 
                <br /><br />
                (3.6) To ensure a better customer experience or to handle other external factors, Vibtree reserves the right to modify the attributes of the products from time to time. You shall be notified about any such changes through e-mail to the primary admin or a pop-up message on the application. 
                <br />
                Vibtree shall not be liable to you or any third party for such modifications. 
                <br /><br />
                (3.7) If your usage is credit-based, then you shall not be able to use the Product if your credit limit is exceeded. 
                <br /><br />
                (3.8) We shall not revoke or change the Vibtree Cloud Numbers allocated to you, unless- 
                <ol className='vib-res-left pp-information-list vib-res-left' >
                    <li>The Vibtree Cloud Number is revoked by the telecommunication service provider; and/or</li>
                    <li>A  regulatory authority bans the use of Vibtree Cloud Number(s); and/or</li>
                    <li>We are required to do so by the law. </li>
                </ol>
                <br />
                (3.9) We shall ensure that the Product is available for use, 24x7, except for in the case of a planned maintenance or when the network and servers of the telecommunication service provider are down. Various functions of the Product will also be subject to delays, limitations and other problems caused by the external infrastructure, services and technology. You understand and agree that we shall not be liable for any default, delays, or any other damage or loss caused by a person/entity outside of our control. 
                <br /><br />
                (3.10) Before we start a planned notification, we shall notify you 12 (twelve) business work hours in advance. 
                <br />
                By business work hours we mean 10 A.M. to 6 P.M IST on business days, or 4:30 A.M. to 12:30 P.M GMT on business days. 
                <br />
                By business day we mean a working day other than Saturday, Sunday or any public holiday in Kolkata, West Bengal, India. 
                <br /><br />
                (3.11) Vibtree may limit the number of "authorized Users" based on the pricing plan or bill plan subscribed by you. 
                <br /><br />
                (3.12) After the termination of this agreement, you or your authorized users shall not use the Vibtree Cloud Numbers allocated to you under your subscription and this agreement. It is clear that we have the right to re-allocate such Vibtree Cloud Number(s) to our other future customers. 
            </p>
            {/*  Limitations And Restrictions end */}

            {/*  Charges, Bill Plan, start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
            4. Charges, Bill Plan, Credit Limit and Invoice
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                (4.1) Charges and Bill Plan: You agree to pay the charges for the use of our Product ( from hereon, referred to as "Charges") based on the pricing plan and bill plan that you have subscribed for. 
                <br /><br />
                (4.2) Credit Limit: You agree that you are responsible for ensuring that your credit limit is not surpassed or breached. Based on your usage, your credit limit may be modified by Vibtree during the use of your Product and the term for which this agreement is valid. 
                <br /><br />
                By Credit Limit, we mean the maximum amount of credit that Vibtree may offer you for the use of our Product & services. 
                <br /><br />
                (4.3) Charge Revision: Vibtree shall give you a 30 (thirty) days advance notice before making any upward revision of charges. In the event of any changes resulting in an overall increase in the input cost, we may revise the charges payable by you by giving you a 15 (fifteen) days advance notice. In case of any disputes arising with respect to the revision of charges, disputes will be resolved through discussion between the parties after either party is notified of any such dispute. In case such disputes about charge revision remains unsolved, the parties can terminate this agreement in accordance with the termination terms. 
                <br /><br />
                (4.4) Invoice Payment: Vibtree will raise an invoice for your use of our Product and services, on or after the last day of each month. If you are using our products on a post-paid basis, you agree to clear all the payments towards your invoice within 30 (thirty) days of the date on which your invoice was generated. In case you are using our Product on a pre-paid basis, then your invoice will be automatically settled against the pre-payment deposit made by you on the basis of your pricing and bill plan. 
                <br /><br />
                (4.5) Disputed Invoice: In the event of a dispute arising from any invoice, whether in part or in whole, you will notify Vibtree about the same within 3 (three) business days from the date of receipt of the invoice. 
                <br /><br />
                Upon receipt of such notification, parties will try to resolve the dispute amicably through discussion. 
                <br /><br />
                (4.6) Pay as you go: Wherever applicable, you may choose the pay as you go option rather than waiting for your monthly invoice to pay the dues on your account to Vibtree. 
            </p>
            {/*  Charges, Bill Plan, end */}

            {/*  Vibtree's Representation and Warranties start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
            5. Vibtree's Representation and Warranties
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                We warrant and represent that:
                 <br /><br />
                (5.1) Valid Existence: Our existence is valid, reputable and reliable under the applicable laws. 
                <br /><br />
                (5.2) Capacity to execute the agreement: The signatory to this agreement has the full right, authority, power and capacity to execute this agreement on behalf of Vibtree. 
            </p>
            {/*  Vibtree's Representation and Warranties end */}


            {/*  Customer's Representation and Warranties start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
            6. Customer's Representation and Warranties
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                You warrant and represent that:
                 <br /><br />
                (6.1) You will ensure that while using our Product, you and/or your authorized User(s) will use the Product in compliance with this agreement and all the laws applicable to you. It is hence made clear, that you and/or your authorized User(s) will not use our Product to make any calls or send messages that may be an infringement of the applicable laws. Before using our Product in any jurisdiction, you and/or your authorized User(s) will acquaint yourself with all the laws applicable to the use of our Product in that jurisdiction. You will only use the Product in the manner permissible by the law if its usage is permitted by the law. Vibtree doesn't provide any representation or warranty in this regard. 
                <br /><br />
                (6.2) You and/or your authorized User(s) will not modify, mirror, copy, duplicate, download, sell, license, republish, transfer, transmit, display, assign, or otherwise exploit commercially or distribute a portion of or all of the proprietary technology and the Product in any form, or assist the third parties in obtaining access to the Product or build a product or service which competes with the Product. 
                <br />
                By "Proprietary Technology" we mean our communication and optimization solutions, configurations, user interfaces, API adaptation details and other related documentation. 
                <br /><br />
                (6.3) You and/or your authorized User(s) will ensure that all your customer data is legally sourced and accurate. 
                <br /><br />
                (6.4) You and/or your authorized User(s) will protect the privacy of the information received/collected by you through the use of our Product. 
                <br /><br />
                (6.5) You and/or your authorized User(s) have duly read and understood our "Privacy Policy" and agree to the terms mentioned therein. 
                <br /><br />
                (6.6) You and/or your authorized User(s) will only make call recordings and use them in line with the applicable laws. 
                <br /><br />
                (6.7) You and/or your authorized User(s) agree not to use our Product to display, host, modify, upload, transmit, publish, update or share any information that:
                <ol className='vib-res-left pp-information-list vib-res-left'>
                    <li>Belongs to another person and which you do not have any right of use;</li>
                    <li>Is egregiously harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or is otherwise unlawful in any manner whatsoever;</li>
                    <li>Harms minors in any way;</li>
                    <li>Infringes any patent, trademark, copyright or other proprietary rights (whether ours or of any other person);</li>
                    <li>Deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
                    <li>Impersonates another person;</li>
                    <li>Threatens the unity, integrity, defense, security or sovereignty of India or any country, friendly relations of India or any country with foreign states, or public order or causes incitement to the commission of any cognizable offense or prevents investigation of any offense or is insulting to any nation;</li>
                    <li>Contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</li>
                    <li>Is aimed at inflicting hatred or dissent based on ethnic groups, religions, races, and inter-groups;</li>
                    <li>Relates to gambling;</li>
                    <li>Relates to any extortion and/or threats;</li>
                    <li>False and/or misleading information resulting in consumer loss, whether knowingly or unknowingly; and/or</li>
                    <li>Violates any law in force for the time being.</li>
                </ol>
                <br />
                (6.8) Domestic Customer(s) and/or its authorized User(s) agree to take the approval for Sender ID from our customer support team before sending out any transactional messages via SMS. 
                <br />
                Further, it is clear that such Domestic Customer(s) agrees to send out a transactional message to a subscriber pertaining to its services or activities only in response to a verifiable request from such a subscriber and/or in accordance with applicable law (as amended from time to time). 
                <br />
                An International Customer and/or its authorized User(s) agree to take approval of the Sender ID from our customer support team before sending out any commercial communication through SMS or any other means.
                <br /><br />
                By "Transactional Message(s)" we mean the transactional message(s) as defined under the Telecom Commercial Communications Customer Preference Regulations, 2010 (as amended from time to time) or any other law as amended from time to time.
                <br /><br />
                By "Sender ID ", we mean the sender ID created by the Customer in accordance with the local telecom or other such applicable laws relating to commercial communication or otherwise, such as the Telecom Commercial Communications Customer Preference Regulations, 2010 (as amended or re-enacted from time to time) that are applicable to Domestic Customers.
                <br /><br />
                (6.9) You and/or your authorized User(s) agree not to use our Product to make a conference call or send a message to any Subscriber registered under the NDNCR and/or NCPR or any other applicable customer preference-based do-not-disturb database for sharing unsolicited commercial communication (except in accordance with the applicable law).
                <br /><br />
                (6.10) Your existence is valid and in good standing under the applicable laws.
                <br /><br />
                (6.11) You have the complete rights, power, authority and capacity to execute this agreement.
                <br /><br />
                (6.12) You and/or your authorized Users will not use the Product to inter-alia collect or process personal data of a data subject (who is in the European Union) in the course of an activity which falls within the scope of European Union law.
                <br /><br />
                (6.13) You and/or your authorized User(s) have obtained and will continue to obtain a clear written consent from every person for (i) the purpose and (ii) the duration, for which their information will be processed by us on your behalf during the course of your use of the Product. 
                <br />
                This consent may be through letter or email or any other manner prescribed by the applicable data protection law. You and your authorized User(s) also agree to provide a copy of the aforesaid written consent to us immediately, upon request, and in such a manner so as not to cause Vibtree or a service provider of Vibtree, to be in violation of any applicable laws.
                <br /><br />
                (6.14) You and/or your authorized User(s) collect information in accordance with applicable data protection laws.
            </p>
            {/*  Customer's Representation and Warranties end */}

            {/*  Know Your Customer Obligations start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                7. Know Your Customer Obligations
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                You agree to provide us with accurate and complete information on the KYC page, company info page and other similar URLs as required by us to you, from time to time. 
                <br /><br />
                By "KYC Page", we mean our 'know your customer' page wherein you are required to upload your documents for proof of identity such as address proof, your incorporation certificate and other similar documents as may be requested from time to time. You can access the KYC Page, and if the location is changed, then we shall indicate the same from time to time.
                <br /><br />
                By "Company Info Page" we mean our company info page wherein you, (if you are a Domestic Customer), will be required to input details about your company such as your registered company address, tax deduction & collection account number (TAN), goods and services tax (GST) number and other similar details as may be requested from time to time (if so needed); and 
                <br />
                If you are an International customer, you will be required to input details about your company such as your registered company address, details related to your tax registration and such other details as may be requested from time to time, if and when needed. You can access the company info page, and if the location is changed, then we shall indicate the same from time to time.
            </p>
            {/*  Know Your Customer Obligations end */}

            {/*  Data Sharing start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                8. Data Sharing
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                (8.1) During the term of this agreement, you will be able to download Customer Data and Vibtree Data for the previous 9 (Nine) months. Customer Data and Vibtree Data beyond this period may be made available to you on request, if it is feasible from a commercial point of view.
                 <br /><br />
                By "Customer Data" we mean the data provided by you and/or your authorized User(s) in the course of your use of the Product. Customer Data will include (as applicable) phone numbers, email addresses and names provided by you or your authorized User(s) in relation to your use of the Product.
                <br /><br />
                By "Vibtree Data" we mean the data automatically generated and recorded by our system pursuant to your and/or your authorized User(s)' use of the Product which includes but is not limited to billing and pricing information, metadata about a call (telecom circle, operator, location of call, time of call and duration), activities that you or your authorized User(s) do in creating workflows, SMS templates, audio uploads and activities performed either by you or your authorized User(s) in and/or during a call such as dual-tone multi-frequency key access and details of the authorized Users.
                <br /><br />
                (8.2) Customer data and Vibtree data will be treated as confidential information; and will be subject to the confidentiality obligations as mentioned in this agreement. All the data, including customer data and Vibtree data shall be handled in compliance with our <a className="vib-h-b-u" href="https://www.vibtree.com/privacy-policy/">privacy policy.</a>  
                <br /><br />
                (8.3) At the time of termination, in compliance with the termination clause in this agreement; you will be responsible for downloading the customer data and Vibtree data for your record keeping. 
                <br /><br />
                (8.4) Once the agreement is terminated, we will be deleting your customer data and Vibtree data at the earliest feasible time. However, it is made clear that we will retain the customer data and Vibtree data as required by applicable laws. 
            </p>
            {/*  Data Sharing end */}

            {/*  Data Sharing start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                9. Customer Support Services
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                Our Customer Support Team will provide you with the customer support services for all your issues or queries arising about your and/or your authorized User(s) usage of our Product. 
                <br /><br />
                By "Customer Support Team" we mean the concerned Vibtree team that offers support services to our customers and/or their authorized Users. The primary concern of our support service team is to provide help and solutions during product downtime and be available for our customers for any kind of assistance. 
                <br /><br />
                By "Customer Support Services" we mean the support and assistance services offered by Vibtree to our customers in accordance with our Support Service Policy. For Domestic Customers, our customer support team will be available from 6 AM to midnight 12 AM (IST) around the year. For International Customers, our customer support team will be available from 12:30 AM to 6:30 PM (GMT), around the year. 
            </p>
            {/*  Data Sharing end */}

            {/*  Point of Contact start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                10. Point of Contact
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                You will provide us with the name, designation, e-mail address and phone co-ordinates of you and/or your representative who will be responsible to coordinate with us for the purposes of this agreement at the Company Info page.
            </p>
            {/*  Point of Contact end */}

            {/*  Publicity start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                11. Publicity
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                You agree to grant us the right to use your brand name and logo on our website and other marketing material, solely for the purpose of marketing. 
            </p>
            {/*  Publicity end */}

            {/*  Vibtree Username & Password start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                12. Vibtree Username & Password
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                You and your authorized User(s) agree to reset the Vibtree username and login password regularly through our dashboard in order to avoid unauthorized access to our Product through your Vibtree account. 
                <br />
                It is clear that when you enter a password, it gets converted to an encrypted code, and we or anyone else cannot access, decrypt and share your password.
                <br />
                In the event of any unauthorized access, you agree to indemnify us against all claims, loss or damage arising from such unauthorized access. You also agree to pay the charges for the use of the Product on account of such unauthorized access.
                <br /><br />
                By "Vibtree username and password" we mean the unique and private credentials for your application to avoid the unauthorized use of a customer's account with Vibtree.
            </p>
            {/*  Vibtree Username & Password end */}

            {/*  Regulatory Violation start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                13. Regulatory Violation
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                In counterbalance of any other provision of this agreement, a Domestic Customer agrees to indemnify us against any liability that may be accrued by us due to a violation of the Regulations and other applicable law by such Domestic Customer and/or its authorized User(s), while using our Product, such as by sending out Promotional Message/(s) or Transactional Message/(s) or making conference calls for communicating unsolicited commercial information to any Subscriber registered under the NDNCR and/or NCPR and/or such similar consumer preference-based do-not-disturb database/register, without first obtaining the Subscriber's necessary consent in the appropriate manner under applicable law.
                <br /><br />
                Undeterred by any other provision of this agreement, an International Customer agrees to indemnify us against any liability that may be accrued by us due to a violation of the applicable law by such International Customer and/or its authorized User(s), while using our Product, such as by sending out any unsolicited commercial communication to a subscriber registered under any applicable customer preference-based DND (do-not-disturb) database. 
            </p>
            {/*  Regulatory Violation end */}

            {/*  Regulatory Disclosure start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                14. Regulatory Disclosure
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                You understand and agree that in order to abide by the applicable laws, we may either disclose or review the content of the conference calls or messages transmitted or televised by you/or your authorized User(s) using our Product under this agreement, and/or perform any other activity, as may be required by us in abidance to the law. In this concern, on request, your point of contact will provide all the information that we may seek within One (1) Business Day. You agree to provide all information required for compliance with the applicable laws, and in such a manner and in such a timeline so as not to cause Vibtree or a service provider of Vibtree, to be in violation of any applicable laws.
                 <br /><br />
                We may be required by the government authorities to review the content of the calls made by you using our Product. In such event, we will have to disclose the content of your calls in order to comply with the law. 
            </p>
            {/*  Regulatory Disclosure end */}

            {/*  Intellectual Property Rights start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                15. Intellectual Property Rights
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                (15.1)You understand that we own and have the right to use all the intellectual property rights in our products. Vibtree is only granting you the limited rights to use our Product according to this agreement, and you do not own the intellectual property rights to our Product (including content, software, mobile apps, enhancements, corrections, adaptations and new additions made to our Product). 
                <br /><br />
                (15.2) It is clear that, at any time, you will not claim and/or apply for any right in/to our Product and any new software, mobile app, corrections, content, enhancements, additions and adaptations with respect to our Product. 
                <br />
                You have agreed to allocate us all the present and future rights (including Intellectual Property Rights), titles and interests, in, over and upon any content, corrections or enhancements, adaptations and additions concerning our Product, in India or any part of the world. 
                <br />
                You also agree that the rights assigned by you are absolute, exclusive, perpetual and irrevocable. We will be entitled to transfer the rights to any person and use it anywhere in the world without requiring any interference or interruption from you.
            </p>
            {/*  Intellectual Property Rights end */}

            {/*  Confidentiality Obligations start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                16. Confidentiality Obligations
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                (16.1) Parties agree that: <br />
                "Confidential Information" means all information disclosed (whether in oral, written, or other tangible or intangible form) by one party (hereafter referred to as the "Disclosing Party") to the other party (hereafter referred to as the "Receiving Party") concerning or related to this Agreement, Product or the Disclosing Party (whether before, on or after the Effective Date), be it expressly designated as confidential or not and that which may reasonably be inferred/ considered to be confidential from its nature or circumstances surrounding its disclosure. 
                <br />
                It is clarified that Confidential Information will include without limitations: (a) any data or information that is competitively sensitive, and not generally known to the public, including, but not limited to, information related to the Product; activities related to marketing, finance, operations; and our vendors/service providers; (b) any scientific or technical information, design, process, procedure, formula, or improvement that is commercially valuable and secret in the sense that its confidentiality affords a party a competitive advantage over its competitors; and (c) all confidential or proprietary concepts, documentation, reports, data, specifications, computer software, source code, object code, flow charts, databases, inventions, know-how, show-how and trade secrets, whether or not- patented or copyrighted.
                <br /><br />
                (16.2) The Receiving Party will keep in confidence the Confidential Information of the Disclosing Party. The Receiving Party will use the same degree of care in protecting the Disclosing Party's Confidential Information as the Receiving Party uses to protect its own Confidential Information from unauthorized use or disclosure, but in no event less than reasonable care. 
                <br />
                Any Confidential Information of the Disclosing Party will be used by the Receiving Party solely for the purpose of carrying out the Receiving Party's obligations under this agreement. 
                <br />
                Further, it is clarified that the Receiving Party may disclose the Confidential Information to its employees, advisors, professional consultants and lawyers (hereafter referred to as "Representative/(s)") on a strict need to know basis, provided such Representative/(s) have entered into a non-disclosure or other confidentiality agreement with the Receiving Party containing terms substantially similar to the terms of confidentiality contained in this agreement.
                <br /><br />
                (16.3) Confidential Information will not include information that: (a) is in or enters the public domain without breach of the provisions of this agreement through no fault of the Receiving Party; (b) the Receiving Party can describe (using the files/documents in existence at the time of disclosure), was in its possession prior to first receiving it from the Disclosing Party; (c) the Receiving Party can describe, was developed by the Receiving Party independently and without use of or reference to the Disclosing Party's Confidential Information; (d) the Receiving Party receives from a third-party without restriction on disclosure and without breach of a nondisclosure obligation; or (e) is required to be disclosed to our service providers, including payment gateway providers, pursuant to a legal proceeding or investigation; or (f) is required to be disclosed pursuant to a judicial or legislative order or proceeding; provided that, to the extent permitted by and practical under the circumstances, Receiving Party will provide to the Disclosing Party prior notice of the intended disclosure and an opportunity to respond or object to the disclosure; or if prior notice is not permitted or practical under the circumstances, a prompt notice of such disclosure. 
                <br /><br />
                (16.4) Both the parties have a duty for protecting each other's confidential information and not share it with other people or parties that are not a part of this agreement.
            </p>
            {/*  Confidentiality Obligations end */}

            {/*  Limitation of Liability start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                17. Limitation of Liability
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                It is clear that Vibtree will not be liable to you or any third party for any indirect, exemplary, consequential, incidental, special, or punitive damages of any kind, including but not limited to the loss of profits, loss of revenue, loss of use, loss of goodwill, interruption of business, arising out of or as a result of this agreement, whether in tort, contract, strict liability or otherwise, even if we have been advised as such or are otherwise aware of the possibility of such damages.
                <br />
                To the maximum extent permitted by law, our total liability arising out of or in connection with this agreement will be limited to the actual direct loss incurred by a Domestic Customer, up to a limit of Rupees Two Thousand (INR 2000/-). With regard to an International Customer, to the maximum extent permitted by law, our total liability arising out of or in connection with this agreement will be limited to the actual direct loss incurred by the International Customer, up to a limit of USD One Hundred (USD 100/-).
                <br /><br />
                Please note that multiple claims will not expand this limitation. This clause will be given full effect even if any remedy specified in this agreement in general is deemed to have failed of its essential purpose.
                <br /><br />
                It is clear that we will not be liable to you and/or your authorized User(s) for any loss or liability that may be accrued to you and/or your authorized User(s) from the use of our Product.
                <br /><br />
                Other than as precisely mentioned in this agreement, you bear the sole responsibility for the results obtained and outcome or conclusions drawn by your use of our Product. 
            </p>
            {/*  Limitation of Liability end */}

            {/*  Indemnity Obligations start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                18. Indemnity Obligations
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                Subject to clause 17 (Limitation of Liability) of this Agreement, Vibtree and the Customer agree that:
                <br /><br />
                You (hereafter referred to as the "Indemnifying Party"), at your sole expense, will defend, indemnify and hold Vibtree, our directors and employees (collectively referred to as the "Indemnified Party") unharmed from and against any, damages, losses, settlements, liabilities, penalties, fines, costs and expenses (including, but not limited to, reasonable attorneys' fees) resulting from any claim, suit, action or proceeding (hereafter referred to as the "Claim") against Vibtree arising from or related to a breach or alleged breach of any provision of this agreement by you and/or your authorized User(s).
                <br /><br />
                The Indemnifying Party's indemnification obligations under this clause are conditioned upon the Indemnified Party: (a) giving notice of the Claim to the Indemnifying Party once the Indemnified Party becomes aware of the Claim; (b) granting control of the defense (including, but not limited to, selection and management of counsel) and settlement of the Claim to the Indemnifying Party (except that the Indemnified Party's prior written approval will be required for any settlement that reasonably can be expected to require an affirmative obligation of or result in any ongoing liability to the Indemnified Party. {/* single bracket has been cleared by developer */} However, it is clarified that the Indemnified Party reserves the right to select and appoint its separate counsel regarding the Claim. 
                <br />
                It is further clarified that if, in the Indemnified Party's view, the Indemnifying Party has not responded to and/or defended the Claim to the satisfaction of the Indemnified Party, the Indemnified Party reserves the right to assume control of the defense at the cost of the Indemnifying Party; and (c) providing reasonable cooperation to the Indemnifying Party and, at the Indemnifying Party's request and expense, assistance in the defense or settlement of the Claim.
            </p>
            {/*  Indemnity Obligations end */}

            {/*  Warranty start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                19. Warranty
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                Without limiting Vibtree's express warranties and obligations under this Agreement, Vibtree hereby disclaims any and all other warranties, express or implied, including but not limited to warranties of saleability, title, non-infringement, and fitness for a particular purpose and warranties related to third-party equipment, material, services or software. Our Product is provided on an "as is" basis to the fullest extent permitted by law. To the extent this disclaimer conflicts with applicable law, the scope and duration of any applicable warranty will be minimum permitted under that law.
            </p>
            {/*  Warranty end */}


            {/*  Term and Termination start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                20. Term and Termination
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                (20.1) Term: This Agreement will commence from the Effective Date and will remain in force unless terminated in accordance with the provision of clause 20.2 (Termination) below.
                 <br /><br />
                (20.2) Termination:
                <br /><br />
                <ol className='vib-res-left pp-information-list vib-res-left' style={{textAlign:'left !important'}}>
                    <li>Subject to clause 20.2 (d) of this agreement-</li>
                    <li>If you are using our Product on a post-paid basis, you may terminate this agreement by giving us an advance written notice of 30 (Thirty) days; 
                                            </li>
                    <li>If you are using our Product on a pre-paid basis, you may stop using the Product at any time at your sole discretion by clicking the "close my account" button on your dashboard which will automatically settle all payments against your pre-payment deposit and terminate this agreement.</li>
                </ol>
                
                <br /><br />

                <ol className='vib-res-left pp-information-list vib-res-left' style={{textAlign:'left !important'}}>
                    <li>You also agree that we may temporarily stop providing the Product to you or terminate the agreement with immediate effect if:</li>
                    <li>You consistently fail to pay an amount that is due as per clause 4 (Charges, Bill Plan, Credit Limit and Invoice) of this agreement;</li>
                    <li>There is a breach or alleged breach of the representations and warranties given by you in this agreement;</li>
                    <li>There is a breach or alleged breach of any of the provisions of this agreement if such breach is not capable of being remedied. Provided however that, in the event of a curable breach, you will cure the breach within 15 (Fifteen) days from the date of issuance of a written intimation of the same, at the end of which if the breach continues, this agreement will automatically come to an end;</li>
                    <li>You suspend or cease, or threaten to suspend or cease, carrying on all or a substantial part of your business;</li>
                    <li>Our relevant license(s) get suspended or revoked;</li>
                    <li>You withdraw your consent to our Privacy Policy;</li>
                    <li>Either you or we file a petition for bankruptcy;</li>
                    <li>Our Product comes under a government or regulatory scrutiny or investigation, or if there is a reasonable anticipation of the same;</li>
                    <li>There is a dispute between the parties and the parties fail to reach a consensus and the dispute persists beyond 21 (Twenty One) days from the date it was notified; </li>
                    <li>Our connectivity service provider(s) suspend or terminate their connectivity services to us;</li>
                    <li>You object to an amendment of this agreement by us in accordance with clause 30 (Amendment); and/or</li>
                    <li>Any law, regulation or a governmental or judicial order/ direction requires us to do so.</li>
                </ol>
                 
                    <br /><br />
                    You agree that we may terminate this agreement, without cause, by providing you an advance notice of 30 (Thirty) days.
                    <br /><br />
                    On termination of this agreement for any reason, any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of termination, will not be affected or prejudiced (including but not limited to our right to be paid for the use of our Product).
            </p>
            {/*  Term and Termination end */}

            {/*  Survival start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                21. Survival
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                Notwithstanding anything else contained in this agreement, you agree that clauses 5 (Vibtree's Representations and Warranties), clause 6 (Customer's Representations and Warranties), clause 15 (Intellectual Property Rights), clause 16 (Confidentiality Obligations), clause 17 (Limitation of Liability), clause 18 (Indemnity Obligations), clause 19 (Warranty), clause 23 (Governing Law) and clause 24 (Dispute Resolution) and such other clauses which by their nature and context are intended to remain binding post the termination of the agreement, will survive and remain in effect even after the agreement is terminated.
            </p>
            {/*  Survival end */}

            {/*  Entire Agreement start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                22. Entire Agreement
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                This agreement and its schedules constitute the entire agreement between the parties and supersedes & extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject matter.
                 <br /><br />
                Each party agrees that it will have no remedies with respect to any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in this agreement.
            </p>
            {/*  Entire Agreement end */}

            {/*  Governing start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                23. Governing Law
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                You understand and agree that Vibtree is operated in India and will be deemed to be solely based in India. This agreement and any dispute or claim (including non-contractual disputes or claims) arising out of or concerning it or its subject matter or formation will be governed by and construed according to the laws of India, without regard to conflicts of laws and principles that would cause laws of another jurisdiction to apply.
            </p>
            {/*  Governing end */}

            {/*  Dispute Resolution start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                24. Dispute Resolution
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                We believe that any dispute can be resolved through communication. In case of any grievance, before taking recourse to the legal remedies available to you in law, please try contacting our Customer Support Team.
                <br />
                Each party irrevocably agrees that the courts of Kolkata, India, will have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or concerning this agreement or its subject matter or formation.
            </p>
            {/*  Dispute Resolution end */}

            {/*  Restrictions start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                25. Restrictions on Transfer
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                Neither this agreement nor any right or duty under this agreement may be transferred, assigned or delegated by a party, by operation of law or otherwise, without the prior written consent of the other party. 
                <br />
                Notwithstanding the preceding statement, each party will have the right to assign this agreement to any successor to substantially all of its business or assets, whether by merger, sale of assets, sale of stock, reorganization or otherwise. 
                <br />
                Subject to the preceding, this agreement will be binding upon and will be accustomed to the benefit of the parties and their respective representatives, administrators, successors and permitted assigns.
            </p>
            {/*  Restrictions end */}

            {/*  Invalid Provision start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                26. Invalid Provision
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                If any provision of this agreement is invalid, illegal or incapable of being enforced by any rule of law or public policy, all other provisions of this agreement will nonetheless remain in full force and effect so long as the economic or legal substance of the transactions contemplated by this agreement is not affected in any adverse manner to any party. Upon such determination that a provision is invalid, illegal, or incapable of being enforced, if required, the parties will negotiate in good faith to modify this agreement to effect the original intent of the parties as closely as possible in an acceptable manner to the end that the transactions contemplated hereby are fulfilled.
            </p>
            {/*  Invalid Provision end */}

            {/*  Nature of Relationship start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                27. Nature of Relationship
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                Nothing in this Agreement is intended to, or will be deemed to, establish any partnership or joint venture between any of the parties, constitute any party as the agent, franchisor, franchisee, employee, representative, owner or partner of the other party, or authorize any party to make or enter into any commitments for or on behalf of any other party, and the relationship between the parties will only be that of independent contractors.
            </p>
            {/*  Nature of Relationship end */}

            {/*  Notices start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                28. Notices
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                (28.1) All notices, requests, demands and other communications which are required or maybe given under this agreement will be in writing and will be deemed to have been duly given: when received, if personally delivered and an acknowledgment in writing is obtained; when transmitted, if transmitted by e-mail; upon receipt of acknowledgement, if sent by registered post with acknowledgement due. 
                <br />
                In each case, notice will be sent to the respective addresses of the parties set forth below. Any change in the address of a party (as stated in clause 28.2 below) should be notified to the other party in a manner set out under this clause 28.1.
                <br /><br />
                (28.2) Any notice or other communication given to a party under or in connection with this agreement will be addressed to:
                <br /><br />
                If to Vibtree, as mentioned in our Contact Us Page.
                <br /><br />
                If to the Customer, as specified in the Company Info Page.            
            </p>
            {/*  Notices end */}

            {/*  Amendment start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                29. Amendment
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                We may amend this agreement (including any policies, such as the Privacy Policy that is incorporated into this agreement) at any time. Before making any amendment to the agreement, we will send you a notice of amendment via e-mail at least 30 (Thirty) days before the amendment becomes effective.
                <br />
                In the notice for amendment, we will inform you about your right to object to the amendment. Your failure to object to the amendment within 30 (Thirty) days of receipt of the said notice of amendment, will constitute your acceptance of the amendment to this agreement by us.            
            </p>
            {/*  Amendment end */}

            {/*  Waiver start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                30. Waiver
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                No failure or delay (in whole or in part) on the part of a party to exercise any right or remedy under this agreement will operate as a waiver thereof or affect any other right or remedy. The waiver of one breach or default or any delay in exercising any rights will not constitute a waiver of any subsequent breach or default.
            </p>
            {/*  Waiver end */}

            {/*  Vis Major start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                31. Vis Major
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                Except for the obligation related to payment of Charges, neither party will be responsible for any failure to perform or delay attributable in whole or in part to any cause beyond its reasonable control, including but not limited to acts of God, government actions, war, civil disturbance, insurrection, sabotage, shortage of energy, or equipment, disruption of communication network/(s) or cloud storage facilities (hereafter referred to as the "Vis Major Event"). Provided however, that if a Vis Major Event occurs, the affected party will, as soon as possible:
                 <br /><br />

                <ol  className='vib-res-left pp-information-list vib-res-left' style={{textAlign:'left !important'}}>
                    <li>Notify the other party of the happening of the Vis Major Event and its impact on the performance of the obligations of the affected party under this agreement; and</li>
                    <li>Use all reasonable efforts to resolve any issues resulting from the Vis Major Event and perform its obligations hereunder.</li>
                </ol>
            </p>
            {/*  Vis Major end */}

            {/*  Beta Release start */}
            <h2 className='vib-res-left vib-container-sub-heading vib-res-margin-top-50-50'>
                32. Beta Release
            </h2>
            <p className='vib-bold-para vib-res-left font-weight-300'>
                Vibtree may make new services available to you on a trial basis at no additional cost for a limited period of time (hereafter, "Trial Period") which will be determined at our sole discretion (hereafter, the "Beta Version Service").
                <br /><br />
                You understand and agree that the Beta Version Service does not form part of our Product. You also understand and agree that Vibtree has no obligations towards you and/or your authorized User(s) concerning the use of our Beta Version Service.
                <br /><br />
                While using a Beta Version Service, your experience may vary from time to time. We may make modifications to a Beta Version Service several times during the Trial Period so as to improve the customer experience.
                <br /><br />
                You and/or your authorized User(s) agree to use a Beta Version Service only in accordance with applicable law.
                <br /><br />
                You agree that once the Trial Period for a Beta Version Service is over and we choose to release the Beta Version Service as a regular service/feature of our Product (hereafter, the "New Feature"), the use of this New Feature by you will be chargeable at a price that will be notified to you before the release of the New Feature (hereafter, "New Feature Charge"). You understand and agree that if after the Trial Period, you choose to use the New Feature, the New Feature Charge will be added to your Pricing and Bill Plan (as defined in clause 4 of this agreement) automatically and this modification to the Pricing and Bill Plan will not be treated as a revision of charges under clause 4.3 (Charge Revision) of the agreement or a modification of this agreement.
                <br /><br />
                Depending upon the nature of a Beta Version Service or a New Feature, you may be required to adhere to certain specific terms and conditions with respect to any particular Beta Version Service or New Feature. We will notify you about such specific terms and conditions of use at the time of the release of a Beta Version Service or a New Feature. By using a Beta Version Service and/or a New Feature, you will be deemed to have consented to such specific terms and conditions of use of a Beta Version Service and/or a New Feature (as the case may be) and no written consent will be required.
            </p>
            {/* Beta Release end */}
            
    </div>
  )
}
