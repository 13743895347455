import React, { useContext, useEffect, useState } from 'react'
import './partnerWithUs.css'
import { Helmet } from 'react-helmet'

import PartnerWithUsPopUp from './PartnerWithUsPopUp'
import { vibContext } from 'src/App'
import LoaderComponent from 'src/LoaderComponent'

export default function PartnerWithUs() {
  const [modalShow, setModalShow] = useState(false);

  const [ImagesLoadCount, setImagesLoadCount] = useState(0);
  const [loader, setloader] = useState(true);

  const [form, setform] = useState({first_name:'',last_name:'',email:'',country_code:'',mobile:'',company:'',comments:''});

  const [ref_page_url, setref_page_url] = useState('');
  const [utm_source_s, setutm_source] = useState('');
  const [utm_medium_s, setutm_medium] = useState('');
  const [utm_campaign_s, setutm_campaign] = useState('');
  const [utm_term_s, setutm_term] = useState('');
  const [utm_content_s, setutm_content] = useState('');
  const [publisherid_s, setpublisherid] = useState('');


  let refferrerUrl=document.referrer
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    // var handleButton=()=>{
      setref_page_url(refferrerUrl)
      setutm_source(params.utm_source)
      setutm_medium(params.utm_medium)
      setutm_campaign(params.utm_campaign)
      setutm_term(params.utm_term)
      setutm_content(params.utm_content)
      setpublisherid(params.publisherid)
    // }
    // eslint-disable-next-line 
  }, []);
  
  const aboutContext= useContext(vibContext);
  const contextId=aboutContext.vibState;
  console.log('outside useEffect',contextId);
  useEffect(() => {
    const element = document.getElementById(contextId);
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0
      });
    }, 100);
  },[contextId]);

    // image loading spinner start 
    const imagesLoadIndicator=()=>{
      const images = document.getElementsByClassName('classForLoaded');
  
      setImagesLoadCount(ImagesLoadCount+1);
  
      if (ImagesLoadCount===images.length-1) {
        setloader(false)
      }
  
    }
    // image loading spinner end

  return (
    <div className='partner-outer-container' style={{overflow:'hidden'}}>
        <Helmet>
        <title>Story behind the growth of a top Cloud Telephony provider in Asia</title>
        {/* <link rel="icon" href={favicon} /> */}
        <meta name="description" content="Vibtree is among the top cloud-based business communication providers. Serving 1000+ businesses across 20+ countries, our services include- Voice, SMS, IVR, toll-free number, virtual numbers, call tracking, cloud calling and IVR flow builder" />
        <link rel="canonical" href="https://www.vibtree.com/company/partner-with-us/" />
        {/* og tags start  */}
        {/* <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Story behind the growth of a top Cloud Telephony provider in Asia" />
        <meta property="og:description" content="Vibtree is among the top cloud-based business communication providers. Serving 1000+ businesses across 20+ countries, our services include- Voice, SMS, IVR, toll-free number, virtual numbers, call tracking, cloud calling and IVR flow builder" />
        <meta property="og:url" content="https://www.vibtree.com/company/partner-with-us/"/>
        <meta property="og:site_name" content="Vibtree Technologies - Award winning cloud telephony service" />
        <meta property="og:image" content='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/partner-up.svg'/>
        <meta property="og:image:secure_url" content='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/partner-up.svg' />
        <meta property="og:image:width" content="700" />
        <meta property="og:image:height" content="700" />
        <meta property="og:image:alt" content="partner-with-us"/>
        <meta property="og:image:type" content="image/svg" /> */}
        {/* og tags end  */}
        {/* twitter tag start  */}
        {/* <meta name= "twitter:site" content="@vibtree"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Story behind the growth of a top Cloud Telephony provider in Asia" />
        <meta name="twitter:description" content="Vibtree is among the top cloud-based business communication providers. Serving 1000+ businesses across 20+ countries, our services include- Voice, SMS, IVR, toll-free number, virtual numbers, call tracking, cloud calling and IVR flow builder" />
        <meta name="twitter:image" content='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/partner-up.svg' /> */}
        {/* twitter tag end  */}
        <script  type="application/ld+json">
        {`{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "Vibtree Technologies",
            "legalName": "Vibtree Technologies",
            "alternateName": "www.vibtree.com",
            "description": "Vibtree is among the top cloud-based business communication providers. Serving 1000+ businesses across 20+ countries, our services include- Voice, SMS, IVR, toll-free number, virtual numbers, call tracking, cloud calling and IVR flow builder",
            "url": "https://www.vibtree.com/company/partner-with-us/",
            "logo": "https://vibtreedan.s3.eu-central-1.amazonaws.com/navbar/vibtree-logo.svg",
            "address":"2C, Astra Towers, ANO-314, 3rd Floor, 1, Action Area I, Newtown, Kolkata, West Bengal 700161",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "18005724590",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/vibtree",
              "https://twitter.com/vibtree",
              "https://www.instagram.com/vibtree",
              "https://www.youtube.com/channel/UCjX9ySvkmLZjJp_U9WdkByQ",
              "https://www.linkedin.com/company/vibtree",
              "https://www.vibtree.com"
            ]}`}
        </script>

        <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "vibtree"
                    }
                },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://www.vibtree.com/",
                        "name": "company"
                    }
                },{
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@id": "https://www.vibtree.com/company/partner-with-us/",
                        "name": "partner-with-us"
                    }
                }]
            }`}
        </script>
        
        </Helmet>
        {/* body schema tag start  */}
        <body itemScope itemType="https://schema.org/WebPage"></body>
        {/* body schema tag end  */}
        {loader===true ? <LoaderComponent/> : null}
        
      <div 
      className="vib-container partner-vib-banner" 
      style={{display:'flex',
              justifyContent:'space-evenly',
              alignItems:'flex-start',
              marginBottom:'100px',
              marginTop:'100px'}}>
        <div className="part-text">
          <h1 className='vib-container-heading vib-res-left'>
          Be Our Partners In <span className="vib-blue">Growth</span>
          </h1>
          <h5 className='vib-bold-para vib-res-left' style={{fontSize:'28px',lineHeight:'40px',fontWeight:'300'}}>
          The Vibtree channel partner program is designed to offer our partners added perks along with business growth.
          </h5>
          <div className="partner-tick" style={{marginTop:'50px'}}>
            <div className="partner-tick-inside">
              <img 
                   onLoad={imagesLoadIndicator} 
                   src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' 
                   className='classForLoaded' 
                   alt="tickmark"/>
              <p className='vib-bold-para' style={{fontSize:'17px'}}>Competitive commission rates</p>
            </div>
            <div className="partner-tick-inside">
              <img 
                  onLoad={imagesLoadIndicator} 
                  src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' 
                  className='classForLoaded' alt="tickmark"/>
              <p className='vib-bold-para' style={{fontSize:'17px'}}>Potential for business growth</p>
            </div>
            <div className="partner-tick-inside">
              <img 
                  onLoad={imagesLoadIndicator} 
                  src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' 
                  className='classForLoaded' 
                  alt="tickmark"/>
              <p className='vib-bold-para' style={{fontSize:'17px'}}>Customer base expansion</p>
            </div>
            <div className="partner-tick-inside">
              <img 
                  onLoad={imagesLoadIndicator} 
                  src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' 
                  className='classForLoaded' 
                  alt="tickmark"/>
              <p className='vib-bold-para' style={{fontSize:'17px'}}>Conversion rewards ensured</p>
            </div>
            <div className="partner-tick-inside">
              <img 
                  onLoad={imagesLoadIndicator} 
                  src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' 
                  className='classForLoaded' 
                  alt="tickmark"/>
              <p className='vib-bold-para' style={{fontSize:'17px'}}>Sales enablement & training</p>
            </div>
            <div className="partner-tick-inside">
              <img 
                  onLoad={imagesLoadIndicator} 
                  src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' 
                  className='classForLoaded' 
                  alt="tickmark"/>
              <p className='vib-bold-para' style={{fontSize:'17px'}}>24x7 technical assistance </p>
            </div>
            <div className="partner-tick-inside">
              <img 
                 onLoad={imagesLoadIndicator} 
                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' 
                 className='classForLoaded' 
                 alt="tickmark"/>
              <p className='vib-bold-para' style={{fontSize:'17px'}}>Advanced resources & support </p>
            </div>
            <div className="partner-tick-inside">
              <img 
                 onLoad={imagesLoadIndicator} 
                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' 
                 className='classForLoaded' 
                 alt="tickmark"/>
              <p className='vib-bold-para' style={{fontSize:'17px'}}>Increased profit margins </p>
            </div>
            <div className="partner-tick-inside">
              <img 
                 onLoad={imagesLoadIndicator} 
                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' 
                 className='classForLoaded' 
                 alt="tickmark"/>
              <p className='vib-bold-para' style={{fontSize:'17px'}}>Lifetime commissions </p>
            </div>
            <div className="partner-tick-inside">
              <img 
                 onLoad={imagesLoadIndicator} 
                 src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/tickmark.svg' 
                 className='classForLoaded' 
                 alt="tickmark"/>
              <p className='vib-bold-para' style={{fontSize:'17px'}}>No payment holdouts </p>
            </div>
          </div>
        </div>

        <div className="part-form">
          <h1 className="vib-container-sub-heading" style={{fontSize:'25px',fontWeight:'700',marginTop:'30px',marginBottom:'20px',textAlign:'center'}}>
            <span style={{color:'#0046BB'}}>Join Our </span>Channel Partner Program
          </h1>
          {/* eslint-disable-next-line  */}
          <form 
                className='part-form-outer'
                method='post' 
                name='EmailForm' 
                encType='multipart/form-data'
                autoComplete="false" 
                role="form" 
                action="https://launcher.vibtree.in/form/submit?formId=12" 
                id="mauticform_partnerwithus" 
                data-mautic-form="partnerwithus"
                partner-with-us 
                style={{padding:'0px',margin:'0px'}}>
          <div className="part-form-outer1">
          {/* const [form, setform] = useState({first_name:'',last_name:'',email:'',mobile:'',company:'',comments:''}); */}
            <input 
                   id="mauticform_input_partnerwithus_first_name" 
                   name="mauticform[first_name]" 
                   onChange={e=>{setform({first_name:e.target.value})}}
                   value={form.first_name} 
                   type="text" 
                   className='part-form-input mauticform-input' 
                   placeholder='First Name' 
                   required/>

            <input 
                  id="mauticform_input_partnerwithus_last_name" 
                  name="mauticform[last_name]" 
                  onChange={e=>{setform({last_name:e.target.value})}}
                  value={form.last_name} 
                  type="text" 
                  className='part-form-input mauticform-input' 
                  placeholder='Last Name' 
                  required/>

            <input 
                  id="mauticform_input_partnerwithus_email" 
                  name="mauticform[email]" 
                  onChange={e=>{setform({email:e.target.value})}}
                  value={form.email}
                  type="email" 
                  className='part-form-input mauticform-input' 
                  placeholder='Business Email' 
                  required/>

            <input 
                  id="mauticform_input_partnerwithus_company" 
                  name="mauticform[company]" 
                  onChange={e=>{setform({company:e.target.value})}}
                  value={form.company} 
                  type="text" 
                  className='part-form-input mauticform-input' 
                  placeholder='Company Name' 
                  required/>

            <input 
                   id="mauticform_input_partnerwithus_country_code" 
                   name="mauticform[country_code]" 
                   onChange={e=>{setform({country_code:e.target.value})}}
                   value={form.country_code} 
                   type="text"
                   className='part-form-input mauticform-input' 
                   placeholder='Country Code' 
                   required/>

            <input 
                   id="mauticform_input_partnerwithus_mobile" 
                   name="mauticform[mobile]" 
                   onChange={e=>{setform({mobile:e.target.value})}}
                   value={form.mobile} 
                   type="tel" 
                   className='part-form-input mauticform-input' 
                   placeholder='Phone Number' 
                   required/>
                <input id="mauticform_input_partnerwithus_ref_page_url" name="mauticform[ref_page_url]" value={ref_page_url} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_service" name="mauticform[service]" value="partner-with-us" className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_utm_source" name="mauticform[utm_source]" value={utm_source_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_utm_medium" name="mauticform[utm_medium]" value={utm_medium_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_utm_campaign" name="mauticform[utm_campaign]" value={utm_campaign_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_utm_term" name="mauticform[utm_term]" value={utm_term_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_utm_content" name="mauticform[utm_content]" value={utm_content_s} className="mauticform-hidden" type="hidden"/>
                <input id="mauticform_input_partnerwithus_publisherid" name="mauticform[publisherid]" value={publisherid_s} className="mauticform-hidden" type="hidden"></input>

          </div>
            <div className="part-form-outer2" style={{marginBottom:'30px'}}>
             <label htmlFor="part-comment" style={{color:'gray', fontWeight:'400'}}>Comments:</label>
             <textarea 
                      id="mauticform_input_partnerwithus_comments" 
                      name="mauticform[comments]" 
                      onChange={e=>{setform({comments:e.target.value})}}
                      value={form.comments}
                      cols="30" 
                      rows="7" 
                      className='part-text-comment mauticform-textarea'></textarea>
             <div className="part-button-container" style={{width:'100%'}}>
             <button 
                    //  onClick={handleButton}
                     type='submit' 
                     className="hvr-bounce-to-bottom1 design1 mauticform-button btn btn-default" 
                     name="mauticform[submit]" 
                     value=""
                     id="mauticform_input_partnerwithus_submit" 
                     style={{height:'40px',width:'30%', fontSize:'22px', fontWeight:'500',float:'right'}}>
              <span style={{position:'relative',top:'-6px'}}>Submit</span>
             </button>
             </div>
            </div>
               <input type="hidden" name="mauticform[formId]" id="mauticform_partnerwithus_id" value="12"/>
               <input type="hidden" name="mauticform[return]" id="mauticform_partnerwithus_return" value=""/>
               <input type="hidden" name="mauticform[formName]" id="mauticform_partnerwithus_name" value="partnerwithus"></input>
          </form>

        </div>

      </div>


      <div className="vib-container partner-up-now" style={{textAlign:'center'}}>

      <div className="partner-center-cover" style={{textAlign:'center',width:'100%'}}>
      <h1 className='vib-container-heading vib-res-center'>
        Partner-Up <span style={{color:'#0046BB'}}>Now!</span> 
      </h1>
      </div>
      
      <div className="partup">
          <div 
          className="partup-text vib-bold-para vib-res-left margin-right-res" 
          style={{fontSize:'28px',
                  fontWeight:'300',
                  lineHeight:'40px',
                  maxWidth:'650px'}}>
          We understand what different businesses need to accomplish their goals of success and 
          hence we are committed to helping our partners in their bid to offer top-class & highly 
          reliable services to their customers. <br /> <br />

          We have in place a partner's program that offers our channel & referral partners 
          exclusive & creative solutions for cloud-based phone systems and other cloud telephony 
          services like cloud-PBX etc.
          </div>
          <img onLoad={imagesLoadIndicator} 
               src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/partner-up.svg' 
               alt="partnerWithUsFirst" 
               className='be-our-partners-in-growth classForLoaded'/>

      </div>

      </div>

      <div className="part-join-now">

       <div className="part-join-now-left">
        <h1 className='vib-container-sub-heading vib-res-left'>
        <span className='blue-under-line' style={{paddingBottom:' 15px',color:'white',fontSize:'33px'}}>
        Our goal is to help our partners progress the way we do!
        </span> 
        </h1>
        <a  
            href 
            className="hvr-bounce-to-bottom1 design1" 
            style={{
              backgroundColor:'#FFCE42',
              color:'black',
              fontWeight:'300',
              fontSize:'26px',
              padding:'5px',
              width:'200px',
              marginBottom:'50px'}}
            onClick={() => setModalShow(true)}>
        Join Now
        </a>
        <PartnerWithUsPopUp
              show={modalShow}
              onHide={() => setModalShow(false)}
        />
       </div>
         
       <div className="part-join-now-right">
        <img 
            onLoad={imagesLoadIndicator} 
            src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/our-goal-is-to-help-our-partners-progress-the-way-we-do.svg' 
            alt="goal" 
            className="part-join-now-right-container classForLoaded"/>
       </div>
      
      </div>

      <div className="vib-container">

        <div className="partner-center-cover" style={{textAlign:'center',width:'100%'}}>
        <h1 className='vib-container-heading vib-res-center'>
          Why Partner With <span style={{color:'#0046BB'}}>Vibtree?</span> 
        </h1>
        </div>
        
        {/* why partner start  */}

        <div className="part-why-inner">

          <div className="part-why-left">

             <div className="part-vib-res-cards-l-to-c">
               <img 
                     onLoad={imagesLoadIndicator} 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/complete-cloud-telephony-product-suite.svg'
                     alt="complete-cloud-telephony-product-suite" 
                     className='partner-why-part classForLoaded' 
                     style={{padding:'26px'}} />
               <h4 
               className='vib-sub-sub-heading' 
               style={{fontSize:'18px',
                       marginTop:'0px',
                       marginBottom:'5px'}}>
               Complete Cloud Telephony Product Suite
               </h4>
               <p>Vibtree offers a complete suite of cloud telephony products that resolve 
               all the problem areas of your customers and help you serve your market better.
               </p>
             </div>  

             <div className="part-why-cus part-vib-res-cards-l-to-c">
               <img 
                     onLoad={imagesLoadIndicator} 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/customized-products-and-services.svg'
                     alt="customized-products-and-services" 
                     className='partner-why-part classForLoaded'/>
               <h4 
               className='vib-sub-sub-heading' 
               style={{fontSize:'18px',
                      marginTop:'0px',
                      marginBottom:'5px',
                      textAlign:'left'}}>
               Customized Products & Services</h4>
               <p>
               We enable our channel partners to offer services to their customers that meet 
               all their needs and that is why we allow them to customize the services 
               according to different clients. 
               </p>
             </div>

          </div>

          <div className="part-why-middle">

             <div className="part-why-middle-img">
               <img 
                     onLoad={imagesLoadIndicator} 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/vibtree.svg'
                     alt="vibtree" 
                     className='partner-why-part-middle classForLoaded' 
                     style={{marginLeft:'10px'}} />
             </div>

             <div className="part-why-cred">
               <img 
                     onLoad={imagesLoadIndicator} 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/customer-credibility.svg' 
                     alt="customer-credibility" 
                     className='partner-why-part classForLoaded' 
                     style={{padding:'20px'}}/>
               <h4 
                  className='vib-sub-sub-heading' 
                  style={{fontSize:'18px',marginTop:'0px',marginBottom:'5px'}}>
                  Customer Credibility
               </h4>
               <p style={{textAlign:'center'}}>
               Being associated with a large vendor like Vibtree offers the benefit 
               of gaining reputation, name recognition and market credibility.
               </p>
             </div>

          </div>

          <div className="part-why-right">

             <div className="part-why-right part-vib-res-cards-r-to-c">
               <img 
                     onLoad={imagesLoadIndicator} 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/increased-profit-margin.svg'
                     alt="increased-profit-margin" 
                     className='partner-why-part classForLoaded' 
                     style={{padding:'25px'}}/>
               <h4 
                   className='vib-sub-sub-heading' 
                   style={{fontSize:'18px',marginTop:'0px',marginBottom:'5px'}}>
                  Increased Profit Margin
               </h4>
               <p>We always focus on the benefit of our partners as we love to 
               see them grow. Based on the sales done by our partners, 
               we offer them additional discounts and increased revenue 
               opportunities.
               </p>
             </div>

             <div className="part-why-right part-vib-res-cards-r-to-c">
               <img 
                     onLoad={imagesLoadIndicator} 
                     src='https://vibtreedan.s3.eu-central-1.amazonaws.com/partner-with-us/advanced-resources-and-support.svg'
                     alt="advanced-resources-and-support" 
                     className='partner-why-part classForLoaded'/>
               <h4 
                     className='vib-sub-sub-heading' 
                     style={{fontSize:'18px',marginTop:'0px',marginBottom:'5px'}}>
                     Advanced Resources & Support
               </h4>
               <p>
               Vibtree offers its channel partners to access our full range of technical 
               and financial resources that include product & market training, campaign 
               templates, marketing assistance, co-opted funds support, technical support 
               and much more. 
               </p>
             </div>

          </div>
        </div>
      </div>
        
    </div>
  )
}
