import React, { useContext, useEffect } from 'react'
import { Helmet } from "react-helmet";
import './voice.css'

import { Accordion, Card } from 'react-bootstrap'
import { useState } from 'react'
import { vibContext } from 'src/App';
import VoiceSlider from './VoiceSlider';
import LoaderComponent from 'src/LoaderComponent';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Group from './../../images/Group.png'
import Voicelaptop from './Voicelaptop';
import Voicemobile from './Voicemobile';

function getWindowDimensions() {
  const { innerWidth: widthprice, innerHeight: height } = window;
  return {
    widthprice,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
export default function Voice() {
  const [accFirst, setaccFirst] = useState('yes');
  const [accSecond, setaccSecond] = useState('no');
  const [accThird, setaccThird] = useState('no');
  const [accFourth, setaccFourth] = useState('no');
  const [accFive, setaccFive] = useState('no');
  const [accSix, setaccSix] = useState('no');
  const { height, widthprice } = useWindowDimensions();
  const [ImagesLoadCount, setImagesLoadCount] = useState(0);
  const [loader, setloader] = useState(true);

  const voiceContext = useContext(vibContext);
  const contextId = voiceContext.vibState;
  useEffect(() => {
    const element = document.getElementById(contextId);
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0
      });
    }, 100);
  }, [contextId]);

  var handleClick1 = () => {
    setaccSecond('no')
    setaccThird('no')
    setaccFourth('no')
    setaccFive('no')
    setaccSix('no')
    return (
      accFirst === 'yes' ? setaccFirst('no') : accFirst === 'no' ? setaccFirst('yes') : null
    )
  }
  var handleClick2 = () => {
    setaccFirst('no');
    setaccThird('no');
    setaccFourth('no');
    setaccFive('no');
    setaccSix('no');
    return (
      accSecond === 'yes' ? setaccSecond('no') : accSecond === 'no' ? setaccSecond('yes') : null
    )
  }
  var handleClick3 = () => {
    setaccFirst('no');
    setaccSecond('no');
    setaccFourth('no');
    setaccFive('no');
    setaccSix('no');
    return (
      accThird === 'yes' ? setaccThird('no') : accThird === 'no' ? setaccThird('yes') : null
    )
  }
  var handleClick4 = () => {
    setaccFirst('no');
    setaccSecond('no');
    setaccThird('no');
    setaccFive('no');
    setaccSix('no');
    return (
      accFourth === 'yes' ? setaccFourth('no') : accFourth === 'no' ? setaccFourth('yes') : null
    )
  }
  var handleClick5 = () => {
    setaccFirst('no');
    setaccSecond('no');
    setaccThird('no');
    setaccFourth('no');
    setaccSix('no');
    return (
      accFive === 'yes' ? setaccFive('no') : accFive === 'no' ? setaccFive('yes') : null
    )
  }
  var handleClick6 = () => {
    setaccFirst('no')
    setaccSecond('no')
    setaccThird('no')
    setaccFourth('no')
    setaccFive('no')
    return (
      accSix === 'yes' ? setaccSix('no') : accSix === 'no' ? setaccSix('yes') : null
    )
  }

  // image loading spinner start 
  const imagesLoadIndicator = () => {
    const images = document.getElementsByClassName('classForLoaded');

    setImagesLoadCount(ImagesLoadCount + 1);

    if (ImagesLoadCount === images.length - 1) {
      setloader(false)
    }

  }
  // image loading spinner end

  return (
    <>
      {
        widthprice > 600 ? <>
          <Voicelaptop />
        </> : <>
          <Voicemobile />
        </>
      }
    </>
  )
}
